import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import container from 'styles/mixins/container';
import media from 'styles/mixins/media';

const Section = styled.section`
  padding: ${rem('25px')} 0 0;
  text-align: center;
  margin-bottom: 50px;

  ${media.bpsMedium`
    padding: ${rem('40px')} 0 0;
  `} ${media.bpsLarge`
    padding: ${rem('65px')} 0 0;
  `};
`;

const Container = styled.div`
  ${container} ${media.bpsLarge`
    padding-left: ${rem('100px')};
    padding-right: ${rem('100px')};
  `};
`;

const Heading = styled.h2`
  font-size: ${rem('18px')};
  font-weight: 400;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  margin: 0 0 ${rem('25px')};
  color: ${props => props.theme.mediumGrey};

  ${media.bpsMedium`
    font-size: ${rem('28px')};
    margin: 0 0 ${rem('30px')};
  `} ${media.bpsLarge`
    margin: 0 0 ${rem('60px')};
  `};
`;

const OrangeText = styled.span`
  color: ${props => props.theme.orange};
`;

const Image = styled.img`
  display: none;

  ${media.bpSmall`
    width: 100%;
    display: block;
    max-width: ${rem('475px')};
    margin: 0 auto;
  `} ${media.bpsLarge`
    max-width: none;
  `};
`;

export { Section, Container, Heading, OrangeText, Image };
