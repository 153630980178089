/**
 * Pure function to update the values of the offerDetails prop
 */
function offerDetails(state = {}, action) {
  if (action.type === 'GET_OFFER') {
    return { ...state, ...action.data };
  }

  return state;
}

export default offerDetails;
