import React from 'react';

import Wrapper from './Wrapper';
import Container from './Container';
import Scroller from './Scroller';
import Car from './Car';
import PulseDot from './PulseDot';
import Tooltip from './Tooltip';

const CarScroller = ({ cars, selectedCar, inputHasValue }) => (
  <Wrapper>
    <Container>
      <Scroller {...{ inputHasValue, selectedCar }}>
        {[...cars, ...cars, ...cars].map((car, index) => {
          if (index > 5 && index < 12) {
            return (
              <Car
                {...{
                  inputHasValue,
                  selectedCar,
                  selected: selectedCar === (index % 6) + 1,
                }}
                key={index}
              >
                <Tooltip
                  {...{
                    inputHasValue,
                    selected: selectedCar === (index % 6) + 1,
                  }}
                >
                  {car.title}
                </Tooltip>
                <PulseDot
                  {...{
                    inputHasValue,
                    selected: selectedCar === (index % 6) + 1,
                  }}
                />
                <img alt={car.title} src={car.image} />
              </Car>
            );
          }

          return (
            <Car {...{ inputHasValue, selectedCar }} key={index}>
              <img alt={car.title} src={car.image} />
            </Car>
          );
        })}
      </Scroller>
    </Container>
  </Wrapper>
);

export default CarScroller;
