import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

export default styled.p`
  display: flex;
  align-items: center;
  color: ${props => props.theme.orange};
  font-size: ${rem('16px')};
  font-weight: 500;
  margin-bottom: ${rem('15px')};

  ${media.bpTablet`
    margin-bottom: ${rem('20px')};
  `};
`;
