import styled from 'styled-components';

import media from '../../../styles/mixins/media';

const Dealer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  left: 0;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.17);
  padding: 1rem;
  z-index: 10;

  ${media.bpSmall`
    position: relative;
    bottom: auto;
    left: auto;
    box-shadow: none;
    padding:0;
  `}
`;

export default Dealer;
