import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Form = styled.form`
  max-width: ${rem('320px')};
  margin-left: auto;
  margin-right: auto;
`;

export default Form;
