import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.nav`
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  width: ${props => rem(props.theme['off-canvas-width'])};
  left: -${props => rem(props.theme['off-canvas-width'])};
  top: 0;
  bottom: 0;
  background: ${props => props.theme.lighterGrey};
  text-align: center;
  z-index: 1;
`;

export default Wrapper;
