import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  padding-bottom: ${rem('20px')};
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  height: 100%;
`;

export default Wrapper;
