/**
 * Pure function for populating the searchDescription prop
 */
function searchDescription(state = '', action) {
  if (action.type === 'SEARCH_RESPONSE') {
    return action.data.searchDescription || state;
  }

  return state;
}

export default searchDescription;
