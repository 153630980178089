import styled, { css } from 'styled-components';
import { transition } from '@oodlefinance/ui-library/utils';
import { hideVisually } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const CHECKBOX_WIDTH = '2rem';
const SMALL_CHECKBOX_WIDTH = '0.75rem';
export const Checkbox = styled.label(
  ({ theme, small }) => css`
    ${transition('background-color', 'border-color')}
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: ${small ? SMALL_CHECKBOX_WIDTH : CHECKBOX_WIDTH};
    height: ${small ? SMALL_CHECKBOX_WIDTH : CHECKBOX_WIDTH};
    margin-right: 1rem;
    color: ${theme.white};
    font-size: ${small && '0.45rem'};
    border: 1px solid ${theme.grey};
    border-radius: 4px;
    content: '';
  `,
);

export const LabelAndErrorWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input(
  ({ theme }) => css`
    ${hideVisually()};

    :checked {
      + ${Checkbox} {
        background-color: ${theme.orange};
        border-color: ${theme.orange};
      }
    }

    :disabled {
      + ${Checkbox}, ~ ${LabelAndErrorWrapper} {
        opacity: 0.5;
      }
    }
  `,
);
