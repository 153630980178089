import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  font-weight: 300;
  color: ${props =>
    props.highlight ? props.theme.blue : props.theme.mediumGrey};

  ${media.bpTablet`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
  `};
`;

export default Text;
