import React from 'react';
import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${rem('1150px')};
  margin: 0 auto;
`;

const SignUpContainer = styled.div`
  background: ${props => props.theme.lighterGrey};
  padding: ${rem('25px')} ${rem('18px')};
  margin-bottom: ${rem('25px')};

  ${media.bpsMedium`
    padding-top: ${rem('40px')};
    padding-bottom: ${rem('40px')};
  `} ${media.bpsLarge`
    width: ${rem('375px')};
    position: absolute;
    top: ${rem('-232px')};
    right: 0;
    padding: ${rem('38px')} ${rem('45px')};
    border-radius: ${rem('4px')};
    border: 1px solid #ebebeb;
    box-shadow: 2px 1px 10px 0px rgba(0,0,0,0.2);
  `};
`;

const SignUp = styled.h2`
  font-size: ${rem('21px')};
  font-weight: 500;
  color: ${props => props.theme.blue};
  margin-top: 0;
  margin-bottom: ${rem('30px')};

  ${media.bpsLarge`
    font-size: ${rem('23px')};
  `};
`;

const Form = styled.form`
  max-width: ${rem('475px')};
  margin: 0 auto;
`;

const Input = styled.input`
  height: ${rem('40px')};
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: ${rem('4px')};
  margin-bottom: ${rem('20px')};
  font-size: ${rem('15px')};
  color: ${props => props.theme.mediumGrey};
  padding: ${rem('12px')};
  width: 100%;
`;

const Submit = styled.input`
  height: ${rem('45px')};
  font-size: ${rem('15px')};
  color: white;
  width: 100%;
  background: linear-gradient(#1097ec, #0d80c9);
  border: none;
  border-radius: ${rem('4px')};
`;

const SignupForm = () => (
  <FormContainer>
    <SignUpContainer>
      <SignUp>Download the FREE report!</SignUp>
      <div id="mc_embed_signup">
        <Form
          action="//carsnip.us10.list-manage.com/subscribe/post?u=fe07bd18af2368b4fedef549f&amp;id=3b7202952d"
          className="validate"
          id="mc-embedded-subscribe-form"
          method="post"
          name="mc-embedded-subscribe-form"
          noValidate
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <Input
                className="required email"
                defaultValue=""
                id="mce-EMAIL"
                name="EMAIL"
                placeholder="* Email Address"
                type="email"
              />
            </div>
            <div className="mc-field-group">
              <Input
                defaultValue=""
                id="mce-WEBSITE"
                name="WEBSITE"
                placeholder="Dealer Website"
                type="text"
              />
            </div>
            <div className="mc-field-group">
              <Input
                defaultValue=""
                id="mce-FNAME"
                name="FNAME"
                placeholder="First Name"
                type="text"
              />
            </div>
            <div className="mc-field-group">
              <Input
                defaultValue=""
                id="mce-LNAME"
                name="LNAME"
                placeholder="Last Name"
                type="text"
              />
            </div>
            <div className="clear" id="mce-responses">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>{' '}
            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
            <div
              aria-hidden="true"
              style={{ position: 'absolute', left: '-5000px' }}
            >
              <input
                name="b_fe07bd18af2368b4fedef549f_3b7202952d"
                tabIndex="-1"
                type="text"
                value=""
              />
            </div>
            <div className="clear">
              <Submit
                className="button"
                id="mc-embedded-subscribe"
                name="subscribe"
                type="submit"
                value="Submit"
              />
            </div>
          </div>
        </Form>
      </div>
    </SignUpContainer>
  </FormContainer>
);

export default SignupForm;
