import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const PolicyLinks = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${rem('390px')}) {
    margin-left: auto;
  }
`;

export default PolicyLinks;
