import React from 'react';

import styled from 'styled-components';

import {
  StyledHero,
  Container,
  Heading,
  Text,
  Hr,
  FadedText,
} from './HeroStyles';
import SignupForm from './SignupForm';
import Option from './Option';

const Tel = styled.p`
  margin: 0 0 20px 0;
  text-align: center;

  a {
    color: ${props => props.theme.mediumGrey};
  }
`;

const Hero = () => (
  <StyledHero id="signup-form">
    <Container>
      <Heading>Sell more cars &amp; reduce your costs</Heading>
      <Text>
        Carsnip is the UK’s largest car search engine and it’s always FREE to
        list your stock.
      </Text>
      <Text>
        Upgrade to Carsnip Premium and boost your stock above your competition.
        100% performance based, you only ever pay for clicks on your adverts.
      </Text>
      <Text>
        Our goal is to get your stock in front of as many potential buyers as
        possible, generate visits to your website, and ultimately help you sell
        more cars.
      </Text>
      <Text>Enquire now to start boosting your sales today!</Text>
    </Container>
    <SignupForm />
    <Container>
      <Option
        heading="Carsnip Premium"
        image="premium"
        text="Our Pay Per Click product drives visitors direct to your website car pages. The higher you set your click
            price, the higher your adverts will appear in search results."
      />
      <Hr />
      <FadedText>
        Carsnip is the UK’s largest car search engine and it’s always FREE to
        list your stock
      </FadedText>
      <Tel>
        Got a question? Call us on <a href="tel:01157270688">0115 727 0688</a>
      </Tel>
    </Container>
  </StyledHero>
);

export default Hero;
