const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * converts a date/time string to a human readable format
 */
const formatDate = (date = '') => {
  // Reject the given date if it's not a string
  if (typeof date !== 'string') {
    return '';
  }

  // Reject if it's not a valid date
  if (Number.isNaN(Date.parse(date))) {
    return '';
  }

  // Try and get the date
  const dateObject = new Date(date);

  // Get the day, month and year
  const day = dateObject.getDate();
  const month = months[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  return `${day} ${month} ${year}`;
};

export default formatDate;
