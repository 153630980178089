import React from 'react';
import PropTypes from 'prop-types';
import { Tick } from '@oodlefinance/ui-library/icons';

import ValidationError from '../ValidationError';
import Label from '../Label';

import { Checkbox, Wrapper, Input, LabelAndErrorWrapper } from './style';

const CheckboxInput = ({
  error,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  small,
  value,
}) => {
  return (
    <Wrapper>
      <Input
        checked={value}
        disabled={isDisabled}
        id={name}
        onChange={onChange}
        readOnly={!onChange}
        required={isRequired}
        type="checkbox"
      />
      <Checkbox aria-hidden="true" htmlFor={name} small={small}>
        <Tick />
      </Checkbox>
      <LabelAndErrorWrapper>
        <Label htmlFor={name} isRequired={isRequired} small={small}>
          {label}
        </Label>
        {error && <ValidationError>{error}</ValidationError>}
      </LabelAndErrorWrapper>
    </Wrapper>
  );
};

CheckboxInput.defaultProps = {
  small: false,
  error: undefined,
  isDisabled: false,
  isRequired: false,
  onChange: undefined,
};

CheckboxInput.propTypes = {
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  small: PropTypes.bool,
  value: PropTypes.bool.isRequired,
};

export default CheckboxInput;
