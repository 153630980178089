import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Button = styled.a`
  display: inline-block;
  font-size: ${rem('15px')};
  text-decoration: none;
  color: white;
  background: ${props => props.theme.orange};
  border: none;
  border-radius: ${rem('4px')};
  padding: ${rem('14px')} ${rem('100px')};

  ${media.bpTablet`
    font-size: ${rem('18px')};
    padding: ${rem('16px')} ${rem('110px')};
  `};
`;

export default Button;
