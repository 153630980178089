import React from 'react';

import Body from './Body';
import Text from './Text';
import Cancel from './Cancel';
import Footer from './Footer';
import OrangeText from './OrangeText';
import SpeechBubble from './SpeechBubble';
import MarketingOption from './MarketingOption';
import ContactText from './ContactText';
import ContactOptions from './ContactOptions';
import ContactOption from './ContactOption';
import SaveButton from './SaveButton';
import Error from './Error';

import Modal from 'shared/Modal';

class MarketingPrefs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };

    this.saveSettings = this.saveSettings.bind(this);
  }

  saveSettings() {
    const {
      onClose,
      contactEmail,
      contactSMS,
      contactPhone,
      contactPost,
    } = this.props;

    // If at least one of the values are selected then we can continue
    if ([contactEmail, contactPhone, contactPost, contactSMS].some(v => v)) {
      this.setState({
        error: false,
      });

      onClose();
    }

    this.setState({
      error: true,
    });
  }

  render() {
    const {
      isOpen,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
      contactPost,
      toggleCheckbox,
      onClose,
      onCancel,
      advertTitle,
    } = this.props;
    const { error } = this.state;

    if (advertTitle !== '') {
      return (
        <Modal
          isOpen={isOpen}
          onCancel={onCancel}
          header="Interested in receiving offers on a car like this {advertTitle}?"
          orangeHeader
          contentLabel="Marketing Preferences"
          hasFooter
        >
          <ContactText>
            Carsnip can match you with sellers and finance providers to help you
            purchase your next car.
          </ContactText>
          <Text>
            If you are happy for Carsnip to share your details with automotive
            funding and retail partners, simply choose your contact preferences
            below:
          </Text>
          <ContactOptions>
            <ContactOption>
              <label htmlFor="contactEmail">
                <input
                  checked={contactEmail}
                  id="contactEmail"
                  onChange={toggleCheckbox.bind(this, 'contactEmail')}
                  type="checkbox"
                />
                Email
              </label>
            </ContactOption>
            <ContactOption>
              <label htmlFor="contactSMS">
                <input
                  checked={contactSMS}
                  id="contactSMS"
                  onChange={toggleCheckbox.bind(this, 'contactSMS')}
                  type="checkbox"
                />
                SMS
              </label>
            </ContactOption>
            <ContactOption>
              <label htmlFor="contactPhone">
                <input
                  checked={contactPhone}
                  id="contactPhone"
                  onChange={toggleCheckbox.bind(this, 'contactPhone')}
                  type="checkbox"
                />
                Phone
              </label>
            </ContactOption>
            <ContactOption>
              <label htmlFor="contactPost">
                <input
                  checked={contactPost}
                  id="contactPost"
                  onChange={toggleCheckbox.bind(this, 'contactPost')}
                  type="checkbox"
                />
                Post
              </label>
            </ContactOption>
          </ContactOptions>
          {error && (
            <Error>
              Please select an option above so we know how best to contact you
            </Error>
          )}
          <SaveButton onClick={this.saveSettings}>
            Yes, save my settings
          </SaveButton>
          <Cancel onClick={onCancel}>No thanks, close</Cancel>
          <Footer>
            <p>
              You can unsubscribe from our third party marketing emails at any
              time
            </p>
          </Footer>
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={isOpen}
        contentLabel="Marketing Preferences"
        header="Can we keep in touch?"
      >
        <Body>
          <OrangeText>
            <SpeechBubble /> Yes, I'm happy for Carsnip to...
          </OrangeText>
          <MarketingOption>
            <label htmlFor="marketingSearch">
              <input
                checked={marketingSearch}
                id="marketingSearch"
                onChange={toggleCheckbox.bind(this, 'marketingSearch')}
                type="checkbox"
              />
              Contact me about my car search
            </label>
          </MarketingOption>
          <MarketingOption>
            <label htmlFor="marketingNewFeatures">
              <input
                checked={marketingNewFeatures}
                id="marketingNewFeatures"
                onChange={toggleCheckbox.bind(this, 'marketingNewFeatures')}
                type="checkbox"
              />
              Inform me about new features that may be of interest to me
            </label>
          </MarketingOption>
          <ContactText>Carsnip can contact me by:</ContactText>
          <ContactOptions>
            <ContactOption>
              <label htmlFor="contactEmail">
                <input
                  checked={contactEmail}
                  id="contactEmail"
                  onChange={toggleCheckbox.bind(this, 'contactEmail')}
                  type="checkbox"
                />
                Email
              </label>
            </ContactOption>
            <ContactOption>
              <label htmlFor="contactSMS">
                <input
                  checked={contactSMS}
                  id="contactSMS"
                  onChange={toggleCheckbox.bind(this, 'contactSMS')}
                  type="checkbox"
                />
                SMS
              </label>
            </ContactOption>
            <ContactOption>
              <label htmlFor="contactPhone">
                <input
                  checked={contactPhone}
                  id="contactPhone"
                  onChange={toggleCheckbox.bind(this, 'contactPhone')}
                  type="checkbox"
                />
                Phone
              </label>
            </ContactOption>
          </ContactOptions>
          <SaveButton onClick={onClose}>Save</SaveButton>
        </Body>
      </Modal>
    );
  }
}

MarketingPrefs.defaultProps = {
  advertTitle: '',
};

export default MarketingPrefs;
