import styled, { css } from 'styled-components';

const Transformer = styled.div`
  height: 100%;
  background: #fff;
  transition: 200ms ease-in-out;

  ${props =>
    props.open
      ? css`
          transform: translateX(${props.theme['off-canvas-width']});
          position: fixed;
          width: 100%;
        `
      : ''};
`;

export default Transformer;
