/**
 * Pure function to update the values of the vrmLookup results prop
 */
function vrmLookupDetails(state = {}, action) {
  switch (action.type) {
    case 'VALUE_VRM_LOOKUP':
    case 'SELL_VRM_LOOKUP':
    case 'VRM_LOOKUP':
    case 'CAR_VALUATION':
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export default vrmLookupDetails;
