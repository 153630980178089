import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Links = styled.section`
  @media (min-width: ${rem('515px')}) {
    display: flex;
    flex-wrap: wrap;
    max-width: ${rem('479px')};
    margin: 0 auto;
  }

  @media (min-width: ${rem('888px')}) {
    max-width: none;
  }

  ${media.bpTablet`
    max-width: ${rem('680px')};
    width: 100%;
  `}
`;

export default Links;
