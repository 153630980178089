import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const SearchWrapper = styled.div`
  width: ${rem('290px')};
  border-radius: ${rem('10px')};
  margin: ${rem('25px')} auto 0;

  ${media.bpsMedium`
    width: ${rem('610px')};
  `} ${media.bpTablet`
    margin-top: ${rem('45px')};
  `};
`;

export default SearchWrapper;
