import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Header = styled.h1(
  props => css`
    font-size: ${rem('21px')};
    font-family: ${props.theme['main-header-font']}, sans-serif;
    margin: ${props.emailSent ? rem('25px') : rem('30px')} auto 0;
    color: ${props.theme.mediumGrey};

    ${media.bpTablet`
    font-size: ${props.emailSent ? rem('22px') : rem('50px')};
    margin: ${rem('120px')} auto 0;
    ${
      props.mobile
        ? css`
            display: none;
          `
        : ''
    }
    ${
      props.emailSent
        ? css`
            margin: ${rem('60px')} auto 0;
          `
        : ''
    }
  `};
  `,
);

export default Header;
