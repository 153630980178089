import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('41px')};
  height: ${rem('31px')};

  ${media.bpTablet`
    width: ${rem('82px')};
    height: ${rem('62px')};
  `}
`;

const Path1 = styled.path`
  fill: #ead8c5;
`;

const Path2 = styled.path`
  fill: #e6e6e6;
`;

const Path3 = styled.path`
  fill: #b3b3b3;
`;

class ExpertsIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 76.2 68.1" x="0px" y="0px">
        <g>
          <Path1
            d="M9.7,68c-0.5,0-1.1-0.1-1.6-0.2l-6.9-1.7C0.5,65.9,0,65.2,0,64.5V1.6C0,0.7,0.7,0,1.6,0h3.2
          c6.2,0,11.3,5.1,11.3,11.3v9l8.4,8.4c1.3,1.3,1.7,3,1.2,4.7c-0.5,1.7-1.8,3-3.5,3.4l-6.2,1.5v6.8c0,0.7-0.4,1.3-1,1.5l-4.3,1.8
          c-0.9,0.4-1,1.2-1,1.5s0.1,1.1,1,1.5l4.4,2c0.6,0.3,1,0.8,1,1.5v6.5c0,2-0.9,3.9-2.5,5.1C12.5,67.5,11.2,68,9.7,68z"
          />
          <Path2
            d="M68,51.8H25.9c-0.6,0-1.2-0.3-1.4-0.9c-0.3-0.5-0.2-1.2,0.2-1.7L34,36.7V17.8c0-4.5,3.6-8.1,8.1-8.1H68
          c4.5,0,8.1,3.6,8.1,8.1v25.9C76.1,48.2,72.5,51.8,68,51.8z"
          />
          <Path3
            d="M64.8,25.9H45.3c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h19.4c0.9,0,1.6,0.7,1.6,1.6
          S65.6,25.9,64.8,25.9z"
          />
          <Path3 d="M55,38.9h-9.7c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6H55c0.9,0,1.6,0.7,1.6,1.6S55.9,38.9,55,38.9z" />
        </g>
      </Svg>
    );
  }
}

export default ExpertsIcon;
