import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Header = styled.div`
  min-height: ${rem('460px')};
  padding-top: ${rem('80px')};
  text-align: center;
  position: relative;
  border-bottom: ${rem('1px')} solid #e4e4e4;
  margin-bottom: ${rem('35px')};

  ${media.bpTablet`
    padding-top: ${rem('160px')};
    height: ${rem('768px')};
    margin-bottom: ${rem('80px')};
  `}

  @media (min-width: ${rem('2000px')}), (min-height: ${rem('1200px')}) {
    max-height: ${rem('1200px')};
  }
`;

export default Header;
