import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import errorHandler from './middleware/errorHandler';
import reducers from './reducers';

// Function that will configure the store
export default function configureStore(initialState) {
  // Create the enhancers for the store
  const enhancers = compose(
    // Allows us to do async actions
    applyMiddleware(thunk, errorHandler),
    // Gives the redux extension access to the app
    window.devToolsExtension &&
      typeof window.location === 'object' &&
      window.location.hostname === 'localhost'
      ? window.devToolsExtension()
      : f => f,
  );

  // Create the store
  return createStore(reducers, initialState, enhancers);
}
