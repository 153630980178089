/**
 * Function to toggle the showAdditionalFields flag
 */
function toggleAdditionalFields(value) {
  return {
    type: 'UPDATE_FLAG',
    data: { showAdditionalFields: value },
  };
}

export { toggleAdditionalFields };
