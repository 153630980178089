import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// Import the reducers
import activeFilters from './activeFilters';
import searchTitle from './searchTitle';
import searchDescription from './searchDescription';
import searchId from './searchId';
import filters from './filters';
import adverts from './adverts';
import advert from './advert';
import pages from './pages';
import rangeSliderValues from './rangeSliderValues';
import selectedFilter from './selectedFilter';
import sortOptions from './sortOptions';
import totalHits from './totalHits';
import totalCrawlAdverts from './totalCrawlAdverts';
import searchString from './searchString';
import showFilterMenu from './showFilterMenu';
import showMobileHeader from './showMobileHeader';
import alternateSearches from './alternateSearches';
import displayFilters from './displayFilters';
import showMobileSaveSearch from './showMobileSaveSearch';
import notificationMessage from './notificationMessage';
import vrmLookupDetails from './vrmLookupDetails';
import valuation from './valuation';
import features from './features';
import offerDetails from './offerDetails';
import notFoundFlag from './notFoundFlag';
import cookiePolicyAgreed from './cookiePolicyAgreed';
import currentlyFetching from './currentlyFetching';
import suggestions from './suggestions';
import flags from './flags';
import seoPageData from './seoPageData';
import selectedAdvert from './selectedAdvert';
import messageDismissed from './messageDismissed';
import visitId from './visitId';

// Ternary functions for reducers that never update
const canonicalUrl = state => (state !== undefined ? state : '');

// Wrapper for routing so we can see previous routes
const routerWrapper = (state, { type, payload } = {}) => {
  if (type === '@@router/LOCATION_CHANGE' && payload.action === 'PUSH') {
    window.pastLocation = payload.pathname + payload.search;
  }

  return routerReducer(state, { type, payload });
};

const rootReducer = combineReducers({
  activeFilters,
  searchTitle,
  searchDescription,
  searchId,
  filters,
  adverts,
  advert,
  pages,
  rangeSliderValues,
  selectedFilter,
  sortOptions,
  totalHits,
  totalCrawlAdverts,
  searchString,
  alternateSearches,
  showFilterMenu,
  displayFilters,
  showMobileSaveSearch,
  showMobileHeader,
  notificationMessage,
  vrmLookupDetails,
  valuation,
  features,
  offerDetails,
  notFoundFlag,
  cookiePolicyAgreed,
  currentlyFetching,
  suggestions,
  flags,
  seoPageData,
  canonicalUrl,
  selectedAdvert,
  messageDismissed,
  visitId,
  routing: routerWrapper,
});

export default rootReducer;
