import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Term = styled.span`
  color: ${props => props.theme.blue};
  font-weight: ${props => (props.highlighted ? '500' : '300')};
  margin-right: ${rem('3px')};

  &:last-child {
    margin-right: 0;
  }
`;

export default Term;
