import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Name = styled.p`
  font-weight: 500;
  flex-grow: 2;
  font-size: ${rem('22px')};
  line-height: ${rem('28px')};
  margin-right: ${rem('20px')};
  margin-bottom: ${rem('10px')};
`;

export default Name;
