import { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const sizes = {
  bpSmall: 550,
  'bpSmall-1': 750,
  bpMedium: 1050,
  bpTablet: 1024,
  bpDesktop: 1400,
  bpsMedium: 768,
  bpsLarge: 1115,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${rem(`${sizes[label]}px`)}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
export { sizes, media as mediaAbove };
