import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import ellipsis from 'polished/lib/mixins/ellipsis';

const Wrapper = styled.li`
  ${ellipsis()}
  display: ${({ viewMore, i }) => (i > 0 && !viewMore ? 'none' : 'block')};
  font-size: ${rem('15px')};
  line-height: ${rem('45px')};
  font-weight: 300;

  @media(min-width: ${rem('1172px')}) {
    font-size: ${rem('14px')};
    line-height: ${rem('30px')};
    display: block;
  }
`;

const Link = styled.a`
  color: #198ddc;
  text-decoration: none;
`;

const ListItem = ({ link, name, viewMore, i }) => (
  <Wrapper {...{ viewMore, i }}>
    <Link href={link}>{name}</Link>
  </Wrapper>
);

ListItem.propTypes = {
  i: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  viewMore: PropTypes.bool.isRequired,
};

export default ListItem;
