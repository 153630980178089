import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Link as RouterLink } from 'react-router-dom';

const Link = styled(RouterLink)`
  font-size: ${rem('13px')};
  text-decoration: none;
  color: ${props => props.theme.lightGrey};
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
`;

export default Link;
