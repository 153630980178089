import styled, { css } from 'styled-components';

import media from 'styles/mixins/media';

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100vh;
  }

  ${props =>
    props.stopScroll &&
    css`
      height: 100vh;
      overflow-y: hidden;

      ${media.bpTablet`
      height: auto;
      overflow-y: auto;
    `}
    `}
`;

export default Site;
