import React from 'react';
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Svg = styled.svg`
  width: ${rem('12px')};
  height: ${rem('12px')};
  flex: 0 0 ${rem('12px')};
`;

const Path = styled.path`
  fill: #646972;

  ${props =>
    props.orange &&
    css`
      fill: #fff;
      stroke: ${props.theme.orange};
      stroke-width: 2px;
    `};
`;

const PhoneIcon = ({ orange }) => (
  <Svg viewBox="0 0 24 24" x="0px" y="0px">
    <Path
      d="M16.3,14.3L13,17.6L6.4,11l3.3-3.3c0.4-0.4,0.4-1,0-1.4l-5-5c-0.4-0.4-1-0.4-1.4,0L0,4.6
    C0,15.7,8.3,24,19.4,24l3.3-3.3c0.4-0.4,0.4-1,0-1.4l-5-5C17.3,13.9,16.7,13.9,16.3,14.3z"
      orange={orange}
    />
  </Svg>
);

export default PhoneIcon;
