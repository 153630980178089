import React from 'react';
import { withRouter } from 'react-router-dom';

import Logo from '../Logo';

import Wrapper from './Wrapper';
import LogoWrapper from './LogoWrapper';
import List from './List';
import Link from './Link';

import { MobileHeaderActions } from 'actions';

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu() {
    const { showMobileHeader, dispatch } = this.props;
    dispatch(MobileHeaderActions.toggleMobileHeader(!showMobileHeader));
  }

  render() {
    const { features } = this.props;

    const links = [
      ['/', 'Home'],
      ['/used-cars', 'Used Cars'],
      features.valueMyCar && ['/value-my-car', 'Value My Car'],
      ['/about', 'About'],
      ['/dealers', 'Dealers'],
    ].filter(Boolean);

    return (
      <Wrapper>
        <LogoWrapper onClick={this.toggleMobileMenu} to="/">
          <Logo />
        </LogoWrapper>
        <List>
          {links.map(([path, text]) => (
            <li key={path}>
              <Link onClick={this.toggleMobileMenu} to={path}>
                {text}
              </Link>
            </li>
          ))}
        </List>
      </Wrapper>
    );
  }
}

export default withRouter(MobileHeader);
