import React from 'react';
import styled from 'styled-components';

import SVG from './SVG';

const StyledSVG = styled(SVG)`
  vertical-align: middle;
`;

const OpenInNewTabIcon = () => (
  <StyledSVG
    viewBox="0 0 512 512"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M455.3,455.3H56.7V56.7H256V0H56.7C25.4,0,0,25.4,0,56.7v398.7C0,486.6,25.4,512,56.7,512h398.7
      c31.3,0,56.7-25.4,56.7-56.7V256h-56.7C455.3,256,455.3,455.3,455.3,455.3z M312.7,0v56.7h102.6L134.8,337.1l40.1,40.1L455.3,96.7
      v102.6H512V0H312.7z"
      fill="currentColor"
    />
  </StyledSVG>
);

export default OpenInNewTabIcon;
