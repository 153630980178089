import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const ErrorText = styled.p`
  padding-top: ${rem('2px')};
  padding-bottom: ${rem('12px')};
  font-size: ${rem('13px')};
  font-weight: light;
  color: ${props => props.theme.errorRed};
`;

export default ErrorText;
