import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../styles/mixins/media';

const Price = styled.p`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: ${rem('10px')};
  margin-right: ${rem('10px')};
  color: ${props => props.theme.mediumGrey};
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};

  ${media.bpSmall`
    font-size: 2em;
  `}
`;

export default Price;
