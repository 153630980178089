import cookie from 'react-cookie';

/*
  Updates the messageDismissed
 */
function dismissMessage() {
  // Set a messageDismissed cookie for 2 weeks
  const d = new Date();
  cookie.save('messageDismissed', 'true', {
    expires: new Date(d.setDate(d.getDate() + 14)),
  });

  return {
    type: 'MESSAGE_DISMISSED',
    data: true,
  };
}

export { dismissMessage };
