import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};
  margin: ${rem('15px')} 0 ${rem('34px')};

  &:last-child {
    margin-bottom: 0;
  }

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
    margin-top: ${rem('35px')};
  `};
`;

export default Text;
