import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import clearFix from 'polished/lib/mixins/clearFix';

import media from '../styles/mixins/media';

const Content = styled.div`
  ${clearFix()}
  padding-top: ${rem('50px')};
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  ${media.bpTablet`
    padding-top: ${rem('68px')};
  `}

  ${props =>
    props.search &&
    css`
      padding-top: ${props.messageDismissed ? rem('76px') : rem('151px')};

      @media (min-width: ${rem('430px')}) {
        padding-top: ${props.messageDismissed ? rem('50px') : rem('95px')};
      }

      ${media.bpTablet`
        padding-top: ${props.messageDismissed ? rem('70px') : rem('122px')};
      `};
    `}
  ${props =>
    props.index || props.seo
      ? css`
          padding-top: 0;

          @media (min-width: 430px) {
            padding-top: 0;
          }

          ${media.bpTablet`
      padding-top: 0;
    `};
        `
      : ''}
`;

export default Content;
