export default () => next => action => {
  // Get the action if there is one
  const errAction = action.type.includes('ERR') ? action : false;

  // If this isn't an error'd action or it has specific error handling
  if (!errAction || (errAction && errAction.hasCatch)) {
    return next(action);
  }

  // If it doesn't return any data, we can't tell if it's an error or not so pass
  if (typeof action.data !== 'object') {
    return next(action);
  }

  // Redirect to the error page if it was not a 404 or 400
  if (
    action.data.statusCode !== 404 &&
    action.data.statusCode !== 400 &&
    typeof window !== 'undefined'
  ) {
    window.location.href = '/error';
  }

  // We still want to action to be passed to the next middleware or reducer
  // so we can handle it's response in the dispatch call if we want
  return next(action);
};
