import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const DescriptionWrapper = styled.div`
  display: none;

  ${media.bpTablet`
    display: block;
    margin: 0 auto ${rem('30px')};
    text-align: center;
    max-width: ${rem('460px')};
  `}
`;

export default DescriptionWrapper;
