import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Heading = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('21px')};
  font-weight: 300;
  color: ${props => props.theme.lightGrey};
  max-width: ${rem('410px')};
  margin: 0 auto;

  ${media.bpTablet`
    font-size: ${rem('16px')};
    line-height: ${rem('28px')};
    max-width: ${rem('630px')};
  `};
`;

export default Heading;
