const formatNumber = (number = 0, char = ',') => {
  // Convert the number to a string so we can manipulate it
  const numberAsString = String(number);

  // If the number is less than 1000, we don't need to format it
  if (numberAsString.length < 4) {
    return numberAsString;
  }

  // Split the string every 3 characters starting from the end
  const chunks = [];
  for (let i = numberAsString.length; i >= 0; i -= 3) {
    const split = numberAsString.substring(i, i - 3);
    // Check that the substring split has a value
    if (split !== '') {
      chunks.unshift(split);
    }
  }

  // Add the comma's to the string
  const finalString = chunks.join(char);

  return finalString;
};

export default formatNumber;
