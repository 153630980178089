import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Errors = styled.ul`
  font-size: ${rem('13px')};
  margin: ${rem('12px')} 0 0;
  font-weight: 300;
  list-style-type: none;
  color: ${props => props.theme.errorRed};
  text-align: left;
  padding: 0;

  li {
    margin-bottom: ${rem('12px')};
  }

  ${props =>
    props.details
      ? css`
          text-align: center;
        `
      : ''} ${media.bpTablet`
    font-size: ${rem('15px')};

    ${props =>
      props.details
        ? css`
            width: ${rem('320px')};
            margin-right: auto;
            margin-left: auto;
          `
        : ''}
  `};
`;

export default Errors;
