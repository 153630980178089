import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  text-align: center;
  padding: ${rem('30px')};

  @media (min-width: ${rem('1172px')}) {
    text-align: left;
    max-width: ${rem('1176px')};
    margin: 0 auto;
    padding: ${rem('30px')} ${rem('30px')} ${rem('65px')};
    display: flex;
  }
`;

const Image = styled.img`
  height: ${rem('62.5px')};

  @media (min-width: ${rem('888px')}) {
    height: auto;
    width: ${rem('245px')};
    margin-left: ${rem('33px')};
    margin-right: ${rem('74px')};
  }
`;

const ContentWrapper = styled.div`
  flex: 0 0 ${rem('550px')};

  @media (min-width: ${rem('1172px')}) {
    flex: 0 1 auto;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('21px')};
  line-height: ${rem('27px')};
  margin: ${rem('10px')} 0 ${rem('30px')};

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('30px')};
  }
`;

const SubTitle = styled.span`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: #999da3;
  font-size: ${rem('21px')};
  line-height: ${rem('27px')};
  display: block;

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('30px')};
    display: inline;

    &:before {
      content: ' - ';
      display: inline;
    }
  }
`;

const Content = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  font-weight: 300;

  @media (min-width: ${rem('1172px')}) {
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
    max-width: ${rem('570px')};
  }
`;

const Header = ({ title, imageUrl, subTitle, content }) => (
  <Wrapper>
    {imageUrl && <Image src={imageUrl} />}
    <ContentWrapper>
      {title && (
        <Title>
          {title}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Title>
      )}
      {content && <Content>{content}</Content>}
    </ContentWrapper>
  </Wrapper>
);

Header.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  subTitle: PropTypes.string,
  content: PropTypes.string,
};

export default Header;
