import styled from 'styled-components';

import media from 'styles/mixins/media';

const Text = styled.p`
  ${media.bpTablet`
    display: inline-block;
  `}
`;

export default Text;
