import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${rem('284px')};
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${rem('515px')}) {
    max-width: 11rem;
    margin-top: ${rem('24px')};
    margin-right: 0;
  }
`;

export default Social;
