import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Copy = styled.p`
  color: white;
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  font-weight: 300;
  margin-bottom: ${rem('10px')};

  @media (min-width: ${rem('990px')}) {
    margin-bottom: 0;
  }

  ${media.bpTablet`
    font-size: ${rem('12px')};
  `};
`;

export default Copy;
