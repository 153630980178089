import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { SearchForm } from '@oodlefinance/shared-components';

import Logo from '../Logo';
import CookiePolicy from '../CookiePolicy';
import MenuIcon from '../../svgs/MenuIcon';

import Nav, { NavLink, StaticNavLink } from './Nav';
import HeaderWrapper from './HeaderWrapper';
import MenuButton from './MenuButton';
import Search from './Search';
import LogoWrapper from './LogoWrapper';
import LogoLink from './LogoLink';
import FilterIcon from './FilterIcon';
import Container from './Container';

import searchFormSearch from 'services/search-form-search';
import { MobileHeaderActions, SearchActions } from 'actions';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
  }

  toggleMobileMenu() {
    const { showMobileHeader, dispatch } = this.props;

    dispatch(MobileHeaderActions.toggleMobileHeader(!showMobileHeader));
  }

  /**
   * Toggles the visiblity of the filters when on mobile
   * @param  {Event} e
   */
  toggleFilters(e) {
    // Prevent the default a click event
    e.preventDefault();

    const { dispatch, showFilterMenu } = this.props;

    // Update the props
    dispatch(SearchActions.toggleFilterMenu(!showFilterMenu));
  }

  render() {
    const {
      isHome = true,
      features,
      dispatch,
      location,
      searchString,
      cookiePolicyAgreed,
      location: { pathname },
      hidePlaceholders,
      messageDismissed,
      flags,
      history,
    } = this.props;

    return (
      <div>
        <HeaderWrapper {...{ pathname, isHome, features, messageDismissed }}>
          {!isHome && (
            <LogoWrapper {...{ pathname }}>
              <LogoLink to="/" {...{ pathname }}>
                <Logo />
              </LogoLink>
            </LogoWrapper>
          )}
          <Container {...{ pathname }}>
            <MenuButton {...{ pathname }} onClick={this.toggleMobileMenu}>
              <MenuIcon />
            </MenuButton>
            {!isHome && (
              <Search {...{ pathname }}>
                <SearchForm
                  {...{
                    location: { ...location, query: qs.parse(location.search) },
                    searchString,
                    searchFormLocation: 'Header',
                    submitFormCallback: (e, { value }) => {
                      searchFormSearch(
                        dispatch,
                        features,
                        history,
                        'Header',
                        flags,
                        value,
                      );
                    },
                    placeholders: hidePlaceholders ? [] : undefined,
                  }}
                />
              </Search>
            )}
            <FilterIcon onClick={this.toggleFilters} {...{ pathname }} />
            <Nav {...{ pathname }}>
              <ul>
                <li>
                  <StaticNavLink activeClassName="active" href="/used-cars">
                    Used Cars
                  </StaticNavLink>
                </li>
                {features.valueMyCar && (
                  <li>
                    <NavLink activeClassName="active" to="/value-my-car">
                      Value My Car
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink activeClassName="active" to="/about/">
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dealers/">
                    Dealers
                  </NavLink>
                </li>
              </ul>
            </Nav>
          </Container>
        </HeaderWrapper>
        <CookiePolicy {...{ cookiePolicyAgreed, dispatch, pathname }} />
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
  isHome: PropTypes.bool,
  features: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  cookiePolicyAgreed: PropTypes.bool,
};

export default Header;
