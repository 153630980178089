import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Car = styled.div`
  width: ${rem('144px')};
  height: ${rem('85px')};
  flex: 0 0 ${rem('144px')};
  margin: 0 ${rem('10px')};
  position: relative;
  opacity: 0.3;
  transition: opacity 500ms ease;

  ${props =>
    (props.inputHasValue || props.selected || props.selectedCar === null) &&
    css`
      opacity: 1;
    `}

  ${media.bpTablet`
    width: ${rem('289px')};
    height: ${rem('170px')};
    flex: 0 0 ${rem('289px')};
    margin: 0 ${rem('15px')};
  `}

  img {
    width: 100%;
    height: 100%;
  }
`;

export default Car;
