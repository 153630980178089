import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('41px')};
  height: ${rem('31px')};

  ${media.bpTablet`
    width: ${rem('82px')};
    height: ${rem('62px')};
  `};
`;

const Polygon = styled.polygon`
  fill: #e6e6e6;
`;

const Circle = styled.circle`
  fill: #e6e6e6;
`;

const Path1 = styled.path`
  fill: #4f4f4f;
`;

const Path2 = styled.path`
  fill: #72c472;
`;

const Path3 = styled.path`
  fill: #4da34d;
`;

const Rect = styled.rect`
  fill: #4da34d;
`;

class CarIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 81.6 62" x="0px" y="0px">
        <g>
          <Polygon points="17.4,1.9 64,1.9 71.7,25.1 9.7,25.1   " />
          <Path1 d="M0,50.3V60C0,61.1,0.9,62,1.9,62h15.5c1.1,0,1.9-0.9,1.9-1.9v-9.7H0z" />
          <Path1 d="M62,50.3V60c0,1.1,0.9,1.9,1.9,1.9h15.5c1.1,0,1.9-0.9,1.9-1.9v-9.7H62z" />
          <Path2
            d="M79.7,30.4l-6.3-6.3L66.7,3.9c-0.8-2.4-3-4-5.5-4H20.2c-2.5,0-4.7,1.6-5.5,4L8,24.1l-6.3,6.3
            C0.6,31.5,0,33,0,34.5v15.8h81.4V34.5C81.4,33,80.8,31.5,79.7,30.4z M18.4,5.1c0.3-0.8,1-1.3,1.8-1.3h40.9c0.8,0,1.6,0.5,1.8,1.3
            l6,18.1H12.4L18.4,5.1z"
          />
          <Rect className="st3" height="5.8" width="81.4" y="46.5" />
          <Circle cx="11.6" cy="36.8" r="3.9" />
          <Circle cx="69.8" cy="36.8" r="3.9" />
          <Path3 d="M48.5,38.7H33c-1.1,0-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9h15.5c1.1,0,1.9,0.9,1.9,1.9S49.5,38.7,48.5,38.7z" />
        </g>
      </Svg>
    );
  }
}

export default CarIcon;
