import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const Path = styled.path`
  fill: #fff;
`;

class SpeechBubble extends React.PureComponent {
  render() {
    return (
      <Svg preserveAspectRatio="none" viewBox="0 0 574.8 279.4" x="0px" y="0px">
        <Path
          d="M2,244.2c63.2,5.1,71.2-56.7,83.7-101.8c15.8-57.1,43.3-92.9,103-114.6c93.6-34.1,306.5-49,366.6,48.7
          c26.4,42.9,26.3,119.6-3.2,161.4c-36.3,51.3-106.9,42.7-163.3,37.4c-70.8-6.8-139.1-21.3-208.3-36.6c-37.3-8.3-48.8-7.4-83.1,0.8
          c-29.6,7.1-51.6,16.3-83.3,9.8L2,244.2z"
        />
      </Svg>
    );
  }
}

export default SpeechBubble;
