import React from 'react';

import {
  Section,
  Container,
  Heading,
  SubHeading,
  Hr,
} from '../WhyPremium/WhyPremiumStyles';
import {
  LadderSection,
  ImageContainer,
  TextContainer,
  LadderHeading,
  Text,
} from '../WhyPremium/Ladder';
import Premium from '../Premium';

const OurPackages = () => (
  <Section>
    <Container>
      <Heading {...{ withSubHeading: true }}>What is Carsnip Premium?</Heading>
      <SubHeading>
        Carsnip is 100% performance based! If we don&apos;t deliver, you
        don&apos;t pay!
      </SubHeading>
      <LadderSection>
        <ImageContainer right>
          <Premium large />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>What is Pay Per Click?</LadderHeading>
          <Text>
            With Carsnip’s Premium Pay Per Click model, you can promote your
            stock to the top of results to ensure buyers see your ads first. You
            decide how much you’re prepared to pay for each click and you ONLY
            pay when a buyer clicks on your listing to your website.
          </Text>
          <Text>
            The Pay Per Click model allows you to generate a high volume of
            potential buyers at a lesser cost than traditional classified sites.
          </Text>
          <Text>
            Driving buyers to your website gives you a powerful digital
            marketing opportunities. Capture data on your potential buyers,
            build your own marketing database, power online retargeting and
            email campaigns - and all under your own brand.
          </Text>
        </TextContainer>
      </LadderSection>
      <Hr />
    </Container>
  </Section>
);

export default OurPackages;
