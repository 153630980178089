import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${props => (props.pageHasFeaturedCar ? rem('5px') : rem('10px'))} 0
    ${rem('10px')};
  width: 100%;
  background: white;

  a {
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  @media (min-width: ${rem('640px')}) {
    padding-right: ${rem('18px')};
    padding-left: ${rem('18px')};
  }

  ${media.bpTablet`
    margin: 0 auto;
    padding-top: ${props => (props.carPage ? rem('8px') : rem('16px'))};
    padding-bottom: ${rem('16px')};
  `};
`;

const Title = styled.h1`
  font-size: ${rem('14px')};
  line-height: ${rem('25px')};
  font-weight: 300;
  padding-bottom: ${rem('7px')};
  display: inline;
  margin-top: 0;
  margin-bottom: 0;
`;

const SubTitle = Title.withComponent('h3');

const MidTitle = styled.h2`
  font-size: ${rem('18px')};
  line-height: ${rem('25px')};
  font-weight: 300;
  padding-bottom: ${rem('7px')};
  margin-top: 0;
  margin-bottom: 0;
`;

export { Wrapper, Title, SubTitle, MidTitle };
