import React from 'react';

import Wrapper from './Wrapper';
import Container from './Container';
import Features from './Features';
import Heading from './Heading';
import Feature from './Feature';
import Text from './Text';
import Icon from './Icon';
import CarIcon from './CarIcon';
import AdviceIcon from './AdviceIcon';
import PriceIcon from './PriceIcon';
import ExpertsIcon from './ExpertsIcon';
import DeliveredIcon from './DeliveredIcon';
import WorkIcon from './WorkIcon';
import QuoteSection from './QuoteSection';
import Quotes from './Quotes';
import Quote from './Quote';
import QuoteMarks from './QuoteMarks';
import Message from './Message';
import Name from './Name';

class Why extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: 1,
    };

    this.switch = this.switch.bind(this);
  }

  componentDidMount() {
    this.switch();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  switch() {
    this.timeout = setTimeout(() => {
      this.setState(prevState => ({
        selected: (prevState.selected + 1) % 3,
      }));
      this.switch();
    }, 8000);
  }

  render() {
    const { selected } = this.state;

    return (
      <Wrapper>
        <Container>
          <Heading>Why choose us?</Heading>
          <Features>
            <Feature>
              <Icon>
                <CarIcon />
              </Icon>
              <Text>The exact car you want</Text>
            </Feature>
            <Feature>
              <Icon>
                <AdviceIcon />
              </Icon>
              <Text>Lots of helpful advice on the best car for you</Text>
            </Feature>
            <Feature>
              <Icon>
                <PriceIcon />
              </Icon>
              <Text>A car at the right price</Text>
            </Feature>
            <Feature>
              <Icon>
                <ExpertsIcon />
              </Icon>
              <Text>The car experts acting FOR you</Text>
            </Feature>
            <Feature>
              <Icon>
                <DeliveredIcon />
              </Icon>
              <Text>The car delivered to your door</Text>
            </Feature>
            <Feature>
              <Icon>
                <WorkIcon />
              </Icon>
              <Text>You can put your feet up whilst we do all the work</Text>
            </Feature>
          </Features>
          <QuoteSection>
            <Heading>What do our customers say?</Heading>
            <Quotes>
              <Quote {...{ selected }}>
                <QuoteMarks />
                <Message>
                  &quot;The service was exactly as advertised - friendly, stress
                  free and professional. The Palmdale team were very easy to
                  deal with, sourced my daughter the exact car she wanted&quot;
                </Message>
                <Name>Dave Webster</Name>
              </Quote>
              <Quote {...{ selected }}>
                <QuoteMarks />
                <Message>
                  &quot;Palmdale don’t just talk the talk, they walk the walk,
                  go the extra mile, and indeed live up to any other positive
                  cliché I can think of.&quot;
                </Message>
                <Name>Prof Justin Joffe</Name>
              </Quote>
              <Quote {...{ selected }}>
                <QuoteMarks />
                <Message>
                  &quot;We were presented with our new car in immaculate
                  condition, delivered to our doorstep within a couple of weeks
                  of starting the process.&quot;
                </Message>
                <Name>Steven Thomas</Name>
              </Quote>
            </Quotes>
          </QuoteSection>
        </Container>
      </Wrapper>
    );
  }
}

export default Why;
