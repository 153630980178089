import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Heading = styled.h1`
  font-size: ${rem('22px')};
  margin: 0 0 ${rem('10px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: ${props => props.theme.darkBlueGrey};

  ${media.bpTablet`
    font-size: ${rem('40px')};
    margin-bottom: ${rem('15px')};
  `};
`;

export default Heading;
