import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Heading = styled.h1`
  font-size: ${rem('18px')};
  line-height: ${rem('26px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  margin: 0 auto ${rem('40px')};
  max-width: ${rem('440px')};
  text-align: center;
  color: ${props => props.theme.darkBlueGrey};

  ${media.bpTablet`
    font-size: ${rem('24px')};
    line-height: ${rem('32px')};
    margin-bottom: ${rem('50px')};
    max-width: ${rem('580px')};
  `};
`;

export default Heading;
