import styled from 'styled-components';
import { resetButton } from '@oodlefinance/ui-library/mixins';

import media from 'styles/mixins/media';

const GetCallbackCTA = styled.button`
  ${resetButton}
  background-color: ${p => p.theme.secondary};
  border-radius: 4px;
  color: ${p => p.theme.white};
  cursor: pointer;
  line-height: 1.5;
  font-size: 0.9375rem;
  padding: 5px 15px;

  ${media.bpSmall`
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 1rem;
  `}
`;

export default GetCallbackCTA;
