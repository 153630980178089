import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookie';
import qs from 'query-string';

import Filters from '../../page-elements/Filters';
import SearchInfo from '../../page-elements/SearchInfo';
import Spinner from '../../page-elements/Spinner';
import SaveSearch from '../../page-elements/SaveSearch';
import SettingsDialog from '../../page-elements/SettingsDialog';

import filtersPropType from '../../../propTypes/filtersPropType';
import advertsPropType from '../../../propTypes/advertsPropType';

import StyledSearch from './StyledSearch';
import Wrapper from './Wrapper';
import ResultsWrapper from './ResultsWrapper';
import Results from './Results';
import Overlay from './Overlay';
import Info from './Info';
import InfoText from './InfoText';
import InfoIcon from './InfoIcon';
import PremiumAdvertPage from './PremiumAdvertPage';

import { getFiltersDescription } from 'services/search-title';
import { saveSearchAlertCarsViewed } from 'services/event-tracking';

class Search extends React.Component {
  componentDidMount() {
    const { adverts, totalHits, activeFilters } = this.props;

    // If postedDate is an active filter (i.e. user come from a saved search email)
    if (
      activeFilters.find(activeFilter => activeFilter.field === 'postedDate')
    ) {
      const filtersDescription = getFiltersDescription(
        activeFilters,
        totalHits,
        adverts,
      );

      saveSearchAlertCarsViewed(filtersDescription);
    }
  }

  get carPage() {
    const { location, adverts } = this.props;
    const { pageType } = qs.parse(location.search);
    return pageType === 'car' && adverts.length > 0 && adverts[0].idMatch;
  }

  get pageHasFeaturedCar() {
    const { adverts } = this.props;
    return adverts.length > 0 && adverts[0].idMatch;
  }

  get premiumListing() {
    const { adverts } = this.props;
    return (
      Array.isArray(adverts) &&
      adverts[0] &&
      Array.isArray(adverts[0].advertData) &&
      adverts[0].advertData[0] &&
      adverts[0].advertData[0].premiumListing
    );
  }

  render() {
    const {
      currentlyFetching,
      totalHits,
      totalCrawlAdverts,
      features,
      adverts,
      pages,
      flags,
      history,
      filters,
      dispatch,
      showFilterMenu,
      searchId,
      visitId,
      location,
    } = this.props;

    const publisherId = cookie.load('publisherId') || '';

    return (
      <StyledSearch>
        <Filters {...this.props} />
        <Wrapper>
          {this.carPage && this.premiumListing ? (
            <PremiumAdvertPage advert={adverts[0]} history={history} />
          ) : (
            <>
              <ResultsWrapper {...{ features }}>
                {currentlyFetching.search && (
                  <Overlay>
                    <Spinner />
                  </Overlay>
                )}
                {!this.pageHasFeaturedCar && !this.carPage && (
                  <SearchInfo {...{ ...this.props, carPage: this.carPage }} />
                )}
                {totalCrawlAdverts !== 0 &&
                  totalCrawlAdverts === totalHits &&
                  totalCrawlAdverts && (
                    <Info>
                      <InfoIcon
                        alt="search help information"
                        src="/images/info_icon.svg"
                      />
                      <InfoText>
                        We didn&apos;t find any exact matches for your search.
                        Here are {totalCrawlAdverts} similar results.
                      </InfoText>
                    </Info>
                  )}
                <Results
                  {...{
                    adverts,
                    pages,
                    dispatch,
                    features,
                    filters,
                    history,
                    location,
                    flags,
                    currentlyFetching,
                    showFilterMenu,
                    searchId,
                    publisherId,
                    visitId,
                  }}
                />
              </ResultsWrapper>
              <SaveSearch {...this.props} />
              {features.settingsDialog && <SettingsDialog {...this.props} />}
            </>
          )}
        </Wrapper>
      </StyledSearch>
    );
  }
}

const pageType = PropTypes.shape({
  current: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  link: PropTypes.string,
});

Search.propTypes = {
  activeFilters: filtersPropType.isRequired,
  adverts: advertsPropType.isRequired,
  currentlyFetching: PropTypes.shape({
    filter: PropTypes.bool.isRequired,
    saveSearch: PropTypes.bool.isRequired,
    search: PropTypes.bool.isRequired,
    sellMyCar: PropTypes.bool.isRequired,
    valueMyCar: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  features: PropTypes.shape().isRequired,
  filters: filtersPropType.isRequired,
  flags: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  pages: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    nextPage: pageType.isRequired,
    pagination: PropTypes.arrayOf(pageType).isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
  searchId: PropTypes.string.isRequired,
  showFilterMenu: PropTypes.bool.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  totalCrawlAdverts: PropTypes.number.isRequired,
  totalHits: PropTypes.number.isRequired,
  visitId: PropTypes.string.isRequired,
};

export default Search;
