import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const NumberIcon = styled.div`
  font-size: ${rem('28px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  border: ${rem('3px')} solid
    ${props => (props.highlight ? '#cfe7f6' : '#d8dadc')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${rem('49px')};
  height: ${rem('49px')};
  margin-right: ${rem('12px')};
  border-radius: ${rem('25px')};
  color: ${props =>
    props.highlight ? props.theme.blue : props.theme.mediumGrey};

  ${media.bpTablet`
    font-size: ${rem('37px')};
    flex: 0 0 ${rem('66px')};
    height: ${rem('66px')};
    margin-right: ${rem('16px')};
    border-radius: ${rem('33px')};
  `};
`;

export default NumberIcon;
