import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const ValuationTitle = styled.p`
  font-size: ${rem('14px')};
  font-weight: 500;

  ${media.bpTablet`
    font-size: ${rem('16px')};
    margin-bottom: ${rem('12px')};
  `}
`;

export default ValuationTitle;
