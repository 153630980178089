import * as NotificationActions from './NotificationActions';

import request from 'request';

/**
 * Function to request a callback
 */
const requestCallback = ({
  recaptchaResponse,
  name,
  telephone,
  commit,
  showroomId,
  vehicleData,
  dealerName,
}) => dispatch => {
  dispatch({
    type: 'REQUEST_CALLBACK_STARTED',
  });

  const showProcessingRequestMessage = dotCounter => {
    dispatch(
      NotificationActions.updateNotificationMessage({
        string: `Processing your request for a callback.${[
          ...Array(dotCounter % 3).keys(),
        ]
          .map(() => '.')
          .join('')}`,
      }),
    );
  };

  showProcessingRequestMessage(0);

  // add a dot to the notification every 2 seconds
  // to keep the user entertained
  let counter = 1;
  const interval = setInterval(() => {
    showProcessingRequestMessage(counter);
    counter++;
  }, 2000);

  request.post(
    '/api/request-callback',
    {
      recaptchaResponse,
      telephone,
      commit,
      vehicleData,
      name,
      showroomId,
      dealerName,
    },
    (err, data) => {
      clearInterval(interval);
      dispatch(
        NotificationActions.updateNotificationMessage({
          string: err
            ? 'Sorry, we cannot request a callback for you at this time. Please try again later.'
            : 'You will receive a callback shortly.',
        }),
      );

      setTimeout(() => {
        dispatch(NotificationActions.removeNotification());
      }, 4000);

      dispatch({
        type: err ? 'REQUEST_CALLBACK_FAILURE' : 'REQUEST_CALLBACK_SUCCESS',
        data,
      });
    },
  );
};

export { requestCallback };
