import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CarsnipLogo from '../svgs/CarsnipLogo';
import MenuIcon from '../svgs/MenuIcon';
import SearchIcon from '../svgs/SearchIcon';

import { MobileHeaderActions } from 'actions';

const Wrapper = styled.header`
  background: #f4f4f5;
  padding: ${rem('11px')};

  @media (min-width: ${rem('912px')}) {
    display: flex;
    flex: 1 0 auto;
    padding: ${rem('16px')} ${rem('26px')};
  }
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: ${rem('8px')};

  @media (min-width: ${rem('912px')}) {
    flex: 0 0 ${rem('152px')};
  }

  svg {
    width: ${rem('73px')};
    height: ${rem('15px')};

    @media (min-width: ${rem('912px')}) {
      width: auto;
      height: ${rem('23px')};
      position: relative;
      top: 5px;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex: 1 0 auto;
  position: relative;

  @media (min-width: ${rem('912px')}) {
    max-width: ${rem('830px')};
  }
`;

const MenuButton = styled.a`
  padding: ${rem('8px')} ${rem('10px')};

  @media (min-width: ${rem('912px')}) {
    display: none;
  }
`;

const Input = styled.input`
  font-size: ${rem('13px')};
  height: ${rem('30px')};
  padding-left: ${rem('12px')};
  padding-right: ${rem('38px')};
  display: block;
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: ${rem('3px')};
  color: #636363;
  outline: #198ddc;
`;

const Button = styled.button`
  position: absolute;
  right: 0;
  height: 100%;
  background: transparent;
  border: none;
  border-bottom-right-radius: ${rem('4px')};
  border-top-right-radius: ${rem('4px')};

  @media (min-width: ${rem('912px')}) {
    background: #ff6000;
    padding: 0 ${rem('10px')};
  }
`;

const ButtonIcon = styled.span`
  svg {
    width: ${rem('10.5px')};
    height: ${rem('10.5px')};

    circle {
      stroke: #c4c7ca;
      fill: transparent;
    }

    path {
      fill: #c4c7ca;
    }

    @media (min-width: ${rem('912px')}) {
      circle {
        stroke: #fff;
        fill: transparent;
      }

      path {
        fill: #fff;
      }
    }
  }
`;

const FormWrapper = styled.div`
  flex: 1 0 auto;
`;

const MenuList = styled.nav`
  flex: 0 0 auto;
  line-height: ${rem('32px')};
  margin-left: ${rem('30px')};
  display: none;

  @media (min-width: ${rem('912px')}) {
    display: block;
  }
`;

const MenuItem = styled(Link)`
  font-size: ${rem('11px')};
  font-weight: 400;
  text-decoration: none;
  color: ${props => props.theme.lightGrey};
  text-transform: uppercase;
  margin-right: ${rem('19px')};

  &:last-child {
    margin-right: 0;
  }
`;

const TopBar = ({ dispatch, showMobileHeader }) => (
  <Wrapper>
    <LogoWrapper>
      <Link to="/">
        <CarsnipLogo />
      </Link>
    </LogoWrapper>
    <FormWrapper>
      <Form action="/search">
        <MenuButton
          onClick={() => {
            dispatch(MobileHeaderActions.toggleMobileHeader(!showMobileHeader));
          }}
        >
          <MenuIcon />
        </MenuButton>
        <Input name="q" placeHolder="Search..." type="text" />
        <Button>
          <ButtonIcon>
            <SearchIcon />
          </ButtonIcon>
        </Button>
      </Form>
    </FormWrapper>
    <MenuList>
      <MenuItem to="/used-cars">Used Cars</MenuItem>
      <MenuItem to="/about">About</MenuItem>
      <MenuItem to="/dealers">Dealers</MenuItem>
    </MenuList>
  </Wrapper>
);

TopBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showMobileHeader: PropTypes.bool.isRequired,
};

export default TopBar;
