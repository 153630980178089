import React from 'react';
import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import Premium from '../Premium';
import Leads from '../Leads';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  max-width: ${rem('560px')};
  margin: ${rem('40px')} auto;

  ${media.bpSmall`
    display: flex;
    align-items: center;
  `};

  ${media.bpsLarge`
    margin: ${rem('60px')} 0;
  `};
`;

const TextWrapper = styled.div`
  margin-top: ${rem('20px')};

  ${media.bpSmall`
    margin-top: 0;
    margin-left: ${rem('30px')};
    text-align: left;
  `};
`;

const Header = styled.h2`
  color: ${props => props.theme.mediumGrey};
  font-size: ${rem('18px')};
  font-weight: 400;
  margin: 0 0 ${rem('15px')};
`;

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
  `};
`;

class Option extends React.PureComponent {
  render() {
    const { heading, text, image } = this.props;

    return (
      <Wrapper>
        {image === 'premium' && <Premium />}
        {image === 'leads' && <Leads />}
        <TextWrapper>
          <Header>{heading}</Header>
          <Text>{text}</Text>
        </TextWrapper>
      </Wrapper>
    );
  }
}

export default Option;
