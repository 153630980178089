import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${rem('557px')};
  margin: 0 auto;
`;

export default Wrapper;
