import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Head = styled.div`
  padding: ${rem('15px')};
  border-top-left-radius: ${rem('4px')};
  border-top-right-radius: ${rem('4px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.green};
  background: white;
  text-align: center;
  border-bottom: ${rem('1px')} solid #e0e0e0;

  ${media.bpTablet`
    padding: ${rem('25px')} 0 ${rem('20px')};
  `};
`;

export default Head;
