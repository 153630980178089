import React from 'react';
import PropTypes from 'prop-types';

import CookiePolicy from '../page-elements/CookiePolicy';

import NotFound from 'components/pages/NotFound';
import Header from 'components/static/Header';
import BodyStyleCards from 'components/static/BodyStyleCards';
import ManufacturerCards from 'components/shared/ManufacturerCards';
import ManufacturerList from 'components/shared/ManufacturerList';
import LocationList from 'components/static/LocationList';
import SearchWrapper from 'components/static/SearchWrapper';
import SearchCTA from 'components/static/SearchCTA';
import BlogPosts from 'components/static/BlogPosts';
import FooterDescription from 'components/static/FooterDescription';
import TopBar from 'components/static/TopBar';
import NoResults from 'components/static/NoResults';

const StaticSEOPages = ({
  seoPageData: {
    status,
    header,
    searchResults,
    sidebar,
    bodyStyleCards,
    manufacturerCards,
    manufacturerList,
    locationList,
    searchCta,
    blogPosts,
    footerDescription,
    noResults,
  },
  dispatch,
  showMobileHeader,
  flags,
  features,
  cookiePolicyAgreed,
  location: { pathname },
}) => (
  <div>
    <TopBar {...{ dispatch, showMobileHeader }} />
    <CookiePolicy {...{ cookiePolicyAgreed, dispatch, pathname }} />
    {status === 404 && <NotFound />}
    {status !== 404 && header && <Header {...header} />}
    {status !== 404 && noResults && <NoResults {...{ noResults }} />}
    {status !== 404 && (searchResults || sidebar) && (
      <SearchWrapper
        {...{ searchResults, sidebar, flags, features, dispatch }}
      />
    )}
    {status !== 404 && bodyStyleCards && (
      <BodyStyleCards {...{ bodyStyleCards }} />
    )}
    {status !== 404 && manufacturerCards && (
      <ManufacturerCards {...manufacturerCards} />
    )}
    {status !== 404 && manufacturerList && (
      <ManufacturerList {...manufacturerList} />
    )}
    {status !== 404 && locationList && <LocationList {...locationList} />}
    {status !== 404 && searchCta && <SearchCTA {...searchCta} />}
    {status !== 404 && blogPosts && <BlogPosts {...blogPosts} />}
    {status !== 404 && footerDescription && (
      <FooterDescription {...footerDescription} />
    )}
  </div>
);

const basicInfo = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const infoWithCount = {
  ...basicInfo,
  count: PropTypes.number.isRequired,
};

const cardPropType = PropTypes.shape({
  ...basicInfo,
  imageUrl: PropTypes.string.isRequired,
});

StaticSEOPages.propTypes = {
  cookiePolicyAgreed: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  features: PropTypes.shape().isRequired,
  flags: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  seoPageData: PropTypes.shape({
    blogPosts: PropTypes.any,
    bodyStyleCards: PropTypes.arrayOf(cardPropType).isRequired,
    footerDescription: PropTypes.any,
    header: PropTypes.shape({
      content: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    locationList: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.shape(basicInfo)).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    manufacturerCards: PropTypes.shape({
      list: PropTypes.arrayOf(cardPropType).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    manufacturerList: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.shape(infoWithCount)).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    noResults: PropTypes.string,
    searchCta: PropTypes.any,
    searchResults: PropTypes.any,
    sidebar: PropTypes.any,
    status: PropTypes.number.isRequired,
  }).isRequired,
  showMobileHeader: PropTypes.bool.isRequired,
};

export default StaticSEOPages;
