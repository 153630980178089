import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import PageWrapper from './PageWrapper';

// Import components that are in the main bundle
import Home from './pages/Home';
import Search from './pages/Search';

// TODO: Move these to use Loadable components
import ValueMyCarEmailSent from './pages/MyCar/ValueMyCarEmailSent';
import ValueMyCar from './pages/MyCar/ValueMyCar';
import ValueMyCarDetails from './pages/MyCar/ValueMyCarDetails';
import ValueMyCarResults from './pages/MyCar/ValueMyCarResults';
import About from './pages/About';
import Dealers from './pages/Dealers';
import YouGov from './pages/YouGov';
import HelpMeChoose from './pages/HelpMeChoose';
import Bot from './pages/Bot';
import ErrorPage from './pages/ErrorPage';
import NotFound from './pages/NotFound';
import SEOPages from './pages/SEOPages';

const addPageWrapper = (Component, parentProps, routeProps) => (
  <PageWrapper {...{ ...routeProps, ...parentProps }}>
    <Component {...{ ...routeProps, ...parentProps }} />
  </PageWrapper>
);

const Routes = props => (
  <Switch>
    <Route
      exact
      path="/"
      render={routeProps => addPageWrapper(Home, props, routeProps)}
    />

    {/* Search Route */}
    <Route
      exact
      path="/search/:filters*"
      render={routeProps => addPageWrapper(Search, props, routeProps)}
    />

    {/* Value my car routes */}
    <Route
      exact
      path="/value-my-car/email-sent"
      render={routeProps =>
        addPageWrapper(ValueMyCarEmailSent, props, routeProps)
      }
    />
    <Route
      exact
      path="/value-my-car/:vrm?"
      render={routeProps => addPageWrapper(ValueMyCar, props, routeProps)}
    />
    <Route
      exact
      path="/value-my-car/details/:lookupId"
      render={routeProps =>
        addPageWrapper(ValueMyCarDetails, props, routeProps)
      }
    />
    <Route
      exact
      path="/value-my-car/:lookupId/:mileage?/:validation"
      render={routeProps =>
        addPageWrapper(ValueMyCarResults, props, routeProps)
      }
    />

    {/* Static page routes */}
    <Route
      exact
      path="/about"
      render={routeProps => addPageWrapper(About, props, routeProps)}
    />
    <Route
      exact
      path="/dealers"
      render={routeProps => addPageWrapper(Dealers, props, routeProps)}
    />
    <Route
      exact
      path="/yougov17"
      render={routeProps => addPageWrapper(YouGov, props, routeProps)}
    />
    <Route
      exact
      path="/help-me-choose"
      render={routeProps => addPageWrapper(HelpMeChoose, props, routeProps)}
    />
    <Route
      exact
      path="/bot"
      render={routeProps => addPageWrapper(Bot, props, routeProps)}
    />

    {/* Redirect routes */}
    <Route exact path="/crawler" render={() => <Redirect to="/bot" />} />
    <Route exact path="/revolution" render={() => <Redirect to="/dealers" />} />

    {/* Error routes */}
    <Route
      exact
      path="/error"
      render={routeProps => addPageWrapper(ErrorPage, props, routeProps)}
    />
    <Route exact path="/500" render={() => <Redirect to="/error" />} />

    {/* SEO pages */}
    <Route
      path="/used-cars"
      render={routeProps => addPageWrapper(SEOPages, props, routeProps)}
    />

    {/* Not found */}
    <Route
      exact
      path="/not-found"
      render={routeProps => addPageWrapper(NotFound, props, routeProps)}
    />
    <Route render={routeProps => addPageWrapper(NotFound, props, routeProps)} />
  </Switch>
);

export default Routes;
