import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('30px')} 0;

  ${media.bpTablet`
    padding: 0 0 ${rem('10px')};
  `}
`;

export default Wrapper;
