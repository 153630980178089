import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export default styled.button`
  display: block;
  border: none;
  border-radius: ${rem('4px')};
  background: ${props => props.theme.orange};
  color: white;
  font-size: ${rem('15px')};
  margin: 0 auto;
  padding: ${rem('9px')} ${rem('72px')};
`;
