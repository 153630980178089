import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Name = styled.p`
  font-size: ${rem('14px')};
  color: white;
  text-align: center;

  ${media.bpTablet`
    font-size: ${rem('18px')};
  `}
`;

export default Name;
