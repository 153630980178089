import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Message = styled.p`
  font-size: ${rem('14px')};
  line-height: ${rem('21px')};
  font-style: italic;
  font-weight: 300;
  color: white;
  text-align: center;
  margin-bottom: ${rem('18px')};

  ${media.bpTablet`
    font-size: ${rem('18px')};
    line-height: ${rem('28px')};
  `}
`;

export default Message;
