import React from 'react';

import Container from './Container';
import Wrapper from './Wrapper';
import Heading from './Heading';
import Text from './Text';
import Button from './Button';

const Contact = () => (
  <Container>
    <Wrapper>
      <Heading>Fancy a chat?</Heading>
      <Text>
        If you have a question about a car or simply want to know more about
        what we do, click the button below!
      </Text>
      <Button
        href="https://help.carsnip.com/hc/en-us/requests/new"
        title="Get in touch"
      >
        Get in touch
      </Button>
    </Wrapper>
  </Container>
);

export default Contact;
