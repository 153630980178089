import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const PhoneCTA = styled.a`
  display: block;
  width: 100%;
  color: ${props => props.theme.orange};
  border: 1px solid ${props => props.theme.orange};
  border-radius: ${rem('4px')};
  padding: ${rem('15px')};
  margin-bottom: ${rem('20px')};
  font-size: ${rem('14px')};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
`;

export default PhoneCTA;
