import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const InlineAdvert = styled.div`
  width: 300px; /* This is the absolute width of the adverts, so should be in px */
  overflow: hidden;
  margin: 0 ${rem('18px')} ${rem('18px')};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  @media (min-width: ${rem('640px')}) {
    margin-bottom: ${rem('36px')};
  }

  ${props =>
    css`
      @media (min-width: ${rem(
          `${props.theme.bpShowFiltersHideMaps3Cols}px`,
        )}) {
      }
    `}

  ${p =>
    p.noBorder
      ? css`
          box-shadow: none;
        `
      : ''}
`;

export default InlineAdvert;
