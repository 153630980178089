import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  max-width: ${rem('500px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};
  margin: ${rem('15px')} auto ${rem('26px')};

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
    margin-top: ${rem('35px')};
    margin-bottom: ${rem('35px')};
  `}

  ${media.bpsLarge`
    margin: ${rem('35px')} 0 0;
    max-width: 60vw;
  `}

  ${media.bpDesktop`
    max-width: ${rem('620px')};
  `}
`;

export default Text;
