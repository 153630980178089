import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const leftPosition = selectedCar =>
  selectedCar === 1
    ? css`
        left: calc(50% - ${rem(164 * 6)} - ${rem(164 / 2)});

        ${media.bpTablet`
        left: calc(50% - ${rem(319 * 6)} - ${rem(319 / 2)});
      `}
      `
    : css`
        left: calc(
          50% - ${rem(164 * 6)} - ${rem(164 * (selectedCar - 1))} -
            ${rem(164 / 2)}
        );

        ${media.bpTablet`
        left: calc(50% - ${rem(319 * 6)} - ${rem(
          319 * (selectedCar - 1),
        )} - ${rem(319 / 2)});
      `}
      `;

const scroll = keyframes`
  100%  {
    transform: translateX(-33.33%);
  }
`;

const Scroller = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
  transition: left 1s ease;

  ${props =>
    props.selectedCar && !props.inputHasValue
      ? leftPosition(props.selectedCar)
      : css`
          animation: ${scroll} 30s linear infinite;
        `}
`;

export default Scroller;
