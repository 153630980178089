import qs from 'query-string';
import has from 'has';

import parseRequest from './url/parse-request';
import { makeUrlCanonical } from './url/make-url-canonical';

import { SearchActions } from 'actions';

/**
 * Builds the query to put on the URL when generating a new URL
 */
const buildQuery = (
  { queryText = '', searchString = '', pages = {} },
  originalQuery = {},
) => {
  // Start with a default query
  let query = '?';

  // Add the query text if it exists
  if (queryText) {
    query += `q=${queryText}`;
  }

  // Add the search string if it exists
  if (searchString) {
    query += `${query.length > 1 ? '&' : ''}s=${encodeURIComponent(
      searchString,
    )}`;
  }

  // Add the page number if it's not the first page
  if (typeof pages === 'object' && pages.currentPage > 1) {
    query += `${query.length > 1 ? '&' : ''}page=${pages.currentPage}`;
  }

  if (originalQuery.pageType) {
    query += `${query.length > 1 ? '&' : ''}pageType=${originalQuery.pageType}`;
  }

  // Set the query to empty if no values were added
  if (query.length === 1) {
    query = '';
  }

  return query;
};

/**
 * Recursive function to make the call to app service
 * @param  {Function}   dispatch           The redux dispatch function
 * @param  {String}     query              The query string to send to the API
 * @param  {String}     url                An optional string to update the url to (it is calculated as well)
 * @param  {Object}     features           The features object from the redux props
 * @param  {Boolean}    fromSearchBox      A Boolean to determine if the url was from a textbox
 * @param  {Boolean}    resetPosition      A Boolean to determine how we want to update the URL and therefore reset the users scroll position or not
 * @param  {Boolean}    resetSearchString  A Boolean to determine if we need to reset the search string
 * @param  {Number}     callLimit          If call limit is 0, we can't make any more calls. (stops infinite loop)
 * @param  {Boolean}    firstAttempt [description]
 */
function makeCallToSearch({
  dispatch,
  searchQuery,
  url = null,
  features,
  fromSearchBox = false,
  resetPosition = true,
  resetSearchString = false,
  callLimit,
  firstAttempt,
  history,
}) {
  // Make the call to the search api
  dispatch(
    SearchActions.search({ query: searchQuery, firstAttempt }, fromSearchBox),
  )
    // Handle the response
    .then(response => {
      // If there's a redirect URL, we need to make another call if we're allowed
      if (
        has(response, 'redirectUrl') &&
        response.redirectUrl &&
        callLimit > 0
      ) {
        const [, newSearchQuery] = response.redirectUrl.split('?');
        makeCallToSearch({
          dispatch,
          searchQuery: newSearchQuery,
          url,
          features,
          fromSearchBox,
          resetPosition,
          resetSearchString,
          callLimit: callLimit - 1,
          firstAttempt: false,
          history,
        });
      }

      // Reset the search string if we're told to
      if (resetSearchString) {
        dispatch(SearchActions.resetSearchString());
      }

      // Create the new URL if one hasn't been provided
      const newUrl = (() => {
        if (!url) {
          // Get the url
          const parsedRequest = parseRequest(response.request);

          // Make the url canoncial (in case it's not)
          const canonicalUrl = `/search${makeUrlCanonical(parsedRequest)}`;

          // Add the query url if it exists
          return `${canonicalUrl}${buildQuery(
            response,
            qs.parse(searchQuery),
          )}`;
        }

        return url;
      })();

      // Reset the scroll position if we're told to
      if (resetPosition) {
        window.scrollTo(0, 0);
      }

      // Update the URL without resetting the scroll position
      if (
        window &&
        window.location &&
        window.location.pathname + window.location.search !== newUrl
      ) {
        history.push(newUrl);
      }
    });
}

/**
 * Runs the search API call
 * @param  {Function}   dispatch           The redux dispatch function
 * @param  {String}     query              The query string to send to the API
 * @param  {Object}     features           The features object from the redux props
 */
const runSearch = (dispatch, query = '', features, history, rest) => {
  // Create the variables for the functions
  const searchQuery = query;
  const callLimit = 5;
  const firstAttempt = true;

  // Make the call to the API
  makeCallToSearch({
    ...rest,
    dispatch,
    features,
    callLimit,
    firstAttempt,
    searchQuery,
    history,
  });
};

export default runSearch;
export { buildQuery };
