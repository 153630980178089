import styled, { css } from 'styled-components';

import media from '../../../styles/mixins/media';

const Container = styled.div(
  ({ premiumCarPage }) => css`
    max-width: ${premiumCarPage && '584px'};
    margin: ${premiumCarPage && '0 auto'};
    padding: 0 20px;

    ${media.bpTablet`
      padding: 0;
    `}
  `,
);

export default Container;
