import React from 'react';
import styled, { keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';

const StyledSpinner = styled.div`
  width: ${rem('40px')};
  height: ${rem('40px')};
`;

const skStretchdelay = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
  }
`;

const animationDelays = [0, -1.1, -1, -0.9, -0.8];

const Rect = styled.div`
  background: ${p => (p.colour ? p.colour : p.theme.orange)};
  height: 100%;
  width: ${rem('6px')};
  margin: 0 ${rem('1px')};
  display: inline-block;
  animation: ${skStretchdelay} 1.2s infinite ease-in-out;
  animation-delay: ${p => p.animationDelay && `${p.animationDelay}s`};
`;

const Spinner = ({ colour }) => (
  <StyledSpinner className="spinner">
    {animationDelays.map(animationDelay => (
      <Rect
        key={animationDelay}
        animationDelay={animationDelay}
        {...{ colour }}
      />
    ))}
  </StyledSpinner>
);

Spinner.propTypes = {
  colour: PropTypes.string,
};

Spinner.defaultProps = {
  colour: undefined,
};

export default Spinner;
export { StyledSpinner, Rect };
