const filterOrder = [
  'carId',
  'price',
  'distance',
  'mileage',
  'bodyStyle',
  'fuel',
  'colour',
  'registrationYear',
  'region',
  'sellerType',
  'gearbox',
  'manufacturer',
  'range',
  'model',
  'variant',
  'insuranceGroup',
  'engineSizeL',
  'co2Emissions',
  'taxBand',
  'mpgCombined',
  'secs0to62',
  'powerBhp',
  'torqueLbsFt',
  'topSpeed',
  'dealerName',
  'mpgUrban',
  'mpgExtraUrban',
  'numberOfDoors',
  'numberOfSeats',
  'numberOfCylinders',
  'registrationLetters',
  'questionApplied',
  'newSearch',
  'dealerDomain',
  'fixedTerm',
  'dealerId',
  'postedDate',
  'showroom',
  'location',
];

export default filterOrder;
