import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../styles/mixins/media';

export const CarouselAndContent = styled.div(
  ({ premiumCarPage }) => css`
    display: ${!premiumCarPage && 'flex'};
    flex-direction: column;

    ${media['bpSmall-1']`
      flex-direction: row;
    `}
  `,
);

export const CarouselContainer = styled.div(
  ({ premiumCarPage }) => css`
    max-height: ${rem('392px')};
    min-height: ${rem('215px')};

    position: relative;
    margin: 0 -${premiumCarPage ? 20 : 40}px;
    width: calc(100% + ${premiumCarPage ? 40 : 80}px);
    flex-shrink: 0;

    ${media.bpSmall`
      height: 392px;
      width: 100%;
      margin: 0;
    `}

    ${media['bpSmall-1']`
      height: ${!premiumCarPage && 'auto'};
      width: ${premiumCarPage ? '100%' : rem('350px')};
    `}
  `,
);

export const CarouselBackground = styled.div`
  display: none;

  ${media.bpTablet`
    display: block;
    height: 392px;
    width: 100%;
    position: absolute;
    background-color: ${p => p.theme.lighterGrey};
    left: 0;
  `}
`;

export const SectionHeader = styled.h3`
  color: ${p => p.theme.mediumGrey};
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
`;

export const CircledCheckMarkWrapper = styled.span`
  margin-right: 0.25rem;
`;

export const AfterDealerHr = styled.hr`
  display: none;

  ${media.bpSmall`
    display:block;
    position: absolute;
    width: 100%;
    left: 0;
    border: 1px solid ${p => p.theme.sidebarGrey};
  `}
`;

export const AfterDealerHrMargin = styled.div`
  display: none;

  ${media.bpSmall`
    display: block;
    height: 1.5rem;
  `}
`;

export const OpenInNewTabIconWrapper = styled.span`
  margin-left: 0.5rem;
`;

export const LineArrowLeftWrapper = styled.span`
  font-size: 0.375rem;
  margin-right: 0.5rem;
`;

export const DealerLocationWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const DealerLogo = styled.img`
  width: 90px;

  ${media.bpSmall`
    width: auto;
  `}
`;

const LINE_HEIGHT = 2;
export const PlainDescription = styled.p`
  font-size: 0.875rem;
  line-height: ${LINE_HEIGHT};

  height: ${LINE_HEIGHT * 3}em;
  overflow: hidden;
  font-weight: 300;
  color: ${p => p.theme.darkGrey};
`;

export const NonPremiumDealerNameWrapper = styled.a`
  text-decoration: none;
  display: inline-block;
  margin-top: 1rem;
  color: ${p => p.theme.darkGrey};
  font-size: 0.875rem;
`;

export const NonPremiumDealerName = styled.span`
  margin-right: 0.5rem;
`;

export const AdvertContent = styled.div(
  ({ premiumCarPage }) => css`
    margin: 0 -${premiumCarPage ? 0 : 20}px;
    width: calc(100% + ${premiumCarPage ? 0 : 40}px);

    ${media.bpSmall`
      margin: 0 0;
      width: 100%;
    `}

    ${media['bpSmall-1']`
      margin-left: ${!premiumCarPage && '2rem'};
    `}
  `,
);
