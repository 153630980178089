import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Image = styled.img`
  height: ${rem('109px')};
  margin-left: -${rem('65px')};

  ${media.bpMedium`
    height: ${rem('245px')};
  `}
`;

export default Image;
