import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const DaveContainer = styled.div`
  position: relative;
  max-width: ${rem('284px')};
  margin: 0 auto;
  height: ${rem('222px')};

  ${media.bpTablet`
    flex: 1 1 60%;
    margin-right: ${rem('70px')};
    height: ${rem('390px')};
    max-width: none;
  `}
`;

export default DaveContainer;
