import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const ResultsWrapper = styled.div`
  padding-bottom: ${rem('20px')};
  position: relative;
  width: 100%;
  padding-right: ${rem('18px')};
  padding-left: ${rem('18px')};

  @media (min-width: ${rem('640px')}) {
    padding-left: 0;
    padding-right: 0;
  }

  ${media.bpTablet`
    padding: 0 ${rem('50px')} ${rem('20px')};
    max-width: ${rem('1448px')};
    overflow: hidden;
  `};
`;

export default ResultsWrapper;
