import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Indicator = styled.div`
  width: ${props => (props.selected ? rem('8px') : rem('4px'))};
  height: ${props => (props.selected ? rem('8px') : rem('4px'))};
  border-radius: ${props => (props.selected ? rem('4px') : rem('2px'))};
  background: ${props => props.theme.orange};
  margin: 0 ${rem('3px')};
  cursor: pointer;
`;

export default Indicator;
