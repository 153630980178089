import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Input = styled.input(
  ({ theme, uppercase, center, error, small, isValid }) => css`
    padding: ${rem('13px')} ${rem('21px')};
    border-radius: ${rem('4px')};
    height: 2.5rem;
    text-align: ${center ? 'center' : 'left'};
    width: 100%;
    border: ${rem('1px')} solid ${theme.grey};
    border-color: ${error && theme.errorRed};
    border-color: ${isValid && theme.green};
    text-transform: ${uppercase && 'uppercase'};

    ${media.bpTablet`
      height: 4rem;

      ${small &&
        css`
          font-size: ${rem('15px')};
          padding: 0.875rem;
          height: 2.75rem;
        `}
    `}
  `,
);

export default Input;
