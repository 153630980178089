import React from 'react';

import Wrapper from './Wrapper';
import Container from './Container';
import Heading from './Heading';
import Step from './Step';
import NumberIcon from './NumberIcon';
import Text from './Text';

class How extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Container>
          <Heading>How does it work?</Heading>
          <Step>
            <NumberIcon>1</NumberIcon>
            <Text>
              Fill in the form and we’ll contact you directly by phone or email.
              You tell use what you want and if you’re not too sure what car you
              want we can help advise you! (Our advice is 100% free)
            </Text>
          </Step>
          <Step>
            <NumberIcon>2</NumberIcon>
            <Text>
              If you would then like us to find you a car, we will! We send you
              a Car Purchasing Checklist, which confirms the car you want us to
              find and lists our fees. (Please ask for a list of fees
              beforehand, if you wish.)
            </Text>
          </Step>
          <Step>
            <NumberIcon>3</NumberIcon>
            <Text>
              We then search for every available car in the UK that meets your
              requirements.
            </Text>
          </Step>
          <Step>
            <NumberIcon>4</NumberIcon>
            <Text>
              We negotiate the price with the seller on your behalf and we make
              sure that the car is in excellent condition.
            </Text>
          </Step>
          <Step>
            <NumberIcon highlight>5</NumberIcon>
            <Text highlight>
              We collect the car and bring it to your door and you enjoy your
              new wheels for many years to come!
            </Text>
          </Step>
        </Container>
      </Wrapper>
    );
  }
}

export default How;
