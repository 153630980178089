import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Body = styled.div`
  padding: ${rem('15px')};

  ${media.bpTablet`
    padding: ${rem('30px')};
  `}
`;

export default Body;
