import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto ${rem('30px')};

  ${media.bpTablet`
    display: block;
    width: 33%;
    margin-bottom: ${rem('50px')};
  `}
`;

export default Feature;
