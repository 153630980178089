import React from 'react';

import SVG from '../SVG';

const ArrowRight = () => (
  <SVG
    role="presentation"
    viewBox="0 0 59 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.0385396,46.0048682 L12.6271805,1.59432049 C11.6,0.5663286 10.2288032,0 8.76673428,0 C7.30466531,0 5.93346856,0.5663286 4.90628803,1.59432049 L1.6356998,4.86409736 C-0.492494929,6.99472617 -0.492494929,10.4576065 1.6356998,12.5849899 L38.9290061,49.8782961 L1.59432049,87.2129817 C0.567139959,88.2409736 0,89.611359 0,91.0726166 C0,92.535497 0.567139959,93.9058824 1.59432049,94.9346856 L4.86490872,98.2036511 C5.89290061,99.231643 7.263286,99.7979716 8.72535497,99.7979716 C10.1874239,99.7979716 11.5586207,99.231643 12.5858012,98.2036511 L57.0385396,53.7525355 C58.0681542,52.7212982 58.6336714,51.3444219 58.6304399,49.8807302 C58.6336714,48.411359 58.0681542,47.0352941 57.0385396,46.0048682 Z"
      fill="currentColor"
    />
  </SVG>
);

export default ArrowRight;
