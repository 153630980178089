import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const NavHeader = styled.h4`
  margin-top: 0;
  margin-bottom: ${rem('8px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.orange};
  font-weight: 400;
  font-size: ${rem('16px')};

  ${media.bpTablet`
    font-size: ${rem('15px')};
  `};
`;

export default NavHeader;
