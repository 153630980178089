import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  position: relative;
  background: white;
  padding: ${rem('20px')} ${props => (props.noSidePadding ? '0' : rem('16px'))};
  max-width: ${rem('370px')};
  margin: 0 auto;

  ${props =>
    props.boxShadow &&
    css`
      box-shadow: ${rem('2px')} ${rem('1px')} ${rem('10px')} 0
        rgba(0, 0, 0, 0.2);
    `}

  ${media.bpTablet`
    padding: ${rem('45px')} ${props =>
    props.noSidePadding ? '0' : rem('40px')};
  `}
`;

export default Wrapper;
