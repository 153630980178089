import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Main = styled.div(
  ({ premiumCarPage }) => css`
    flex: 1 1 auto;
    max-width: ${premiumCarPage && rem('765px')};
    margin-bottom: ${premiumCarPage && rem('30px')};

    @media (min-width: ${props => rem(props.colLayoutBp)}) {
      margin-bottom: 0;
    }
  `,
);

export default Main;
