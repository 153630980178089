import React from 'react';

import OpenInNewTabIcon from '../../svgs/OpenInNewTabIcon';

import {
  OpenInNewTabIconWrapper,
  Wrapper,
  TextContainer,
  Text,
  Fadeout,
  ReadMoreContainer,
  ReadMoreLink,
} from './style';

const DescriptionWithLink = ({ text, fullDescriptionUrl }) => (
  <Wrapper>
    <TextContainer>
      <Text>{text}</Text>
      <Fadeout />
    </TextContainer>
    <ReadMoreContainer>
      <ReadMoreLink href={fullDescriptionUrl} target="_blank">
        Read the full description
        <OpenInNewTabIconWrapper>
          <OpenInNewTabIcon />
        </OpenInNewTabIconWrapper>
      </ReadMoreLink>
    </ReadMoreContainer>
  </Wrapper>
);

export default DescriptionWithLink;
