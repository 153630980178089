/**
 * Pure function for populating the searchId prop
 */
function searchId(state = '', action) {
  if (action.type === 'SEARCH_RESPONSE') {
    return action.data.searchId || state;
  }

  return state;
}

export default searchId;
