import React from 'react';
import styled from 'styled-components';

const Circle = styled.circle`
  stroke-width: 13;
  stroke-miterlimit: 10;
  fill: none;
  stroke: #fff;
`;

const Line = styled.g`
  fill: #fff;
`;

export default () => (
  <svg viewBox="-95 -30 100.2 100" x="0px" y="0px">
    <g id="icomoon-ignore" />
    <Circle cx="-50.8" cy="14.2" id="XMLID_6_" r="37.8" />
    <Line id="XMLID_1_">
      <path
        d="M2.9,67.8c-2.9,2.9-7.8,2.8-11-0.4l-18.9-18.9c-3.1-3.1-3.3-8-0.4-11s7.8-2.8,11,0.4L2.6,56.9
        C5.7,60,5.8,64.9,2.9,67.8z"
      />
    </Line>
  </svg>
);

export { Circle, Line };
