import React from 'react';
import has from 'has';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import Spinner from '../../../page-elements/Spinner';
import MarketingPrefs from '../../../page-elements/MarketingPrefs';
import Wrapper from '../Wrapper';
import Header from '../Header';
import DetailsPanel from '../DetailsPanel';

import FormHeader from '../DetailsForm/FormHeader';
import FormText from '../DetailsForm/FormText';
import Form from '../DetailsForm/Form';
import InputGroup from '../InputGroup';
import Errors from '../Errors';
import Confirm from '../DetailsForm/Confirm';
import commit from '../../../../../git.ver';
import SubmitButtonWrapper from '../SubmitButtonWrapper';

import FormWrapper from './FormWrapper';

import SubmitButton from 'shared/SubmitButton';
import Input from 'shared/Input';

import { emailRegex } from 'services/regex';
import { VrmActions } from 'actions';
import { valueMyCarRequested } from 'services/event-tracking';

class ValueMyCarDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      confirm: false,
      errors: {},
      mileage: '',
      showMarketingPrefs: false,
      marketingSearch: false,
      marketingNewFeatures: false,
      contactEmail: false,
      contactSMS: false,
      contactPhone: false,
    };

    this.updateEmail = this.updateEmail.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateMileage = this.updateMileage.bind(this);
    this.toggleMarketingPrefs = this.toggleMarketingPrefs.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.notYourCar = this.notYourCar.bind(this);
  }

  /**
   * Get the details for the given vrm
   */
  componentDidMount() {
    const { lookupId } = this.props.match.params;
    const { lookupId: lookupIdProp } = this.props.vrmLookupDetails;

    // If broswer has been refreshed or Url has been changed, get the vrm details by lookupId
    if (lookupId !== lookupIdProp) {
      this.props
        .dispatch(VrmActions.getVrmLookup(lookupId))
        .then(({ body: vrmLookupDetails }) => {
          this.setState({
            mileage: vrmLookupDetails.mileage,
          });
        });
    } else {
      // Load the mileage if it's coming from the previous page
      const { vrmLookupDetails } = this.props;
      this.setState({
        mileage: vrmLookupDetails.mileage,
      });
    }
  }

  /**
   * Updates the email value
   */
  updateEmail(e) {
    const { value: email } = e.target;
    const { errors } = this.state;

    delete errors.email;

    this.setState({
      email,
      errors,
    });
  }

  /**
   * Updates the mileage value
   */
  updateMileage(e) {
    const { value: mileage } = e.target;

    this.setState({
      mileage,
    });
  }

  /**
   * Send back to landing page to try again
   */
  notYourCar() {
    const { history } = this.props;
    history.push('/value-my-car');
  }

  /**
   * Toggles the confirm checkbox
   */
  toggleConfirm() {
    const { confirm, errors } = this.state;

    delete errors.confirm;

    this.setState({
      confirm: !confirm,
      errors,
    });
  }

  /**
   * @param {String} option The marketing preference to toggle
   */
  toggleMarketingPrefs(option) {
    const currentState = this.state[option];

    this.setState({
      [option]: !currentState,
    });
  }

  /**
   * Validate the form and open the marketing preferences pop up
   */
  handleSubmit(e) {
    e.preventDefault();

    // Get the values we need
    const { value } = this.email;
    const { confirm } = this.state;

    // Trim the email address value from the input
    const email = value.trim();

    // Flag to see if an error has been set
    const errors = {};

    // Only if the email is valid we should send off the request
    if (!emailRegex.test(email)) {
      // Set error
      errors.email = 'Please enter a valid email address';
    }

    // If the user hasn't confirmed the terms
    if (!confirm) {
      // Set error
      errors.confirm = 'Please confirm terms and conditions';
    }

    // Stop submission if an error was found
    if (Object.keys(errors).length) {
      this.setState({
        errors,
      });
      return false;
    }

    let marketingPrefsSaved = localStorage.getItem('marketingPrefsSaved');
    marketingPrefsSaved = JSON.parse(marketingPrefsSaved);

    if (marketingPrefsSaved && marketingPrefsSaved.commit === commit) {
      this.setState(
        {
          marketingSearch: marketingPrefsSaved.marketingSearch,
          marketingNewFeatures: marketingPrefsSaved.marketingNewFeatures,
          contactEmail: marketingPrefsSaved.contactEmail,
          contactSMS: marketingPrefsSaved.contactSMS,
          contactPhone: marketingPrefsSaved.contactPhone,
        },
        () => {
          this.sendEmail();
        },
      );
    } else {
      this.setState({ showMarketingPrefs: true });
    }

    return true;
  }

  /**
   * Sends off the email and VRM to app service to send the email
   */
  sendEmail() {
    const {
      confirm,
      mileage,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
    } = this.state;
    const { vrmLookupDetails, history } = this.props;
    const { lookupId, manufacturer, model } = vrmLookupDetails;
    const { value } = this.email;
    const email = value.trim();

    // Set marketingPrefsSaved localStorage item.
    localStorage.setItem(
      'marketingPrefsSaved',
      JSON.stringify({
        marketingSearch,
        marketingNewFeatures,
        contactEmail,
        contactSMS,
        contactPhone,
        commit,
      }),
    );

    // Close the marketing preferences pop up
    this.setState({ showMarketingPrefs: false });

    const body = {
      mileage,
      privacyPolicy: confirm,
      commit,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
    };

    this.props
      .dispatch(
        VrmActions.sendEmail(lookupId, encodeURIComponent(email.trim()), body),
      )
      .then(() => {
        valueMyCarRequested(manufacturer, model); // event tracking
        history.push('/value-my-car/email-sent');
      });
  }

  render() {
    const { vrmLookupDetails, currentlyFetching, features } = this.props;
    const { title, vrm, registrationYear } = vrmLookupDetails;

    const {
      email,
      confirm,
      errors,
      mileage,
      showMarketingPrefs,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
    } = this.state;

    return (
      <>
        <MarketingPrefs
          {...{
            isOpen: showMarketingPrefs,
            marketingSearch,
            marketingNewFeatures,
            contactEmail,
            contactSMS,
            contactPhone,
            toggleCheckbox: this.toggleMarketingPrefs,
            onClose: this.sendEmail,
          }}
        />
        <Wrapper details>
          <Header mobile>Your Vehicle</Header>
          <DetailsPanel
            {...{
              vrmLookupDetails,
              valueMyCarDetailsTitle: 'Your Vehicle',
              title,
              registrationYear,
              features,
              vrm,
              mileage,
              updateMileage: this.updateMileage,
              notYourCar: this.notYourCar,
              value: true,
            }}
          />

          <FormWrapper>
            <FormHeader>Get your valuation</FormHeader>
            <FormText>
              Your valuation will be emailed straight over to you for free!
            </FormText>
            <Form noValidate onSubmit={this.handleSubmit}>
              <Confirm error={errors.confirm}>
                <input
                  checked={confirm}
                  id="confirm"
                  name="confirm"
                  onChange={this.toggleConfirm}
                  type="checkbox"
                  value={confirm}
                />
                <label htmlFor="confirm">
                  I have read the{' '}
                  <a
                    href="/articles/privacy-policy/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Privacy Policy"
                  >
                    Carsnip Privacy Policy
                  </a>{' '}
                  and I'm happy for my personal details to be used as decsribed.
                </label>
              </Confirm>
              <InputGroup email>
                <Input
                  small
                  error={has(errors, 'email')}
                  onChange={this.updateEmail}
                  placeholder="Email Address"
                  ref={input => {
                    this.email = input;
                  }}
                  type="email"
                  value={email}
                />
              </InputGroup>
              <SubmitButtonWrapper marginLeft={rem('13px')} value>
                <SubmitButton
                  disabled={
                    Object.keys(errors).length || currentlyFetching.valueMyCar
                  }
                  loading={currentlyFetching.valueMyCar}
                  type="submit"
                  small
                >
                  {currentlyFetching.valueMyCar ? (
                    <Spinner />
                  ) : (
                    'Get My Valuation'
                  )}
                </SubmitButton>
              </SubmitButtonWrapper>
            </Form>
            {errors && (
              <Errors details>
                {Object.entries(errors).map(([key, error]) => (
                  <li key={key}>{error}</li>
                ))}
              </Errors>
            )}
            <p className="MyCar__tcs">
              We respect your privacy and we never send spam
            </p>
          </FormWrapper>
        </Wrapper>
      </>
    );
  }
}

ValueMyCarDetails.propTypes = {
  vrmLookupDetails: PropTypes.object,
};

export default ValueMyCarDetails;
