import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('16px')} ${rem('12px')};
  top: ${props =>
    props.pathname.includes('/search') ? rem('76px') : rem('50px')};
  font-size: ${rem('13px')};
  background: ${props => props.theme.lighterGrey};
  position: ${props =>
    props.pathname.includes('/used-cars') ? 'static' : 'fixed'};
  width: 100%;
  color: ${props => props.theme.secondary};
  text-align: center;
  z-index: 10;
  border-top: 0.0625rem solid #d3d3d3;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: ${rem('430px')}) {
    top: ${rem('50px')};
  }

  ${media.bpTablet`
    top: ${rem('68px')};
  `};
`;

export default Wrapper;
