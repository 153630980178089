import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Text = styled.p`
  max-width: ${rem('200px')};
  font-size: ${rem('18px')};
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.mediumGrey};
  text-align: center;
`;

export default Text;
