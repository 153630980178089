import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FilterItem } from '@oodlefinance/shared-components';

// SVG's
import SearchIcon from '../../svgs/SearchIcon';

import FilterHeader from './FilterHeader';

import {
  Wrapper,
  Content,
  Body,
  Footer,
  SearchCarsButton,
} from './FiltersStyles';

import { SearchActions } from 'actions';
import runSearch from 'services/run-search';

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.clearFilters = this.clearFilters.bind(this);
    this.toggleFilterMenu = this.toggleFilterMenu.bind(this);
    this.selectFilter = this.selectFilter.bind(this);
    this.updatesearch = this.updatesearch.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
  }

  clearFilters() {
    const { dispatch, features, history } = this.props;

    runSearch(dispatch, '', features, history);
  }

  toggleFilterMenu() {
    const { dispatch, showFilterMenu } = this.props;

    dispatch(SearchActions.toggleFilterMenu(!showFilterMenu));
  }

  /**
   * Selects a filter by updating the selectedFilter prop
   * TODO: Look at moving selectFilter logic into state instead of redux
   */
  selectFilter(filter) {
    const { filters, dispatch, selectedFilter } = this.props;
    const parentFilter = filters.find(f => f.queryName === filter.parentFilter);
    const parentHasValue =
      typeof parentFilter === 'object' &&
      typeof parentFilter.selected === 'object' &&
      !!Object.keys(parentFilter.selected).length;
    const usesFlyout =
      filter.fieldType === 'term' ||
      (filter.fieldType === 'subTerm' &&
        parentHasValue &&
        parentFilter.selected.values.length === 1);

    if (usesFlyout) {
      return;
    }

    // If the filter has a parent and that parent has no values
    if (!!filter.parentFilter && !parentHasValue) {
      return;
    }

    // If the filter is already selected, unset it
    const displayName =
      typeof selectedFilter === 'string'
        ? selectedFilter
        : selectedFilter.displayName;
    if (displayName === filter.displayName) {
      dispatch(SearchActions.updateFilters(''));
      return;
    }

    dispatch(SearchActions.updateFilters(filter.displayName));
  }

  updatesearch(updateUrl) {
    const { dispatch, features, history } = this.props;
    runSearch(dispatch, updateUrl, features, history);
  }

  removeFilter({ clearUrl }) {
    this.updatesearch(clearUrl);
  }

  render() {
    const {
      dispatch,
      totalHits,
      selectedFilter,
      showFilterMenu: open,
      filters,
      features,
      messageDismissed,
    } = this.props;

    const checkForActiveFilters = filter =>
      filter.fieldType === 'price'
        ? Object.keys(filter[filter.current].selected).length
        : Object.keys(filter.selected).length;

    // Get the currently selected filter
    const selectedFilterObject =
      filters.find(filter => selectedFilter === filter.displayName) || {};

    // Calculate if the reset button should be active
    const activeFiltersExist = filters.some(checkForActiveFilters);

    return (
      <Wrapper {...{ features, open, messageDismissed }}>
        <Content {...{ open }}>
          <FilterHeader
            {...{
              clearFilters: this.clearFilters,
              activeFiltersExist,
              dispatch,
              toggleFilterMenu: this.toggleFilterMenu,
            }}
          />
          <Body>
            {filters.map((filter, index) => (
              <FilterItem
                childFilter={filters.find(
                  f => f.queryName === filter.childFilter,
                )}
                filter={filter}
                key={`filter-item-${index}`}
                parentFilter={filters.find(
                  f => f.queryName === filter.parentFilter,
                )}
                removeActiveFilterCallback={this.removeFilter}
                selectedFilter={selectedFilterObject}
                selectFilterCallback={this.selectFilter}
                updateSearchCallback={this.updatesearch}
              />
            ))}
          </Body>
          <Footer>
            <SearchCarsButton onClick={this.toggleFilterMenu}>
              <SearchIcon />
              View {totalHits.toLocaleString()} cars
            </SearchCarsButton>
          </Footer>
        </Content>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
};

export default Filters;
