import React from 'react';
import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('215px')};
  height: ${rem('75px')};

  ${media.bpTablet`
    width: ${rem('600px')};
    height: ${rem('185px')};
  `};
`;

class TotalIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 585.4 199">
        <linearGradient
          gradientTransform="matrix(1 0 0 -1 0 200)"
          gradientUnits="userSpaceOnUse"
          id="a"
          x1={209.684}
          x2={209.684}
          y1={186.9}
          y2={0.6}
        >
          <stop offset={0} stopColor="#ff6000" />
          <stop offset={0.345} stopColor="#ff731f" stopOpacity={0.879} />
          <stop offset={0.941} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <path
          d="M210.3 13.1c44.6 0 70.5 38.1 70.5 94.5 0 58.3-32.4 91.8-71.3 91.8-38.6 0-71-32.9-71-85.6 0-52.1 21.3-100.7 71.8-100.7zm-42.4 98.2c0 31.3 14.6 60.5 40.8 60.5 25.7 0 43.5-23.8 43.5-63.2 0-36.2-14-68.3-42.1-68.3-33.8 0-42.2 38.1-42.2 71z"
          fill="url(#a)"
        />
        <linearGradient
          gradientTransform="matrix(1 0 0 -1 0 200)"
          gradientUnits="userSpaceOnUse"
          id="b"
          x1={378.483}
          x2={378.483}
          y1={186.9}
          y2={0.6}
        >
          <stop offset={0} stopColor="#ff6000" />
          <stop offset={0.345} stopColor="#ff731f" stopOpacity={0.879} />
          <stop offset={0.941} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <path
          d="M379.1 13.1c44.6 0 70.5 38.1 70.5 94.5 0 58.3-32.4 91.8-71.3 91.8-38.6 0-71-32.9-71-85.6-.1-52.1 21.3-100.7 71.8-100.7zm-42.4 98.2c0 31.3 14.6 60.5 40.8 60.5 25.7 0 43.5-23.8 43.5-63.2 0-36.2-14-68.3-42.1-68.3-33.9 0-42.2 38.1-42.2 71z"
          fill="url(#b)"
        />
        <linearGradient
          gradientTransform="matrix(1 0 0 -1 0 200)"
          gradientUnits="userSpaceOnUse"
          id="c"
          x1={533.584}
          x2={533.584}
          y1={199.2}
          y2={1}
        >
          <stop offset={0} stopColor="#ff6000" />
          <stop offset={0.345} stopColor="#ff731f" stopOpacity={0.879} />
          <stop offset={0.941} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <path
          d="M582.6 176.1c2.4 3 3.8 5.4 3.8 8.6 0 6.2-7 14.3-15.4 14.3-4.9 0-8.6-3.2-12.7-8.4L521.6 146c-2.7 2.4-9.2 8.6-11.6 11.1l-.3 26.5c0 9.5-3.5 15.4-14.3 15.4-10.3 0-14.9-5.7-14.6-15.9l.5-29.7c0-40.5-.3-77.2-.3-118.5 0-4.9 0-14.6-.3-20.3 0-9.5 7.3-13.8 15.1-13.8 9.7 0 14.8 4.6 14.8 14.6 0 35.9-.5 74.3-.8 110.2l45.6-46.4c4.3-4.6 8.4-7.3 13-7.3 7 0 14.6 7.6 14.6 14.3 0 3.5-2.2 6.8-5.4 10.3-10.3 10.3-26.5 25.1-35.9 34l40.9 45.6z"
          fill="url(#c)"
        />
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="d"
          x1={64.508}
          x2={64.508}
          y1={13.046}
          y2={197.355}
        >
          <stop offset={0} stopColor="#ff6000" />
          <stop offset={0.345} stopColor="#ff731f" stopOpacity={0.879} />
          <stop offset={0.941} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <path
          d="M15.9 40.6C4.8 40.6 1 34.7 1 26c0-8.9 6.5-13 14.3-13h98.6c10 0 14.1 6.2 14.1 13.5 0 6.8-3.8 12.7-8.4 19.7C95 80.6 75 120.6 66.1 147.9c-5.7 17.6-8.9 30-10.3 36.2-1.9 8.4-4.9 13.2-15.9 13.2-10.5 0-15.9-5.1-14.9-15.9 1.9-12.7 6.2-23.5 11.6-39.2 11.4-31.9 28.9-67 54-102.4-18.4-.3-25.1.3-43.8.3-12.3 0-21.7.5-30.9.5z"
          fill="url(#d)"
        />
      </Svg>
    );
  }
}

export default TotalIcon;
