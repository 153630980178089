import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Button = styled.a`
  display: inline-block;
  font-size: ${rem('9.5px')};
  color: ${props => props.theme.secondary};
  border: ${rem('1px')} solid ${props => props.theme.secondary};
  border-radius: ${rem('4px')};
  padding: ${rem('5px')} ${rem('7px')};
  cursor: pointer;

  @media (min-width: ${rem('430px')}) {
    margin-left: ${rem('15px')};
  }

  ${media.bpTablet`
    font-size: ${rem('11px')};
  `};
`;

export default Button;
