/*
  Based on http://www.area-codes.org.uk/formatting.php
 */
const formatPhone = phoneNumber => {
  if (!phoneNumber) {
    return '';
  }
  let innerPhoneNumber = phoneNumber.trim();
  if (innerPhoneNumber === '') {
    return innerPhoneNumber;
  }
  if (innerPhoneNumber.startsWith('+')) {
    // international number
    if (!innerPhoneNumber.startsWith('+44')) {
      return innerPhoneNumber; // no UK - return as is - we don't know how to format this
    }
    innerPhoneNumber = innerPhoneNumber.substring(3);
  }

  innerPhoneNumber = innerPhoneNumber.replace(/[^0-9]/g, '');
  if (!innerPhoneNumber.startsWith('0')) {
    innerPhoneNumber = `0${innerPhoneNumber}`;
  }

  if (innerPhoneNumber.startsWith('0800')) {
    return innerPhoneNumber.replace(/(.{4})(.*)/, '$1 $2');
  }
  if (
    innerPhoneNumber.startsWith('09') ||
    innerPhoneNumber.startsWith('08') ||
    (innerPhoneNumber.startsWith('03') && innerPhoneNumber.length === 11)
  ) {
    return innerPhoneNumber.replace(/(.{4})(.{3})(.{4})/, '$1 $2 $3');
  }
  if (
    innerPhoneNumber.startsWith('07') ||
    (innerPhoneNumber.startsWith('05') && innerPhoneNumber.length === 11)
  ) {
    return innerPhoneNumber.replace(/(.{5})(.{6})/, '$1 $2');
  }
  if (innerPhoneNumber.startsWith('02') && innerPhoneNumber.length === 11) {
    return innerPhoneNumber.replace(/(.{3})(.{4})(.{4})/, '$1 $2 $3');
  }
  if (
    innerPhoneNumber.length > 9 &&
    [
      '019467',
      '017687',
      '017684',
      '017683',
      '016977',
      '016974',
      '016973',
      '015396',
      '015395',
      '015394',
      '015242',
      '013873',
    ].includes(innerPhoneNumber.substring(0, 6))
  ) {
    return innerPhoneNumber.replace(/(.{6})(.{*})/, '$1 $2');
  }
  if (innerPhoneNumber.match(/(01[0-9]1 | 011[0-9]).{7}/)) {
    return innerPhoneNumber.replace(/(.{4})(.{3})(.{4})/, '$1 $2 $3');
  }
  if (innerPhoneNumber.startsWith('01') && innerPhoneNumber.length > 9) {
    return innerPhoneNumber.replace(/(.{5})(.*)/, '$1 $2');
  }
  return innerPhoneNumber;
};

export default formatPhone;
