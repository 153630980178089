import React from 'react';

import Header from './Header';
import Featured from './Featured';
import WhoAreWe from './WhoAreWe';
import Contact from './Contact';

class About extends React.PureComponent {
  render() {
    return (
      <>
        <Header />
        <Featured />
        <WhoAreWe />
        <Contact />
      </>
    );
  }
}

export default About;
