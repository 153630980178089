import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Name = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  max-width: ${rem('500px')};
  font-weight: 300;
  color: ${props => props.theme.orange};
  margin: 0 auto;

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
  `} ${media.bpsLarge`
    text-align: left;
    max-width: none;
  `};
`;

export default Name;
