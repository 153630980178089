import React from 'react';

import {
  NoResultsHeader,
  RemoveFiltersText,
  NoResultsWrapper,
} from './StyledResults';

const NoResults = () => (
  <NoResultsWrapper>
    <NoResultsHeader>0 adverts found...</NoResultsHeader>
    <RemoveFiltersText>
      Click the filters above to change the search criteria
    </RemoveFiltersText>
  </NoResultsWrapper>
);

export default NoResults;
