import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import clearFix from 'polished/lib/mixins/clearFix';

const Form = styled.form`
  ${clearFix()}
  margin-top: ${rem('20px')};
`;

export default Form;
