import React from 'react';
import PropTypes from 'prop-types';

import filtersPropType from '../propTypes/filtersPropType';

import MobileHeader from './page-elements/MobileHeader';
import Notification from './page-elements/Notification';
import Header from './page-elements/Header';
import Footer from './page-elements/Footer';
import MessageBar from './page-elements/MessageBar';

import Transformer from './Transformer';
import Meta from './Meta';
import Site from './Site';
import Content from './Content';

import runSearch from 'services/run-search';
import parseUrl from 'services/url/parse-url';
import getDocumentHeadValues from 'services/get-document-head-values';
import { removeSearch } from 'services/url/manipulate-url';
import { makeUrlCanonical } from 'services/url/make-url-canonical';

import { MobileHeaderActions } from 'actions';
import Overlay from 'shared/Overlay';

class PageWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  componentDidMount() {
    const { dispatch, features, history, location } = this.props;

    window.onpopstate = () => {
      // Get the url
      const url = location.pathname;

      // Get the query
      const query = parseUrl(removeSearch(url), location.search);

      // Only make the search request if the new url is the search page
      if (url.includes('search')) {
        // Make a search with the value and handle results
        runSearch(dispatch, query, features, history, {
          url: makeUrlCanonical(url + location.search),
        });
      }
    };
  }

  toggleMobileMenu() {
    const { showMobileHeader, dispatch } = this.props;

    dispatch(MobileHeaderActions.toggleMobileHeader(!showMobileHeader));
  }

  render() {
    const {
      dispatch,
      children,
      location,
      searchString,
      showFilterMenu,
      showMobileHeader,
      notificationMessage,
      searchTitle,
      searchDescription,
      features,
      cookiePolicyAgreed,
      cars,
      totalHits,
      filters,
      messageDismissed,
      flags,
      history,
    } = this.props;

    const documentHead = getDocumentHeadValues(
      { totalHits, cars, filters, searchTitle, searchDescription },
      location.pathname,
    );

    const isHome = new RegExp('^/?$').test(location.pathname);
    const isSearch = location.pathname.startsWith('/search');

    const shouldHidePlaceholdersForFilter = filter =>
      filter.fieldType === 'price'
        ? Object.keys(filter[filter.current].selected).length
        : Object.keys(filter.selected).length;

    return (
      <Transformer open={showMobileHeader}>
        <Site stopScroll={showFilterMenu}>
          <MobileHeader {...{ features, dispatch, showMobileHeader }} />
          {!location.pathname.startsWith('/used-cars') && (
            <div>
              <Meta
                meta={[
                  { name: 'description', content: documentHead.description },
                ]}
                title={documentHead.title}
              />
              <Notification
                {...{
                  notificationMessage,
                  dispatch,
                  location,
                  cookiePolicyAgreed,
                  messageDismissed,
                }}
              />
              <Header
                {...{
                  dispatch,
                  searchString,
                  features,
                  isHome,
                  cookiePolicyAgreed,
                  location,
                  showMobileHeader,
                  history,
                  hidePlaceholders: filters.some(
                    shouldHidePlaceholdersForFilter,
                  ),
                  messageDismissed,
                  flags,
                }}
              />
            </div>
          )}
          {features.messageBar && isSearch && (
            <MessageBar
              {...{ dispatch, messageDismissed, features, location }}
            />
          )}
          {showMobileHeader && <Overlay onClick={this.toggleMobileMenu} />}
          <Content
            features={features}
            index={isHome}
            messageDismissed={messageDismissed}
            search={isSearch}
            seo={location.pathname.startsWith('/used-cars')}
          >
            {children}
          </Content>
          <Footer
            {...{ pathname: location.pathname, features, showFilterMenu }}
          />
        </Site>
      </Transformer>
    );
  }
}

PageWrapper.propTypes = {
  cars: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ),
  children: PropTypes.node.isRequired,
  cookiePolicyAgreed: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  features: PropTypes.shape().isRequired,
  filters: filtersPropType.isRequired,
  flags: PropTypes.shape().isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.any,
  }).isRequired,
  messageDismissed: PropTypes.bool.isRequired,
  notificationMessage: PropTypes.shape().isRequired,
  searchDescription: PropTypes.string.isRequired,
  searchString: PropTypes.string,
  searchTitle: PropTypes.string.isRequired,
  showFilterMenu: PropTypes.bool.isRequired,
  showMobileHeader: PropTypes.bool.isRequired,
  totalHits: PropTypes.number.isRequired,
};

PageWrapper.defaultProps = {
  cars: [],
  searchString: '',
};

export default PageWrapper;
