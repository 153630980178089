import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Image = styled.img`
  margin: ${rem('31px')} 0;
  display: block;
  width: 100%;

  ${media.bpTablet`
    top: ${rem('70px')};
    right: 0;
    height: ${rem('520px')};
    margin: 0;
    position: absolute;
    z-index: 1;
    transform: translateX(47%);
    width: auto;
  `} ${media.bpDesktop`
    left: ${rem('219px')};
    top: ${rem('165px')};
    height: auto;
  `};
`;

export default Image;
