import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';

const conf = config();

/* global grecaptcha */

class Recaptcha extends React.Component {
  constructor(props) {
    super(props);

    this.captcha = null;

    this.loadCaptcha = this.loadCaptcha.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.resetRecaptcha = this.resetRecaptcha.bind(this);
    this.explicitRender = this.explicitRender.bind(this);
  }

  loadCaptcha() {
    this.explicitRender();
    this.props.onRecaptchaLoad(this.captcha);
  }

  explicitRender() {
    if (
      typeof grecaptcha !== 'undefined' &&
      typeof grecaptcha.render === 'function' &&
      this.captcha === null
    ) {
      const { oodle = false } = this.props;
      const wrapper = document.createElement('div'); // need to use a wrapper so we can delete recaptcha client on unmount
      this.captcha = grecaptcha.render(wrapper, {
        sitekey: oodle
          ? conf.recaptchaOodleSiteKey
          : conf.recaptchaInvisibleSiteKey,
        size: 'invisible',
        callback: this.successCallback,
        'expired-callback': this.resetRecaptcha,
      });

      this.recaptchaTarget.appendChild(wrapper);
    }
  }

  componentDidMount() {
    if (
      typeof grecaptcha !== 'undefined' &&
      typeof grecaptcha.render === 'function'
    ) {
      try {
        this.loadCaptcha();
      } catch (e) {
        this.resetRecaptcha();
      }
    } else {
      window.onloadCallback = () => {
        this.loadCaptcha();
      };
    }
  }

  componentDidUpdate() {
    this.explicitRender();
  }

  componentWillUnmount() {
    if (this.captcha !== null && this.recaptchaTarget) {
      while (this.recaptchaTarget.firstChild) {
        this.recaptchaTarget.removeChild(this.recaptchaTarget.firstChild);
      }
      this.resetRecaptcha();
      // eslint-disable-next-line no-underscore-dangle
      if (window.___grecaptcha_cfg && window.___grecaptcha_cfg.clients) {
        // eslint-disable-next-line no-underscore-dangle
        delete window.___grecaptcha_cfg.clients[this.captcha]; // hack to prevent memory leak!
      }
      this.captcha = null;
    }
  }

  resetRecaptcha() {
    if (
      this.captcha !== null &&
      grecaptcha &&
      typeof grecaptcha.reset === 'function'
    ) {
      grecaptcha.reset(this.captcha);
    }
  }

  successCallback(response) {
    this.props.onChange(response);
  }

  render() {
    return (
      <div
        className="g-recaptcha"
        ref={div => {
          this.recaptchaTarget = div;
        }}
      />
    );
  }
}

Recaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
  page: PropTypes.string,
};

export default Recaptcha;
