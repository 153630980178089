import React from 'react';
import PropTypes from 'prop-types';

import ResetIcon from '../../svgs/ResetIcon';
import CloseIcon from '../../svgs/CloseIcon';

import {
  FilterHeaderStyles,
  Row,
  FreeAlerts,
  Reset,
  Close,
} from './FilterHeaderStyles';

import { SaveSearchActions } from 'actions';

const FilterHeader = ({
  clearFilters,
  activeFiltersExist,
  dispatch,
  toggleFilterMenu,
}) => {
  function showGetFreeAlerts() {
    if (
      activeFiltersExist &&
      !window.location.href.includes('source=saved-search')
    ) {
      dispatch(SaveSearchActions.toggleMobileVisibility(true));
    }
  }

  return (
    <FilterHeaderStyles>
      <Row>
        <FreeAlerts {...{ activeFiltersExist }} onClick={showGetFreeAlerts}>
          Get free alerts
        </FreeAlerts>
        <Reset active={activeFiltersExist} onClick={clearFilters}>
          <ResetIcon />
          <span>Reset</span>
        </Reset>
        <Close onClick={toggleFilterMenu}>
          <CloseIcon />
          <span>Close</span>
        </Close>
      </Row>
    </FilterHeaderStyles>
  );
};

FilterHeader.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  activeFiltersExist: PropTypes.bool.isRequired,
};

export default FilterHeader;
