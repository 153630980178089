import React from 'react';

import Spinner from '../../../page-elements/Spinner';

import Overlay from '../Overlay';

import Wrapper from './Wrapper';
import Head from './Head';
import OfferTitle from './OfferTitle';
import Offer from './Offer';
import Body from './Body';
import Header from './Header';
import Title from './Title';
import Detail from './Detail';
import Bold from './Bold';
import Input from './Input';
import Button from './Button';
import Footer from './Footer';

class DetailsPanel extends React.PureComponent {
  render() {
    const {
      vrmLookupDetails,
      valueMyCarDetailsTitle,
      title,
      registrationYear,
      features,
      vrm,
      mileage,
      updateMileage,
      notYourCar,
      value,
      offer,
      conf,
      valuation,
      currentlyFetching,
      handleAcceptOfferClick,
    } = this.props;

    return (
      <Wrapper>
        {Object.keys(vrmLookupDetails).length === 0 && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}
        {offer && (
          <Head>
            <OfferTitle>Your Offer</OfferTitle>
            <Offer>
              {conf.currencySymbol}
              {valuation}
            </Offer>
          </Head>
        )}
        <Body>
          {valueMyCarDetailsTitle && <Header>{valueMyCarDetailsTitle}</Header>}
          <Title>{title}</Title>
          <Detail>
            <Bold>Year:</Bold> {registrationYear}
          </Detail>
          {(value || offer) && (
            <Detail>
              <Bold>{value && 'Est. '}Mileage:</Bold>{' '}
              {value && features.editableMileage ? (
                <Input onChange={updateMileage} type="text" value={mileage} />
              ) : (
                mileage
              )}
            </Detail>
          )}
          <Detail>
            <Bold>Registration:</Bold> {vrm}
          </Detail>
          {offer && (
            <Button
              loading={currentlyFetching.sellMyCar}
              onClick={handleAcceptOfferClick}
            >
              {currentlyFetching.sellMyCar ? <Spinner /> : 'Accept Offer'}
            </Button>
          )}
        </Body>
        {!offer && <Footer onClick={notYourCar}>NOT YOUR CAR?</Footer>}
      </Wrapper>
    );
  }
}

export default DetailsPanel;
