import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import clearFix from 'polished/lib/mixins/clearFix';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  ${clearFix()} text-align: center;
  margin: ${rem('36px')} auto ${rem('46px')};

  ${media.bpMedium`
    width: ${rem('786px')};
    text-align: left;
    margin: ${rem('80px')} auto ${rem('90px')};
  `};
`;

const TextContent = styled.div`
  h1 {
    font-size: ${rem('70px')};
    font-weight: 600;
    color: #5d5d5d;
    margin: 0;

    ${media.bpMedium`
      font-size: ${rem('100px')};
      margin-top: ${rem('34px')};
    `};
  }

  p {
    font-size: ${rem('19px')};
    font-weight: 400;
    color: #a3a3a3;
    margin: ${rem('18px')} 0 ${rem('38px')};

    ${media.bpMedium`
      font-size: ${rem('24px')};
      margin: ${rem('15px')} 0 ${rem('32px')};
    `};
  }

  a {
    text-decoration: none;
  }

  button {
    display: block;
    margin: 0 auto ${rem('30px')};
    padding: ${rem('7px')} ${rem('20px')};
    border-radius: ${rem('4px')};
    border: none;
    background: ${props => props.theme.orange};
    color: white;
    cursor: pointer;

    ${media.bpMedium`
      margin-left: 0;
    `};
  }

  ${media.bpMedium`
    float: left;
    width: 50%;
  `};
`;

const Image = styled.img`
  height: ${rem('109px')};

  ${media.bpMedium`
    height: ${rem('305px')};
  `};
`;

const NotFound = () => (
  <Wrapper>
    <TextContent>
      <h1>404</h1>
      <p>Looks like you&apos;ve gotten lost...</p>
      <Link to="/">
        <button type="button">Take me home</button>
      </Link>
    </TextContent>
    <div>
      <Image src="/images/sat_nav.png" />
    </div>
  </Wrapper>
);

export default NotFound;
