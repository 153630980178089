import React from 'react';

import { Section, Container, Heading, OrangeText, Image } from './GraphStyles';

const Graph = () => (
  <Section>
    <Container>
      <Heading>
        The <OrangeText>UK&apos;s BIGGEST</OrangeText> car search engine
      </Heading>
      <Image alt="" src="/images/graph.png" />
    </Container>
  </Section>
);

export default Graph;
