import styled from 'styled-components';

const Dave = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 95%;
`;

export default Dave;
