import React from 'react';
import qs from 'query-string';
import { SearchForm } from '@oodlefinance/shared-components';

import Logo from '../../../page-elements/Logo';

import cars from './cars';

import Header from './Header';
import SearchWrapper from './SearchWrapper';
import CarScroller from './CarScroller';
import ScrollIcon from './ScrollIcon';

import searchFormSearch from 'services/search-form-search';

class TopSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCar: null,
      inputHasValue:
        props.searchString || qs.parse(props.location.search).s || '',
    };

    this.selectCar = this.selectCar.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
  }

  selectCar(number) {
    this.setState({
      selectedCar: number + 1,
    });
  }

  updateInput(inputHasValue) {
    this.setState({ inputHasValue });
  }

  submitSearch(e, { value }) {
    e.preventDefault();

    const { dispatch, features, flags, history } = this.props;
    searchFormSearch(dispatch, features, history, 'Home', flags, value);
  }

  render() {
    const { searchString, location, dispatch, features } = this.props;
    const { selectedCar, inputHasValue } = this.state;

    return (
      <Header>
        <Logo {...{ large: true, fadeIn: true }} />
        <SearchWrapper>
          <SearchForm
            {...{
              location: { ...location, query: qs.parse(location.search) },
              searchString,
              speech: true,
            }}
            autoFocusInput
            boxShadow
            carSelectedCallback={this.selectCar}
            fadeIn
            placeholders={cars.map(car => car.placeholder)}
            searchFormLocation="Home"
            submitFormCallback={this.submitSearch}
            typePlaceholders
            updateInputCallback={this.updateInput}
          />
        </SearchWrapper>
        <CarScroller
          {...{ cars, selectedCar, inputHasValue, dispatch, features }}
        />
        <ScrollIcon>
          <svg version="1.1" viewBox="-127 257 319 192" x="0px" y="0px">
            <polygon
              points="-94.6,257 -127,289.3 33,449 33,449 33,449 193,289.3 160.6,257 33,384.3 "
              style={{ fill: '#E4E4E4' }}
            />
          </svg>
        </ScrollIcon>
      </Header>
    );
  }
}

export default TopSection;
