import React from 'react';
import PropTypes from 'prop-types';

// Services

// Components
import Wrapper from './Wrapper';
import Term from './Term';
import Suggestion from './Suggestion';

import runSearch from 'services/run-search';

const Suggestions = ({ dispatch, suggestions, features, history }) => {
  /**
   * Function to handle the clicking of the did you mean link
   */
  function handleClick(query = '') {
    runSearch(dispatch, `q=${query}&s=${query}`, features, history, {});
  }

  // Get the values
  const { newQuery, highLightedQuery } = suggestions;

  // If there are any suggestions to make, show them
  if (newQuery && highLightedQuery) {
    // Generate the terms to show
    const terms = highLightedQuery.map(term => (
      <Term key={term.value} {...{ ...term }}>
        {term.value}
      </Term>
    ));

    // Return the did you mean link/text
    return (
      <Wrapper>
        <Suggestion onClick={handleClick.bind(this, newQuery)}>
          Did you mean: {terms}?
        </Suggestion>
      </Wrapper>
    );
  }

  // Making sure elements don't move across the page between searches
  return <Wrapper />;
};

Suggestions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
};

export default Suggestions;
