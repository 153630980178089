import React from 'react';

import MyCarEmailSent from './EmailSent';

const valuationText = 'Your vehicle valuation has been sent to your inbox';
const valuationButtonText = 'Find your next car';

const ValueMyCarEmailSent = ({ dispatch, features, flags, history }) => (
  <MyCarEmailSent
    {...{
      valuationText,
      valuationButtonText,
      history,
      purpose: 'Value',
      dispatch,
      features,
      flags,
    }}
  />
);

export default ValueMyCarEmailSent;
