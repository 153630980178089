/**
 * SeoPageData reducer
 */

function seoPageData(state = {}, action) {
  if (action.type === 'MANUFACTURER_RESPONSE') {
    return { ...action.data };
  }

  return state;
}

export default seoPageData;
