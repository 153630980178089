import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: 0 ${rem('18px')} ${rem('14px')};
  width: 100%;

  ${media.bpTablet`
    margin: 0 auto;
    padding: 0 0 ${rem('6px')} 0;
  `}
`;

export default Wrapper;
