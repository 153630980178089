import React from 'react';

import SVG from '../SVG';

const ArrowLeft = () => (
  <SVG
    role="presentation"
    viewBox="0 0 59 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5919003,46.0048682 L46.0032593,1.59432049 C47.0304399,0.5663286 48.4016366,0 49.8637056,0 C51.3257745,0 52.6969713,0.5663286 53.7241518,1.59432049 L56.9947401,4.86409736 C59.1229348,6.99472617 59.1229348,10.4576065 56.9947401,12.5849899 L19.7014338,49.8782961 L57.0361194,87.2129817 C58.0632999,88.2409736 58.6304399,89.611359 58.6304399,91.0726166 C58.6304399,92.535497 58.0632999,93.9058824 57.0361194,94.9346856 L53.7655311,98.2036511 C52.7375392,99.231643 51.3671539,99.7979716 49.9050849,99.7979716 C48.4430159,99.7979716 47.0718192,99.231643 46.0446386,98.2036511 L1.5919003,53.7525355 C0.562285697,52.7212982 -0.0032315445,51.3444219 -1.37732605e-05,49.8807302 C-0.0032315445,48.411359 0.562285697,47.0352941 1.5919003,46.0048682 Z"
      fill="currentColor"
    />
  </SVG>
);

export default ArrowLeft;
