import has from 'has';

import alphaComparison from '../alpha-comparison';

const allowedQueryString = [
  'pageSize',
  'page',
  'q',
  'sortField',
  'sortOrder',
  's',
  'showMore',
  'unsubscribe',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'w.v',
  'pid',
  'pageType',
  'source',
];

/**
 * Removes any duplicate values from the array
 */
const dedupe = (sections = []) => {
  // Object to store first occurence of elements
  const store = {};

  // Loop over the sections and filter elements that are in the store
  sections.filter(e => {
    // Remove any null values
    if (!e) {
      return false;
    }

    // Get the key of the section
    const key = e.split('/')[0];

    // If the key isn't in the store, add it
    if (has(store, key) === false) {
      store[key] = true;
      return true;
    }

    return false;
  });

  return sections;
};

/**
 * Finds the sections in the url and orders them
 * @param  {String} url The original URL that needs to be ordered
 * @return {String}     The new ordered URL
 */
const sortFilters = (url = '') => {
  // Get the query for later use
  const [oldUrl, query = ''] = url.split('?');

  // remove the search from the url
  const searchlessUrl = oldUrl.replace('/search', '');

  // Create a blank sections array to populate
  const sections = [];

  // Create the array of url parts and strip any invalid parts
  const parts = (searchlessUrl.startsWith('/')
    ? searchlessUrl.substr(1)
    : searchlessUrl
  )
    .split('/')
    .filter(s => typeof s === 'string' && s.length > 0);

  // Split the URL into sections
  // e - current item in the loop
  // i - index of the loop
  // a - original array we're looping over
  parts.forEach((e, i, a) => {
    // Get the next element in the array
    const next = a[i + 1];

    // Ensure there are values to add
    if (!next || !e) {
      return;
    }

    // If we're on the key of the section return it with the next
    if (typeof e === 'string' && i % 2 === 0 && typeof next === 'string') {
      sections.push(`${e}/${next}`);
    }
  });

  // Sort the sections by first character and dedupe
  const sortedSections = dedupe(sections).sort(alphaComparison);

  // Sort the values of each section
  for (let i = 0; i < sortedSections.length; i++) {
    // Get the key and values
    const [key, values] = sortedSections[i].split('/');

    // Remove duplicate values
    const cleanedValues = Array.from(new Set(values.split(',')));

    // If there are multiple values
    if (cleanedValues.length !== 0) {
      // Split the values by comma and re-order
      const sortedValues = cleanedValues.sort(alphaComparison).join(',');

      // Replace the old values order with the new
      sortedSections[i] = `${key}/${sortedValues}`;
    }
  }

  // Join the sections by '/'
  let newUrl = sortedSections.join('/');

  // Reappend the query if it exists
  if (query.length !== 0) {
    newUrl += `?${query}`;
  }

  // Add a slash if we removed one
  if (searchlessUrl.startsWith('/') === true) {
    newUrl = `/${newUrl}`;
  }

  // If we removed search, add it back
  if (oldUrl.startsWith('/search') === true) {
    newUrl = `/search${newUrl}`;
  }

  return newUrl;
};

/**
 * A function that will update or add a key value in the url using a query pair
 * @param  {String} url     The url string we're updating
 * @param  {Array}  query   The query array with the key and value as the
 * @return {String}         The updated url with the new Value
 */
const addKeyValue = (url = '', queryPair) => {
  // Ensure that the queryPair is an array and not just undefined
  if (!Array.isArray(queryPair)) {
    return url;
  }

  // If the key isn't in the url already, add it and return
  if (!url.includes(queryPair[0])) {
    return `${url.endsWith('/') ? url : `${url}/`}${queryPair[0]}/${
      queryPair[1]
    }`;
  }

  // Find the index of the '/' in the url
  const index = url.indexOf(queryPair[0]) + queryPair[0].length;

  // Get the current value(s) of the keys
  const currentValue = url
    .substr(index, url.length)
    .match(/([a-z0-9]+,?)+/i)[0];

  // Split the current Key value into an array
  const splitValues = currentValue.split(',');

  // Add in the new value if it doesn't exist already
  if (!splitValues.includes(queryPair[1])) {
    splitValues.push(queryPair[1]);
  }

  // Sort the values alphabetically
  const sortedValues = splitValues.sort(alphaComparison);

  // Recreate the value string and return
  const newValue = sortedValues.join(',');
  return url.replace(currentValue, newValue);
};

/**
 * Gets a url and converts the query section into a canonical url if it's invalid
 * @param  {String} url The given url string
 * @return {String}     The new corrected URL
 */
const makeUrlCanonical = (url = '') => {
  // No point running through function if URL is empty
  if (url.length === 0) {
    return '';
  }

  // Get the current path and query
  const [originalPath = '', query = ''] = url.split('?');

  // Trim off the trailing slash if it has one
  let trimmedUrl = originalPath.endsWith('/')
    ? originalPath.slice(0, -1)
    : originalPath;

  // Create a newQuery variable to store the new query (after removing unallowed values)
  let newQuery = '?';

  // If there's no query then sort the url and return
  if (query.length === 0) {
    return sortFilters(trimmedUrl);
  }

  // Split up the query values into an array of queries
  const queries = query.split('&');

  // Loop over the queries
  for (let i = 0; i < queries.length; i++) {
    // Get the key value pair
    const queryPair = queries[i].split('=');

    // Check if the current query is an approved query value
    if (!allowedQueryString.includes(queryPair[0])) {
      // Add to URL
      trimmedUrl = addKeyValue(trimmedUrl, queryPair);
    } else {
      // Add to the new Query value
      newQuery += `${newQuery.length > 1 ? '&' : ''}${queries[i]}`;
    }
  }

  // Add the query if one exists
  if (newQuery.length > 1) {
    trimmedUrl += newQuery;
  }

  // Sort the URL sections
  return sortFilters(trimmedUrl);
};

export { makeUrlCanonical, sortFilters };
