import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Search = styled.div`
  overflow: hidden;
  flex: 1 1 auto;

  ${media.bpTablet`
    max-width: ${rem('412px')};
    flex: 1 1 ${rem('412px')};

    ${p =>
      p.pathname.startsWith('/search') &&
      css`
        max-width: ${rem('455px')};
        flex: 1 1 ${rem('455px')};
        margin: 0 ${rem('40px')};
      `}
  `}
`;

export default Search;
