import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from '../../../../styles/mixins/media';

import container from 'styles/mixins/container';

const Banner = styled.section`
  background: ${props => props.theme.lighterGrey};
  padding: ${rem('25px')} 0 ${rem('15px')};
  text-align: center;
  margin-bottom: ${rem('25px')};

  ${media.bpsLarge`
    padding: ${rem('25px')} 0;
    text-align: left;
    margin-bottom: ${rem('40px')};
  `};
`;

const Container = styled.div`
  ${container}

  ${media.bpsLarge`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${rem('20px')};
    padding-right: ${rem('20px')};
  `}

  ${media.bpDesktop`
    padding: 0;
  `}
`;

const Text = styled.p`
  font-family: '${props => props.theme['main-header-font']}', sans-serif;
  color: ${props => props.theme.mediumGrey};
  font-size: ${rem('16px')};
  margin-bottom: ${rem('25px')};

  ${media.bpsLarge`
    margin-bottom: 0;
    flex: 1 0 auto;
  `}
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.bpSmall`
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

const FordLogo = styled.img`
  width: ${rem('190px')};
  height: ${rem('60px')};
  margin: 0 ${rem('5px')} ${rem('30px')};

  ${media.bpSmall`
    margin: 0 ${rem('20px')} ${rem('5px')};
  `} ${media.bpsLarge`
    margin: 0 ${rem('40px')} 0 0;
  `};
`;

const ImperialLogo = styled.img`
  width: ${rem('195px')};
  height: ${rem('22px')};
  margin: 0 ${rem('5px')} ${rem('30px')};

  ${media.bpSmall`
    margin: 0 ${rem('20px')} ${rem('5px')};
  `} ${media.bpsLarge`
    margin: 0 ${rem('40px')} 0 0;
  `};
`;

const PeterVardyLogo = styled.img`
  width: ${rem('224px')};
  height: ${rem('48px')};
  margin: 0 ${rem('5px')} ${rem('20px')};

  ${media.bpSmall`
    margin: 0 ${rem('20px')} ${rem('5px')};
  `} ${media.bpsLarge`
    margin: 0;
  `};
`;

export {
  Banner,
  Container,
  Text,
  Logos,
  FordLogo,
  ImperialLogo,
  PeterVardyLogo,
};
