import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  position: relative;
  padding: ${rem('30px')} 0 ${rem('40px')};
  background: url('/images/help-me-choose/hero_pattern.png') repeat;

  ${media.bpTablet`
    padding: ${rem('50px')} 0 ${rem('90px')};
  `};
`;

export default Wrapper;
