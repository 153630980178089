import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    advertData: PropTypes.arrayOf(
      PropTypes.shape({
        advertId: PropTypes.string,
        cpc: PropTypes.number,
        dealerDomain: PropTypes.string,
        dealerId: PropTypes.string,
        dealerName: PropTypes.string,
        dealerTelephone: PropTypes.string,
        description: PropTypes.string,
        hashedUri: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.string),
        impressionId: PropTypes.string,
        latitude: PropTypes.number,
        links: PropTypes.shape({
          colour: PropTypes.string,
          fuel: PropTypes.string,
          gearbox: PropTypes.string,
          locality: PropTypes.string,
          model: PropTypes.string,
          range: PropTypes.string,
          registrationLetters: PropTypes.string,
          registrationYear: PropTypes.string,
          showroom: PropTypes.string,
        }),
        locality: PropTypes.string,
        longitude: PropTypes.number,
        mapUri: PropTypes.string,
        postcode: PropTypes.string,
        premiumListing: PropTypes.bool,
        price: PropTypes.number,
        region: PropTypes.string,
        sellerTags: PropTypes.arrayOf(PropTypes.string),
        showroom: PropTypes.string,
        telephone: PropTypes.arrayOf(PropTypes.string),
        uri: PropTypes.string,
      }),
    ),
    advertType: PropTypes.string,
    capId: PropTypes.string,
    carId: PropTypes.string,
    colour: PropTypes.string,
    fuel: PropTypes.string,
    gearbox: PropTypes.string,
    idMatch: PropTypes.bool,
    manufacturer: PropTypes.string,
    mileage: PropTypes.number,
    monthlyCost: PropTypes.shape({
      annualApr: PropTypes.number,
      apr: PropTypes.number,
      deposit: PropTypes.number,
      docFee: PropTypes.number,
      flatRate: PropTypes.number,
      monthlyPayment: PropTypes.number,
      optFee: PropTypes.number,
      term: PropTypes.number,
      totalRepayable: PropTypes.number,
    }),
    priority: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.string),
    range: PropTypes.string,
    registrationLetters: PropTypes.string,
    registrationYear: PropTypes.number,
    runningCosts: PropTypes.shape({
      fuelPrice: PropTypes.number,
    }),
    score: PropTypes.number,
    thumbnails: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    vehicleTags: PropTypes.arrayOf(PropTypes.string),
    vrn: PropTypes.string,
  }),
);
