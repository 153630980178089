import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Button from '../../shared/Button';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  right: ${props => (props.open ? '0' : '-100%')};
  transition: all 300ms ease;
  z-index: 6;
  background-color: #fff;
  box-shadow: ${props =>
    props.open ? `${rem('4px')} 0 ${rem('8px')} 0 rgba(0,0,0,0.1)` : 'none'};

  ${media.bpTablet`
    right: auto;
    position: static;
    height: auto;
    flex: 0 0 ${rem('260px')};
    max-width: ${rem('260px')};
    background: ${props => props.theme.lighterGrey};
    border-right: 1px solid #e3e3e4;
  `};
`;

const Body = styled.div`
  /* height equal to 100% - header and footer */
  height: calc(100% - ${rem('133px')});
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${media.bpTablet`
    overflow: visible;
  `};
`;

const Footer = styled.div`
  height: ${rem('63px')};
  padding: ${rem('11px')} ${rem('17px')};
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  color: white;
  border-top: 1px solid #e3e3e4;
  box-shadow: 0 ${rem('-4px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.1);

  ${media.bpTablet`
    display: none;
  `};
`;

const SearchCarsButton = styled(Button)`
  font-size: ${rem('17px')};
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${props => props.theme.blueGradient};
  text-transform: none;

  svg {
    width: ${rem('17px')};
    height: ${rem('17px')};
    margin-right: ${rem('9px')};
    vertical-align: text-top;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

class Content extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.open !== this.props.open) {
      return false;
    }

    return true;
  }

  render() {
    return <ContentWrapper>{this.props.children}</ContentWrapper>;
  }
}

export { Wrapper, Content, Body, Footer, SearchCarsButton };
