import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div(
  ({ featured, safeAndSound, safeAndSoundVip, imageFlashClass }) => css`
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  margin: 0 ${rem('18px')} ${rem('18px')};
  position: relative;
  width: 300px; /* This is the absolute width of the adverts, so should be in px */

  @media(min-width:  ${rem('640px')}) {
    margin-bottom: ${rem('36px')};
    justify-content: flex-start;

    ${featured &&
      css`
        width: auto;
        margin-left: auto;
        margin-right: auto;
      `}
  }

  ${featured &&
    css`
      @media (min-width: ${rem('720px')}) {
        margin-left: ${rem('18px')};
        margin-right: ${rem('18px')};
      }
    `}

  ${featured &&
    css`
      @media (min-width: ${rem('830px')}) {
        width: 100%;
      }
    `}

  /* Safe and Sound Triangle */
  ${safeAndSound &&
    css`
      &:before {
        content: url('/images/SS_approved_triangle.png');
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    `}

  /* Safe and Sound VIP Triangle */
  ${safeAndSoundVip &&
    css`
      &:before {
        content: url('/images/SS_vip_approved_triangle.png');
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    `}

  ${imageFlashClass &&
    css`
    & > div > a:after {
      content: url('/images/advert/${imageFlashClass}.png');
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      padding: 0;
      height: 100px;
    }
  `}
`,
);

export default Wrapper;
