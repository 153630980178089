import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  position: absolute;
  top: ${rem('-10px')};
  left: ${rem('-10px')};
  width: ${rem('40px')};
  height: ${rem('30px')};

  ${media.bpTablet`
    top: ${rem('-28px')};
    left: ${rem('-25px')};
    width: ${rem('80px')};
    height: ${rem('60px')};
  `}
`;

const G = styled.g`
  fill: white;
  opacity: 0.08;
`;

class QuoteMarks extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 74.5 58.5" x="0px" y="0px">
        <G>
          <path
            d="M32.3,9.5c-10.5,5.1-15.7,11.1-15.7,18c4.5,0.5,8.2,2.3,11.1,5.4c2.9,3.1,4.3,6.7,4.3,10.8c0,4.3-1.4,8-4.3,11
            c-2.8,3-6.4,4.5-10.6,4.5c-4.8,0-8.9-1.9-12.3-5.8c-3.4-3.9-5.2-8.6-5.2-14.1c0-16.6,9.2-29.5,27.7-38.9L32.3,9.5z M75,9.5
            c-10.4,5.1-15.5,11.1-15.5,18c4.4,0.5,8.1,2.3,11,5.4c2.9,3.1,4.3,6.7,4.3,10.8c0,4.3-1.4,8-4.2,11c-2.8,3-6.3,4.5-10.6,4.5
            c-4.8,0-8.9-1.9-12.4-5.8c-3.5-3.9-5.2-8.6-5.2-14.1c0-16.6,9.3-29.5,27.8-38.9L75,9.5z"
          />
        </G>
      </Svg>
    );
  }
}

export default QuoteMarks;
