import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import List from './List';

const Wrapper = styled.div`
  border-top: 1px solid #e6e6e6;
`;

const Title = styled.h3`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('15px')};
  margin: ${rem('30px')} ${rem('20px')} ${rem('25px')};

  @media (min-width: ${rem('1172px')}) {
    font-size: ${rem('18px')};
    margin: ${rem('50px')} ${rem('20px')} ${rem('40px')};
  }
`;

const ViewMore = styled.p`
  color: #198ddc;
  font-weight: 500;
  margin-left: ${rem('20px')};
  margin-right: ${rem('20px')};
  margin-bottom: ${rem('20px')};

  @media (min-width: ${rem('1172px')}) {
    display: none;
  }
`;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMore: false,
    };
  }

  render() {
    const { title, list } = this.props;
    const { viewMore } = this.state;

    return (
      <Wrapper>
        <Title>{title}</Title>
        <List
          {...{ viewMore, list: [list.slice(0, 13), list.slice(13, -1)] }}
        />
        {!viewMore && (
          <ViewMore
            onClick={() => this.setState(p => ({ viewMore: !p.viewMore }))}
          >
            View more...
          </ViewMore>
        )}
      </Wrapper>
    );
  }
}

Sidebar.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
};

export default Sidebar;
