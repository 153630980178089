import React from 'react';
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Link } from 'react-router-dom';

import media from 'styles/mixins/media';

const LogoLink = styled(({ className, children, to }) => (
  <Link {...{ className, children, to }} />
))`
  display: none;

  img {
    height: ${rem('30px')};
    width: ${rem('112px')};
  }

  ${media.bpTablet`
    margin-right: ${rem('26px')};
    display: block;
  `}

  ${p =>
    p.pathname === '/' &&
    css`
      display: block;
    `}

  ${p =>
    p.pathname.startsWith('/search') &&
    css`
      display: inline-block;

      svg {
        height: ${rem('18px')};
      }

      ${media.bpTablet`
        margin-right: 0;

        svg {
          height: ${rem('30px')};
        }
      `}
    `}
`;

export default LogoLink;
