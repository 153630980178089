import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Button = styled.button`
  background: ${props => props.theme.orange};
  width: 100%;
  border: none;
  padding: ${rem('7px')};
  cursor: pointer;
`;

export default Button;
