/**
 * Pure function for populating the suggestions prop
 */
function suggestions(state = {}, action) {
  if (action.type === 'SEARCH_RESPONSE') {
    return { ...action.data.suggestions };
  }

  return state;
}

export default suggestions;
