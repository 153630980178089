import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Indicators = styled.div`
  position: absolute;
  bottom: ${rem('-15px')};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`;

export default Indicators;
