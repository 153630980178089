import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import container from 'styles/mixins/container';
import media from 'styles/mixins/media';

const Section = styled.section`
  padding-bottom: ${rem('40px')};
  text-align: center;
  max-width: ${rem('475px')};
  margin: 0 auto;

  ${media.bpsMedium`
    padding-bottom: ${rem('50px')};
    max-width: ${rem('550px')};
  `} ${media.bpsLarge`
    padding-bottom: ${rem('60px')};
    max-width: none;
    text-align: left;
  `};
`;

const Container = styled.div`
  ${container} ${media.bpsLarge`
    padding-left: ${rem('100px')};
    padding-right: ${rem('100px')};
  `};
`;

const Heading = styled.h2`
  font-size: ${rem('18px')};
  font-weight: 400;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  margin: 0 0 ${props => (props.withSubHeading ? rem(15) : rem(25))};
  color: ${props => props.theme.mediumGrey};

  ${media.bpsMedium`
    font-size: ${rem('30px')};
    margin: 0 0 ${props => (props.withSubHeading ? rem(15) : rem(30))};
  `} ${media.bpsLarge`
    text-align: center;
    margin: 0 0 ${props => (props.withSubHeading ? rem(15) : rem(80))};
  `};
`;

const SubHeading = styled.h3`
  font-size: ${rem('17px')};
  font-weight: 300;
  text-align: center;
  color: ${props => props.theme.mediumGrey};
  margin: 0 0 ${rem('25px')};

  ${media.bpsMedium`
    margin: 0 0 ${rem('30px')};
  `} ${media.bpsLarge`
    margin: 0 0 ${rem('80px')};
    font-size: ${rem('18px')};
  `};
`;

const PartnerLogos = styled.img`
  max-width: 400px;
  max-height: 250px;
`;

const Hr = styled.hr`
  border: 0;
  height: ${rem('1px')};
  background: #e0e1e3;
`;

export { Section, Container, Heading, SubHeading, PartnerLogos, Hr };
