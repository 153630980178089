import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('92px')};
  height: ${rem('106px')};
  flex: 0 0 ${rem('100px')};

  ${props =>
    props.large &&
    media.bpsMedium`
      width: ${rem('215px')};
      height: ${rem('247px')};
      flex: 0 0 ${rem('215px')};
  `};
`;

const Path1 = styled.path`
  opacity: 0.3;
  fill: url(#SVGID_1_);
`;

const Path2 = styled.path`
  opacity: 0.3;
  fill: url(#SVGID_2_);
`;

const Path3 = styled.path`
  fill: url(#SVGID_3_);
`;

class Premium extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 13 15" {...{ large: this.props.large }}>
        <g>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="SVGID_1_"
            x1="1.5"
            x2="1.5"
            y1="14.901"
            y2="10.901"
          >
            <stop offset="8.213141e-04" style={{ stopColor: '#FFDAC3' }} />
            <stop offset="1" style={{ stopColor: '#FF6000' }} />
          </linearGradient>
          <Path1
            d="M0.2,10.9h2.6C2.9,10.9,3,11,3,11.1v3.6c0,0.1-0.1,0.2-0.2,0.2H0.2c-0.1,0-0.2-0.1-0.2-0.2v-3.6
            C0,11,0.1,10.9,0.2,10.9z"
          />
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="SVGID_2_"
            x1="11.5"
            x2="11.5"
            y1="14.901"
            y2="7.901"
          >
            <stop offset="8.213141e-04" style={{ stopColor: '#FFDAC3' }} />
            <stop offset="1" style={{ stopColor: '#FF6000' }} />
          </linearGradient>
          <Path2
            d="M10.2,7.9h2.6C12.9,7.9,13,8,13,8.1v6.6c0,0.1-0.1,0.2-0.2,0.2h-2.6c-0.1,0-0.2-0.1-0.2-0.2V8.1
            C10,8,10.1,7.9,10.2,7.9z"
          />
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="SVGID_3_"
            x1="6.5"
            x2="6.5"
            y1="14.901"
            y2="0"
          >
            <stop offset="8.213141e-04" style={{ stopColor: '#FFDAC3' }} />
            <stop offset="1" style={{ stopColor: '#FF6000' }} />
          </linearGradient>
          <Path3
            d="M6.4,0.1L2.3,4.6c-0.1,0.1,0,0.3,0.1,0.3H5v9.8c0,0.1,0.1,0.2,0.2,0.2h2.6c0.1,0,0.2-0.1,0.2-0.2V4.9h2.6
            c0.2,0,0.3-0.2,0.1-0.3L6.6,0.1C6.6,0,6.4,0,6.4,0.1z"
          />
        </g>
      </Svg>
    );
  }
}

export default Premium;
