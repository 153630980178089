import React from 'react';
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../styles/mixins/media';
import Calendar from '../../svgs/Calendar';
import Car from '../../svgs/Car';
import Cog from '../../svgs/Cog';
import Odometer from '../../svgs/Odometer';
import Engine from '../../svgs/Engine';

const IconWrapper = styled.span`
  font-size: 1.25rem;
  margin-right: ${rem('5px')};

  @media (min-width: ${rem('600px')}) {
    margin-right: ${rem('8px')};
  }
`;

const DetailsUl = styled.ul(
  ({ theme }) => css`
    max-width: 40rem;
    margin: 0.5rem 0 1.5rem;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    color: ${theme.darkGrey};

    li {
      font-size: ${rem('13px')};
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-basis: 33.3333%;
      padding: 0.5rem;

      ${media.bpSmall`
        flex-basis: 20%;
        padding: 0;
        padding-right: 0.5rem;
      `}

      @media (min-width: ${rem('600px')}) {
        font-size: ${rem('15px')};
      }
    }
  `,
);

const Details = ({
  premiumCarPage,
  registrationYear,
  bodyStyle,
  mileage,
  gearbox,
  engineSizeL,
  fuel,
}) => {
  const details = [
    [registrationYear, Calendar, 'Calendar Icon'],
    [bodyStyle, Car, 'Body Style Icon'],
    [mileage, Odometer, 'Mileage Icon'],
    [gearbox, Cog, 'Gearbox Icon'],
    [
      fuel || engineSizeL,
      Engine,
      'Engine Icon',
      `${engineSizeL ? `${engineSizeL}L ` : ''} ${fuel}`,
    ],
  ];
  return (
    <DetailsUl premiumCarPage={premiumCarPage}>
      {details.map(
        ([data, Icon, alt, displayString]) =>
          !!data && (
            <li key={alt}>
              <IconWrapper>
                <Icon alt={alt} />
              </IconWrapper>
              {displayString || data}
            </li>
          ),
      )}
    </DetailsUl>
  );
};

export default Details;
