import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Title = styled.h1`
  font-weight: 300;
  font-size: 1.125rem;
  color: ${props => props.theme.mediumGrey};
  margin: 0 0 ${rem('20px')};
`;

export default Title;
