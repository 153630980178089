import React from 'react';

import EmailSent from '../../../svgs/EmailSent';
import Wrapper from '../Wrapper';
import Header from '../Header';
import SubmitButtonWrapper from '../SubmitButtonWrapper';

import Text from './Text';

import SubmitButton from 'shared/SubmitButton';

import runSearch from 'services/run-search';
import { myCarFindNextCar } from 'services/event-tracking';

class MyCarEmailSent extends React.Component {
  constructor(props) {
    super(props);
    this.buttonClicked = this.buttonClicked.bind(this);
  }

  buttonClicked() {
    const { purpose, dispatch, features, history } = this.props;

    myCarFindNextCar(purpose); // event tracking
    runSearch(dispatch, '', features, history, {});
  }

  render() {
    const { valuationText, valuationButtonText } = this.props;

    return (
      <Wrapper emailSent>
        <Header emailSent>BOOM!</Header>
        <Text>{valuationText}</Text>
        <EmailSent />
        <SubmitButtonWrapper emailSent>
          <SubmitButton stretch onClick={this.buttonClicked}>
            {valuationButtonText}
          </SubmitButton>
        </SubmitButtonWrapper>
      </Wrapper>
    );
  }
}

export default MyCarEmailSent;
