import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Previous = styled.button`
  position: absolute;
  left: ${rem('-14px')};
  top: 50%;
  transform: translateY(-50%);
  width: ${rem('28px')};
  height: ${rem('28px')};
  background: ${props => props.theme.orange};
  opacity: 0.9;
  border: none;
  border-radius: ${rem('4px')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: ${rem('10.5px')};
    height: ${rem('16px')};
    transform: rotate(180deg);
  }

  ${media.bpTablet`
    left: ${rem('-21px')};
    width: ${rem('42px')};
    height: ${rem('42px')};
    padding-right: ${rem('9px')};

    svg {
      width: ${rem('15px')};
      height: ${rem('24px')};
    }
  `};
`;

export default Previous;
