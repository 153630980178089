import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../../../styles/mixins/media';

const fadeIn = keyframes`
  0%, 50% {
    opacity: 0;
    top: ${rem('-54px')};
  }
  100% {
    opacity: 1;
    top: ${rem('-74px')};
  }
`;

const fadeInTablet = keyframes`
  0%, 50% {
    opacity: 0;
    top: ${rem('-95px')};
  }
  100% {
    opacity: 1;
    top: ${rem('-115px')};
  }
`;

const Tooltip = styled.div`
  display: none;

  ${props =>
    props.selected &&
    !props.inputHasValue &&
    css`
    font-size: ${rem('14px')};
    white-space: nowrap;
    padding: ${rem('15px')} ${rem('22px')};
    position: absolute;
    top: ${rem('-74px')};
    left: 50%;
    transform: translateX(-50%);
    color: ${props.theme.mediumGrey};
    display: block;
    background: white;
    border: ${rem('1px')} solid #f1f1f1;
    border-radius: ${rem('16px')};
    filter: drop-shadow(${rem('1px')} ${rem('2px')} ${rem(
      '6px',
    )} rgba(0,0,0,0.13));
    animation: 2s ${fadeIn} ease-out;

    ${media.bpTablet`
      font-size: ${rem('18px')};
      padding: ${rem('29px')} ${rem('38px')};
      top: ${rem('-115px')};
      border-radius: ${rem('18px')};
      animation: 2s ${fadeInTablet} ease-out;
    `}

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 9px;
      margin-left: -9px;

      ${media.bpTablet`
        border-width: ${rem('18px')};
        margin-left: ${rem('-18px')};
      `}
  `}
`;

export default Tooltip;
