import React from 'react';

import FilterArrow from '../../../../svgs/FilterArrow';

import Wrapper from './Wrapper';
import Content from './Content';
import Slide from './Slide';
import Previous from './Previous';
import Next from './Next';
import Indicators from './Indicators';
import Indicator from './Indicator';

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.select = this.select.bind(this);
  }

  previous() {
    this.setState(state => ({
      selected: state.selected === 0 ? 4 : state.selected - 1,
    }));
  }

  next() {
    this.setState(state => ({
      selected: state.selected === 4 ? 0 : state.selected + 1,
    }));
  }

  select(number) {
    this.setState({
      selected: number,
    });
  }

  render() {
    const { selected } = this.state;

    return (
      <Wrapper>
        <Content>
          <Slide {...{ selected }}>
            <div>
              <img
                alt="Team Awards"
                src="/images/team-slider/team_awards.jpg"
              />
            </div>
            <div>
              <img
                alt="Car Dealer Expo"
                src="/images/team-slider/cardealer_expo.jpg"
              />
            </div>
            <div>
              <img
                alt="Carsnip uniform"
                src="/images/team-slider/carsnip_uniform.jpg"
              />
            </div>
            <div>
              <img alt="Night Out" src="/images/team-slider/night_out.jpg" />
            </div>
            <div>
              <img alt="Track Day" src="/images/team-slider/track_day.jpg" />
            </div>
          </Slide>
          <Previous onClick={this.previous}>
            <FilterArrow colour="white" />
          </Previous>
          <Next onClick={this.next}>
            <FilterArrow colour="white" />
          </Next>
          <Indicators>
            {[0, 1, 2, 3, 4].map(num => (
              <Indicator
                onClick={() => this.select(num)}
                selected={selected === num}
              />
            ))}
          </Indicators>
        </Content>
      </Wrapper>
    );
  }
}

export default Slider;
