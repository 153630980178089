import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const MainWrapper = styled.section`
  padding-top: ${rem('30px')};
  border-bottom: ${rem('1px')} solid #454b53;

  ${media.bpTablet`
    padding: ${rem('50px')} 0 ${rem('45px')};
  `}

  @media(min-width: ${rem('1152px')}) {
    display: flex;
  }
`;

export default MainWrapper;
