import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Card from './Card';

const Wrapper = styled.div`
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('10px')};
  padding: 0 ${rem('18px')};
`;

const Title = styled.h3`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('21px')};
  text-align: center;
  margin-bottom: ${rem('30px')};

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('22px')};
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${rem('1216px')};
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${rem('888px')}) {
    margin-bottom: ${rem('55px')};
  }
`;

const ManufacturerCards = ({ title, list, dispatch, features, flags }) => (
  <Wrapper id="by-manufacturer">
    <Title>{title}</Title>
    <Cards>
      {list.map(manufacturer => (
        <Card
          key={manufacturer.name}
          {...{ ...manufacturer, dispatch, features, flags }}
        />
      ))}
    </Cards>
  </Wrapper>
);

ManufacturerCards.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default ManufacturerCards;
