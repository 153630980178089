import request from 'request';

/**
 * Function to get manufacturer data
 */
function getManufacturerData() {
  return dispatch =>
    new Promise((resolve, reject) => {
      request.get('/api/manufacturers', (err, res) => {
        // If there is an error
        if (err) {
          reject(res);
          return dispatch({ type: 'ERR', data: res, hasCatch: true });
        }

        resolve(res);
        return dispatch({ type: 'MANUFACTURER_RESPONSE', data: res });
      });
    });
}

export { getManufacturerData };
