import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    margin-bottom: ${rem('30px')};
  }
`;

export default List;
