import styled, { css } from 'styled-components';

import media from 'styles/mixins/media';

export default styled.span(
  ({ value, emailSent, marginLeft }) => css`
    display: inline-block;
    width: ${!value && '100%'};
    margin-bottom: ${emailSent && '1rem'};

    ${media.bpTablet`
      width: ${value ? '8.75rem' : '18.75rem'};
      margin-left: ${marginLeft};
      float: ${value && 'left'};
    `}
  `,
);
