import React from 'react';
import styled from 'styled-components';

import SVG from './SVG';

const Path = styled.path`
  fill: currentColor;
  stroke: currentColor;
  stroke-miterlimit: 10;
`;

const CloseIcon = () => (
  <SVG viewBox="0 0 21 21" x="0px" y="0px">
    <Path
      d="M12,10.5l8.2-8.2c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-8.2,8.2L2.2,0.8c-0.4-0.4-1-0.4-1.4,0
      c-0.4,0.4-0.4,1,0,1.4l8.3,8.3l-8.3,8.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l8.3-8.3l8.3,8.3c0.4,0.4,1,0.4,1.4,0
      c0.4-0.4,0.4-1,0-1.4L12,10.5z"
      id="Close"
    />
  </SVG>
);

export default CloseIcon;
