/**
 * Updates the prop that hides or shows the mobile header
 * @param {Boolean} newValue  The value to update the prop with
 */
function toggleMobileHeader(newValue) {
  return {
    type: 'UPDATE_SHOW_MOBILE_HEADER',
    data: newValue,
  };
}

export { toggleMobileHeader };
