import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  margin-top: ${rem('20px')};
  margin-bottom: ${rem('32px')};
  border-radius: ${rem('4px')};
  background-color: ${props => props.theme.lighterGrey};
  border: ${rem('1px')} solid #e0e0e0;
  color: ${props => props.theme.mediumGrey};
  box-shadow: 0 0 ${rem('15px')} ${rem('4px')} rgba(0, 0, 0, 0.1);
  position: relative;

  ${media.bpTablet`
    margin-top: ${rem('50px')};
    width: ${rem('345px')};
    float: left;
  `};
`;

export default Wrapper;
