import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('30px')};
  padding: 0 ${rem('18px')};
  border-top: 1px solid #eff0f1;
  border-bottom: 1px solid #eff0f1;

  @media (min-width: ${rem('1172px')}) {
    max-width: ${rem('1116px')};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${rem('60px')};
    padding: 0 0 ${rem('30px')} ${rem('50px')};
    border-bottom: none;
  }
`;

export default Wrapper;
