import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const FilterHeaderStyles = styled.div`
  height: ${rem('70px')};
  padding: ${rem('17px')} ${rem('12px')};
  background: ${props => props.theme.mediumGrey};
  border-bottom: none;
  display: flex;

  ${media.bpTablet`
    padding: ${rem('20px')} ${rem('18px')} ${rem('18px')};
    background: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e4;
    flex-shrink: 0;
  `};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FreeAlerts = styled.button`
  padding: 0;
  border: none;
  background: none;
  text-transform: uppercase;
  color: white;
  font-size: ${rem('11px')};
  opacity: ${props => (props.activeFiltersExist ? '1' : '0.5')};
  cursor: ${props => (props.activeFiltersExist ? 'pointer' : 'auto')};

  ${media.bpTablet`
    text-transform: none;
    color: ${props => props.theme.blue};
    font-size: ${rem('14px')};
  `};
`;

const Reset = styled.div`
  height: ${rem('22px')};
  padding-top: ${rem('7px')};
  padding-bottom: ${rem('7px')};
  box-sizing: content-box;
  opacity: ${props => (props.active ? '1' : '0.5')};
  cursor: ${props => (props.active ? 'pointer' : 'auto')};
  margin-left: auto;

  * {
    vertical-align: middle;
  }

  span {
    margin-left: ${rem('6px')};
    font-size: ${rem('11px')};
    color: #fff;
    text-transform: uppercase;
  }

  svg {
    width: ${rem('12px')};
    height: ${rem('12px')};
  }

  ${media.bpTablet`
    height: auto;
    padding: 0;

    span {
      margin-left: ${rem('8px')};
      font-size: ${rem('14px')};
      color: ${props => props.theme.blue};
      text-transform: none;
    }

    svg {
      width: ${rem('14px')};
      height: ${rem('14px')};
    }
  `};
`;

const Close = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: ${rem('20px')};
  color: ${p => p.theme.white};
  font-size: ${rem('11px')};
  text-transform: uppercase;

  span {
    margin-left: ${rem('6px')};
  }

  ${media.bpTablet`
    display: none;
  `};
`;

export { FilterHeaderStyles, Row, FreeAlerts, Reset, Close };
