import React from 'react';
import styled from 'styled-components';

import media from 'styles/mixins/media';

const Path = styled.path`
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;

  ${media.bpTablet`
    stroke: ${props => props.theme.blue};
  `};
`;

const Polyline = styled.polyline`
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;

  ${media.bpTablet`
    stroke: ${props => props.theme.blue};
  `};
`;

const ResetIcon = () => (
  <svg id="All_Filters" viewBox="0 0 12.2 12.2" x="0px" y="0px">
    <g transform="translate(0, 0)">
      <Path d="M11.7,6.1c0,3-2.5,5.5-5.5,5.5S0.6,9.1,0.6,6.1s2.5-5.5,5.5-5.5c2.2,0,4,1.2,4.9,3" />
      <Polyline points="11.5,0.4 11.1,3.7 7.8,3.2   " />
    </g>
  </svg>
);

export default ResetIcon;
