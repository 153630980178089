import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Button = styled.button`
  margin-top: ${rem('10px')};
  font-size: ${rem('12px')};
  line-height: ${rem('12px')};
  padding: ${rem('5px')} ${rem('18px')};
  border-radius: ${rem('4px')};
  border: 1px solid ${props => props.theme.secondary};
  background: transparent;
  color: ${props => props.theme.secondary};

  ${media.bpTablet`
    margin-left: ${rem('20px')};
    display: inline-block;
    margin-top: 0;
  `};
`;

export default Button;
