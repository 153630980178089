import React from 'react';

import Hero from './Hero';
import DealerBanner from './DealerBanner';
import WhyPremium from './WhyPremium';
import OurPackages from './OurPackages';
import OurCustomers from './OurCustomers';
import Graph from './Graph';

const Dealers = () => (
  <div>
    <Hero />
    <DealerBanner />
    <OurPackages />
    <WhyPremium />
    <OurCustomers />
    <Graph />
  </div>
);

export default Dealers;
