import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Content = styled.div`
  @media (min-width: ${props => rem(props.colLayoutBp)}) {
    display: flex;
    justify-content: space-between;
  }
`;

export default Content;
