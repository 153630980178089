import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Polyline = styled.polyline`
  fill: none;
  stroke: ${props => (props.colour ? props.colour : '#898C91')};
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const FilterArrow = ({ colour }) => (
  <svg
    id="Isolation_Mode"
    version="1.1"
    viewBox="0 0 7.7 14"
    x="0px"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0px"
  >
    <Polyline {...{ colour }} points="0.6,13.5 7.1,7 0.6,0.6 " />
  </svg>
);

FilterArrow.propTypes = {
  colour: PropTypes.string.isRequired,
};

export default FilterArrow;
