import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Submit = styled.input`
  padding-top: ${rem('11px')};
  padding-bottom: ${rem('11px')};
  font-size: ${rem('10px')};
  text-transform: uppercase;
  background: ${props => props.theme.orange};
  border: none;
  border-radius: ${rem('4px')};
  color: #fff;
  width: 100%;

  &:active,
  &:hover {
    background: ${props => props.theme.orangeHover};
    color: #fff;
    cursor: pointer;
  }
`;

export default Submit;
