import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const firstChildOrders = ['1', '0', '2'];
const secondChildOrders = ['2', '1', '0'];
const thirdChildOrders = ['0', '2', '1'];

const Quote = styled.div(
  ({ selected }) => css`
    position: relative;
    max-width: ${rem('480px')};

    &:first-child,
    &:last-child {
      display: none;

      ${media.bpTablet`
      display: block;
    `}
    }

    &:nth-child(1) {
      order: ${firstChildOrders[selected]};

      ${media.bpTablet`
      opacity: ${selected === 0 ? '1' : '0.2'};
    `}
    }

    &:nth-child(2) {
      order: ${secondChildOrders[selected]};
      ${media.bpTablet`
      opacity: ${selected === 1 ? '1' : '0.2'};
    `}
    }

    &:nth-child(3) {
      order: ${thirdChildOrders[selected]};
      ${media.bpTablet`
      opacity: ${selected === 2 ? '1' : '0.2'};
    `}
    }

    ${media.bpTablet`
    margin: 0 ${rem('60px')};
    flex: 0 0 ${rem('480px')};
  `}
  `,
);

export default Quote;
