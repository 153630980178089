/**
 * Function to handle the query
 * @param  {String}, query, The query string
 */
const parseQuery = (url, query) =>
  `${url.length ? `${url}` : ''}${
    url.length && query.length ? '&' : ''
  }${query.replace('?', '')}`;

/**
 * Parses a url string to return an object of parameters
 * @param {String}, url, The pathname to convert into a query string (E.g. colour/Red/manufacturer/Audi)
 * @param {String}, originalQuery, The original query that should come from the location object
 */
const parseUrl = (url = '', originalQuery = '') => {
  let query = '';

  // If url is empty, return empty
  if (!url.length) {
    return parseQuery(url, originalQuery);
  }

  // Loop over the filters and start generating the query string
  const filterSets = url.split('/');
  let lastKey = '';
  filterSets.forEach((filterSet, index) => {
    if (index % 2 === 0) {
      lastKey = filterSet;
    } else {
      query += `${index > 1 ? '&' : ''}`;
      const values = filterSet.split(',');
      for (let j = 0; j < values.length; j++) {
        query += `${j > 0 ? '&' : ''}${lastKey}=${values[j]}`;
      }
    }
  });

  // Append the original query string onto the end
  query = parseQuery(query, originalQuery);

  // Ensure that the url didn't contain a query
  query = query.replace('?', '&');

  return query;
};

export default parseUrl;
