import React from 'react';
import { Link } from 'react-router-dom';

import Wrapper from './Wrapper';
import TextContainer from './TextContainer';
import Title from './Title';
import Text from './Text';
import Button from './Button';
import Image from './Image';

const ErrorPage = () => (
  <Wrapper>
    <TextContainer>
      <Title>Error</Title>
      <Text>Looks like something went wrong...</Text>
      <Link to="/">
        <Button>Take me home</Button>
      </Link>
    </TextContainer>
    <div>
      <Image src="/images/lemon_car.png" />
    </div>
  </Wrapper>
);

export default ErrorPage;
