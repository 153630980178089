import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const fadeIn = keyframes`
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Svg = styled.svg`
  display: none;

  ${props =>
    props.selected &&
    !props.inputHasValue &&
    css`
      display: block;
      width: ${rem('27px')};
      height: ${rem('27px')};
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      animation: 1s ${fadeIn} ease-out;
    `}
`;

const Circle = styled.circle`
  fill: #ffffff;
`;

const G = styled.g`
  opacity: 0.58;
`;

const Path = styled.path`
  fill: #ff6000;
`;

const PulseDot = ({ selected, inputHasValue }) => (
  <Svg {...{ selected, inputHasValue }} viewBox="0 0 25.9 25.9" x="0px" y="0px">
    <g>
      <Circle cx="13" cy="12.9" r="5" />
      <G>
        <Path
          d="M13,7.9c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5S10.2,7.9,13,7.9 M13-0.1c-7.2,0-13,5.8-13,13s5.8,13,13,13
          s13-5.8,13-13S20.2-0.1,13-0.1L13-0.1z"
        />
      </G>
    </g>
    <g>
      <Circle cx="13" cy="12.9" r="5" />
      <G>
        <Path
          d="M13,7.9c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5S10.2,7.9,13,7.9 M13,3.9c-5,0-9,4-9,9s4,9,9,9s9-4,9-9
          S18,3.9,13,3.9L13,3.9z"
        />
      </G>
    </g>
  </Svg>
);

export default PulseDot;
