import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Header = styled.h2`
  display: none;

  ${media.bpTablet`
    display: block;
    font-size: ${rem('24px')};
    margin-top: 0;
    margin-bottom: ${rem('40px')};
    text-align: center;
    font-family: ${props => props.theme['main-header-font']}, 'sans-serif';
    color: ${props => props.theme.mediumGrey};
  `};
`;

export default Header;
