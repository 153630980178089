/**
 * Function that scrolls the user to the top of the page
 */
function scrollTop() {
  const elements = document.getElementsByClassName('Site');

  // If no element was found then bail out
  if (!elements.length) {
    return undefined;
  }

  // Get the first element
  const page = elements[0];

  // Check that the page element has the scrollTop attribute
  if (typeof page.scrollTop !== 'number') {
    return undefined;
  }

  // Scroll the page to the top
  page.scrollTop = 0;

  // Return the reset value so we can test
  return page.scrollTop;
}

export default scrollTop;
