import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Overlay;
