import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Item = styled.li`
  color: #e5e5e5;
  font-size: ${rem('15px')};
  line-height: ${rem('37px')};
  font-weight: 300;
  white-space: nowrap;

  a {
    color: #e5e5e5;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;

    span {
      text-decoration: none;
    }
  }

  a:visited {
    color: #e5e5e5;
  }

  ${media.bpTablet`
    font-size: ${rem('12px')};
    line-height: ${rem('23px')};
  `}
`;

export default Item;
