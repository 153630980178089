import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import formatNumber from 'utils/formatNumber';

const Wrapper = styled.a`
  flex: 0 0 ${rem('264px')};
  height: ${rem('162px')};
  margin-left: ${rem('10px')};
  margin-right: ${rem('10px')};
  margin-bottom: ${rem('10px')};
  border: 1px solid #eff0f1;
  box-shadow: 0 0 ${rem('14px')} 0 rgba(0, 0, 0, 0.09);
  text-align: center;
  text-decoration: none;

  @media (min-width: ${rem('604px')}) {
    margin-bottom: ${rem('20px')};
  }

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('15px')};
  }
`;

const Icon = styled.img`
  display: block;
  height: ${rem('43px')};
  margin: ${rem('41px')} auto;
`;

const Title = styled.p`
  color: ${props => props.theme.darkBlueGrey};
  display: inline;
`;

const Counter = styled.span`
  color: #999da3;
  margin-left: ${rem('5px')};
`;

const Card = ({ count, name, imageUrl, link }) => (
  <Wrapper href={link}>
    <Icon src={imageUrl} />
    <Title>{name}</Title>
    {count && <Counter>({formatNumber(count)})</Counter>}
  </Wrapper>
);

Card.propTypes = {
  count: PropTypes.number,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default Card;
