/**
 * Pure function for populating the notificationMessage prop
 */
function notificationMessage(state = {}, action) {
  // set notificationMessage to what was passed through in the action
  if (action.type === 'UPDATE_NOTIFICATION') {
    return { ...action.data };
  }

  // set back to default if REMOVE_NOTIFICATION is fired
  if (action.type === 'REMOVE_NOTIFICATION') {
    return {};
  }

  return state;
}

export default notificationMessage;
