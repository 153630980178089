/**
 * Pure function for populating the alternateSearches prop
 */
function alternateSearches(state = {}, action) {
  if (action.type === 'SEARCH_RESPONSE' && action.data) {
    return { ...action.data.alternateSearches };
  }

  return state;
}

export default alternateSearches;
