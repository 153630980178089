// Tag function for query string
const encodeValues = (strings, ...values) => {
  let str = '';

  if (!strings) {
    return str;
  }

  // Loop over each separated string and replace ampersands by encoding
  strings.forEach((string, i) => {
    str += string + encodeURIComponent(values[i] || '');
  });

  return str;
};

export default encodeValues;
