import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('100px')};
  height: ${rem('96px')};
  flex: 0 0 ${rem('100px')};

  ${props =>
    props.large &&
    media.bpsMedium`
      width: ${rem('226px')};
      height: ${rem('217px')};
      flex: 0 0 ${rem('226px')};
  `};
`;

const Path1 = styled.path`
  fill: url(#SVGID_4_);
`;

class Leads extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 62.3 60" {...{ large: this.props.large }}>
        <g>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="SVGID_4_"
            x1="31"
            x2="31"
            y1="0"
            y2="60"
          >
            <stop offset="0" style={{ stopColor: '#25AAAA' }} />
            <stop offset="0.9715" style={{ stopColor: '#FFFFFF' }} />
          </linearGradient>
          <Path1
            d="M60,0H2C0.9,0,0,0.9,0,2v44c0,1.1,0.9,2,2,2h18.5l9.7,11.6c0.2,0.2,0.5,0.4,0.8,0.4s0.6-0.1,0.8-0.4L41.5,48
		H60c1.1,0,2-0.9,2-2V2C62,0.9,61.1,0,60,0z M31,10c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S27.1,10,31,10z M44,37c0,0.6-0.4,1-1,1
		H19c-0.6,0-1-0.4-1-1v-2c0-1.8,0.9-3.4,2.4-4.3C22.5,29.5,26,28,31,28c4.9,0,8.4,1.5,10.5,2.7c1.5,0.9,2.5,2.5,2.5,4.3V37z"
          />
        </g>
      </Svg>
    );
  }
}

export default Leads;
