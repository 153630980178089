import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../../styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('148px')};
  height: ${rem('98px')};

  ${media.bpsMedium`
    width: ${rem('295px')};
    height: ${rem('196px')};
  `}
`;

const RevCounterIcon = () => (
  <Svg viewBox="0 0 48 32" x="0px" y="0px">
    <g>
      <path
        d="M26,3h-4C11,3,2,12,2,23v0h44v0C46,12,37,3,26,3z"
        style={{ fill: '#FFFFFF' }}
      />
      <path
        d="M38.8,19c-0.4,0-0.8-0.2-0.9-0.6c-1.3-3.2-3.6-5.8-6.6-7.5c-0.5-0.3-0.7-0.9-0.4-1.4
        c0.3-0.5,0.9-0.7,1.4-0.4c3.4,1.9,6.1,4.9,7.5,8.5c0.2,0.5,0,1.1-0.6,1.3C39.1,19,39,19,38.8,19z"
        style={{ fill: '#B3B3B3' }}
      />
      <path
        d="M9.2,19c-0.1,0-0.3,0-0.4-0.1c-0.5-0.2-0.8-0.8-0.6-1.3C10.8,11.2,17,7,24,7c2.9,0,5.7,0.7,8.2,2.1
        c0.5,0.3,0.7,0.9,0.4,1.4c-0.3,0.5-0.9,0.7-1.4,0.4C29.1,9.6,26.5,9,24,9c-6.1,0-11.6,3.7-13.9,9.4C9.9,18.8,9.6,19,9.2,19z"
        style={{ fill: '#43A6DD' }}
      />
      <path
        d="M24,0C10.8,0,0,10.8,0,24c0,1.1,0.9,2,2,2h14.3c0.9,3.4,4,6,7.7,6s6.8-2.6,7.7-6H46c1.1,0,2-0.9,2-2
        C48,10.8,37.2,0,24,0z M4.1,22C5.1,11.9,13.6,4,24,4s18.9,7.9,19.9,18H4.1z"
        style={{ fill: '#444444' }}
      />
      <path
        d="M21.8,27.4c-1.9-1.2-2.4-3.7-1.2-5.5S30,14,30,14s-1.4,10.3-2.6,12.2S23.7,28.6,21.8,27.4z"
        style={{ fill: '#E86C60' }}
      />
    </g>
  </Svg>
);

export default RevCounterIcon;
