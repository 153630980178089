import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import AdvertContainer from '../page-elements/AdvertContainer';
import advertsPropType from '../../propTypes/advertsPropType';

const Wrapper = styled.div`
  border-top: 1px solid #e6e6e6;

  @media (min-width: ${rem('1172px')}) {
    padding-right: ${rem('60px')};
  }
`;

const Title = styled.h3`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.darkBlueGrey};
  margin: ${rem('26px')} 0;
  font-size: ${rem('15px')};
  font-weight: 400;

  @media (min-width: ${rem('640px')}) {
    padding: 0 ${rem('18px')};
  }

  @media (min-width: ${rem('1172px')}) {
    font-size: ${rem('22px')};
    margin: ${rem('50px')} 0 ${rem('40px')};
    font-weight: 500;
  }
`;

const AdWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${rem('640px')}) {
    justify-content: flex-start;
  }
`;

const SearchResults = ({ title, adverts, flags, ...props }) => (
  <Wrapper>
    <Title>{title}</Title>
    <AdWrapper>
      {adverts.map((advert, index) => (
        <AdvertContainer
          key={`${advert.carId}-ad`}
          staticAd
          {...{ index, advert, flags }}
          {...props}
        />
      ))}
    </AdWrapper>
  </Wrapper>
);

SearchResults.propTypes = {
  adverts: advertsPropType.isRequired,
  flags: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
};

export default SearchResults;
