import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const YouGovLogo = styled.img`
  margin-top: ${rem('30px')};
  width: ${rem('120px')};

  ${media.bpsMedium`
    margin-top: ${rem('50px')};
    width: auto;
  `}

  ${media.bpsLarge`
    margin-top: ${rem('80px')};
  `}
`;

export default YouGovLogo;
