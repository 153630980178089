import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Input = styled.input`
  border: ${rem('1px')} solid #c7c7c7;
  border-radius: ${rem('4px')};
  padding: ${rem('4px')};
  width: ${rem('115px')};
  box-shadow: inset ${rem('1px')} ${rem('1px')} ${rem('3px')} -${rem('1px')} rgba(0, 0, 0, 0.3);

  ${media.bpTablet`
    width: auto;
  `}
`;

export default Input;
