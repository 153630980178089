import React from 'react';

import Wrapper from './Wrapper';
import Heading from './Heading';
import Badge from './Badge';
import Input from './Input';
import Select from './Select';
import Submit from './Submit';

class Form extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      tel: '',
      bestTime: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO
  }

  render() {
    const { heading, boxShadow, badge, noSidePadding } = this.props;
    const { name, email, tel, bestTime } = this.state;

    return (
      <Wrapper {...{ boxShadow, noSidePadding }}>
        <Heading>{heading}</Heading>
        {badge && <Badge />}
        <form onSubmit={this.handleSubmit}>
          <Input
            name="name"
            onChange={this.handleInputChange}
            placeholder="Full name"
            type="text"
            value={name}
          />
          <Input
            name="email"
            onChange={this.handleInputChange}
            placeholder="Email address"
            type="email"
            value={email}
          />
          <Input
            name="tel"
            onChange={this.handleInputChange}
            placeholder="Phone number"
            type="text"
            value={tel}
          />
          <Select
            name="bestTime"
            onChange={this.handleInputChange}
            value={bestTime}
          >
            <option disabled value="">
              Best time to call?
            </option>
            <option value="9am-11am">9am-11am</option>
            <option value="11am-1pm">11am-1pm</option>
            <option value="1pm-3pm">1pm-3pm</option>
            <option value="3pm-5pm">3pm-5pm</option>
          </Select>
          <Submit type="submit" value="Submit" />
        </form>
      </Wrapper>
    );
  }
}

export default Form;
