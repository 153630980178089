import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Cancel = styled.p`
  text-align: center;
  width: 100%;
  font-weight: 300;
  font-size: ${rem('14px')};
  padding-top: ${rem('10px')};
  cursor: pointer;
`;

export default Cancel;
