/**
 * Pure function for updating the showFilterMenu prop
 */
function showFilterMenu(state = false, action) {
  if (action.type === 'UPDATE_SHOW_FILTER_MENU') {
    return !!action.data;
  }

  return state;
}

export default showFilterMenu;
