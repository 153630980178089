import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Title = styled.h1`
  font-size: ${rem('70px')};
  font-weight: 600;
  color: #5d5d5d;
  margin: 0;

  ${media.bpMedium`
    font-size: ${rem('100px')};
    margin-top: ${rem('34px')};
  `}
`;

export default Title;
