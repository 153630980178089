import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('25px')} 0 ${rem('30px')};
  text-align: center;

  ${media.bpTablet`
    padding: ${rem('55px')} 0 ${rem('95px')};
  `}
`;

export default Wrapper;
