/**
 * Pure function for populating the cookiePolicyAgreed prop
 */
function cookiePolicyAgreed(state = false, action) {
  if (action.type === 'COOKIE_POLICY_AGREED') {
    return true;
  }

  return state;
}

export default cookiePolicyAgreed;
