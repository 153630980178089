import runSearch from './run-search';
import encodeValues from './encode-values';
import { searchSubmitted } from './event-tracking';

const searchFormSearch = (
  dispatch,
  features,
  history,
  searchFormLocation,
  flags,
  value,
) => {
  // Get the entered string
  const searchValue = value.trim();
  const query = encodeValues`q=${searchValue}&s=${searchValue}`;

  // Event Tracking
  searchSubmitted(searchFormLocation, value);

  // Make a search with the value and handle results
  runSearch(dispatch, query, features, history, {});
};

export default searchFormSearch;
