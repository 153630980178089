import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const PolicyLink = styled.li`
  display: inline;
  color: white;
  font-size: ${rem('14px')};
  font-weight: 300;

  &:after {
    content: '-';
    margin-left: ${rem('15px')};
    margin-right: ${rem('15px')};
  }

  &:last-child:after {
    content: '';
    margin: 0;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  a:visited {
    color: white;
  }

  ${media.bpTablet`
    font-size: ${rem('12px')}
  `}
`;

export default PolicyLink;
