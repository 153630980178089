/**
 * Pure function for updating the advert prop
 */
function advert(state = {}, action) {
  if (action.type === 'GET_ADVERT' && action.data) {
    return { ...action.data };
  }

  if (action.type === 'CLEAR_ADVERT') {
    return {};
  }

  return state;
}

export default advert;
