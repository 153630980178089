import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Link as RouterLink } from 'react-router-dom';

const LogoWrapper = styled(RouterLink)`
  padding: ${rem('25px')} 0 ${rem('32px')};
  display: block;

  img {
    width: ${rem('121px')};
    height: ${rem('32px')};
  }
`;

export default LogoWrapper;
