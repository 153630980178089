/**
 * Pure function for populating the searchTitle prop
 */
function searchTitle(state = '', action) {
  if (action.type === 'SEARCH_RESPONSE') {
    return action.data.searchTitle || state;
  }

  return state;
}

export default searchTitle;
