import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  margin-top: ${rem('16px')};
  margin-left: ${rem('12.5px')};
  margin-right: ${rem('12.5px')};
  width: 100%;
  min-width: ${rem('190px')};
  max-width: ${rem('264px')};
`;

const Image = styled.img`
  width: 100%;
  display: block;
`;

const ContentWrapper = styled.div`
  padding: ${rem('20px')};
  border-top: none;
  border: 1px solid #e0e1e3;
`;

const Title = styled.h4`
  font-size: ${rem('18px')};
  font-weight: 400;
  margin: 0;
`;

const Content = styled.p`
  font-size: ${rem('15px')};
  margin-top: ${rem('16px')};
  margin-bottom: 0;
`;

const ReadMore = styled.a`
  font-size: ${rem('15px')};
  color: #0e8edf;
`;

const Card = ({ title, content, imageUrl, link }) => (
  <Wrapper>
    <Image src={imageUrl} />
    <ContentWrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
      <ReadMore href={link}>read more...</ReadMore>
    </ContentWrapper>
  </Wrapper>
);

Card.propTypes = {
  content: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Card;
