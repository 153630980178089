import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../Logo';

import GoogleAd from '../GoogleAd';

import Wrapper from './Wrapper';
import Container from './Container';
import Main from './Main';
import Copyright from './Copyright';
import MainWrapper from './MainWrapper';
import DescriptionWrapper from './DescriptionWrapper';
import Description from './Description';
import Links from './Links';
import Newsletter from './Newsletter';
import Nav from './Nav';
import NavHeader from './NavHeader';
import List from './List';
import Item from './Item';
import Highlight from './Highlight';
import Social from './Social';
import SocialLink from './SocialLink';
import Twitter from './Twitter';
import Facebook from './Facebook';
import LinkedIn from './LinkedIn';
import CopyrightTop from './CopyrightTop';
import Copy from './Copy';
import PolicyLinks from './PolicyLinks';
import PolicyLink from './PolicyLink';
import FCAText from './FCAText';
import FCAHeader from './FCAHeader';

const Footer = ({ pathname = '', features, showFilterMenu }) => {
  const search = pathname.startsWith('/search');

  if (pathname.startsWith('/advert/')) {
    return null;
  }

  return (
    <Wrapper {...{ search, features }}>
      {!showFilterMenu && (
        <GoogleAd
          {...{ id: 'div-gpt-ad-1553511193902-0', width: 1, height: 1 }}
        />
      )}
      <Main>
        <Container {...{ search, features }}>
          <MainWrapper {...{ features }}>
            <DescriptionWrapper {...{ features }}>
              <Link title="Home" to="/">
                <Logo />
              </Link>
              <Description>
                Find your perfect car in as little as two clicks with Carsnip’s
                Magical Car Search&trade;. With more{' '}
                <a href="/used-cars" title="Used cars">
                  cars for sale
                </a>{' '}
                than any other UK car website, Carsnip combines simple car
                search with direct links to dealers to connect you with your
                perfect car faster.
              </Description>
            </DescriptionWrapper>
            <Links {...{ features }}>
              <Nav>
                <NavHeader>ABOUT</NavHeader>
                <List>
                  <Item>
                    <Link title="Our Mission" to="/about">
                      Our Mission
                    </Link>
                  </Item>
                  <Item>
                    <a
                      href="https://help.carsnip.com/hc/en-us"
                      title="Help & FAQ"
                    >
                      Help & FAQ
                    </a>
                  </Item>
                  <Item>
                    <a
                      href="https://help.carsnip.com/hc/en-us/requests/new"
                      title="Contact us"
                    >
                      Contact us
                    </a>
                  </Item>
                </List>
              </Nav>
              <Nav>
                <NavHeader>SERVICES</NavHeader>
                <List>
                  <Item>
                    <Link title="Find your perfect car" to="/">
                      Find your perfect car
                    </Link>
                  </Item>
                  <Item>
                    <a href="/articles" title="Articles">
                      Articles
                    </a>
                  </Item>
                  <Item>
                    <a href="/car-advice" title="Car Advice">
                      Car Advice
                    </a>
                  </Item>
                  <Item>
                    <Link title="Value my car" to="/value-my-car">
                      Value my car
                    </Link>
                  </Item>
                </List>
              </Nav>
              <Nav>
                <NavHeader>USED CARS</NavHeader>
                <List>
                  <Item>
                    <a href="/used-cars" title="All used cars">
                      All used cars
                    </a>
                  </Item>
                  <Item>
                    <a
                      href="/used-cars#by-location"
                      title="Used cars by location"
                    >
                      Used cars by location
                    </a>
                  </Item>
                  <Item>
                    <a
                      href="/used-cars/body-style"
                      title="Used cars by body style"
                    >
                      Used cars by body style
                    </a>
                  </Item>
                  <Item>
                    <a
                      href="/used-cars#by-manufacturer"
                      title="Used cars by brand"
                    >
                      Used cars by brand
                    </a>
                  </Item>
                </List>
              </Nav>
              <Nav>
                <NavHeader>DEALERS</NavHeader>
                <List>
                  <Item>
                    <a href="https://dealer.carsnip.com" title="Dealer Portal">
                      Dealer Portal
                    </a>
                  </Item>
                  <Item>
                    <a href="/news" title="Dealer News">
                      Dealer news
                    </a>
                  </Item>
                  <Item>
                    <Link title="Boost your stock" to="/dealers">
                      Boost your stock
                    </Link>
                  </Item>
                  <Item>
                    <a
                      href="https://carsnipdealers.typeform.com/to/CAbmIg"
                      title="List your stock"
                    >
                      List your stock<Highlight>FREE</Highlight>
                    </a>
                  </Item>
                </List>
              </Nav>
            </Links>
          </MainWrapper>
          <Newsletter>
            <Social>
              <SocialLink
                href="https://twitter.com/carsnip"
                rel="noopener"
                target="_blank"
                title="Twitter"
              >
                <Twitter />
              </SocialLink>
              <SocialLink
                href="https://facebook.com/carsnip"
                rel="noopener"
                target="_blank"
                title="Facebook"
              >
                <Facebook />
              </SocialLink>
              <SocialLink
                href="https://uk.linkedin.com/company/carsnip-com"
                rel="noopener"
                target="_blank"
                title="LinkedIn"
              >
                <LinkedIn />
              </SocialLink>
            </Social>
          </Newsletter>
          <FCAHeader>Important Information</FCAHeader>
          <FCAText>
            Please note, if you click on a vehicle picture, you may be
            redirected to the dealer's website. It is possible that each dealer
            has its own panel of lenders and different finance options may be
            available through them.
          </FCAText>
        </Container>
      </Main>
      <Copyright>
        <Container {...{ search, features }}>
          <CopyrightTop>
            <Copy>
              {new Date().getFullYear()} Carsnip Ltd (09296599) Registered
              Office: 2 New Bailey, 6 Stanley Street, Salford, Greater
              Manchester, M3 5GS, United Kingdom.
            </Copy>
            <PolicyLinks>
              <PolicyLink>
                <a href="/articles/terms-conditions-use/" title="Terms of use">
                  Terms of use
                </a>
              </PolicyLink>
              <PolicyLink>
                <a href="/articles/privacy-policy/" title="Privacy policy">
                  Privacy policy
                </a>
              </PolicyLink>
              <PolicyLink>
                <a
                  title="Complaints procedure"
                  href="/articles/complaints-procedure/"
                >
                  Complaints procedure
                </a>
              </PolicyLink>
            </PolicyLinks>
          </CopyrightTop>
        </Container>
      </Copyright>
    </Wrapper>
  );
};

export default Footer;
