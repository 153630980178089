import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Title = styled.h1`
  font-size: ${rem('21px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.mediumGrey};
  margin: ${rem('30px')} auto 0;

  ${media.bpsMedium`
    font-size: ${rem('40px')};
    margin-top: ${rem('70px')};
  `};
`;

export default Title;
