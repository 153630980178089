import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.form`
  border: 2px solid #ffdfcc;
  width: 100%;
  padding: ${rem('25px')};
  margin-bottom: ${rem('30px')};
  margin-right: ${rem('30px')};

  @media (min-width: ${props => rem(props.colLayoutBp)}) {
    margin-right: 0;
  }
`;

export default Wrapper;
