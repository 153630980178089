import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const AlImage = styled.img`
  width: ${rem('151px')};
  height: ${rem('151px')};
  margin-bottom: ${rem('25px')};

  ${media.bpsLarge`
    margin-bottom: 0;
    margin-right: ${rem('45px')};
    flex: 0 0 auto;
  `}
`;

export default AlImage;
