/**
 * Pure function for populating the sortOptions prop
 */
function sortOptions(state = [], action) {
  if (
    action.type === 'SEARCH_RESPONSE' &&
    action.data &&
    Array.isArray(action.data.sortOptions)
  ) {
    return [...action.data.sortOptions];
  }

  return state;
}

export default sortOptions;
