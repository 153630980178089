import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Triangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: ${rem('30px')} solid white;
  border-right: 100vw solid transparent;

  ${media.bpTablet`
    border-bottom: ${rem('125px')} solid white;
  `}

  @media(min-width: ${rem('1600px')}) {
    border-bottom: ${rem('145px')} solid white;
  }
`;

export default Triangle;
