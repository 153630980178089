import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Icon = styled.img`
  width: ${rem('13px')};
  height: ${rem('13px')};
  margin-right: ${rem('10px')};
  vertical-align: top;

  ${media.bpTablet`
    width: ${rem('16px')};
    height: ${rem('16px')};
  `}
`;

export default Icon;
