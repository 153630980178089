import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Container = styled.div`
  margin-top: ${rem('170px')};
  position: relative;
  height: ${rem('85px')};

  ${media.bpTablet`
    margin-top: ${rem('245px')};
    height: ${rem('170px')};
  `}
`;

export default Container;
