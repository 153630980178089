import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Pagination } from '@oodlefinance/shared-components';
import qs from 'query-string';

// Components
import AdvertContainer from '../../page-elements/AdvertContainer';

import GoogleAd from '../../page-elements/GoogleAd';

import SearchInfo from '../../page-elements/SearchInfo';

import InlineAdvert from './InlineAdvert';
import NoResults from './NoResults';
import { ResultsWrapper } from './StyledResults';
import PaginationWrapper from './PaginationWrapper';

import { removeSearch } from 'services/url/manipulate-url';
import scrollTop from 'services/scroll-top';
import parseUrl from 'services/url/parse-url';
import runSearch from 'services/run-search';

class Results extends React.Component {
  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this);
    this.handleLink = this.handleLink.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.showFilterMenu !== this.props.showFilterMenu) {
      return false;
    }

    return true;
  }

  /**
   * Send off the request for new data and update the url
   * @param  {String} url The new url
   */
  changePage(url) {
    // Get dispatch
    const { dispatch, features, history } = this.props;

    const splitUrl = url.includes('?') ? url.split('?') : [];

    // Get the query for the request
    const query = parseUrl(removeSearch(splitUrl[0]), splitUrl[1]);

    // Send user to the top of the page
    scrollTop();

    // Make a search with the value and handle results
    runSearch(dispatch, query, features, history, { url });
  }

  /**
   * Gets the url for a page link and takes the user to it,
   * Uses the window.location object so that the data is more accurate to the browser state
   * @param  {String} link The link of the next page (comes from the API)
   */
  handleLink(link) {
    const { currentPage } = this.props;
    const page = (link.match(/page=\d+/) || ['', ''])[0].split('=')[1] || 1;

    // Stop the reload of the page if it's the one they're on
    if (page === currentPage) {
      return;
    }

    let newURL = '';

    const { location } = window;

    // If there isn't a query already in the URL
    // then create one and push to the browser
    if (!location.search) {
      newURL = `${location.pathname}?page=${page}`;
    }

    // If there are any other queries but not page query
    if (!!location.search && location.search.indexOf('page=') === -1) {
      newURL = `${location.pathname}${location.search}&page=${page}`;
    }

    // If there is a page query value
    if (location.search.indexOf('page=') > -1) {
      newURL = `${location.pathname}${location.search}`.replace(
        /page=\d+/i,
        `page=${page}`,
      );
    }

    // If there is a carId, remove it
    if (location.pathname.includes('carId/')) {
      newURL = newURL.replace(/\/carId\/[^/|?]*/g, '');
    }

    // Change the page
    this.changePage(newURL);
  }

  get carPage() {
    const { location, adverts } = this.props;
    const { pageType } = qs.parse(location.search);
    return pageType === 'car' && adverts.length > 0 && adverts[0].idMatch;
  }

  get pageHasFeaturedCar() {
    const { adverts } = this.props;
    return adverts.length > 0 && adverts[0].idMatch;
  }

  render() {
    const {
      adverts,
      pages,
      features,
      flags,
      dispatch,
      searchId,
      publisherId,
      visitId,
    } = this.props;

    if (!adverts.length) {
      return (
        <NoResults
          {...{
            features,
          }}
        />
      );
    }

    return (
      <ResultsWrapper>
        {adverts.map((advert, index) => {
          switch (advert.advertType) {
            case 'valueMyCar':
              return (
                <InlineAdvert key={`ga-${index}`} noBorder>
                  <GoogleAd
                    {...{
                      id: 'div-gpt-ad-1553511231316-1',
                      width: 300,
                      height: 250,
                    }}
                  />
                </InlineAdvert>
              );
            case 'googleAdvert':
              return (
                <InlineAdvert {...{ features }} key={`ga-${index}`} noBorder>
                  <GoogleAd
                    {...{
                      id: 'div-gpt-ad-1553511231316-0',
                      width: 300,
                      height: 250,
                    }}
                  />
                </InlineAdvert>
              );
            case 'car':
            default:
              return (
                <Fragment key={`${advert.carId}-${index}-ad`}>
                  <AdvertContainer
                    {...{
                      index,
                      pages,
                      features,
                      flags,
                      advert,
                      dispatch,
                      searchId,
                      publisherId,
                      visitId,
                    }}
                  />
                  {index === 0 && this.pageHasFeaturedCar && !this.carPage && (
                    <SearchInfo
                      {...{ ...this.props, pageHasFeaturedCar: true }}
                    />
                  )}
                </Fragment>
              );
          }
        })}
        {Object.keys(pages).length !== 0 && (
          <PaginationWrapper {...{ features }}>
            <Pagination
              currentPage={pages.currentPage}
              handleLinkClickCallback={this.handleLink}
              nextPage={pages.nextPage}
              pages={pages.pagination}
              previousPage={pages.previousPage}
              totalPages={pages.totalPages}
            />
          </PaginationWrapper>
        )}
      </ResultsWrapper>
    );
  }
}

Results.defaultProps = {
  alternateSearches: {},
};

Results.propTypes = {
  adverts: PropTypes.array.isRequired,
  pages: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Results;
