import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../../styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('115px')};
  height: ${rem('115px')};

  ${media.bpsMedium`
    width: ${rem('230px')};
    height: ${rem('230px')};
  `}
`;

const MoneyIcon = () => (
  <Svg viewBox="0 0 46 45.9" x="0px" y="0px">
    <g>
      <circle cx="32" cy="16.1" r="6" style={{ fill: '#EFD358' }} />
      <circle cx="18" cy="6.1" r="6" style={{ fill: '#EFD358' }} />
      <path
        d="M20,7.1h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S20.6,7.1,20,7.1z"
        style={{ fill: '#B29930' }}
      />
      <path
        d="M34,17.1h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S34.6,17.1,34,17.1z"
        style={{ fill: '#B29930' }}
      />
      <path
        d="M43,34.3l-17.5,1.5c-1.6,0.1-3.2-0.2-4.7-1L16,32.1h11.3c1.5,0,2.7-1.2,2.7-2.7v0c0-1.3-0.9-2.4-2.2-2.7
        l-10.1-1.9c-2.4-0.5-4.9-0.7-7.4-0.7H2v14.5l16.4,6.9c1.8,0.7,3.8,0.7,5.7,0.2L46,37.1v0C46,35.4,44.6,34.2,43,34.3z"
        style={{ fill: '#EAD8C5' }}
      />
      <path
        d="M6,43.1H1c-0.6,0-1-0.4-1-1v-19c0-0.6,0.4-1,1-1h5c0.6,0,1,0.4,1,1v19C7,42.6,6.6,43.1,6,43.1z"
        style={{ fill: '#5A7A84' }}
      />
    </g>
  </Svg>
);

export default MoneyIcon;
