/**
 * Pure function for populating the totalHits prop
 */
function totalHits(state = 0, action) {
  if (
    action.type === 'SEARCH_RESPONSE' &&
    action.data &&
    typeof action.data.totalHits === 'number'
  ) {
    return action.data.totalHits;
  }

  return state;
}

export default totalHits;
