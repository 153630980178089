/**
 * Pure function for populating the displayFilters prop
 */
function displayFilters(state = {}, action) {
  if (
    action.type === 'SEARCH_RESPONSE' &&
    typeof action.data === 'object' &&
    typeof action.data.features === 'object'
  ) {
    return { ...action.data.displayFilters };
  }

  return state;
}

export default displayFilters;
