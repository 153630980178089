import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Button = styled.a`
  border: none;
  border-radius: ${rem('4px')};
  font-size: ${rem('15px')};
  background: linear-gradient(to top, #e95800, #ff6e17);
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
  width: ${rem('180px')};
  height: ${rem('37px')};
`;

export default Button;
