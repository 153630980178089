import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Button = styled.button`
  display: block;
  margin: 0 auto ${rem('30px')};
  padding: ${rem('7px')} ${rem('20px')};
  border-radius: ${rem('4px')};
  border: none;
  background: ${props => props.theme.orange};
  color: #fff;

  ${media.bpMedium`
    margin-left: 0;
  `};
`;

export default Button;
