import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Nav = styled.div`
  margin: 0 auto ${rem('25px')};
  max-width: ${rem('284px')};

  & > a {
    color: #F29226;
    text-decoration: none;
  }
  
    @media(min-width: ${rem('515px')}) {
      width: 50%;
    }

  @media(min-width: ${rem('888px')}) {
    width: auto;
  }

  ${media.bpTablet`
    margin-bottom: 0;
  `}

  @media(min-width: ${rem('1152px')}) {
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default Nav;
