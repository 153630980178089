import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const FormHeader = styled.h1`
  font-size: ${rem('21px')};
  margin: 0;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.mediumGrey};

  ${media.bpTablet`
    font-size: ${rem('40px')};
  `};
`;

export default FormHeader;
