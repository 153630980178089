import React from 'react';
import has from 'has';
import qs from 'query-string';

import Spinner from '../../../page-elements/Spinner';
import NotFound from '../../NotFound';
import Wrapper from '../Wrapper';
import Header from '../Header';
import Text from '../Text';
import Form from '../Form';
import Clearfix from '../Clearfix';
import InputGroup from '../InputGroup';
import Errors from '../Errors';
import Image from '../Image';
import SubmitButtonWrapper from '../SubmitButtonWrapper';

import SubmitButton from 'shared/SubmitButton';
import Input from 'shared/Input';
import Recaptcha from 'shared/Recaptcha';
import { valueMyCarLandingLookup } from 'services/event-tracking';
import { VrmActions } from 'actions';
import { vrmRegex } from 'services/regex';

/* global grecaptcha */

class ValueMyCar extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = '';

    this.state = {
      vrm: '',
      errors: {},
    };

    this.updateReg = this.updateReg.bind(this);
    this.invalidError = this.invalidError.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleVerifiedChange = this.handleVerifiedChange.bind(this);
    this.onRecaptchaLoad = this.onRecaptchaLoad.bind(this);
  }

  componentDidMount() {
    const { match = {}, location = {} } = this.props;

    // Check the URL params for VRM
    if (match.params.vrm) {
      this.setState({
        vrm: match.params.vrm.replace(/\s/g, ''),
      });
    }

    // Check the query string for VRM
    if (location.query && qs.parse(location.query).vrm) {
      this.setState({
        vrm: qs.parse(location.query).vrm.replace(/\s/g, ''),
      });
    }
  }

  /**
   * Updates the value of the input box
   */
  updateReg(e) {
    const { value: vrm } = e.target;
    const { errors } = this.state;

    delete errors.vrm;

    // Check the VRM is valid
    if (vrmRegex.test(vrm)) {
      this.setState({
        vrm,
        errors,
      });
    }
  }

  invalidError() {
    const { errors } = this.state;

    errors.vrm = 'Please enter a valid registration number';

    this.setState({
      errors,
    });
  }

  checkForm(e) {
    e.preventDefault();

    const { vrm, errors } = this.state;

    // Check that the VRM is valid
    if (!vrm.length || !vrmRegex.test(vrm)) {
      errors.vrm = 'Please enter a valid registration number';

      this.setState({
        errors,
      });

      return false;
    }

    grecaptcha.execute(this.captcha); // Check the recaptcha
    return true;
  }

  submitForm() {
    const { vrm, recaptchaResponse } = this.state;
    const { dispatch, history } = this.props;

    dispatch(VrmActions.vrmLookup(vrm, 'valuation', recaptchaResponse))
      .then(body => {
        if (body && has(body, 'lookupId')) {
          valueMyCarLandingLookup(body.manufacturer, body.model); // event tracking
          history.push(`/value-my-car/details/${body.lookupId}`);
        } else {
          this.invalidError();
        }
      })
      .catch(() => {
        this.invalidError();
      });
  }

  onRecaptchaLoad(id) {
    this.captcha = id;
  }

  handleVerifiedChange(response) {
    this.setState(
      {
        recaptchaResponse: response,
      },
      () => {
        this.submitForm();
      },
    );
  }

  render() {
    const { vrm, errors } = this.state;
    const { features, currentlyFetching } = this.props;

    // If the feature isn't allowed
    if (!has(features, 'valueMyCar')) {
      return <NotFound />;
    }

    return (
      <div>
        <Wrapper landing>
          <Header>Value My Car</Header>
          <Text>
            <span>Free car valuation</span>, Get an instant price whether you're
            buying or selling
          </Text>
          <Form id="value-my-car-form" onSubmit={this.checkForm}>
            <Clearfix>
              <InputGroup>
                <img
                  alt="gb registration plate flag"
                  src="/images/gb_tag.png"
                />
                <Input
                  center
                  onChange={this.updateReg}
                  placeholder="ENTER REG"
                  type="text"
                  uppercase
                  value={vrm}
                />
              </InputGroup>
              <SubmitButtonWrapper marginLeft="1.5rem">
                <SubmitButton
                  disabled={
                    Object.keys(errors).length || currentlyFetching.valueMyCar
                  }
                  loading={currentlyFetching.valueMyCar}
                  type="submit"
                  stretch
                >
                  {currentlyFetching.valueMyCar ? (
                    <Spinner />
                  ) : (
                    'Find my vehicle'
                  )}
                </SubmitButton>
              </SubmitButtonWrapper>
            </Clearfix>
            {errors && (
              <Errors>
                {Object.entries(errors).map(([key, error]) => (
                  <li key={key}>{error}</li>
                ))}
              </Errors>
            )}
            <Recaptcha
              invisible
              onChange={this.handleVerifiedChange}
              onRecaptchaLoad={this.onRecaptchaLoad}
            />
          </Form>
          <Image src="/images/sell-value_car.png" />
        </Wrapper>
      </div>
    );
  }
}

export default ValueMyCar;
