import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('25px')} 0;
  text-align: center;

  ${media.bpTablet`
    display: flex;
    align-items: center;
    padding: ${rem('75px')} 0;
  `}
`;

export default Wrapper;
