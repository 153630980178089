import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Text = styled.h2`
  font-weight: 400;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('17px')};
  text-align: center;

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('20px')};
    text-align: center;
    margin-top: ${rem('40px')};
    margin-bottom: ${rem('35px')};
  }

  @media (min-width: ${rem('1024px')}) {
    font-size: ${rem('24px')};
  }
`;

export default Text;
