import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('11px')};
  top: ${rem('70px')};
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  transition: all 0.3s ease-out;
  z-index: 7;
  opacity: 1;

  ${media.bpTablet`
    height: ${rem('380px')};
    width: ${rem('400px')};
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #CECECE;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.34);
    z-index: 4;
  `} ${props =>
    props.hide
      ? css`
          transform: translateX(100%);
          opacity: 0;

          ${media.bpTablet`
      transform: translateX(-${rem('680px')});
    `};
        `
      : ''};
`;

export default Wrapper;
