import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${rem('40px')};

  a {
    padding: ${rem('18px')} ${rem('40px')};
    font-size: 0.8rem;
  }
`;

export default ButtonWrapper;
