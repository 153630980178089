import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${rem('13px')};

  &:last-child {
    margin-bottom: 0;
  }
`;

export default ButtonWrapper;
