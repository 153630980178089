import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Features = styled.div`
  padding-bottom: ${rem('15px')};
  border-bottom: 1px solid #4e5259;
  max-width: ${rem('580px')};
  margin: 0 auto;

  ${media.bpTablet`
    max-width: ${rem('1120px')};
    display: flex;
    flex-wrap: wrap;
    padding-bottom: ${rem('20px')};
    padding-right: ${rem('65px')};
    padding-left: ${rem('65px')};
  `}
`;

export default Features;
