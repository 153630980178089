import styled from 'styled-components';

import media from 'styles/mixins/media';

const TextContainer = styled.div`
  a {
    text-decoration: none;
  }

  ${media.bpMedium`
    float: left;
    width: 50%;
  `}
`;

export default TextContainer;
