import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Footer = styled.div`
  background-color: #f2f2f4;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: ${rem('20px')};

  p {
    font-weight: 300;
    font-style: italic;
    font-size: ${rem('12px')};
    text-align: center;
  }
`;

export default Footer;
