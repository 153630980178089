import styled from 'styled-components';
import { rgba } from 'polished';

const LINE_HEIGHT = 2;

export const Wrapper = styled.div`
  font-size: 0.875rem;
  line-height: ${LINE_HEIGHT};
`;

export const Fadeout = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${LINE_HEIGHT * 3}em;
  background-image: linear-gradient(to bottom, ${rgba('white', 0.68)}, white);
`;

export const TextContainer = styled.div`
  max-height: ${LINE_HEIGHT * 5}em;
  position: relative;
  overflow: hidden;
`;

export const Text = styled.p`
  font-weight: 300;
  color: ${p => p.theme.darkGrey};
`;

export const ReadMoreContainer = styled.div`
  text-align: center;
`;

export const ReadMoreLink = styled.a`
  text-decoration: none;
  color: ${p => p.theme.orange};
`;

export const OpenInNewTabIconWrapper = styled.span`
  margin-left: 0.5rem;
`;
