import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import Text from './Text';

const NoResults = ({ noResults }) => (
  <Wrapper>
    <Text>{noResults}</Text>
  </Wrapper>
);

NoResults.propTypes = {
  noResults: PropTypes.string.isRequired,
};

export default NoResults;
