/**
 * Pure function for updating the selectedAdvert prop
 */
function selectedAdvert(state = {}, action) {
  if (action.type === 'SELECT_ADVERT' && action.data) {
    return { ...action.data };
  }

  if (action.type === 'DESELECT_ADVERT') {
    return {};
  }

  return state;
}

export default selectedAdvert;
