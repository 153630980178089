import React, { useEffect, useState } from 'react';
import exenv from 'exenv';

import AddThis from '../AdvertContainer/AddThis';
import { LineArrowLeft } from '../../svgs/LineArrows';
import OpenInNewTabIcon from '../../svgs/OpenInNewTabIcon';
import CircledCheckMark from '../../svgs/CircledCheckMark';

import DescriptionWithLink from '../../shared/DescriptionWithLink';

import ImageCarousel from '../../shared/ImageCarousel';

import Wrapper from './Wrapper';
import Container from './Container';
import BackButton from './BackButton';
import Content from './Content';
import Main from './Main';
import CTA from './CTA';
import Prices from './Prices';
import Title from './Title';
import Dealer from './Dealer';
import PhoneCTA from './PhoneCTA';
import PhoneIcon from './PhoneIcon';
import Forms from './Forms';
import DirectEnquiry from './DirectEnquiry';
import Name from './Name';
import Details from './Details';
import CarPageMap from './CarPageMap';
import GetCallbackCTA from './GetCallbackCTA';
import GetCallbackModal from './GetCallbackModal';
import CTAContainer from './CTAContainer';

import {
  AdvertContent,
  CarouselContainer,
  CarouselBackground,
  CarouselAndContent,
  SectionHeader,
  CircledCheckMarkWrapper,
  AfterDealerHr,
  AfterDealerHrMargin,
  OpenInNewTabIconWrapper,
  LineArrowLeftWrapper,
  DealerLocationWrapper,
  DealerLogo,
  PlainDescription,
  NonPremiumDealerNameWrapper,
  NonPremiumDealerName,
} from './style';

import formatPhone from 'utils/formatPhone';

const StandardFeaturedAdvert = ({
  advert: {
    advertData,
    title,
    registrationYear,
    bodyStyle,
    manufacturer,
    mileage,
    gearbox,
    fuel,
    range,
    engineSizeL,
    vrn,
    products = [],
  },
  history,
  premiumCarPage,
}) => {
  const [backButtonString, setBackButtonString] = useState(
    'Back to Search Results',
  );
  const [CTAButtonString, setCTAButtonString] = useState('View Full Listing');

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 550px)');
    const listener = () => {
      if (mediaQueryList.matches) {
        setCTAButtonString('View Listing');
        setBackButtonString('Back');
      } else {
        setCTAButtonString('View Full Listing');
        setBackButtonString('Back to Search Results');
      }
    };

    // call it once in case we are on a small screen
    listener();

    mediaQueryList.addListener(listener);

    return () => mediaQueryList.removeListener(listener);
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const colLayoutBp = 1128;
  const canRequestCallback = products.includes('calldrip');
  const {
    price = 0,
    images,
    dealerName,
    description,
    hashedUri,
    mapUri,
    dealerTelephone,
    showroom,
  } = advertData[0];

  return (
    <Wrapper>
      <GetCallbackModal
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        showroomId={showroom}
        dealerName={dealerName}
        vehicleData={{
          year: registrationYear,
          make: manufacturer,
          model: range,
          licensePlate: vrn,
          bodyStyle,
          fuelType: fuel,
        }}
      />
      <Container id="car-page-container" premiumCarPage={premiumCarPage}>
        {exenv.canUseDOM && (
          <BackButton onClick={() => history.goBack()} type="button">
            <LineArrowLeftWrapper>
              <LineArrowLeft />
            </LineArrowLeftWrapper>{' '}
            {backButtonString}
          </BackButton>
        )}
        <header>
          <Prices
            {...{
              price,
            }}
          />
          <Title>{title}</Title>
        </header>
        <Content {...{ colLayoutBp }}>
          <Main {...{ colLayoutBp, premiumCarPage }}>
            {premiumCarPage && <CarouselBackground />}
            <CarouselAndContent premiumCarPage={premiumCarPage}>
              <CarouselContainer premiumCarPage={premiumCarPage}>
                <ImageCarousel
                  altText="Pictures of the car"
                  fillParent
                  images={images}
                />
              </CarouselContainer>
              <AdvertContent premiumCarPage={premiumCarPage}>
                {premiumCarPage && (
                  <>
                    <Dealer>
                      <span>
                        {dealerName === 'BuyaCar' ? (
                          <DealerLogo
                            alt="BuyaCar logo"
                            src="/images/buyacar-logo-white-background.jpg"
                          />
                        ) : (
                          <Name>{dealerName}</Name>
                        )}
                        <span
                          style={{
                            display: 'flex',
                            fontSize: 14,
                            color: '#333',
                          }}
                        >
                          <CircledCheckMarkWrapper>
                            <CircledCheckMark />
                          </CircledCheckMarkWrapper>{' '}
                          Premium Seller
                        </span>
                      </span>
                      <CTAContainer containsTwoButtons={canRequestCallback}>
                        <CTA
                          href={hashedUri}
                          target="_blank"
                          hasSiblingButton={canRequestCallback}
                        >
                          {CTAButtonString}
                          <OpenInNewTabIconWrapper>
                            <OpenInNewTabIcon />
                          </OpenInNewTabIconWrapper>
                        </CTA>
                        {canRequestCallback && (
                          <GetCallbackCTA
                            onClick={() => {
                              setModalIsOpen(true);
                            }}
                            type="button"
                          >
                            Get Callback
                          </GetCallbackCTA>
                        )}
                      </CTAContainer>
                    </Dealer>
                    <AfterDealerHr />
                    <AfterDealerHrMargin />
                  </>
                )}
                <Details
                  {...{
                    premiumCarPage,
                    registrationYear,
                    bodyStyle,
                    mileage,
                    gearbox,
                    fuel,
                    engineSizeL,
                  }}
                />
                {description && premiumCarPage && (
                  <>
                    <SectionHeader>Dealer Description</SectionHeader>
                    <DescriptionWithLink
                      fullDescriptionUrl={hashedUri}
                      text={description}
                    />
                  </>
                )}
                {description && !premiumCarPage && (
                  <PlainDescription>{description}</PlainDescription>
                )}
                {!premiumCarPage && (
                  <NonPremiumDealerNameWrapper href={hashedUri} target="_blank">
                    <NonPremiumDealerName>{dealerName}</NonPremiumDealerName>
                    <OpenInNewTabIcon />
                  </NonPremiumDealerNameWrapper>
                )}
                {dealerTelephone && (
                  <PhoneCTA href={`tel:${dealerTelephone.replace(/\s/g, '')}`}>
                    <PhoneIcon orange /> Call Dealer{' '}
                    {formatPhone(dealerTelephone)}
                  </PhoneCTA>
                )}

                <Forms {...{ colLayoutBp }}>
                  {products.includes('direct-enquiry') && (
                    <DirectEnquiry {...{ hashedUri, dealerTelephone }} />
                  )}
                </Forms>
                {premiumCarPage && !!mapUri && (
                  <DealerLocationWrapper>
                    <SectionHeader>Dealer Location</SectionHeader>
                    <CarPageMap {...{ mapUri }} />
                  </DealerLocationWrapper>
                )}
                <SectionHeader>Share With Friends</SectionHeader>
                <AddThis />
              </AdvertContent>
            </CarouselAndContent>
          </Main>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default StandardFeaturedAdvert;
