import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Icon = styled.div`
  flex: 0 0 ${rem('45px')};
  display: flex;
  justify-content: center;
`;

export default Icon;
