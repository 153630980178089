import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const FormText = styled.p`
  font-size: ${rem('13px')};
  margin-top: ${rem('8px')};
  margin-bottom: ${rem('17px')};
  color: ${props => props.theme.mediumGrey};
  font-weight: 500;

  ${media.bpTablet`
    font-size: ${rem('16px')};
    margin-top: ${rem('20px')};
    margin-bottom: ${rem('50px')};
    font-weight: 300;
  `};
`;

export default FormText;
