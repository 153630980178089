import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Info = styled.div`
  background-color: ${props => props.theme.lighterGrey};
  margin: 0 ${rem('18px')} ${rem('18px')};
  padding: ${rem('9px')} ${rem('10px')} ${rem('10px')} ${rem('32px')};
  position: relative;

  ${media.bpTablet`
    margin: 0 0 ${rem('27px')};
  `};
`;

export default Info;
