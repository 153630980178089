import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Hero = styled.div`
  text-align: center;
  position: relative;

  ${media.bpsLarge`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 ${rem('20px')} ${rem('130px')};
  `}
`;

export default Hero;
