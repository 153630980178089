import styled from 'styled-components';
import { resetButton } from '@oodlefinance/ui-library/mixins';

import media from 'styles/mixins/media';

const CTA = styled.a`
  ${resetButton}
  background-color: ${p => p.theme.orange};
  border-radius: 4px;
  color: ${p => p.theme.white};
  font-size: 0.9375rem;
  line-height: 1.5;
  padding: ${p => (p.hasSiblingButton ? '5px' : '10px')} 15px;
  text-decoration: none;

  ${media.bpSmall`
    padding-top: 10px;
    padding-bottom: 10px;
  `}
`;

export default CTA;
