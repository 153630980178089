import React from 'react';

import SVG from './SVG';

const Odometer = () => (
  <SVG
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="10"
    strokeWidth="2"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 4v4M26.6 8.4l-2.8 2.8M31 19h-4M1 19h4M13.9 16.9L8 11" />
    <circle cx="16" cy="19" r="3" />
    <path d="M28 28a15 15 0 10-24 0h24z" />
  </SVG>
);

export default Odometer;
