import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import {
  theme,
  reset,
  reactSelect,
  baseStyles,
} from '@oodlefinance/shared-components';

import Routes from './Routes';

const ThemeWrapper = props => {
  // Add global styling
  const GlobalStyle = createGlobalStyle`
   ${reset}
   ${baseStyles()}
   ${reactSelect}
   body.no-scroll {
     overflow: hidden;
     height: 100vh;
     width: 100vw;
     position: fixed;
   }

   /* AddThis button styling override */
   .at-share-btn {
      border-radius: 0.25rem !important;
    }
 `;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes {...props} />
    </ThemeProvider>
  );
};

export default ThemeWrapper;
