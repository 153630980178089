import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Input = styled.input`
  font-size: ${rem('17px')};
  font-weight: 500;
  padding: 0 ${rem('16px')};
  height: ${rem('49px')};
  width: 100%;
  margin-bottom: ${rem('16px')};
  border: 1px solid #c7c7c7;
  border-radius: ${rem('4px')};

  ${media.bpTablet`
    font-size: ${rem('16px')};
    height: ${rem('44px')};
  `}
`;

export default Input;
