import React from 'react';

import Wrapper from './Wrapper';
import Container from './Container';
import Title from './Title';
import Text from './Text';

const Bot = () => (
  <Wrapper>
    <section id="intro">
      <Container>
        <Title>CarsnipBot</Title>
        <Text>
          CarsnipBot is our web crawler. Crawling is a process by which
          CarsnipBot discovers new cars and dealer websites to be added to our
          database of new cars.
        </Text>
        <Text>
          We use a number of servers running in AWS (Amazon Web Services) to
          perform this crawl which we do overnight during periods of the lowest
          load on dealer websites. CarsnipBot uses an algorithmic process,
          software determines which sites to crawl, how often, and how many
          pages to fetch from each site based on the number of changes that
          occur on these websites.
        </Text>
        <Text>
          CarsnipBot&apos;s crawl process begins with a list of dealer website
          URLs, generated from manual entry from our tech team based on both
          dealer supplied and generic industry data. As CarsnipBot visits each
          of these websites it detects car adverts on each page and adds them to
          its list of adverts to crawl. New car adverts, changes to existing car
          adverts, and sold or wanted adverts are noted and used to update the
          search engine.
        </Text>
      </Container>
    </section>
    <section id="about">
      <Container>
        <Title>CarsnipBot and your site</Title>
        <Text>
          For most sites, CarsnipBot shouldn&apos;t access your site more than
          once every few seconds on average. However, due to network delays,
          it&apos;s possible that the rate will appear to be slightly higher
          over short periods. In general, CarsnipBot should download only one
          copy of each car advert. If you see that CarsnipBot is downloading a
          page multiple times, it&apos;s probably because the crawler was
          stopped and restarted.
        </Text>
        <Text>
          CarsnipBot was designed to be distributed on several servers to
          improve performance. Therefore, your logs may show visits from several
          machines at AWS, all with the user-agent CarsnipBot. Our goal is to
          crawl as many pages from your site as we can on each visit without
          overwhelming your server&apos;s bandwidth. We request each advert with
          a 5 second gap in between each request. We never perform multiple
          requests.
        </Text>
        <Text>
          <b>MYTHBUSTER</b> - We heard that some dealers are being told by their
          web-hosts that our crawler can slow down or &apos;break&apos; their
          website. THIS IS NOT TRUE! If this was true then the Google Crawler or
          any other search engine crawler or even a surge in visitors could do
          the same. We have invested extra resource to double check and triple
          check that we DO NOT AFFECT your website performance. If we do cause
          any issues then please get in touch as it could be time to change your
          web-host.
        </Text>
        <Text>
          CarsnipBot discovers car adverts by following links from page to page
          on your website.
        </Text>
      </Container>
    </section>
    <section id="services">
      <Container>
        <Title>Blocking CarsnipBot from content on your site</Title>
        <Text>
          If you really want to prevent CarsnipBot from crawling content on your
          site, you have a number of options, including using robots.txt to
          block access to files and directories on your server.
        </Text>
        <Text>
          Once you&apos;ve created your robots.txt file, there may be a small
          delay before CarsnipBot discovers your changes. If CarsnipBot is still
          crawling content you&apos;ve blocked in robots.txt, check that the
          robots.txt is in the correct location. It must be in the top directory
          of the server e.g. www.yourwebsite.com/robots.txt (placing the file in
          a subdirectory won&apos;t have any effect).
        </Text>
        <Text>
          If you just want to prevent the &quot;file not found&quot; error
          messages in your web server log, you can create an empty file named
          robots.txt. If you want to prevent CarsnipBot from following any links
          on a page of your site, you can use the nofollow meta tag. To prevent
          CarsnipBot from following an individual link, add the
          rel=&quot;nofollow&quot; attribute to the link itself.
        </Text>
        <Text>
          Test that your robots.txt is working as expected. Be very cautious
          when using robots.txt - you may prevent all or any crawlers from
          indexing your content.
        </Text>
      </Container>
    </section>
    <section id="contact">
      <Container>
        <Title>Problems with spammers pretending to be CarsnipBot</Title>
        <Text>
          The IP addresses used by CarsnipBot change from time to time. The best
          way to identify accesses by CarsnipBot is to use the user-agent
          (CarsnipBot). You can verify that a bot accessing your server really
          is CarsnipBot by using a reverse DNS lookup.
        </Text>
        <Text>
          CarsnipBot and all respectable search engine bots will respect the
          directives in robots.txt, but some spammers do not.
        </Text>
      </Container>
    </section>
  </Wrapper>
);

export default Bot;
