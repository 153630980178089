import React from 'react';
import styled from 'styled-components';

import SVG from './SVG';

const LineArrowPolygon = () => (
  <polygon
    fill="currentColor"
    points="9.0075 2.25 0 2.25 0 3.75 9.0075 3.75 9.0075 6 12 3 9.0075 0 9.0075 2.25"
  />
);

const LineArrowRightSVG = styled(SVG)`
  vertical-align: middle;
`;

export const LineArrowRight = () => (
  <LineArrowRightSVG viewBox="0 0 12 6" xmlns="http://www.w3.org/2000/svg">
    <LineArrowPolygon />
  </LineArrowRightSVG>
);

const LineArrowLeftSVG = styled(SVG)`
  vertical-align: middle;
  transform: rotate(180deg);
`;

export const LineArrowLeft = () => (
  <LineArrowLeftSVG viewBox="0 0 12 6" xmlns="http://www.w3.org/2000/svg">
    <LineArrowPolygon />
  </LineArrowLeftSVG>
);
