import React from 'react';

import uuid from 'uuid';

import CloseIcon from '../../svgs/CloseIcon';

import Wrapper from './Wrapper';

import Text from './Text';
import Button from './Button';
import Close from './Close';

import { MessageActions, UserActions } from 'actions';

class MessageBar extends React.Component {
  constructor(props) {
    super(props);
    this.dismissMessage = this.dismissMessage.bind(this);
  }

  handleCarflixAdClick(variant, e) {
    const { dispatch, searchId, location } = this.props;
    const carPage =
      location.query.pageType === 'car' && location.pathname.includes('carId');

    const page = carPage ? 'car' : 'search';
    e.preventDefault();

    dispatch(
      UserActions.trackInteractions({
        interactions: [
          {
            id: uuid.v4(),
            eventName: `${page}.carflix-banner-advert-${variant}.click`,
            sourceUrl: window.location.href,
            searchId,
          },
        ],
      }),
    );
    // survey link for getting more info about users used car preferences
    const link = 'https://forms.gle/xmoddyzEXWipS1KL6';

    window.open(link, '_blank');
  }

  dismissMessage() {
    this.props.dispatch(MessageActions.dismissMessage());
  }

  render() {
    const { messageDismissed } = this.props;

    const text =
      'Help us make buying a used car easier - Do you have 5 mins to answer a few questions?';
    const buttonText = 'Yes, I’ll help';
    const variant = 1;

    return (
      <Wrapper {...{ messageDismissed }}>
        <Text>{text}</Text>
        <Button onClick={this.handleCarflixAdClick.bind(this, variant)}>
          {buttonText}
        </Button>
        <Close onClick={this.dismissMessage}>
          <CloseIcon />
        </Close>
      </Wrapper>
    );
  }
}

export default MessageBar;
