import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Partnership = styled.div`
  position: absolute;
  bottom: ${rem('10px')};
  right: 0;
  display: flex;
  align-items: center;

  ${media.bpTablet`
    left: ${rem('195px')};
    bottom: ${rem('15px')};
  `} p {
    font-size: ${rem('11px')};
    color: ${props => props.theme.mediumGrey};

    ${media.bpTablet`
      font-size: ${rem('15px')};
    `};
  }

  img {
    width: ${rem('74px')};
    height: ${rem('28px')};
    margin-left: ${rem('6px')};

    ${media.bpTablet`
      width: ${rem('120px')};
      height: ${rem('48px')};
      margin-left: ${rem('10px')};
    `};
  }
`;

export default Partnership;
