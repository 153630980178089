import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('30px')} 0;
  background: url('/images/help-me-choose/help_pattern.jpg') repeat;

  ${media.bpTablet`
    padding: ${rem('60px')} 0 ${rem('90px')};
  `};
`;

export default Wrapper;
