import React from 'react';

import Wrapper from './Wrapper';
import Text from './Text';
import Button from './Button';
import Link from './Link';

import { CookiePolicyActions } from 'actions';

class CookiePolicy extends React.Component {
  constructor(props) {
    super(props);
    this.acceptCookiePolicy = this.acceptCookiePolicy.bind(this);
  }

  acceptCookiePolicy() {
    this.props.dispatch(CookiePolicyActions.acceptCookiePolicy());
  }

  render() {
    const { cookiePolicyAgreed, pathname } = this.props;

    if (cookiePolicyAgreed) {
      return null;
    }

    return (
      <Wrapper {...{ pathname }}>
        <Text>
          We use cookies to improve your experience. By continuing you agree to
          Carsnip's{' '}
          <Link
            href="/articles/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
            title="Carsnip Cookie Policy"
          >
            cookie policy
          </Link>{' '}
          and{' '}
          <Link
            href="/articles/terms-conditions-use/"
            rel="noopener noreferrer"
            target="_blank"
            title="Carsnip Terms of use"
          >
            terms of use
          </Link>
          .
        </Text>
        <Button onClick={this.acceptCookiePolicy}>I AGREE</Button>
      </Wrapper>
    );
  }
}

export default CookiePolicy;
