import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('36px')};
  height: ${rem('35px')};

  ${media.bpTablet`
    width: ${rem('72px')};
    height: ${rem('70px')};
  `}
`;

const Path1 = styled.path`
  fill: #c9514b;
`;

const Path2 = styled.path`
  fill: #e6e6e6;
`;

const Path3 = styled.path`
  fill: #e86c60;
`;

const Path4 = styled.path`
  fill: #a67c52;
`;

const Path5 = styled.path`
  fill: #43a6dd;
`;

const Path6 = styled.path`
  fill: #72c472;
`;

class DeliveredIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 71.3 68.1" x="0px" y="0px">
        <g>
          <Path1 d="M10.8,24.8v-17c0-0.9,0.7-1.5,1.5-1.5h6.2c0.9,0,1.5,0.7,1.5,1.5v9.4L10.8,24.8z" />
          <Path2 d="M35.6,1.5L9.3,24.9V65c0,1.7,1.4,3.1,3.1,3.1h46.4c1.7,0,3.1-1.4,3.1-3.1V24.9L35.6,1.5z" />
          <Path3
            d="M69.6,34c-0.4,0-0.7-0.1-1-0.4l-33-30l-33,30C2,34.2,1,34.2,0.4,33.5c-0.6-0.6-0.5-1.6,0.1-2.2l34-30.9
            c0.6-0.5,1.5-0.5,2.1,0l34,30.9c0.6,0.6,0.7,1.6,0.1,2.2C70.5,33.9,70.1,34,69.6,34z"
          />
          <Path4 d="M41.8,48H29.4c-0.9,0-1.5,0.7-1.5,1.5v18.6h15.5V49.5C43.3,48.7,42.6,48,41.8,48z" />
          <Path5
            d="M41.8,38.7H29.4c-0.9,0-1.5-0.7-1.5-1.5v-9.3c0-0.9,0.7-1.5,1.5-1.5h12.4c0.9,0,1.5,0.7,1.5,1.5v9.3
            C43.3,38,42.6,38.7,41.8,38.7z"
          />
          <Path6
            d="M20.6,68.1c0.7-1.4,1.1-3,1.1-4.6c0-6-4.8-10.8-10.8-10.8C4.8,52.6,0,57.5,0,63.4c0,1.7,0.4,3.2,1.1,4.6H20.6z
            "
          />
          <Path6
            d="M70.1,68.1c0.7-1.4,1.1-3,1.1-4.6c0-6-4.8-10.8-10.8-10.8c-6,0-10.8,4.8-10.8,10.8c0,1.7,0.4,3.2,1.1,4.6H70.1
            z"
          />
        </g>
      </Svg>
    );
  }
}

export default DeliveredIcon;
