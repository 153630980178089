import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.li`
  max-width: ${rem('264px')};
  margin-left: ${rem('10px')};
  margin-right: ${rem('10px')};
  margin-bottom: ${rem('20px')};
  display: ${({ viewMore, i }) => (i > 0 && !viewMore ? 'none' : 'block')};

  @media (min-width: ${rem('1172px')}) {
    font-size: ${rem('14px')};
    display: block;
    max-width: ${rem('232px')};
  }
`;

const Link = styled.a`
  color: #198ddc;
  text-decoration: none;
`;

const ListItem = ({ name, count, link, viewMore, i }) => (
  <Wrapper {...{ viewMore, i }}>
    <Link href={link}>
      {name} {count ? `(${count})` : ''}
    </Link>
  </Wrapper>
);

export default ListItem;
