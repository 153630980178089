import styled from 'styled-components';

import media from 'styles/mixins/media';

export default styled.span`
  display: ${p => (p.containsTwoButtons ? 'inline-grid' : 'inline')};
  grid-gap: 0.5rem;

  ${media.bpSmall`
    display: inline;
  `}
`;
