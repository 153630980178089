import React from 'react';

import { withRouter } from 'react-router-dom';

import Wrapper from './Wrapper';
import Icon from './Icon';

import { unsubscribeQueryRegex } from 'services/regex';
import { NotificationActions } from 'actions';

/**
 * Component to render Notification message
 * notificationMessage should be a React element to avoid type checking errors
 * If it includes an icon, include the classname Notification__icon on the img to get correct styles
 */
class Notification extends React.Component {
  componentDidMount() {
    const { dispatch, notificationMessage, location, history } = this.props;

    // If the URL contains the unsubscribe query param, remove it.
    if (location.search.includes('unsubscribe=')) {
      const newUrl = `${location.pathname}${location.search}`.replace(
        unsubscribeQueryRegex,
        '',
      );
      history.push(newUrl);
    }

    if (
      notificationMessage.string ===
      'You have been unsubscribed from these updates'
    ) {
      setTimeout(() => {
        dispatch(NotificationActions.removeNotification());
      }, 4000);
    }
  }

  render() {
    const {
      notificationMessage,
      cookiePolicyAgreed,
      messageDismissed,
    } = this.props;

    // Show Notification if the notificationMessage string is not empty
    if (notificationMessage.string) {
      return (
        <Wrapper {...{ cookiePolicyAgreed, messageDismissed }}>
          {notificationMessage.image && (
            <Icon src={notificationMessage.image} />
          )}
          {notificationMessage.string}
        </Wrapper>
      );
    }

    return null;
  }
}

export default withRouter(Notification);
