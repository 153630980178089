import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Container = styled.div`
  padding-left: ${rem('18px')};
  padding-right: ${rem('18px')};
  margin: 0 auto;

  @media (min-width: ${rem('1152px')}) {
    padding-left: 0;
    padding-right: 0;
    max-width: ${rem('1116px')};
  }
`;

export default Container;
