import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  background-color: ${props => props.theme.lighterGrey};
  padding: ${rem('20px')} ${rem('18px')};

  @media (min-width: ${rem('1172px')}) {
    padding-top: ${rem('35px')};
    padding-bottom: ${rem('35px')};
  }
`;

const ContentWrapper = styled.div`
  max-width: ${rem('1117px')};
  margin: 0 auto;
`;

const Title = styled.h3`
  font-weight: 500;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('16px')};
  margin-top: 0;
  margin-bottom: ${rem('15px')};
  max-width: ${rem('765px')};

  @media (min-width: ${rem('1172px')}) {
    font-size: ${rem('18px')};
  }
`;

const Content = styled.p`
  font-weight: 300;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('13px')};
  line-height: ${rem('21px')};
  max-width: ${rem('765px')};

  @media (min-width: ${rem('1172px')}) {
    font-size: ${rem('14px')};
    line-height: ${rem('22px')};
  }
`;

const FooterDescription = ({ title, content }) => (
  <Wrapper>
    <ContentWrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </ContentWrapper>
  </Wrapper>
);

FooterDescription.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FooterDescription;
