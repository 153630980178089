import React from 'react';
import PropTypes from 'prop-types';
import exenv from 'exenv';

import Wrapper from './Wrapper';
import ScrollContainer from './ScrollContainer';
import Tab from './Tab';
import Fields from './Fields';
import Field from './Field';
import Button from './Button';
import Section from './Section';

import parseUrl from 'services/url/parse-url';
import runSearch from 'services/run-search';
import { removeSearch } from 'services/url/manipulate-url';
import { FlagActions } from 'actions';

class SettingsDialog extends React.Component {
  constructor(props) {
    super(props);

    let fields = [
      { label: 'priceoffset', value: null, param: 'o.p' },
      { label: 'pricescale', value: null, param: 's.p' },
      { label: 'pricefunction', value: null, param: 'f.p' },
      { label: 'priceboost', value: null, param: 'w.p' },
      { label: 'mileageoffset', value: null, param: 'o.m' },
      { label: 'mileagescale', value: null, param: 's.m' },
      { label: 'mileagefunction', value: null, param: 'f.m' },
      { label: 'mileageboost', value: null, param: 'w.m' },
      { label: 'distanceoffset', value: null, param: 'o.d' },
      { label: 'distancescale', value: null, param: 's.d' },
      { label: 'distancefunction', value: null, param: 'f.d' },
      { label: 'distanceboost', value: null, param: 'w.d' },
      { label: 'feedboost', value: null, param: 'w.f' },
      { label: 'cpcboost', value: null, param: 'w.v' },
      { label: 'distributionboost', value: null, param: 'w.s' },
      {
        label: 'showAdditionalFields',
        value: null,
        param: 'showAdditionalFields',
      },
    ];

    if (exenv.canUseDOM && typeof window.localStorage === 'object') {
      const data = window.localStorage.getItem('SettingsDialog');
      if (data) {
        fields = JSON.parse(data);
      }
    }

    this.state = {
      fields,
      show: false,
    };

    this.updateSearch = this.updateSearch.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.toggleAdditionalFields = this.toggleAdditionalFields.bind(this);
  }

  updateSearch() {
    const { dispatch, location, features, history } = this.props;
    const { fields } = this.state;

    const fieldValues = [];

    for (let i = fields.length - 1; i >= 0; i--) {
      const field = fields[i];

      if (field.value) {
        fieldValues.push(`${field.param}=${field.value}`);
      }
    }

    const query = `${parseUrl(
      removeSearch(location.pathname),
      location.search,
    )}&${fieldValues.join('&')}`;

    runSearch(dispatch, query, features, history, {});

    // Update local storage
    if (exenv.canUseDOM) {
      window.localStorage.setItem('SettingsDialog', JSON.stringify(fields));
    }
  }

  updateValue(param, value) {
    const { fields } = this.state;

    // Find the field being used
    const i = fields.findIndex(f => f.param === param);

    // Update the value of that field
    fields[i].value = value;

    // Update state
    this.setState({ fields });
  }

  toggleAdditionalFields() {
    const { flags, dispatch } = this.props;

    dispatch(FlagActions.toggleAdditionalFields(!flags.showAdditionalFields));
  }

  render() {
    const { features } = this.props;
    const { fields, show } = this.state;

    return (
      <Wrapper {...{ show }}>
        <Tab onClick={() => this.setState(p => ({ show: !p.show }))} />
        <ScrollContainer>
          {features.showAdditionalFields && (
            <Section>
              <h3>Toggle Advert Data</h3>
              <Button onClick={this.toggleAdditionalFields}>toggle</Button>
            </Section>
          )}
          <Section>
            <h3>Function Scores</h3>
            <Fields>
              {fields.map(field => (
                <Field
                  key={field.label}
                  {...{ ...field, updateValue: this.updateValue }}
                />
              ))}
            </Fields>
            <Button onClick={this.updateSearch}>update</Button>
          </Section>
        </ScrollContainer>
      </Wrapper>
    );
  }
}

SettingsDialog.propTypes = {
  features: PropTypes.object.isRequired,
};

export default SettingsDialog;
