import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const CopyrightTop = styled.div`
  text-align: center;

  @media (min-width: ${rem('990px')}) {
    display: flex;
    align-items: center;
    text-align: left;
  }
`;

export default CopyrightTop;
