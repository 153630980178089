import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  margin: ${rem('20px')} 0;
  color: #626567;

  ${media.bpsMedium`
    margin: ${rem('40px')} 0;
  `}
`;

export default Wrapper;
