import React from 'react';

import {
  Banner,
  Container,
  Text,
  Logos,
  FordLogo,
  ImperialLogo,
  PeterVardyLogo,
} from './DealerBannerStyles';

const DealerBanner = () => (
  <Banner>
    <Container>
      <Text>Over 6,000 dealers already onboard including...</Text>
      <Logos>
        <FordLogo alt="Fords of Windsor Logo" src="/images/fow.png" />
        <ImperialLogo alt="Imperial Logo" src="/images/imperial.png" />
        <PeterVardyLogo alt="Peter Vardy Logo" src="/images/peter_vardy.png" />
      </Logos>
    </Container>
  </Banner>
);

export default DealerBanner;
