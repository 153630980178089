import React from 'react';

import SVG from './SVG';

const Calendar = () => (
  <SVG viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 2h-4V0h-2v2h-4V0h-2v2H7V0H5v2H1a1 1 0 00-1 1v20c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm-1 20H2V8h20v14z"
      fill="currentColor"
    />
    <path
      d="M4 11h4v3H4zM10 11h4v3h-4zM4 16h4v3H4zM10 16h4v3h-4zM16 11h4v3h-4z"
      fill="currentColor"
    />
  </SVG>
);

export default Calendar;
