import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Header = styled.h2`
  font-size: ${rem('18px')};
  font-weight: 500;
  margin: 0 0 ${rem('20px')};
  color: ${props => props.theme.orange};
  display: flex;
  align-items: center;
`;

export default Header;
