import React from 'react';

import SVG from './SVG';

const Cog = () => (
  <SVG
    fill="none"
    stroke="currentColor"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23 12a2 2 0 00-2-2h-1.262a7.968 7.968 0 00-.852-2.058l.892-.892a2 2 0 10-2.828-2.828l-.892.892A7.968 7.968 0 0014 4.262V3a2 2 0 00-4 0v1.262a7.968 7.968 0 00-2.058.852l-.892-.892A2 2 0 104.222 7.05l.892.892A7.968 7.968 0 004.262 10H3a2 2 0 000 4h1.262c.189.732.477 1.422.852 2.058l-.892.892a2 2 0 102.828 2.828l.892-.892a7.953 7.953 0 002.058.852V21a2 2 0 004 0v-1.262a7.968 7.968 0 002.058-.852l.892.892a2 2 0 102.828-2.828l-.892-.892A7.953 7.953 0 0019.738 14H21a2 2 0 002-2z" />
    <path d="M12 9a3 3 0 110 6 3 3 0 010-6z" />
  </SVG>
);

export default Cog;
