import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: ${rem('660px')};
  margin: 0 auto;

  ${media.bpTablet`
    max-width: ${rem('760px')};
  `}
`;

export default Logos;
