import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ListItem from './ListItem';

const Wrapper = styled.div`
  background-color: #f4f4f5;
  padding: ${rem('20px')} ${rem('18px')};

  @media (min-width: ${rem('1172px')}) {
    padding-top: ${rem('40px')};
  }
`;

const Title = styled.h3`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 500;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('15px')};
  margin-bottom: ${rem('25px')};
  text-align: center;

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('22px')};
    margin-top: ${rem('10px')};
    margin-bottom: ${rem('55px')};
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-content: space-around;

  @media (min-width: ${rem('604px')}) {
    height: ${rem('1862px')};
  }

  @media (min-width: ${rem('888px')}) {
    max-width: ${rem('852px')};
    height: ${rem('1190px')};
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
  }

  @media (min-width: ${rem('1172px')}) {
    max-width: ${rem('1136px')};
    height: ${rem('900px')};
  }
`;

const LocationList = ({ list, title }) => (
  <Wrapper id="by-location">
    <Title>{title}</Title>
    <List>
      {list.map(location => (
        <ListItem key={location.name} {...location} />
      ))}
    </List>
  </Wrapper>
);

LocationList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default LocationList;
