export default [
  {
    placeholder: '4x4 70,000 miles £15k Cardiff',
    title: 'Land Rover Discovery',
    image: '/images/home/land_rover_discovery.jpg',
  },
  {
    placeholder: 'Sports Convertible Blue £21k',
    title: 'Jaguar XK Convertible',
    image: '/images/home/jaguar_xk.jpg',
  },
  {
    placeholder: 'BMW X4 £14k Black',
    title: 'BMW X4',
    image: '/images/home/bmw_x4.jpg',
  },
  {
    placeholder: 'Petrol Hatchback Manual £12k',
    title: 'Renault Clio',
    image: '/images/home/renault_clio.jpg',
  },
  {
    placeholder: '5 Door Petrol London Automatic',
    title: 'Ford Fiesta',
    image: '/images/home/ford_fiesta.jpg',
  },
  {
    placeholder: 'Hatchback 5 Door Diesel',
    title: 'Volkswagen Golf',
    image: '/images/home/vw_golf.jpg',
  },
];
