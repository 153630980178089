import React from 'react';
import styled from 'styled-components';

import Price from './Price';

const PricesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const formatCurrency = num =>
  num
    .toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    })
    .split('.')[0];

const Prices = ({ price }) => (
  <PricesWrapper>
    <Price>{formatCurrency(price)}</Price>
  </PricesWrapper>
);

export default Prices;
