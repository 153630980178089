import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from '../../../../styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('126px')};
  height: ${rem('120px')};

  ${media.bpsMedium`
    width: ${rem('252px')};
    height: ${rem('240px')};
  `}
`;

const GraphIcon = () => (
  <Svg viewBox="0 0 46 44" x="0px" y="0px">
    <g>
      <path
        d="M25,0h-4c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1V1C26,0.4,25.6,0,25,0z"
        style={{ fill: '#B3B3B3' }}
      />
      <path
        d="M41,4H5C4.4,4,4,4.4,4,5v28c0,0.6,0.4,1,1,1s1-0.4,1-1h34c0,0.6,0.4,1,1,1s1-0.4,1-1V5C42,4.4,41.6,4,41,4z
        "
        style={{ fill: '#E6E6E6' }}
      />
      <path
        d="M23,42c-0.6,0-1-0.4-1-1v-8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C24,41.6,23.6,42,23,42z"
        style={{ fill: '#B3B3B3' }}
      />
      <path
        d="M11,44c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-1-0.2-1.4l8-10c0.3-0.4,1-0.5,1.4-0.2c0.4,0.3,0.5,1,0.2,1.4
        l-8,10C11.6,43.9,11.3,44,11,44z"
        style={{ fill: '#B3B3B3' }}
      />
      <path
        d="M35,44c-0.3,0-0.6-0.1-0.8-0.4l-8-10c-0.3-0.4-0.3-1.1,0.2-1.4c0.4-0.3,1.1-0.3,1.4,0.2l8,10
        c0.3,0.4,0.3,1.1-0.2,1.4C35.4,43.9,35.2,44,35,44z"
        style={{ fill: '#B3B3B3' }}
      />
      <path
        d="M45,34H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h44c0.6,0,1,0.4,1,1S45.6,34,45,34z"
        style={{ fill: '#898989' }}
      />
      <path
        d="M24,10h-2c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V11C25,10.4,24.6,10,24,10z"
        style={{ fill: '#43A6DD' }}
      />
      <path
        d="M16,18h-2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-6C17,18.4,16.6,18,16,18z"
        style={{ fill: '#43A6DD' }}
      />
      <path
        d="M32,16h-2c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-8C33,16.4,32.6,16,32,16z"
        style={{ fill: '#43A6DD' }}
      />
    </g>
  </Svg>
);

export default GraphIcon;
