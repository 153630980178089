import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const SpeechContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${rem('-10px')};
  width: ${rem('250px')};
  height: ${rem('155px')};

  ${media.bpTablet`
    right: 0;
    left: ${rem('100px')};
    width: ${rem('445px')};
    height: ${rem('275px')};
  `}
`;

export default SpeechContainer;
