import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Heading = styled.h2`
  font-size: ${rem('23px')};
  font-weight: 400;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  margin: ${rem('10px')} 0 ${rem('30px')};
  text-align: center;
  color: ${props => props.theme.darkBlueGrey};

  ${media.bpTablet`
    font-size: ${rem('28px')};
    margin-bottom: ${rem('40px')};
  `};
`;

export default Heading;
