import has from 'has';

import filterOrder from './filter-order';

/**
 * A function that will convert the request object from
 * the search api to a url string
 * @param  {Object}, request, The request object that contains the params
 * @return {String},          The new url string
 */
const parseRequest = (request = {}) => {
  // Create the intial string
  let url = '';

  // Check if the request even has any values
  if (!Object.keys(request).length) {
    // Return an empty string to express an empty url
    return url;
  }

  // Loop over the request
  for (let i = 0; i < filterOrder.length; i++) {
    if (has(request, filterOrder[i])) {
      let values = Array.isArray(request[filterOrder[i]])
        ? request[filterOrder[i]]
        : [request[filterOrder[i]]];
      values = values.map(value => encodeURIComponent(value));

      // for range filters we need to make sure that there is only one underscore and remove anything after the second underscore
      if (values.length === 1 && values[0].includes('_')) {
        let fieldValue = values[0];
        fieldValue = fieldValue.replace(/([^_]*_[^_]*).*/, '$1');
        url += `/${filterOrder[i]}/${fieldValue}`;
      } else {
        url += `/${filterOrder[i]}/${values.join(',')}`;
      }
    }
  }
  // Return the new url
  return url;
};

export default parseRequest;
