import React from 'react';

import SVG from './SVG';

const Engine = () => (
  <SVG
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 32 27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M31 15v8M9 1h10M31 19h-5M14 4V1M23 13l-3-5H7l-3 5H1v8h3l3 5h19V13z" />
  </SVG>
);

export default Engine;
