import React from 'react';

import StandardFeaturedAdvert from '../../page-elements/StandardFeaturedAdvert';

import { ResultsWrapper } from './StyledResults';

const PremiumAdvertPage = ({ advert, history }) => (
  <ResultsWrapper>
    <StandardFeaturedAdvert
      {...{
        advert,
        history,
        premiumCarPage: true,
      }}
    />
  </ResultsWrapper>
);

export default PremiumAdvertPage;
