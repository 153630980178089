/* global dataLayer */

const generalInfoToGA = (adverts, activeFilters) => {
  const advertMaps = {};

  adverts.forEach((advert, index) => {
    // Only track car adverts
    if (advert.advertType !== 'car') {
      return;
    }

    const advertMap = {};
    const advertData = advert.advertData[0];

    advertMap.id = advert.carId;
    advertMap.index = index;
    advertMap.url = advertData.hashedUri;
    advertMap.name = advert.title;
    advertMap.registrationYear = advert.registrationYear;
    advertMap.gearbox = advert.gearbox;
    advertMap.fuelType = advert.fuel;
    advertMap.mileage = advert.mileage;
    advertMap.bodyStyle = advert.bodyStyle;
    advertMap.engineSize = advert.engineSizeL;
    advertMap.dealerName = advertData.dealerName;
    advertMap.price = advertData.price;

    advertMaps[advert.carId] = advertMap;
  });

  dataLayer.push({
    advertMaps,
    activeFilters,
  });
};

const advertLoaded = (car, position) => {
  if (car.advertType !== 'car') {
    return;
  }
  const advertData = car.advertData[0];

  dataLayer.push({
    event: `Advert.${advertData.premiumListing ? 'Paid' : ''}Loaded`,
    eventCategory: 'Advert',
    eventAction: `Advert${advertData.premiumListing ? 'Paid' : ''}Loaded`,
    eventLabelGA: `${advertData.dealerName}__${advertData.postcodeLetters}`,
    eventLabel: `${advertData.dealerName}__${advertData.dealerDomain}__${advertData.postcodeLetters}__${position}__${car.carId}__${advertData.uri}`,
  });
};

const advertImpression = (car, page, position) => {
  if (car.advertType !== 'car') {
    return;
  }
  const advertData = car.advertData[0];

  dataLayer.push({
    event: `Advert.${advertData.premiumListing ? 'Paid' : ''}Impression`,
    eventCategory: 'Advert',
    eventAction: `Advert${advertData.premiumListing ? 'Paid' : ''}Impression`,
    eventLabelGA: `${advertData.dealerName}__${advertData.postcodeLetters}`,
    eventLabel: `${advertData.dealerName}__${advertData.dealerDomain}__${advertData.postcodeLetters}__${page}__${position}__${car.carId}__${advertData.uri}`,
  });
};

const advertClicked = (carId, advertData, position) => {
  dataLayer.push({
    event: `Advert.${advertData.premiumListing ? 'Paid' : ''}Click`,
    eventCategory: 'Advert',
    eventAction: `Advert${advertData.premiumListing ? 'Paid' : ''}Click`,
    eventLabelGA: `${advertData.dealerName}__${advertData.postcodeLetters}`,
    eventLabel: `${advertData.dealerName}__${advertData.dealerDomain}__${advertData.postcodeLetters}__${position}__${carId}__${advertData.uri}`,
  });
};

const searchSubmitted = (location, query) => {
  dataLayer.push({
    event: `Search.${location}`,
    eventCategory: 'Search',
    eventAction: `Search${location}`,
    eventLabel: query,
  });
};

const saveSearchMobileButtonClicked = filtersDescription => {
  dataLayer.push({
    event: 'SaveSearch.MobileButtonClicked',
    eventCategory: 'SaveSearch',
    eventAction: 'SaveSearchMobileButtonClicked',
    eventLabel: filtersDescription,
  });
};

const saveSearchRequested = filtersDescription => {
  dataLayer.push({
    event: 'SaveSearch.Requested',
    eventCategory: 'SaveSearch',
    eventAction: 'SaveSearchRequested',
    eventLabel: filtersDescription,
  });
};

const saveSearchAlertCarsViewed = filtersDescription => {
  dataLayer.push({
    event: 'SaveSearch.AlertCarsViewed',
    eventCategory: 'SaveSearch',
    eventAction: 'SaveSearchAlertCarsViewed',
    eventLabel: filtersDescription,
  });
};

const valueMyCarLandingLookup = (make, model) => {
  dataLayer.push({
    event: 'ValueMyCar.LandingLookup',
    eventCategory: 'ValueMyCar',
    eventAction: 'ValueMyCarLandingLookup',
    eventLabel: `${make} ${model}`,
  });
};

const valueMyCarWidgetLookup = (make, model) => {
  dataLayer.push({
    event: 'ValueMyCar.WidgetLookup',
    eventCategory: 'ValueMyCar',
    eventAction: 'ValueMyCarWidgetLookup',
    eventLabel: `${make} ${model}`,
  });
};

const valueMyCarRequested = (make, model) => {
  dataLayer.push({
    event: 'ValueMyCar.Requested',
    eventCategory: 'ValueMyCar',
    eventAction: 'ValueMyCarRequested',
    eventLabel: `${make} ${model}`,
  });
};

const valueMyCarComplete = () => {
  dataLayer.push({
    event: 'ValueMyCar.Complete',
    eventCategory: 'ValueMyCar',
    eventAction: 'ValueMyCarComplete',
    // eventLabel: `${make} ${model}` //TODO add in when manufacturer and model are added to getValuation response
  });
};

const myCarFindNextCar = purpose => {
  dataLayer.push({
    event: `${purpose}MyCar.FindNextCar`,
    eventCategory: `${purpose}MyCar`,
    eventAction: `${purpose}MyCarFindNextCar`,
  });
};

const filterChanged = name => {
  dataLayer.push({
    event: 'Filter.Changed',
    eventCategory: 'Filter',
    eventAction: `Filter${name.replace(/\s/g, '')}`,
  });
};

export {
  generalInfoToGA,
  advertLoaded,
  advertImpression,
  searchSubmitted,
  advertClicked,
  saveSearchMobileButtonClicked,
  saveSearchRequested,
  saveSearchAlertCarsViewed,
  valueMyCarLandingLookup,
  valueMyCarWidgetLookup,
  valueMyCarRequested,
  valueMyCarComplete,
  myCarFindNextCar,
  filterChanged,
};
