import React from 'react';
import PropTypes from 'prop-types';

import CarsnipLogo from '../svgs/CarsnipLogo';

const Logo = ({ large, fadeIn }) => <CarsnipLogo {...{ large, fadeIn }} />;

Logo.propTypes = {
  fadeIn: PropTypes.bool,
  large: PropTypes.bool,
};

Logo.defaultProps = {
  fadeIn: false,
  large: false,
};

export default Logo;
