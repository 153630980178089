import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.li`
  margin-top: ${rem('6px')};
`;

const Label = styled.label`
  display: block;
`;

const Input = styled.input`
  display: block;
  border: ${rem('1px')} solid #d2d2d2;
  margin-top: ${rem('4px')};
  width: 100%;
  padding: ${rem('4px')};
`;

const Field = ({ label, param, value, updateValue }) => {
  function handleChange(e) {
    e.preventDefault();

    updateValue(param, e.target.value);
  }

  return (
    <Wrapper>
      <Label htmlFor={label}>{label}</Label>
      <Input
        name={label}
        onChange={handleChange}
        type="text"
        value={value || ''}
      />
    </Wrapper>
  );
};

export default Field;
