import React from 'react';

import NotFound from '../NotFound';

import Hero from './Hero';
import How from './How';
import Why from './Why';
import FormSection from './FormSection';

class HelpMeChoose extends React.PureComponent {
  render() {
    const { features } = this.props;

    if (!features.helpMeChoose) {
      return <NotFound />;
    }

    return (
      <>
        <Hero />
        <How />
        <Why />
        <FormSection />
      </>
    );
  }
}

export default HelpMeChoose;
