import styled from 'styled-components';

const Slide = styled.div`
  display: flex;
  width: 500%;
  transform: translateX(-${props => props.selected * (100 / 5)}%);
  transition: transform 500ms ease;

  div img {
    width: 100%;
  }
`;

export default Slide;
