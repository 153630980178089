import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Forms = styled.div`
  @media (min-width: ${rem('700px')}) {
    display: flex;
  }

  @media (min-width: ${props => rem(props.colLayoutBp)}) {
    display: block;
  }
`;

export default Forms;
