import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { StyledSpinner, Rect } from '../../../page-elements/Spinner';

const Button = styled.button`
  font-size: ${rem('14px')};
  border-radius: ${rem('4px')};
  height: ${rem('40px')};
  margin-top: ${rem('10px')};
  width: 100%;
  color: white;
  background: ${props => props.theme.green};
  border: none;

  ${StyledSpinner} {
    height: ${rem('30px')};
    margin: 0 auto;
  }

  ${Rect} {
    background: white;
  }
`;

export default Button;
