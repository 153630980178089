import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Svg = styled.svg`
  width: ${rem('18px')};
  height: ${rem('18px')};
  margin-right: ${rem('8px')};
`;

const Path = styled.path`
  fill: none;
  stroke: #ff6000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

class SpeechBubble extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 16 16">
        <Path d="M4.5 5.5h7M4.5 8.5h7M15.5 7C15.5 3.4 12.1.5 8 .5S.5 3.4.5 7s3.4 6.5 7.5 6.5c.5 0 1 0 1.5-.1l4 2.1v-4.1c1.2-1.2 2-2.7 2-4.4z" />
      </Svg>
    );
  }
}
export default SpeechBubble;
