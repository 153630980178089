/**
 * Pure function for populating the currentlyFetching prop
 */
function currentlyFetching(state = {}, action) {
  switch (action.type) {
    case 'VALUE_VRM_LOOKUP_STARTED':
    case 'VRM_EMAIL_SUBMITTED':
      return { ...state, valueMyCar: true };

    case 'VALUE_VRM_LOOKUP':
    case 'VALUE_VRM_LOOKUP_ERR':
    case 'VRM_EMAIL_SENT':
    case 'VRM_EMAIL_ERR':
      return { ...state, valueMyCar: false };

    case 'SELL_VRM_LOOKUP_STARTED':
    case 'GET_MY_PRICE_STARTED':
    case 'ACCEPT_OFFER_STARTED':
      return { ...state, sellMyCar: true };

    case 'SELL_VRM_LOOKUP':
    case 'SELL_VRM_LOOKUP_ERR':
    case 'GET_MY_PRICE':
    case 'GET_MY_PRICE_ERR':
    case 'ACCEPT_OFFER':
    case 'ACCEPT_OFFER_ERR':
      return { ...state, sellMyCar: false };

    case 'SEARCH_STARTED':
      return { ...state, search: true };

    case 'SEARCH_RESPONSE':
    case 'SEARCH_ERR':
      return { ...state, search: false };

    case 'FILTER_STARTED':
      return { ...state, filter: true };

    case 'FILTER_RESPONSE':
    case 'FILTER_ERR':
      return { ...state, filter: false };

    case 'SAVE_SEARCH_STARTED':
      return { ...state, saveSearch: true };

    case 'SEARCH_SAVED':
    case 'SAVE_SEARCH_ERR':
      return { ...state, saveSearch: false };

    default:
      return state;
  }
}

export default currentlyFetching;
