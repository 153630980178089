// Import app dependencies
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import LoadScript from 'react-load-script';

import Promise from 'promise-polyfill';

import App from './components/App';
import store from './store';

// Promise ES6

// Set the public path for the bundles
// eslint-disable-next-line no-underscore-dangle, no-undef, camelcase
__webpack_public_path__ = '/static/';
// Add to the window
if (!window.Promise) {
  window.Promise = Promise;
}

// Initialize the GA code
ReactGA.initialize('UA-111035192-1');

// Function called whenever the router updates its state
function LogPageView(props) {
  const { hash, pathname, search } = window.location;

  // Remove scroll lock when changing page
  document.body.classList.remove('no-scroll');

  // Track the page change
  ReactGA.pageview(pathname + search);
  const pageChange = new CustomEvent('pagechange', {
    detail: {
      url: pathname + search,
    },
  });
  window.dispatchEvent(pageChange);

  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          block: 'start',
        });
        window.scroll(0, window.scrollY - 70); // account for fixed header
      }
    }, 0);
  } else {
    // Send the user to the top of the page
    document.body.scrollIntoView({
      block: 'start',
    });
  }

  return props.children;
}

// Create the store
// eslint-disable-next-line no-underscore-dangle
const appStore = store(window.__PRELOADED_STATE__);

// Find the app root
const root = document.getElementById('render-app');

const handleScriptLoad = () => {
  window.addthis.init();
};

const container = (
  <Provider store={appStore}>
    <BrowserRouter>
      <LogPageView>
        <LoadScript
          onLoad={handleScriptLoad}
          url="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5e6a613e6c7776e0"
        />
        <App />
      </LogPageView>
    </BrowserRouter>
  </Provider>
);

// Hydrate the Server rendered app
hydrate(container, root);
