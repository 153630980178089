import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';
import Button from 'shared/Button';

const NextButton = styled(Button)`
  font-size: ${rem('14px')};
  padding: ${rem('12px')} 0;
  margin-top: ${rem('20px')};
  border: none;
  width: 100%;
  text-transform: none;

  ${media.bpTablet`
    width: ${rem('220px')};
    padding: ${rem('14px')} 0;
    margin-top: ${rem('50px')};
  `};
`;

export default NextButton;
