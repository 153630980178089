import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Svg = styled.svg`
  width: ${rem('59px')};
  height: ${rem('31px')};

  @media (min-width: ${rem('515px')}) {
    width: ${rem('52px')};
    height: ${rem('28px')};
  }
`;

const Path1 = styled.path`
  fill: #70747a;
`;

const Path2 = styled.path`
  fill: ${props => props.theme.white};
`;

const Facebook = () => (
  <Svg viewBox="0 0 52 28">
    <g>
      <Path1
        d="M38,2c6.6,0,12,5.4,12,12c0,6.6-5.4,12-12,12H14C7.4,26,2,20.6,2,14C2,7.4,7.4,2,14,2H38 M38,0H14
          C6.3,0,0,6.3,0,14v0c0,7.7,6.3,14,14,14h24c7.7,0,14-6.3,14-14v0C52,6.3,45.7,0,38,0L38,0z"
      />
    </g>
    <g>
      <Path2
        d="M24.5,20.9l0-6.2h-2.5v-2.5h2.5v-1.6c0-2.3,1.4-3.4,3.5-3.4c1,0,1.8,0.1,2.1,0.1v2.4l-1.4,0
          c-1.1,0-1.3,0.5-1.3,1.3v1.1h3.3l-1.2,2.5h-2v6.2H24.5z"
        id="White_1_"
      />
    </g>
  </Svg>
);

export default Facebook;
