import styled from 'styled-components';

export default styled.p`
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  color: ${p => p.theme.errorRed};
  font-weight: bold;
  font-size: 0.875rem;
`;
