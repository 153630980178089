import React from 'react';

import Form from '../Form';

import Wrapper from './Wrapper';
import Container from './Container';
import Triangle from './Triangle';
import Heading from './Heading';
import Content from './Content';
import DaveContainer from './DaveContainer';
import Dave from './Dave';
import SpeechContainer from './SpeechContainer';
import SpeechBubble from './SpeechBubble';
import Speech from './Speech';
import Highlight from './Highlight';
import Partnership from './Partnership';

class Hero extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Container>
          <Heading>
            Want one of our car-finding experts to find, negotiate, check and
            deliver your perfect car for you?
          </Heading>
          <Content>
            <DaveContainer>
              <Dave
                alt="Carsnip Dave"
                src="/images/help-me-choose/carsnip_dave.svg"
              />
              <SpeechContainer>
                <SpeechBubble />
                <Speech>
                  <Highlight>Hi I’m Dave!</Highlight> I will find you ANY car
                  you want and make sure it’s a great example at a great price…
                  all without you having to do ANY of the work!
                </Speech>
              </SpeechContainer>
              <Partnership>
                <p>In partnership with</p>
                <img
                  alt="Palmdale Logo"
                  src="/images/help-me-choose/palmdale_logo.png"
                />
              </Partnership>
            </DaveContainer>
            <Form badge boxShadow heading="Let's get started!" />
          </Content>
        </Container>
        <Triangle />
      </Wrapper>
    );
  }
}

export default Hero;
