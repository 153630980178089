/**
 * A List of regex's that are used in multiple places
 */

// Used to match the search string from the url
const searchStringRegex = new RegExp(/(&|\?)s=[^&]*/);

// Used to test against strings for email
const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

// Used to match the unsubscribe query string
const unsubscribeQueryRegex = new RegExp(/(&|\?)unsubscribe=[^&]*/);

// Used to match a valid VRM
const vrmRegex = new RegExp(/^[a-zA-Z0-9]{0,10}$/);

const postcodeRegex = new RegExp(
  /(([A-Z][0-9][0-9]?)|(([A-Z][A-Z][0-9][0-9]?)|(([A-Z][0-9][A-HJKPSTUW])|([A-Z][A-Z][0-9][ABEHMNPRVWXY]))))([0-9][A-Z]{2})/i,
);

export {
  searchStringRegex,
  emailRegex,
  unsubscribeQueryRegex,
  vrmRegex,
  postcodeRegex,
};
