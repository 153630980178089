import { getTitle } from './search-title';

const getDocumentHeadValues = (state, url) => {
  const {
    totalHits,
    cars,
    activeFilters,
    searchTitle,
    searchDescription,
  } = state;

  // Default title, meta tags
  const documentHead = {
    title: "The UK's largest used car search engine - carsnip.com",
    description: "The UK's largest used car search engine - carsnip.com",
  };
  // Search
  if (url.includes('/search')) {
    documentHead.title =
      `${searchTitle} on carsnip.com` ||
      getTitle({ totalHits, cars, activeFilters });
    documentHead.description =
      searchDescription || getTitle({ totalHits, cars, activeFilters });
  }

  // Homepage
  if (url === '/') {
    documentHead.title =
      "Officially The UK's Biggest Car Search Engine - Carsnip.com";
    documentHead.description =
      "Carsnip is officially the UK's largest car search engine, so whether you are looking for used cars or new cars, you can find them all in one place - our powerful but simple to use search tools, including map-based search will help you discover the right car & deal for you quickly & efficiently.";
  }

  if (url.includes('/about')) {
    documentHead.title =
      'Carsnip, The Story So Far - Information About The Teams & Investors';
    documentHead.description =
      "Details about the teams and investors that have brought Carsnip.com to life. Carsnip is now officially the UK's largest car search engine and as a platform we are literally driving down the usually expensive costs of selling a car, especially for car dealerships";
  }

  if (url.includes('/dealers')) {
    documentHead.title =
      "Car Dealers Come & Join The UK's Largest Car Search Engine | Carsnip";
    documentHead.description =
      "To ensure that your car stock is getting the traffic it deserves from Carsnip then sign up today for free - if you would like to promote your stock further then you can sign up to Carsnip's premium listings, a pay on performance model with full control all with your own personal account manager";
  }

  return documentHead;
};

export default getDocumentHeadValues;
