/**
 * Pure function for populating the selectedFilter prop
 */
function selectedFilter(state = {}, action) {
  if (
    action.type === 'UPDATE_FILTER_RESPONSE' &&
    typeof action.data === 'string'
  ) {
    return action.data;
  }

  if (action.type === 'UPDATE_FILTER_RESPONSE') {
    return { ...action.data };
  }

  return state;
}

export default selectedFilter;
