import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Close = styled.div`
  position: absolute;
  top: ${rem('13px')};
  right: ${rem('12px')};
  width: ${rem('12px')};
  height: ${rem('12px')};
  cursor: pointer;
  display: flex;
  font-size: ${rem('12px')};
  color: #747880;

  @media (min-width: ${rem('430px')}) {
    top: ${rem('15px')};
    right: ${rem('20px')};
  }

  ${media.bpTablet`
    top: ${rem('20px')};
  `}
`;

export default Close;
