import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import SearchIcon from '../svgs/SearchIcon';

const Wrapper = styled.div`
  border-top: 1px solid #e6e6e6;
  padding: ${rem('45px')} ${rem('20px')};
  max-width: ${rem('1117px')};
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h3`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('21px')};
  margin: 0 ${rem('20px')};
  text-align: center;

  @media (min-width: ${rem('912px')}) {
    font-size: ${rem('30px')};
  }
`;

const SubTitle = styled.p`
  font-size: ${rem('13px')};
  color: ${props => props.theme.darkBlueGrey};
  text-align: center;
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('22px')};

  @media (min-width: ${rem('912px')}) {
    font-size: ${rem('14px')};
    margin-top: ${rem('12px')};
    margin-bottom: ${rem('40px')};
  }
`;

const Button = styled.a`
  width: 100%;
  padding: ${rem('16px')} ${rem('10px')};
  border-radius: ${rem('4px')};
  font-size: ${rem('14px')};
  background: linear-gradient(to top, #e95800, #ff6e17);
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: block;
  max-width: ${rem('356px')};
  margin: 0 auto;

  svg {
    width: ${rem('16px')};
    height: ${rem('16px')};
    margin-right: ${rem('8px')};
    vertical-align: middle;

    @media (min-width: ${rem('912px')}) {
      width: ${rem('23px')};
      height: ${rem('23px')};
      margin-right: ${rem('12px')};
    }
  }

  span {
    vertical-align: middle;
  }

  @media (min-width: ${rem('912px')}) {
    font-size: ${rem('22px')};
    font-weight: 300;
    display: inline-block;
    padding: ${rem('10px')} ${rem('20px')} ${rem('15px')};
    max-width: none;
    width: auto;
  }
`;

const SearchCTA = ({ title, subTitle, link, buttonText }) => (
  <Wrapper>
    <Title>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
    <Button href={link}>
      <SearchIcon />
      <span>{buttonText}</span>
    </Button>
  </Wrapper>
);

SearchCTA.propTypes = {
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SearchCTA;
