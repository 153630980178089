import React from 'react';

import Container from './Container';
import Wrapper from './Wrapper';
import TextWrapper from './TextWrapper';
import Heading from './Heading';
import Text from './Text';
import Slider from './Slider';

const WhoAreWe = () => (
  <Container>
    <Wrapper>
      <TextWrapper>
        <Heading>So who are we?</Heading>
        <Text>
          Remember there is no &apos;I&apos; in team. Our close knit team work
          together to bring you awesome car search that works for everyone! We
          care about two things: making our team stronger to make our customers
          lives better. That&apos;s it. We hope you like the results.
        </Text>
      </TextWrapper>
      <Slider />
    </Wrapper>
  </Container>
);

export default WhoAreWe;
