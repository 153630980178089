import 'intersection-observer';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Background, Wrapper } from './style';

const LazyImage = ({ altText, ratio, rootMargin, url }) => {
  const observerRef = useRef();
  const wrapperRef = useRef(null);
  const [loadedUrl, setLoadedUrl] = useState();

  useEffect(() => {
    let preloader;

    const handleLoad = () => setLoadedUrl(url);

    observerRef.current = new IntersectionObserver(
      ([element]) => {
        if (wrapperRef.current && element.isIntersecting) {
          preloader = new Image();
          preloader.addEventListener('load', handleLoad, { once: true });
          preloader.src = url;
        }
      },
      { rootMargin },
    );

    if (wrapperRef.current) observerRef.current.observe(wrapperRef.current);

    return () => {
      if (preloader) preloader.removeEventListener('load', handleLoad);
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  if (loadedUrl && observerRef.current) {
    observerRef.current.disconnect();
    observerRef.current = null;
  }

  return (
    <Wrapper isLoaded={loadedUrl} ratio={ratio} ref={wrapperRef}>
      {loadedUrl && (
        <Background aria-label={altText} role="img" url={loadedUrl} />
      )}
    </Wrapper>
  );
};

LazyImage.propTypes = {
  altText: PropTypes.string,
  ratio: PropTypes.number,
  rootMargin: PropTypes.string,
  url: PropTypes.string.isRequired,
};

LazyImage.defaultProps = {
  altText: null,
  ratio: null,
  rootMargin: '100px',
};

export default LazyImage;
