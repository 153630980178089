import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Card from './Card';

const Wrapper = styled.div`
  margin-left: ${rem('20px')};
  margin-right: ${rem('20px')};
  margin-bottom: ${rem('60px')};
`;

const Title = styled.h3`
  font-size: ${rem('15px')};
  font-weight: 400;
  text-align: center;

  @media (min-width: ${rem('912px')}) {
    font-size: ${rem('22px')};
  }
`;

const Posts = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const BlogPosts = ({ posts, title }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Posts>
      {posts.map((post, i) => (
        <Card key={i + post.title} {...post} />
      ))}
    </Posts>
  </Wrapper>
);

BlogPosts.propTypes = {
  posts: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default BlogPosts;
