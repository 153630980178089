/*
  Update the notificationMessage which will display the Notification component
 */
function updateNotificationMessage(message) {
  return {
    type: 'UPDATE_NOTIFICATION',
    data: message,
  };
}

/*
  Sets the notificationMessage back to default, hiding the Notification component
 */
function removeNotification() {
  return {
    type: 'REMOVE_NOTIFICATION',
  };
}

export { updateNotificationMessage, removeNotification };
