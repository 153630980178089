import has from 'has';

import config from './configs/search-title-config';

import formatNumber from 'utils/formatNumber';

const siMultipliers = ['', 'k', 'M', 'G', 'T'];

/**
  about the config data:

  nounTypeFields: fields containing nouns that can be used instead of car eg. ford, saloon, C Class
  The order of nounTypeFields should be used to make the final noun for the filter

  locationTypeFields:  fields to be treated as locations, prefixed with in eg. in Cardiff, in London
  carOriginTypeFields: fields to be treated as where the car is comming from, prefixed with from eg. from Motorhub
  searchTypeFields: fields containing search terms, prefixed with matching eg. matching awiegf
  withFields: range fields to come after the word 'with'
  adjectiveDescriptors:  words to add after certain adjective type fields to make tham make sense, eg. 7 doors instead of just 7
  modifiedFieldNames: name overrides for various fields
  modifiedTrailingUnits:  terms to replace some units to bettter describe the data
* */

function getLinkerString(count) {
  // return ' ' + count + ' '
  if (count === 1) {
    return '';
  }
  if (count === 2) {
    return ' or';
  }
  if (count > 2) {
    return ',';
  }

  return undefined;
}

function getNounFromFilterEntry(filterEntry, numberFound) {
  if (filterEntry.filters !== undefined) {
    let noun = '';
    filterEntry.filters.forEach(filter => {
      for (let j = filter.list.length - 1; j >= 0; j--) {
        const nounFromFilterEntry = getNounFromFilterEntry(
          filter.list[j],
          numberFound,
        );

        noun = ` ${filterEntry.key}${nounFromFilterEntry}${noun}`;
      }
    });
    return noun;
  }
  const nounFromFilterEntry = filterEntry.key;
  numberFound.numberFound++; // eslint-disable-line no-param-reassign
  return ` ${nounFromFilterEntry}${getLinkerString(numberFound.numberFound)}`;
}

function getNouns(filters, moreThanOne) {
  // loops in this function go backwards to build up the string from right to left

  const nouns = [];
  let firstMatchingSection = true;
  for (
    let nounFieldIndex = config.nounTypeFields.length - 1;
    nounFieldIndex >= 0;
    nounFieldIndex--
  ) {
    // loop through the noun fields
    const numberFound = { numberFound: 0 };
    for (
      let filterIndex = filters.length - 1;
      filterIndex >= 0;
      filterIndex--
    ) {
      // loop through the filters
      const filter = filters[filterIndex];
      if (filter.field === config.nounTypeFields[nounFieldIndex]) {
        // filter matches nounField
        let nounString = '';
        for (
          let listIndex = filter.list.length - 1;
          listIndex >= 0;
          listIndex--
        ) {
          // loop through the entries in this filter
          const noun = getNounFromFilterEntry(
            filter.list[listIndex],
            numberFound,
          );
          nounString = noun + nounString;
        }
        nouns.push({
          field: filter.field,
          filterValue: nounString,
          displayName: filter.name,
        });
      }
    }
    firstMatchingSection =
      firstMatchingSection && numberFound.numberFound === 0;
  }

  return nouns.length === 0
    ? [{ filterValue: moreThanOne ? ' cars' : ' car' }]
    : nouns;
}

function generateListString(list = []) {
  let listString = '';
  for (let listIndex = 0; listIndex < list.length; listIndex++) {
    const word = list[listIndex].key;
    listString =
      listString +
      (listIndex === list.length - 1 && list.length > 1 ? ' or ' : ' ') +
      word +
      (listIndex < list.length - 2 ? ',' : '');
  }
  return listString;
}

function generateListsForFilters(
  filters,
  matchingFilter,
  addDescriptorFunction,
) {
  const filterList = [];
  for (let filterIndex = 0; filterIndex < filters.length; filterIndex++) {
    const filter = filters[filterIndex];
    let listsString = '';
    if (matchingFilter(filter.field, filter)) {
      listsString += generateListString(filter.list);
      if (addDescriptorFunction) {
        listsString = addDescriptorFunction(listsString, filter.field);
      }
      filterList.push({
        field: filter.field,
        filterValue: listsString,
        displayName: filter.name,
      });
    }
  }

  return filterList;
}

function getAdjectives(filters) {
  function matchingFilter(field, filter) {
    return (
      config.nounTypeFields.indexOf(field) === -1 &&
      config.locationTypeFields.indexOf(field) === -1 &&
      config.carOriginTypeFields.indexOf(field) === -1 &&
      config.searchTypeFields.indexOf(field) === -1 &&
      !filter.isRange
    );
  }

  function addDescriptorFunction(listString, field) {
    if (config.adjectiveDescriptors[field]) {
      return `${listString} ${config.adjectiveDescriptors[field]}`;
    }

    return listString;
  }

  return generateListsForFilters(
    filters,
    matchingFilter,
    addDescriptorFunction,
  );
}

function addIfNotEmpty(filterList, toAdd) {
  if (filterList.length > 0) {
    filterList.push({ filterValue: toAdd });
  }
  return filterList;
}

function getLocations(filters) {
  const locations = generateListsForFilters(
    filters,
    field => config.locationTypeFields.indexOf(field) >= 0,
  );
  return addIfNotEmpty(locations, ' in');
}

function getCarOrigins(filters) {
  const carOrigins = generateListsForFilters(
    filters,
    field => config.carOriginTypeFields.indexOf(field) >= 0,
  );
  return addIfNotEmpty(carOrigins, ' from');
}

function getSearches(filters) {
  const searches = generateListsForFilters(
    filters,
    field => config.searchTypeFields.indexOf(field) >= 0,
  );
  return addIfNotEmpty(searches, ' matching');
}

function findRangePostposition(elementNumber) {
  if (elementNumber !== 0) {
    return elementNumber === 1 ? ' and' : ',';
  }

  return '';
}

function getSIString(num, filterField) {
  if (filterField === 'registrationYear') {
    return num;
  }

  let localNum = num;
  for (let k = 0; k < siMultipliers.length; ++k) {
    if (localNum < 1000) {
      return num.toString() + siMultipliers[k];
    }

    localNum /= 1000;
  }

  return undefined;
}

function findlinkerWord(filterField, name, regYearOverride) {
  if (filterField === 'registrationYear') {
    return regYearOverride;
  }
  if (filterField === 'price') {
    return 'for ';
  }
  if (filterField === 'distance') {
    return '';
  }
  return name.length > 0 ? 'of ' : 'with '; // of if the name is used, with if it isn't
}

function getSingleValueRange(name, filterField, filterValue, trailingUnit) {
  return ` ${name}${findlinkerWord(
    filterField,
    name,
    'in ',
  )}${filterValue}${trailingUnit}`;
}

function getBoundedRange(name, filterField, filterValue, trailingUnit) {
  return ` ${name}between ${filterValue.replace('to', 'and')}${trailingUnit}`;
}

function getDoubleBoundedRange(name, filterField, filterValue) {
  return ` ${findlinkerWord(filterField, name, 'with ')}${filterValue}`;
}

function getSingleBoundedRange(name, filterField, filterValue, trailingUnit) {
  return ` ${name}${findlinkerWord(
    filterField,
    name,
    '',
  )}${filterValue}${trailingUnit}`;
}

function getFilterString(filter, name, filterValue, trailingUnit) {
  if (
    getSIString(filter.from, filter.field) ===
    getSIString(filter.to, filter.field)
  ) {
    return getSingleValueRange(name, filter.field, filterValue, trailingUnit);
  }
  if (filter.from > filter.min && filter.to < filter.max) {
    return getBoundedRange(name, filter.field, filterValue, trailingUnit);
  }
  if (filter.from <= filter.min && filter.to >= filter.max) {
    // this shall be removed as we must remove any filters
    return getDoubleBoundedRange(name, filter.field, filterValue, trailingUnit);
  }
  return getSingleBoundedRange(name, filter.field, filterValue, trailingUnit);
}

function getRangeFieldsString(filters) {
  let withFilters = [];
  const rangeFilters = [];

  let rangeFilterNumber = 0;
  let withFilterNumber = 0;

  for (let i = filters.length - 1; i >= 0; i--) {
    const filter = filters[i];
    if (filter.isRange) {
      const filterValue = filter.list[0].key;
      const name = (() => {
        if (config.modifiedFieldNames[filter.field]) {
          return config.modifiedFieldNames[filter.field];
        }

        if (config.withFields.indexOf(filter.field) >= 0) {
          return `${filter.name} `;
        }

        return '';
      })();

      const trailingUnit =
        config.modifiedTrailingUnits[filter.field] !== undefined
          ? config.modifiedTrailingUnits[filter.field]
          : filter.trailingUnit;

      const stringForThisFilter = getFilterString(
        filter,
        name,
        filterValue,
        trailingUnit,
      );

      if (config.withFields.indexOf(filter.field) >= 0) {
        // add filterField when range works field: filter.field,
        withFilters.push({
          filterValue: stringForThisFilter,
          field: filter.field,
          displayName: filter.name,
          postposition: findRangePostposition(withFilterNumber),
          isRange: true,
        });
        withFilterNumber++;
      } else {
        // add filterField when range works field: filter.field,
        rangeFilters.push({
          filterValue: stringForThisFilter,
          field: filter.field,
          displayName: filter.name,
          postposition: findRangePostposition(rangeFilterNumber),
          isRange: true,
        });
        rangeFilterNumber++;
      }
    }
  }
  withFilters = addIfNotEmpty(withFilters, ' with');
  for (let i = 0; i < rangeFilters.length; i++) {
    withFilters.push(rangeFilters[i]);
  }
  return withFilters;
}
function getStringFromFilterList(filterList) {
  let filterString = '';
  for (let i = filterList.length; i-- > 0; ) {
    filterString =
      filterString +
      filterList[i].filterValue +
      (filterList[i].postposition !== undefined
        ? filterList[i].postposition
        : '');
  }
  return filterString;
}

function addFilters(filterTypes, filterList) {
  for (let i = filterList.length; i-- > 0; ) {
    filterTypes.push(filterList[i]);
  }
}

export function getFiltersDescription(filters = [], totalHits, cars) {
  // gets a string describing the sort of cars that will be included by the current filters

  // if we have the new filters data, just return 'used cars'
  if (filters.length && has(filters[0], 'displayName')) {
    return 'used cars';
  }

  const carIdCount = filters.filter(f => f.field === 'carId').length;

  if (carIdCount === 1 && cars.length === 1) {
    return cars[0].title;
  }

  const nouns = getNouns(filters, totalHits > 1);
  const adjectives = getAdjectives(filters);
  const locations = getLocations(filters);
  const rangeFilters = getRangeFieldsString(filters);
  const carOrigins = getCarOrigins(filters);
  const searches = getSearches(filters);

  return `used${getStringFromFilterList(adjectives)}${getStringFromFilterList(
    nouns,
  )}${getStringFromFilterList(locations)}${getStringFromFilterList(
    carOrigins,
  )}${getStringFromFilterList(rangeFilters)}${getStringFromFilterList(
    searches,
  )}`;
}

/**
 * Gets the title string for the page using data from props
 * @param  {Number} options.totalHits The total number of cars available for that search
 * @param  {Array}  options.activeFilters   The array that contains the activeFilters
 * @param  {Array}  options.cars      The array of cars for that page
 * @return {String}                   The title
 */
export function getTitle({ totalHits, activeFilters, cars }) {
  // gets a title for a page including the number of hits and 'on carsnip.com'
  return `${formatNumber(totalHits)} ${getFiltersDescription(
    activeFilters,
    totalHits,
    cars,
  )} on Carsnip.com`;
}

export function getFiltersDescriptionWithTypes(filters = [], totalHits) {
  // gets a string describing the sort of cars that will be included by the current filters
  const filteredFilters = filters.filter(f => f.field !== 'carId');

  const filterTypes = [];

  addFilters(filterTypes, getAdjectives(filteredFilters));
  addFilters(filterTypes, getNouns(filteredFilters, totalHits > 1));
  addFilters(filterTypes, getLocations(filteredFilters));
  addFilters(filterTypes, getCarOrigins(filteredFilters));
  addFilters(filterTypes, getRangeFieldsString(filteredFilters));
  addFilters(filterTypes, getSearches(filteredFilters));
  return filterTypes;
}
