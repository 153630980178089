/**
 * Pure function for populating the filter prop
 */
function features(state = {}, action) {
  if (
    action.type &&
    action.type === 'UPDATE_FEATURES' &&
    typeof action.data === 'object'
  ) {
    return { ...action.data };
  }

  return state;
}

export default features;
