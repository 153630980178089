import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const LadderSection = styled.div`
  margin-bottom: ${rem('40px')};

  ${media.bpsLarge`
    display: flex;
    margin-bottom: ${rem('120px')};

    &:last-child {
      margin-bottom: ${rem('90px')};
    }
  `};
`;

const ImageContainer = styled.div`
  margin-bottom: ${rem('20px')};

  ${media.bpsLarge`
    margin-bottom: 0;
    flex: 1 0 ${rem('400px')};
    display: flex;
    align-items: center;
    justify-content: center;
    order: ${props => (props.right ? '1' : '0')};
  `};
`;

const TextContainer = styled.div`
  ${media.bpsLarge`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
`;

const LadderHeading = styled.h3`
  font-size: ${rem('20px')};
  font-weight: 400;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.mediumGrey};
  margin: 0 0 ${rem('20px')} ${media.bpsLarge`
    font-size: ${rem('26px')};
    margin: 0 0 ${rem('30px')};
  `};
`;

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};
  margin: 0 0 ${rem('20px')};

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('28px')};
  `} ${media.bpsLarge`
    margin: 0 0 ${rem('30px')};

    &:last-child {
      margin: 0;
    }
  `};
`;

export { LadderSection, ImageContainer, TextContainer, LadderHeading, Text };
