/**
 * Pure function for updating the showMobileHeader prop
 */
function showMobileHeader(state = false, action) {
  if (action.type === 'UPDATE_SHOW_MOBILE_HEADER') {
    return !!action.data;
  }

  return state;
}

export default showMobileHeader;
