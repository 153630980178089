import styled from 'styled-components';

const SocialLink = styled.a`
  display: block;

  &:hover path {
    fill: ${props => props.theme.orange};
  }
`;

export default SocialLink;
