import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

// Components
import SearchResults from './SearchResults';
import Sidebar from './Sidebar';

const Wrapper = styled.div`
  max-width: ${rem('1117px')};
  margin: 0 auto;

  @media (min-width: ${rem('1172px')}) {
    display: flex;
  }
`;

const ResultsWrapper = styled.div`
  width: 100%;
  padding: 0 ${rem('18px')};

  @media (min-width: ${rem('640px')}) {
    padding: 0;
  }

  @media (min-width: ${rem('1172px')}) {
    width: auto;
    flex: 1 1 auto;
    overflow: hidden;
  }
`;

const SidebarWrapper = styled.div`
  width: 100%;

  @media (min-width: ${rem('1172px')}) {
    width: auto;
    flex: 1 0 auto;
    max-width: ${rem('350px')};
  }
`;

const SearchWrapper = ({
  sidebar,
  searchResults,
  flags,
  features,
  dispatch,
}) => (
  <Wrapper>
    <ResultsWrapper>
      {searchResults && (
        <SearchResults {...searchResults} {...{ flags, features, dispatch }} />
      )}
    </ResultsWrapper>
    <SidebarWrapper>{sidebar && <Sidebar {...sidebar} />}</SidebarWrapper>
  </Wrapper>
);

SearchWrapper.propTypes = {
  sidebar: PropTypes.object.isRequired,
  searchResults: PropTypes.object.isRequired,
};

export default SearchWrapper;
