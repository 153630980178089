import request from 'request';

/**
 * Action to get a set of results for a search
 * @param {Object}  search          An object that contains the query and firstAttempt boolean
 * @param {Boolean} fromSearchBox   A boolean to determine if the search was made from a search box or not
 */
function search(searchObject = {}, fromSearchBox) {
  // Get the default values
  const { firstAttempt = false, query = null } = searchObject;

  // Return the action results
  return dispatch => {
    // action to facilitate showing the spinner until SEARCH_RESPONSE
    dispatch({ type: 'SEARCH_STARTED', data: '', fromSearchBox });

    return new Promise((resolve, reject) => {
      request.get(`/api/search${query ? `?${query}` : ''}`, (err, data) => {
        // If there is an error
        if (err) {
          reject(data);
          return dispatch({ type: 'SEARCH_ERR', data });
        }

        // If there is a redirect URL, re run the search with the redirect url
        if (data && data.redirectUrl && firstAttempt) {
          resolve(data);
          return dispatch({ type: 'REDIRECT_URL' });
        }

        resolve(data);
        return dispatch({ type: 'SEARCH_RESPONSE', data });
      });
    });
  };
}

/**
 * Used when a user clicks the checkbox inputs on a dropdown filter (does not update the search)
 * @param  {filterOptions} new filterOptions with selected options active
 */
function updateSelectedOptions(filterOptions = {}) {
  return {
    type: 'FILTER_RESPONSE',
    data: filterOptions.filterOptions,
    name: filterOptions.name,
  };
}

/**
 * Updates the filter that has been selected on the page
 * @param selectedFilter  The field of the selected filter
 */
function updateFilters(data = {}) {
  // If the filter is a string then apply just a string
  if (typeof data === 'string') {
    return {
      type: 'UPDATE_FILTER_RESPONSE',
      data,
    };
  }

  // Get the filter and levels from the data object
  const { filter = '', level = '', backLevel = '' } = data;
  return {
    type: 'UPDATE_FILTER_RESPONSE',
    data: {
      filter,
      level,
      backLevel,
    },
  };
}

/**
 * Updates the prop that hides or shows the filters menu on mobile
 * @param {Boolean} newValue  The value to update the prop with
 */
function toggleFilterMenu(newValue) {
  return {
    type: 'UPDATE_SHOW_FILTER_MENU',
    data: newValue,
  };
}

function resetSearchString() {
  return {
    type: 'RESET_SEARCH_STRING',
  };
}

export {
  search,
  updateSelectedOptions,
  updateFilters,
  toggleFilterMenu,
  resetSearchString,
};
