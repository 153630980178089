import styled from 'styled-components';

import container from 'styles/mixins/container';
import media from 'styles/mixins/media';

const Container = styled.div`
  ${container}

  ${media.bpsLarge`
    margin: 0;
  `}

  ${media.bpDesktop`
    margin: 0 auto;
    width: 100%;
  `}
`;

export default Container;
