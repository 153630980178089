import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const AlText = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  max-width: ${rem('500px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};
  margin: 0 auto ${rem('26px')};

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
    margin-bottom: ${rem('35px')};
  `} ${media.bpsLarge`
    text-align: left;
    max-width: none;
  `};
`;

export default AlText;
