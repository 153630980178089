import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import clearFix from 'polished/lib/mixins/clearFix';

import media from 'styles/mixins/media';

const Form = styled.form`
  ${media.bpTablet`
    ${clearFix()}
    margin-top: ${rem('59px')};
    margin-bottom: ${rem('165px')};
    position: relative;
    z-index: 2;
  `};
`;

export default Form;
