import request from 'request';

/**
 * Function to send off a request to create user interaction(s)
 */
function trackInteractions(data) {
  return dispatch =>
    new Promise((resolve, reject) => {
      request.post('/api/interactions', data, (err, innerData) => {
        // If there is an error
        if (err) {
          reject(err);
          return dispatch({ type: 'ERR', innerData, hasCatch: true });
        }

        return resolve(innerData);
      });
    });
}

export { trackInteractions };
