import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { StyledSpinner, Rect } from '../page-elements/Spinner';

import media from 'styles/mixins/media';

const SubmitButton = styled.button(
  ({ theme, loading, stretch, small }) => css`
    background: ${theme.orange};
    color: ${theme.white};
    border-radius: ${rem('4px')};
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    max-width: ${!stretch && '10rem'};
    height: 2.5rem;
    border: none;
    opacity: ${loading && '0.6'};

    &:active,
    &:hover {
      background: ${theme.orangeHover};
    }

    ${media.bpTablet`
      height: ${small ? '2.75rem' : '4rem'};
      font-size: ${small && rem('15px')};
    `}

    ${StyledSpinner} {
      height: ${rem('30px')};
      margin: 0 auto;
    }

    ${Rect} {
      background: ${theme.white};
    }
  `,
);

export default SubmitButton;
