import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { StyledSpinner } from '../../page-elements/Spinner';

import media from 'styles/mixins/media';

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;

  ${StyledSpinner} {
    margin-top: ${rem('150px')};
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    ${media.bpTablet`
      left: calc(50% + ${rem(
        '130px',
      )}); // mid-page minus half filters bar width
    `}
  }
`;

export default Overlay;
