import styled, { css } from 'styled-components';
import { cover } from 'polished';

import { mediaAbove } from '../../../styles/mixins/media';

export const Wrapper = styled.div(
  p => css`
    ${p.fillParent && cover()}
    position: ${!p.fillParent && 'relative'};
    padding-bottom: ${!p.fillParent && `${p.ratio * 100}%`};
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
  `,
);

export const Item = styled.div(
  p => css`
    position: absolute;
    top: 0;
    right: -1rem;
    bottom: 0;
    left: -1rem;
    z-index: ${p.isActive ? 5 : 0};
    transform: ${p.isNext && 'translate3d(1rem, 0, 0)'};
    transform: ${p.isPrevious && 'translate3d(-1rem, 0, 0)'};
    transform: ${p.isActive && 'translate3d(0, 0, 0)'};
    opacity: ${p.isActive ? 1 : 0};
    transition: transform 750ms ease, opacity 500ms ease;
  `,
);

export const Arrow = styled.button(
  p => css`
    position: absolute;
    top: 50%;
    right: ${p.isNext && 0};
    left: ${p.isPrevious && 0};
    z-index: 5;
    display: block;
    width: 2.5rem;
    height: 7rem;
    color: ${p.theme.white};
    background-color: rgba(1, 1, 1, 0.5);
    border: none;
    border-radius: ${p.isNext ? '0.25rem 0 0 0.25rem' : '0 0.25rem 0.25rem 0'};
    transform: translateY(-50%);
    cursor: pointer;
    transition: background-color 500ms ease;

    &:hover {
      background-color: rgba(1, 1, 1, 1);
    }
  `,
);

export const ImageNumberIndicator = styled.aside(
  p => css`
    position: absolute;
    padding: 0.25rem 0.5rem;
    bottom: 0;
    left: 1rem;
    z-index: 10;
    color: ${p.theme.white};
    background-color: rgba(1, 1, 1, 0.5);
    border-radius: 0.5rem 0.5rem 0 0;
    font-size: 0.75rem;
    line-height: 1;

    ${mediaAbove.bpTablet(css`
      display: none;
    `)}
  `,
);
