import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Fields = styled.ul`
  list-style: none;
  padding: 0;
  min-width: ${rem('280px')};
  margin-bottom: ${rem('10px')};
`;

export default Fields;
