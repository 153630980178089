import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const HeaderWrapper = styled.header`
  flex-shrink: 0;
  height: ${rem('50px')};
  padding: ${rem('11px')};
  background: ${props => props.theme.lighterGrey};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 15;
  max-width: 100vw;
  display: flex;
  align-items: center;

  ${media.bpTablet`
    height: ${rem('68px')};
    padding: ${rem('16px')} ${rem('26px')};
  `} ${props =>
    props.isHome &&
    css`
      background: ${props.theme.white};
      justify-content: flex-end;
    `};

  ${p =>
    p.pathname.startsWith('/search') &&
    css`
      height: ${rem('76px')};
      flex-wrap: wrap;
      box-shadow: ${p.messageDismissed
        ? '0px 4px 8px 0px rgba(0, 0, 0, 0.1)'
        : 'none'};

      @media (min-width: ${rem('430px')}) {
        flex-wrap: nowrap;
        height: ${rem('50px')};
      }

      ${media.bpTablet`
          height: ${rem('70px')};
          background: ${p.theme.white};
          padding: 0;
        `}
    `}};
`;

export default HeaderWrapper;
