import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const InputGroup = styled.div`
  margin-bottom: ${rem('8px')};
  width: 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }

  ${media.bpTablet`
    width: ${rem('300px')};
    float: left;

    ${props =>
      props.email
        ? css`
            max-width: ${rem('248px')};
          `
        : ''}
  `}
`;

export default InputGroup;
