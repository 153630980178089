import React from 'react';

/* global googletag */

class GoogleAd extends React.Component {
  componentDidMount() {
    const { id } = this.props;

    if (typeof window.googletag === 'object') {
      googletag.cmd.push(() => {
        googletag.display(id);
      });
    }
  }

  render() {
    const { id, width, height } = this.props;

    return (
      <div
        id={id}
        style={{ height: `${height}px`, width: `${width}px`, margin: 'auto' }}
      />
    );
  }
}

export default GoogleAd;
