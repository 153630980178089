import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const PaginationWrapper = styled.div`
  padding: 0 ${rem('18px')};
  width: 100%;
  margin-top: ${rem('15px')};
  margin-bottom: ${rem('10px')};
`;

export default PaginationWrapper;
