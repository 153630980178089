/**
 * Pure function for populating the messageDismissed prop
 */
function messageDismissed(state = false, action) {
  if (action.type === 'MESSAGE_DISMISSED') {
    return true;
  }

  return state;
}

export default messageDismissed;
