import request from 'request';

/**
 * Create a VRM lookup
 */
function vrmLookup(vrm, purpose, recaptchaResponse) {
  return dispatch => {
    const actionPrefix = purpose === 'valuation' ? 'VALUE_' : 'SELL_';
    dispatch({ type: `${actionPrefix}VRM_LOOKUP_STARTED` });

    return new Promise((resolve, reject) => {
      request.post(
        `/api/cars?vrm=${vrm}&purpose=${purpose}`,
        { recaptchaResponse },
        (err, data) => {
          if (err) {
            reject(err);
            return dispatch({
              type: `${actionPrefix}VRM_LOOKUP_ERR`,
              data,
              hasCatch: true,
            });
          }

          resolve(data);
          return dispatch({ type: `${actionPrefix}VRM_LOOKUP`, data });
        },
      );
    });
  };
}

/**
 * Get vrm details using a lookupId
 */
function getVrmLookup(lookupId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      request.get(`/api/cars/${lookupId}`, (err, data) => {
        if (err) {
          reject(err);
          return dispatch({ type: 'ERR', data });
        }

        resolve(data);
        return dispatch({ type: 'VRM_LOOKUP', data });
      });
    });
}

/**
 * Send off the valuation email
 */
function sendEmail(lookupId, email, body) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'VRM_EMAIL_SUBMITTED', data: true });

      request.post(
        `/api/valuations/${lookupId}/${email}`,
        body,
        (err, data) => {
          if (err) {
            reject(err);
            return dispatch({ type: 'VRM_EMAIL_ERR', data });
          }

          resolve(data);
          return dispatch({ type: 'VRM_EMAIL_SENT', data });
        },
      );
    });
}

/**
 * Get the valuation results
 */
function getValuation(lookupId, mileage, validation) {
  return dispatch =>
    new Promise((resolve, reject) => {
      const url = mileage
        ? `/api/valuations/${lookupId}/${mileage}/${validation}`
        : `/api/valuations/${lookupId}/${validation}`;

      request.get(url, (err, data) => {
        if (err) {
          reject(err);
          return dispatch({ type: 'ERR', data });
        }

        resolve(data);
        return dispatch({ type: 'CAR_VALUATION', data });
      });
    });
}

/**
 * Post user details and car mileage. User will get a price offer emailed to them.
 */
function getMyPrice(lookupId, formData) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'GET_MY_PRICE_STARTED' });

      request.post(`/api/valuations?car=${lookupId}`, formData, (err, data) => {
        if (err) {
          reject(err);
          return dispatch({ type: 'GET_MY_PRICE_ERR', data });
        }

        resolve(data);
        return dispatch({ type: 'GET_MY_PRICE', data });
      });
    });
}

/**
 * Get an offer by Id to present to the user
 */
function getOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      request.get(`/api/valuations/${id}`, (err, data) => {
        if (err) {
          reject(err);
          return dispatch({ type: 'ERR', data });
        }

        resolve(data);
        return dispatch({ type: 'GET_OFFER', data });
      });
    });
}

/**
 * Updates car to accept the offer from Car Buyers Group
 */
function acceptOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'ACCEPT_OFFER_STARTED' });

      request.put(`/api/valuations/${id}`, (err, data) => {
        if (err) {
          reject(err);
          return dispatch({ type: 'ACCEPT_OFFER_ERR', data });
        }

        resolve(data);
        return dispatch({ type: 'ACCEPT_OFFER', data });
      });
    });
}

export {
  vrmLookup,
  getVrmLookup,
  sendEmail,
  getValuation,
  getMyPrice,
  getOffer,
  acceptOffer,
};
