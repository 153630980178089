import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import container from 'styles/mixins/container';
import media from 'styles/mixins/media';

const Container = styled.div`
  ${container}
  ${media.bpTablet`
    padding-left: ${rem('18px')};
    padding-right: ${rem('18px')};
  `}
`;

export default Container;
