import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Submit = styled.input`
  font-size: ${rem('17px')};
  font-weight: 500;
  padding: 0 ${rem('16px')};
  height: ${rem('49px')};
  width: 100%;
  border: none;
  border-radius: ${rem('4px')};
  background: linear-gradient(#1097ec, #0d80c9);
  color: white;
  cursor: pointer;

  ${media.bpTablet`
    font-size: ${rem('16px')};
    height: ${rem('44px')};
  `}
`;

export default Submit;
