import React from 'react';

import Leads from '../Leads';

import {
  Section,
  Container,
  Heading,
  PartnerLogos,
  Hr,
} from './WhyPremiumStyles';
import {
  LadderSection,
  ImageContainer,
  TextContainer,
  LadderHeading,
  Text,
} from './Ladder';
import GraphIcon from './GraphIcon';
import MoneyIcon from './MoneyIcon';
import RevCounterIcon from './RevCounterIcon';

const WhyPremium = () => (
  <Section>
    <Container>
      <Heading>Why Choose Carsnip?</Heading>
      <LadderSection>
        <ImageContainer>
          <PartnerLogos
            alt="Facebook Marketplace"
            src="/images/facebook-marketplace.png"
          />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Get Facebook Marketplace for FREE</LadderHeading>
          <Text>
            Carsnip has partnered with Facebook Marketplace, to give all our
            premium dealers additional advertising and leads. Facebook
            Marketplace is free to all Carsnip Premium customers. We simply
            forward your Carsnip feed to the Marketplace, and when potential
            buyers send an enquiry you can access it in your very own{' '}
            <a
              href="https://dealer.carsnip.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Dealer Portal
            </a>
            .
          </Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer right>
          <GraphIcon />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>100% Perfomance Based</LadderHeading>
          <Text>
            Carsnip’s pay per click model ensures you only pay if a premium
            advert delivers a visitor to your website. Listing is always free.
          </Text>
          <Text>
            Every month we send you a report detailing your total clicks, the
            organic vs. paid, and clicks we have removed (e.g duplicate clicks).
          </Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer>
          <MoneyIcon />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Control Your Campaign & Budget</LadderHeading>
          <Text>
            Carsnip does not dictate how much you pay, you tell us the maximum
            you’re prepared to pay per click and we’ll promote your stock
            according to your bid price.
          </Text>
          <Text>
            To help manage your budget you can also set a monthly limit and
            we’ll never charge you more.
          </Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer right>
          <RevCounterIcon />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Relevant Leads, Maximum Opportunity</LadderHeading>
          <Text>
            Carsnip’s Relevance Score ranks every advert and uses this to
            present only the most relevant adverts to the car buyer.
          </Text>
          <Text>
            This ensures buyers only see your stock if it’s relevant to their
            search criteria, reducing your costs and resulting in higher
            conversion rates when a buyer clicks on your advert.
          </Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer>
          <PartnerLogos
            alt="Partner Logos"
            src="/images/new-partner-logos.png"
          />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Reach Millions of Potential Buyers</LadderHeading>
          <Text>
            Carsnip powers used-car search behind a growing number of the UK’s
            most popular Automotive websites. By advertising with us your reach
            will be over 10 million impressions a month!
          </Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer right>
          <Leads large />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Promote Your Brand & Customer Loyalty</LadderHeading>
          <Text>
            Carsnip is a search engine. Unlike traditional car sites, this means
            when a buyer clicks your advert they go direct to your website.
          </Text>
          <Text>
            This promotes your brand and gives you the opportunity to build a
            relationship with the buyer that converts into sales not only today,
            but in the future too.
          </Text>
        </TextContainer>
      </LadderSection>
      <Hr />
    </Container>
  </Section>
);

export default WhyPremium;
