import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const FormWrapper = styled.div`
  text-align: center;
  margin-bottom: 1.875rem;

  ${media.bpTablet`
    margin-top: ${rem('50px')};
    margin-left: ${rem('85px')};
    width: ${rem('420px')};
    float: left;
  `};
`;

export default FormWrapper;
