import React from 'react';

import Container from './Container';
import Wrapper from './Wrapper';
import TotalIcon from './TotalIcon';
import Heading from './Heading';
import Mission from './Mission';

class Header extends React.PureComponent {
  render() {
    return (
      <Container>
        <Wrapper>
          <TotalIcon />
          <Heading>All the cars in one place...</Heading>
          <Mission>
            All the cars means more choice for you the consumer! Carsnip aims to
            make your search experience the quickest and simplest whilst
            offering you the widest variety of choice!
          </Mission>
        </Wrapper>
      </Container>
    );
  }
}

export default Header;
