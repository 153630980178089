import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Date = styled.h2`
  font-size: ${rem('13px')};
  font-weight: 300;
  text-align: center;

  ${media.bpTablet`
    font-size: ${rem('16px')};
  `}
`;

export default Date;
