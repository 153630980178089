import React from 'react';

import Page from './Page';
import Hero from './Hero';
import Container from './Container';
import YouGovLogo from './YouGovLogo';
import Heading from './Heading';
import Text from './Text';
import SignupForm from './SignupForm';
import QuoteSection from './QuoteSection';
import QuoteContainer from './QuoteContainer';
import AlImage from './AlImage';
import AlText from './AlText';
import Name from './Name';

const YouGov = () => (
  <Page>
    <Hero>
      <Container>
        <YouGovLogo alt="YouGov logo" src="/images/yougov_logo.png" />
        <Heading>Find out how car buying habits are changing</Heading>
        <Text>
          We carried out a UK-wide survey of over 2000 car buyers to investigate
          how the nation&apos;s car buying habits are changing!
        </Text>
      </Container>
      <SignupForm />
      <Container>
        <Text>
          Dealers, find out what your customers really think and want from car
          dealers, and understand how their purchasing habits are changing. Most
          importantly, discover the things car dealers need to know to stay
          ahead of the game.
        </Text>
        <Text>
          This wide-ranging, independent report features comment from some of
          the leading figures in them motor industry, and includes a full
          breakdown of the date and what it really means for the used car
          market.
        </Text>
      </Container>
    </Hero>
    <QuoteSection>
      <QuoteContainer>
        <AlImage alt="Alastair Image" src="/images/alastair_circle.png" />
        <div>
          <AlText>
            &quot;The 2017 Buyersapos; Census clearly shows what we have
            believed for some time - that buyers are changing the ways they
            research, find and buy cars. This online YouGov survey asked car
            buyers around the country a number of questions about their car
            buying habits; how they did it, where they did it, what they thought
            about it and what was important to them. The results show how the
            UKapos;s car buying habits have changed, and are continuing to
            change in the light of new technologies and new generational
            changes.&quot;
          </AlText>
          <Name>Alastair Campbell, CEO, Carsnip</Name>
        </div>
      </QuoteContainer>
    </QuoteSection>
  </Page>
);

export default YouGov;
