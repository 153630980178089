import styled from 'styled-components';

const ratio = viewBox => {
  const [, , width, height] = viewBox.split(' ');
  return parseFloat(width, 10) / parseFloat(height, 10);
};

const SVG = styled.svg`
  width: ${p => ratio(p.viewBox)}em;
  height: 1em;
  overflow: hidden;
`;

export default SVG;
