import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('42px')} 0;
  text-align: center;
  border-bottom: ${rem('1px')} solid #ebebeb;

  ${media.bpTablet`
    padding: ${rem('90px')} 0;
  `}
`;

export default Wrapper;
