import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const MobileText = styled.span`
  font-size: ${rem('12px')};
  font-weight: 300;
  display: inline;

  ${media.bpTablet`
    display: none;
  `}
`;

const TabletText = styled.span`
  font-size: ${rem('12px')};
  font-weight: 300;
  display: none;

  ${media.bpTablet`
    display: inline;
  `}
`;

export { MobileText, TabletText };
