import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const LogoWrapper = styled.div(
  p =>
    p.pathname.startsWith('/search') &&
    css`
      display: block;
      flex: 1 0 100%;
      text-align: center;
      margin-bottom: ${rem('8px')};

      @media(min-width: ${rem('430px')}) {
      flex: 0 1 auto;
        text-align: left;
        margin-bottom: 0;
        padding-right: ${rem('19px')};
      }

      ${media.bpTablet`
        height: 100%;
        padding-right: 0;
        margin-right: 0;
        margin-left: ${rem('68px')};
        display: flex;
        justify-content: center;
        align-items: center;
      `}

      @media(min-width: ${rem('1360px')}) {
        margin-left: ${rem('58px')};
      }

      @media(min-width: ${rem('1930px')}) {
        margin-left: ${rem('78px')};
      }
    `,
);

export default LogoWrapper;
