import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';
import container from 'styles/mixins/container';

const Wrapper = styled.div`
  max-width: ${rem('445px')};
  text-align: center;
  ${container}

  ${media.bpTablet`
    padding: 0 ${rem('85px')};
    overflow-x: hidden;
    position: relative;
    text-align: left;
    max-width: none;
    width: 100%;
    margin: 0;

    ${props =>
      props.landing
        ? css`
            min-height: ${rem('560px')};
          `
        : ''}

    ${props =>
      props.details || props.offer
        ? css`
            max-width: ${rem('1024px')};
            margin: 0 auto ${rem('40px')};
          `
        : ''}

    ${props =>
      props.emailSent
        ? css`
            text-align: center;
          `
        : ''}
  `}

  ${media.bpDesktop`
    padding: 0;
    overflow-x: visible;
    margin: 0 auto;

    ${props =>
      props.landing
        ? css`
            max-width: ${rem('1068px')};
          `
        : ''}

    ${props =>
      props.details
        ? css`
            max-width: ${rem('850px')};
            margin: 0 auto ${rem('40px')};
          `
        : ''}
  `}
`;

export default Wrapper;
