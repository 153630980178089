import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import clearFix from 'polished/lib/mixins/clearFix';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  ${clearFix()}
  text-align: center;
  margin: ${rem('36px')} auto ${rem('46px')};

  ${media.bpMedium`
    width: ${rem('786px')};
    text-align: left;
    margin: ${rem('80px')} auto ${rem('90px')};
  `}
`;

export default Wrapper;
