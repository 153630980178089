import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('13px')};
  margin-top: ${rem('16px')};
  font-weight: 300;

  ${media.bpTablet`
    font-size: ${rem('16px')};
  `}
`;

export default Text;
