import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import commit from '../../../../../git.ver';

import CloseIcon from '../../../svgs/CloseIcon';

import { CloseModalButton, InputWrapper, Info, PrivacyNotice } from './style';

import SubmitButton from 'shared/SubmitButton';
import Modal from 'shared/Modal';
import Input from 'shared/Input';
import Label from 'shared/Label';
import ValidationError from 'shared/ValidationError';
import CheckboxInput from 'shared/CheckboxInput';
import Recaptcha from 'shared/Recaptcha';
import { CalldripActions } from 'actions';

/* global grecaptcha */

const getPhoneNumberError = (phoneNumber, parsedPhoneNumber) => {
  if (!phoneNumber) {
    return 'Please enter your phone number';
  }

  if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
    return 'Invalid phone number';
  }

  if (parsedPhoneNumber.country !== 'GB') {
    return 'Provided phone number is not a valid UK phone number';
  }

  return undefined;
};

const GetCallbackModal = ({
  isOpen,
  onCancel,
  showroomId,
  vehicleData,
  dealerName,
}) => {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState(undefined);
  const [name, setName] = useState('');
  const [submitWasClicked, setSubmitWasClicked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [privacyIsChecked, setPrivacyIsChecked] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const captchaRef = useRef(null);
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'GB');
  const submitHandler = () => {
    setSubmitWasClicked(true);
    const nameError = name.trim() ? undefined : 'Please enter your name';
    const phoneError = getPhoneNumberError(phoneNumber, parsedPhoneNumber);
    const privacyError = privacyIsChecked
      ? undefined
      : 'Please acknowledge privacy notice';

    setValidationErrors({
      name: nameError,
      phone: phoneError,
      privacy: privacyError,
    });

    if (![nameError, phoneError, privacyError].some(Boolean)) {
      dispatch(
        CalldripActions.requestCallback({
          recaptchaResponse,
          name,
          telephone: phoneNumber,
          commit,
          showroomId,
          dealerName,
          vehicleData,
        }),
      );
      setName('');
      setPhoneNumber('');
      setSubmitWasClicked(false);
      setPrivacyIsChecked(false);
      setRecaptchaResponse(undefined);
      onCancel();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={() => {
        setValidationErrors(undefined);
        setSubmitWasClicked(false);
        onCancel();
      }}
      header="Get a callback from an advisor today"
      contentLabel="Form to request a callback"
    >
      <CloseModalButton type="button" onClick={onCancel}>
        <CloseIcon />
      </CloseModalButton>
      <Info>
        Dealers typically respond within 60 seconds during trading hours.
      </Info>
      <form>
        <InputWrapper>
          <Label htmlFor="callbackModalName">Name</Label>
          <Input
            autoComplete="name"
            small
            id="callbackModalName"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            isValid={submitWasClicked && !validationErrors?.name}
          />
          {validationErrors?.name && (
            <ValidationError>{validationErrors.name}</ValidationError>
          )}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="callbackModalPhoneNumber">Phone Number</Label>
          <Input
            autoComplete="tel"
            inputMode="tel"
            onChange={e => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            onBlur={() => {
              if (parsedPhoneNumber) {
                setPhoneNumber(parsedPhoneNumber.formatNational());
              }
            }}
            small
            id="callbackModalPhoneNumber"
            isValid={submitWasClicked && !validationErrors?.phone}
            type="text"
          />
          {validationErrors?.phone && (
            <ValidationError>{validationErrors.phone}</ValidationError>
          )}
        </InputWrapper>
        <PrivacyNotice>
          Carsnip will transfer your personal contact details to Commerce
          Unlimited Ltd, and 3rd party companies in order to facilitate this
          request. By ticking the box below you confirm that you read our
          Privacy Notice and consent for Carsnip transferring your personal
          data:
        </PrivacyNotice>
        <InputWrapper>
          <CheckboxInput
            error={validationErrors?.privacy}
            label="Yes, I would like a callback from the dealer about this car. Please transfer my contact details to Commerce Unlimited Ltd."
            name="callbackModalPrivacyCheckbox"
            onChange={e => {
              setPrivacyIsChecked(e.target.checked);
              if (!submitWasClicked) {
                grecaptcha.execute(captchaRef.current);
              }
            }}
            small
            value={privacyIsChecked}
          />
        </InputWrapper>
        <Recaptcha
          invisible
          onChange={setRecaptchaResponse}
          onRecaptchaLoad={id => {
            captchaRef.current = id;
          }}
        />
        <SubmitButton small onClick={submitHandler} type="button">
          Submit now
        </SubmitButton>
      </form>
    </Modal>
  );
};

export default GetCallbackModal;
