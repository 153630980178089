import React from 'react';

import Wrapper from './Wrapper';
import Header from './Header';
import EnquiryIcon from './EnquiryIcon';

import ButtonWrapper from './ButtonWrapper';
import Button from './Button';

import formatPhone from 'utils/formatPhone';

const DirectEnquiry = ({ hashedUri, dealerTelephone }) => (
  <Wrapper>
    <Header>
      <EnquiryIcon />
      Contact the seller
    </Header>
    <ButtonWrapper>
      <Button href={hashedUri}>Visit Dealer Website</Button>
    </ButtonWrapper>
    <ButtonWrapper>
      <Button href={hashedUri}>Contact Dealer</Button>
    </ButtonWrapper>
    {!!dealerTelephone && (
      <ButtonWrapper href={`tel:${dealerTelephone.replace(/\s/g, '')}`}>
        <Button>{formatPhone(dealerTelephone)}</Button>
      </ButtonWrapper>
    )}
  </Wrapper>
);

export default DirectEnquiry;
