import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import List from './List';

const Wrapper = styled.div`
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('30px')};
  padding: 0 ${rem('18px')};

  @media (min-width: ${rem('1172px')}) {
    max-width: ${rem('1116px')};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${rem('60px')};
    padding: 0 0 ${rem('30px')};
  }
`;

const Title = styled.h3`
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  font-weight: 500;
  color: ${props => props.theme.darkBlueGrey};
  font-size: ${rem('15px')};
  margin-bottom: ${rem('35px')};
  text-align: center;

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('18px')};
    text-align: center;
    margin-top: ${rem('40px')};
  }
`;

const ViewMore = styled.p`
  color: #198ddc;
  text-align: center;

  @media (min-width: ${rem('1172px')}) {
    display: none;
  }
`;

class ManufacturerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMore: false,
    };
  }

  render() {
    const { title, list, dispatch, features, flags } = this.props;
    const { viewMore } = this.state;

    return (
      <Wrapper>
        <Title>{title}</Title>
        <List
          {...{
            viewMore,
            dispatch,
            features,
            list: [list.slice(0, 12), list.slice(12, -1)],
            flags,
          }}
        />
        {!viewMore && (
          <ViewMore
            onClick={() => this.setState(p => ({ viewMore: !p.viewMore }))}
          >
            View more...
          </ViewMore>
        )}
      </Wrapper>
    );
  }
}

ManufacturerList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default ManufacturerList;
