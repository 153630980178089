import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const FCAHeader = styled.p`
  color: white;
  font-size: ${rem('14px')};
  line-height: ${rem('21px')};
  font-weight: 300;
  padding-bottom: ${rem('12px')};

  ${media.bpTablet`
    font-size: ${rem('12px')};
  `};
`;

export default FCAHeader;
