import React from 'react';

import { Wrapper, Title, SubTitle, MidTitle } from './SearchInfoStyles';
import Suggestions from './Suggestions';

const SearchInfo = ({
  filters,
  searchDescription,
  features,
  dispatch,
  history,
  suggestions,
  pageHasFeaturedCar,
  flags,
}) => {
  const hasActiveFilter = filters.some(
    filter =>
      Object.keys(typeof filter.selected === 'object' ? filter.selected : {})
        .length,
  );

  const Description =
    hasActiveFilter && pageHasFeaturedCar ? (
      <>
        <MidTitle>Similar Cars</MidTitle>
        <SubTitle>
          <span>Showing results for: </span>
          {searchDescription}
        </SubTitle>
      </>
    ) : (
      <Title>
        <span>Showing results for: </span>
        {searchDescription}
      </Title>
    );

  return (
    <Wrapper {...{ pageHasFeaturedCar }}>
      {Description}
      {features.suggestions &&
        typeof suggestions === 'object' &&
        Object.keys(suggestions).length !== 0 && (
          <Suggestions
            {...{ dispatch, suggestions, history, features, flags }}
          />
        )}
    </Wrapper>
  );
};

export default SearchInfo;
