import styled from 'styled-components';

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
  z-index: 20;
  opacity: 0.6;
`;

export default Overlay;
