import React from 'react';

import { Section, Container, Heading, PartnerLogos, Hr } from './styles';
import {
  LadderSection,
  ImageContainer,
  TextContainer,
  LadderHeading,
  Text,
} from './Ladder';
import SellSection from './SellSection';

const OurCustomers = () => (
  <Section>
    <Container>
      <Heading>What Our Customers Say</Heading>
      <LadderSection>
        <ImageContainer>
          <PartnerLogos
            alt="T.C.Harrison Ford Logo"
            src="/images/tchf_logo.jpg"
          />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>TC Harrison</LadderHeading>
          <Text>
            “We are more than happy with the level of traffic Carsnip delivers.
            Their continued support gives us an additional avenue to target used
            car buyers in an already challenging market place.”
          </Text>
          <Text>Matt Vause, Group Marketing Manager</Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer right>
          <PartnerLogos alt="Cambria Logo" src="/images/cambria_logo.png" />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Cambria Automobiles</LadderHeading>
          <Text>
            “I am very pleased with Carsnip’s performance. Carsnip drives highly
            engaged, in market buyers who are ready to purchase, to our vehicle
            detail pages”
          </Text>
          <Text>Ian Godbold, Marketing Director</Text>
        </TextContainer>
      </LadderSection>
      <LadderSection>
        <ImageContainer>
          <PartnerLogos
            alt="Peter Vardy Logo"
            src="/images/peter_vardy_logo.png"
          />
        </ImageContainer>
        <TextContainer>
          <LadderHeading>Peter Vardy</LadderHeading>
          <Text>
            “Qualified leads generated from Carsnip are one of our cheapest
            sources along with our own organic traffic and the conversion is
            comparable with all other sources. So it’s cheaper and we are
            converting sales at the same rate.”
          </Text>
          <Text>Euan Cameron, Marketing Director</Text>
        </TextContainer>
      </LadderSection>
      <Hr />
      <SellSection />
    </Container>
  </Section>
);

export default OurCustomers;
