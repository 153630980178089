/**
 * Pure function for populating the adverts prop
 */
function adverts(state = [], action) {
  if (
    action.type === 'SEARCH_RESPONSE' &&
    action.data &&
    Array.isArray(action.data.adverts)
  ) {
    return [...action.data.adverts];
  }

  return state;
}

export default adverts;
