import React from 'react';

import Container from './Container';
import Wrapper from './Wrapper';
import Heading from './Heading';
import Logos from './Logos';
import CarDealerLogo from './CarDealerLogo';
import TheGuardianLogo from './TheGuardianLogo';
import AMOnlineLogo from './AMOnlineLogo';
import MotorTraderLogo from './MotorTraderLogo';
import MinuteHackLogo from './MinuteHackLogo';
import BusinessCloudLogo from './BusinessCloudLogo';
import FinancialTimesLogo from './FinancialTimesLogo';

class Featured extends React.PureComponent {
  render() {
    return (
      <Container>
        <Wrapper>
          <Heading>AS FEATURED ON</Heading>
          <Logos>
            <CarDealerLogo
              alt="Car Dealer Logo"
              src="/images/press/cardealer.png"
            />
            <TheGuardianLogo
              alt="The Guardian Logo"
              src="/images/press/the_guardian.png"
            />
            <AMOnlineLogo
              alt="AM Online Logo"
              src="/images/press/amonline.png"
            />
            <MotorTraderLogo
              alt="Motortrader Logo"
              src="/images/press/motortrader.png"
            />
            <MinuteHackLogo
              alt="Minutehack Logo"
              src="/images/press/minutehack.png"
            />
            <BusinessCloudLogo
              alt="Business Cloud Logo"
              src="/images/press/business_cloud.png"
            />
            <FinancialTimesLogo
              alt="Financial Times Logo"
              src="/images/press/financial_times.png"
            />
          </Logos>
        </Wrapper>
      </Container>
    );
  }
}

export default Featured;
