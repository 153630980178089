import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { resetButton } from '@oodlefinance/ui-library/mixins';

const BackButton = styled.button`
  ${resetButton}
  float: left;
  cursor: pointer;

  img {
    width: ${rem('9px')};
    height: ${rem('16px')};
    margin-right: ${rem('8px')};
    transform: rotate(180deg);
    vertical-align: middle;
  }
`;

export default BackButton;
