import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const NoResultsWrapper = styled.div`
  padding: 0 ${rem('18px')};
`;

const NoResultsHeader = styled.div`
  font-size: ${rem('20px')};
  margin: ${rem('10px')} 0;
  font-weight: 400;
`;

const RemoveFiltersText = styled.div`
  padding-bottom: ${rem('10px')};
  font-weight: 300;
`;

export { ResultsWrapper, NoResultsWrapper, NoResultsHeader, RemoveFiltersText };
