module.exports = () => {
  // Make the config safe for server side rendering
  const w = typeof window === 'undefined' ? { location: {} } : window;

  // Create the environment based on domain name
  let environment = 'development';
  switch (w.location.host) {
    case 'carsnip.com':
      environment = 'production';
      break;
    case 'carsnip.ninja':
    case 'dev.carsnip.ninja':
      environment = 'staging';
      break;
    default:
      break;
  }

  // Check if we're in test mode
  if (typeof process === 'object' && process.env.NODE_ENV === 'test') {
    environment = 'test';
  }

  return {
    currencySymbol: '£',
    environment,
    recaptchaInvisibleSiteKey: '6LdVlUgUAAAAALoJp3eSfAJVRzBb3KNmU4h7dyZS',
    recaptchaOodleSiteKey: '6LetIV0UAAAAAPjWsDwHSk2jNGv6Rs-6CzpP_T_p',
    carsnipOodleGAId: 'UA-50451602-3',
    googleMapsApiKey: 'AIzaSyCj4eMoJR1rSJKqypveJhTe8kxPROkK_6w',
    polyfills: [
      'core-js/fn/string/includes',
      'core-js/fn/string/starts-with',
      'core-js/fn/string/ends-with',
      'core-js/fn/array/from',
      'core-js/fn/array/is-array',
      'core-js/fn/array/find',
      'core-js/fn/array/find-index',
      'core-js/fn/array/includes',
      'core-js/fn/object/assign',
      'core-js/fn/object/entries',
      'core-js/fn/set',
      'core-js/fn/map',
      'core-js/fn/symbol',
      'custom-event-polyfill',
    ],
  };
};
