import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  padding: ${rem('30px')} 0;
  max-width: ${rem('1020px')};
  margin: 0 auto;

  ${media.bpTablet`
    background: url("/images/help-me-choose/arrows_left.jpg") no-repeat,
      url("/images/help-me-choose/arrows_right.jpg") no-repeat;
    background-position: center left, center right;
  `};
`;

export default Wrapper;
