import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.li`
  max-width: ${rem('264px')};
  margin-left: ${rem('10px')};
  margin-right: ${rem('10px')};
  margin-bottom: ${rem('20px')};

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('14px')};
  }
`;

const Link = styled.a`
  color: #198ddc;
  text-decoration: none;
`;

const ListItem = ({ name, link }) => (
  <Wrapper>
    <Link href={link}>{name}</Link>
  </Wrapper>
);

ListItem.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ListItem;
