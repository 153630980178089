import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const TextWrapper = styled.div`
  ${media.bpTablet`
    text-align: left;
    margin-right: ${rem('50px')};
  `}
`;

export default TextWrapper;
