import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Nav = styled.nav`
  padding: ${rem('9px')} 0;
  display: none;
  margin-left: auto;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  li {
    margin-right: ${rem('19px')};
    display: inline;
  }

  li:last-child {
    margin-right: 0;
  }

  ${media.bpTablet`
    display: block;

    ${p =>
      p.pathname.startsWith('/search') &&
      css`
        margin-right: ${rem('40px')};
      `}
  `};
`;

const NavLink = styled(({ className, children, to }) => (
  <Link {...{ className, children, to }} />
))`
  font-size: ${rem('11px')};
  font-weight: 400;
  text-decoration: none;
  color: ${props => props.theme.lightGrey};
  text-transform: uppercase;

  &.${props => props.activeClassName} {
    color: ${props => props.theme.mediumGrey};
    font-weight: 500;
  }
`;

const StaticNavLink = styled.a`
  font-size: ${rem('11px')};
  font-weight: 400;
  text-decoration: none;
  color: ${props => props.theme.lightGrey};
  text-transform: uppercase;
`;

NavLink.defaultProps = {
  activeClassName: 'active',
};

export default Nav;
export { NavLink, StaticNavLink };
