import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Container = styled.div`
  padding: 0 ${rem('18px')};
  max-width: ${rem('1220px')};
  margin: 0 auto;

  ${media.bpTablet`
    padding: 0 ${rem('30px')};
  `}
`;

export default Container;
