import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('30px')};
  height: ${rem('34px')};

  ${media.bpTablet`
    width: ${rem('60px')};
    height: ${rem('68px')};
  `}
`;

const Path = styled.path`
  fill: #898989;
`;

class AdviceIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 59.7 68.1" x="0px" y="0px">
        <g>
          <Path
            d="M29.8,0C13.4,0,0,13.4,0,29.8v19.1c0,4.7,3.8,8.5,8.5,8.5h6.4c1.2,0,2.1-1,2.1-2.1V34c0-1.2-1-2.1-2.1-2.1H4.3
            v-2.1c0-14.1,11.5-25.5,25.5-25.5s25.5,11.5,25.5,25.5v2.1H44.7c-1.2,0-2.1,1-2.1,2.1v21.3c0,1.2,1,2.1,2.1,2.1h6.4
            c1.6,0,3-0.5,4.3-1.2v1.2c0,3.5-2.9,6.4-6.4,6.4H36.2c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1h12.8c5.9,0,10.6-4.8,10.6-10.6V29.8
            C59.6,13.4,46.2,0,29.8,0z"
          />
        </g>
      </Svg>
    );
  }
}

export default AdviceIcon;
