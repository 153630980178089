import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Image = styled.img`
  max-width: ${rem('162px')};
  margin-top: ${rem('31px')};
  margin-bottom: ${rem('29px')};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;

  ${media.bpTablet`
    max-width: ${rem('220px')};
  `}
`;

export default Image;
