import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export default styled.div`
  display: flex;
  margin-bottom: ${rem('9px')};

  input {
    margin-top: ${rem('1px')};
    margin-right: ${rem('8px')};
  }

  label {
    font-size: ${rem('14px')};
    font-weight: 300;
    color: ${props => props.theme.mediumGrey};
  }
`;
