/**
 * Pure function for populating the filters prop
 */
function filters(state = [], action) {
  if (
    action.type === 'SEARCH_RESPONSE' &&
    action.data &&
    Array.isArray(action.data.filters)
  ) {
    return [...action.data.filters];
  }

  return state;
}

export default filters;
