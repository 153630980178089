import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import formatNumber from 'utils/formatNumber';
import runSearch from 'services/run-search';

const Wrapper = styled.a`
  flex: 0 0 ${rem('264px')};
  height: ${rem('162px')};
  padding: ${rem('10px')};
  margin-left: ${rem('10px')};
  margin-right: ${rem('10px')};
  margin-bottom: ${rem('10px')};
  border: 1px solid #eff0f1;
  box-shadow: 0 0 ${rem('14px')} 0 rgba(0, 0, 0, 0.09);
  text-align: center;
  font-weight: 300;
  text-decoration: none;

  @media (min-width: ${rem('604px')}) {
    margin-bottom: ${rem('20px')};
  }

  @media (min-width: ${rem('888px')}) {
    font-size: ${rem('15px')};
  }
`;

const Image = styled.img`
  display: block;
  height: 50%;
  margin: ${rem('20px')} auto;
`;

const Name = styled.p`
  color: ${props => props.theme.darkBlueGrey};
  display: inline;
`;

const Counter = styled.span`
  color: #999da3;
  margin-left: ${rem('5px')};
`;

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(manufacturer, e) {
    const { dispatch, features, history } = this.props;

    if (dispatch) {
      e.preventDefault();

      const query = `manufacturer=${manufacturer}`;
      runSearch(dispatch, query, features, history, {});
    }
  }

  render() {
    const { imageUrl, name, count, link } = this.props;

    return (
      <Wrapper href={link} onClick={this.onClick.bind(null, name)}>
        <Image src={imageUrl} />
        <Name>{name}</Name>
        {count && <Counter>({formatNumber(count)})</Counter>}
      </Wrapper>
    );
  }
}

Card.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default withRouter(Card);
