import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const AMOnlineLogo = styled.img`
  width: ${rem('86.5px')};
  height: ${rem('17px')};
  margin: 0 ${rem('7px')} ${rem('16px')};

  ${media.bpTablet`
    width: auto;
    height: auto;
    margin-bottom: ${rem('20px')};
  `}
`;

export default AMOnlineLogo;
