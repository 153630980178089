import React from 'react';

import Spinner from '../../../page-elements/Spinner';
import Wrapper from '../Wrapper';
import AcceptedSection from '../AcceptedSection';
import Overlay from '../Overlay';

import Title from './Title';
import Date from './Date';
import Image from './Image';
import ValuationWrapper from './ValuationWrapper';
import ValuationTitle from './ValuationTitle';
import ValuationPrice from './ValuationPrice';
import ButtonWrapper from './ButtonWrapper';
import NextButton from './NextButton';

import config from 'config';
import runSearch from 'services/run-search';
import { valueMyCarComplete } from 'services/event-tracking';
import formatDate from 'services/format-date';
import { VrmActions } from 'actions';

const conf = config();

class ValueMyCarResults extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { lookupId, validation, mileage } = this.props.match.params;

    // Get the data for the page
    this.props
      .dispatch(VrmActions.getValuation(lookupId, mileage, validation))
      .then(() => {
        valueMyCarComplete(); // event tracking
      });
  }

  handleClick() {
    const { dispatch, features, history } = this.props;

    runSearch(dispatch, '', features, history, {});
  }

  render() {
    const { date, title, status, valuation = {} } = this.props.valuation;

    const {
      private: individual = {}, // private is a reserved word
      tradein = {},
      forecourt = {},
      auction = {},
    } = valuation;

    return (
      <Wrapper>
        <AcceptedSection>
          {!status && (
            <Overlay>
              <Spinner />
            </Overlay>
          )}
          {status === 'InProgress' ? (
            <Title>
              Sorry we were unable to complete your valuation at this time,
              please try again
            </Title>
          ) : (
            <div>
              <Title>{title}</Title>
              <Date>Prices for {formatDate(date)}</Date>
              <Image src="/images/sell-value_car.png" />
              <ValuationWrapper>
                <ValuationTitle>Private guide price</ValuationTitle>
                <ValuationPrice>{`${conf.currencySymbol}${(individual &&
                  individual.bad) ||
                  ''} - ${conf.currencySymbol}${(individual &&
                  individual.vGood) ||
                  ''}`}</ValuationPrice>
              </ValuationWrapper>
              <ValuationWrapper>
                <ValuationTitle>Part-exchange guide price</ValuationTitle>
                <ValuationPrice>{`${conf.currencySymbol}${(tradein &&
                  tradein.bad) ||
                  ''} - ${conf.currencySymbol}${(tradein && tradein.vGood) ||
                  ''}`}</ValuationPrice>
              </ValuationWrapper>
              <ValuationWrapper>
                <ValuationTitle>Forecourt guide price</ValuationTitle>
                <ValuationPrice>{`${conf.currencySymbol}${(forecourt &&
                  forecourt.bad) ||
                  ''} - ${conf.currencySymbol}${(forecourt &&
                  forecourt.vGood) ||
                  ''}`}</ValuationPrice>
              </ValuationWrapper>
              <ValuationWrapper>
                <ValuationTitle>Auction guide price</ValuationTitle>
                <ValuationPrice>{`${conf.currencySymbol}${(auction &&
                  auction.bad) ||
                  ''} - ${conf.currencySymbol}${(auction && auction.vGood) ||
                  ''}`}</ValuationPrice>
              </ValuationWrapper>
              <ButtonWrapper>
                <NextButton onClick={this.handleClick}>
                  Find your next car
                </NextButton>
              </ButtonWrapper>
            </div>
          )}
        </AcceptedSection>
      </Wrapper>
    );
  }
}

export default ValueMyCarResults;
