import React from 'react';
import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import exenv from 'exenv';
import rem from 'polished/lib/helpers/rem';

import { Header } from './style';

import media from 'styles/mixins/media';
import config from 'config';

const conf = config();

const ReactModalAdapter = ({
  className,
  onCancel,
  header,
  orangeHeader,
  children,
  ...props
}) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  if (conf.environment !== 'test' && exenv.canUseDOM) {
    Modal.setAppElement('#render-app');
  } else {
    Modal.setAppElement('body');
  }

  return (
    <Modal
      className={contentClassName}
      onRequestClose={onCancel}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    >
      {header && <Header orange={orangeHeader}>{header}</Header>}
      {children}
    </Modal>
  );
};

export default styled(ReactModalAdapter)(
  ({ theme, hasFooter, header }) => css`
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 20;
    }

    &__content {
      position: relative;
      border: 1px solid ${theme.grey};
      background: ${theme.white};
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border-radius: 4px;
      outline: none;
      padding: ${hasFooter ? '1.25rem 1.25rem 5.5rem' : '1.25rem'};
      max-width: 545px;
      max-height: 100vh;

      ${media.bpTablet`
        padding: ${header ? '1rem' : '3.125rem'} 2rem ${
        hasFooter ? rem('93px') : rem('35px')
      };
      `};
    }
  `,
);
