import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Cancel = styled.p`
  margin-top: ${rem('30px')};
  font-size: ${rem('13px')};
  font-weight: 300;
  text-align: center;
  color: ${props => props.theme.lightGrey};
`;

export default Cancel;
