import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Error = styled.p`
  color: #d40e0e;
  font-size: ${rem('15px')};
  text-align: center;
  margin-bottom: ${rem('15px')};
`;

export default Error;
