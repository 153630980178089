import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const QuoteSection = styled.div`
  background: ${props => props.theme.lighterGrey};
  padding: ${rem('25px')} 0;
  text-align: center;

  ${media.bpsLarge`
    padding: ${rem('40px')} 0;
  `};
`;

export default QuoteSection;
