import React from 'react';

import SVG from './SVG';

const Car = () => (
  <SVG viewBox="0 0 32 20" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1, 1)"
    >
      <path d="M21,17 L9,17" />
      <polyline points="3 15 0 15 0 7 5 0 19 0 23 7 30 7 30 15 27 15" />
      <path d="M6,12 C7.657,12 9,13.343 9,15 C9,16.657 7.657,18 6,18 C4.343,18 3,16.657 3,15 C3,13.343 4.343,12 6,12 Z" />
      <path d="M24,12 C25.657,12 27,13.343 27,15 C27,16.657 25.657,18 24,18 C22.343,18 21,16.657 21,15 C21,13.343 22.343,12 24,12 Z" />
      <path d="M0,7 L23,7" />
      <path d="M12,0 L12,7" />
    </g>
  </SVG>
);

export default Car;
