import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Confirm = styled.div`
  padding-bottom: ${rem('15px')};
  max-width: ${rem('320px')};
  position: relative;
  margin-left: auto;
  margin-right: auto;

  input[type='checkbox'] {
    position: absolute;
    left: 0;

    & + label {
      position: absolute;
      left: 0;
      display: block;
      height: ${rem('12px')};
      width: ${rem('12px')};
      cursor: pointer;
      border: ${rem('1px')} solid ${props => props.theme.orange};
      border-radius: ${rem('3px')};
    }

    &:checked + label {
      background-image: url('../images/checkbox_tick.png');
      background-color: ${props => props.theme.orange};
      background-repeat: no-repeat;
      background-position: 18% 49%;
    }

    ${props =>
      props.error &&
      css`
        & + label {
          border: 1px solid red;
        }
      `};
  }

  p {
    margin-left: ${rem('20px')};
    font-size: ${rem('11px')};
  }

  a {
    color: ${props => props.theme.blue};
    text-decoration: none;
  }
`;

export default Confirm;
