import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.div`
  font-size: ${rem('12px')};
  line-height: ${rem('19px')};
  color: ${props => props.theme.secondary};
  margin-bottom: ${rem('10px')};

  @media (min-width: ${rem('430px')}) {
    margin-bottom: 0;
  }

  ${media.bpTablet`
    font-size: ${rem('14px')};
  `};
`;

export default Text;
