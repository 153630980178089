import styled, { css } from 'styled-components';
import { cover } from 'polished';

export const Wrapper = styled.figure(
  p => css`
    transition: opacity 500ms ease;
    position: ${p.ratio ? 'relative' : 'absolute'};
    top: 0;
    left: 0;
    width: 100%;
    height: ${!p.ratio && '100%'};
    padding-top: ${p.ratio && `${p.ratio * 100}%`};
    margin: 0;
    overflow: hidden;
    opacity: ${p.isLoaded ? 1 : 0};
  `,
);

export const Background = styled.div`
  ${cover()};
  background: ${p => `transparent url(${p.url}) center/cover no-repeat`};
`;
