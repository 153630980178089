import styled from 'styled-components';

import media from 'styles/mixins/media';

const Content = styled.div`
  ${media.bpTablet`
    display: flex;
    align-items: flex-end;
  `}
`;

export default Content;
