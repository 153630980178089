import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  font-weight: 300;
  color: white;
  text-align: left;
  margin-left: ${rem('20px')};
  opacity: 0.8;

  ${media.bpTablet`
    font-size: ${rem('16px')};
    line-height: ${rem('24px')};
    text-align: center;
    margin: ${rem('18px')} auto 0;
    max-width: ${rem('230px')};
  `}
`;

export default Text;
