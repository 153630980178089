import React from 'react';
import has from 'has';

import Spinner from '../Spinner';

import commit from '../../../../git.ver';

import MarketingPrefs from '../MarketingPrefs';

import Wrapper from './Wrapper';

import Overlay from './Overlay';
import BackButton from './BackButton';
import { MobileText, TabletText } from './BackButtonText';
import Envelope from './Envelope';
import Text from './Text';
import Form from './Form';
import EmailInput from './EmailInput';
import ErrorText from './ErrorText';
import Submit from './Submit';
import Confirm from './Confirm';
import Cancel from './Cancel';

import { saveSearchRequested } from 'services/event-tracking';
import { getFiltersDescription } from 'services/search-title';
import parseUrl from 'services/url/parse-url';
import { emailRegex } from 'services/regex';
import { removeSearch } from 'services/url/manipulate-url';
import { SaveSearchActions, NotificationActions } from 'actions';

class SaveSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {},
      confirm: false,
      showMarketingPrefs: false,
      marketingSearch: false,
      marketingNewFeatures: false,
      contactEmail: false,
      contactSMS: false,
      contactPhone: false,
    };

    this.hidePanel = this.hidePanel.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.handleSubmitResponse = this.handleSubmitResponse.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.toggleMarketing = this.toggleMarketing.bind(this);
    this.submit = this.submit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleMarketingPrefs = this.toggleMarketingPrefs.bind(this);
    this.saveRequest = this.saveRequest.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Hides the panel by clicking the back button
   */
  hidePanel() {
    const { dispatch } = this.props;
    dispatch(SaveSearchActions.toggleMobileVisibility(false));
  }

  /**
   * Updates the email input
   */
  updateEmail(e) {
    const { value: email } = e.target;
    const { errors } = this.state;

    // Determine if the email is valid
    const isValid = emailRegex.test(email);

    // We need to remove the error if it existed and the email is now valid
    if (has(errors, 'email') && isValid) {
      delete errors.email;
    }

    // Update the state object
    this.setState({
      email,
      errors,
    });
  }

  /**
   * Remove notification after the given time or 4 seconds by default
   * @param {Number}  time   The time at which the notification should be removed
   */
  removeNotification(time = 4000) {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(NotificationActions.removeNotification());
    }, time);
  }

  /**
   * Handles the response from the submit request by showing a notification
   * @param  {JSX}  content   The JSX to put into the notification component
   */
  handleSubmitResponse(notificationMessage) {
    const { dispatch } = this.props;

    // Show the notification component
    dispatch(
      NotificationActions.updateNotificationMessage(notificationMessage),
    );

    // Hide the panel for mobile
    this.hidePanel();

    // Clear the email input
    this.setState({
      email: '',
    });

    // Hide the notification bar after 4 seconds
    this.removeNotification(4000);
  }

  /**
   * Toggles the value of the confirmation checkbox
   */
  toggleConfirm() {
    const { confirm, errors } = this.state;
    delete errors.confirm;
    this.setState({
      errors,
      confirm: !confirm,
    });
  }

  /**
   * Toggles the value of the marketing checkbox
   */
  toggleMarketing() {
    const { marketing } = this.state;
    this.setState({
      marketing: !marketing,
    });
  }

  /**
   * @param {String} option The marketing preference to toggle
   */
  toggleMarketingPrefs(option) {
    const currentState = this.state[option];

    this.setState({
      [option]: !currentState,
    });
  }

  /**
   * Submit the email form
   */
  submit(e) {
    e.preventDefault();

    const { confirm } = this.state;
    const email = this.state.email.trim();

    const errors = {};

    // Check if the email is not valid and show error if it's not
    if (!emailRegex.test(email)) {
      errors.email = 'Please type a valid email';
    }

    // If the user hasn't accepted terms
    if (!confirm) {
      errors.confirm = 'Please confirm terms and conditions';
    }

    // Stop submission if an error was found
    if (Object.keys(errors).length) {
      this.setState({
        errors,
      });
      return false;
    }

    let marketingPrefsSaved = localStorage.getItem('marketingPrefsSaved');
    marketingPrefsSaved = JSON.parse(marketingPrefsSaved);

    if (marketingPrefsSaved && marketingPrefsSaved.commit === commit) {
      this.setState(
        {
          marketingSearch: marketingPrefsSaved.marketingSearch,
          marketingNewFeatures: marketingPrefsSaved.marketingNewFeatures,
          contactEmail: marketingPrefsSaved.contactEmail,
          contactSMS: marketingPrefsSaved.contactSMS,
          contactPhone: marketingPrefsSaved.contactPhone,
        },
        () => {
          this.saveRequest();
        },
      );
    } else {
      this.setState({ showMarketingPrefs: true });
    }

    return true;
  }

  /**
   * When marketing pref pop up is complete, send off the save search request
   */
  saveRequest() {
    const { dispatch, location, cars, activeFilters, totalHits } = this.props;
    const {
      email,
      confirm,
      marketing,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
    } = this.state;

    // Set marketingPrefsSaved localStorage item.
    localStorage.setItem(
      'marketingPrefsSaved',
      JSON.stringify({
        marketingSearch,
        marketingNewFeatures,
        contactEmail,
        contactSMS,
        contactPhone,
        commit,
      }),
    );

    // Close the marketing preferences pop up
    this.setState({ showMarketingPrefs: false });

    // Get the url as a canonical url and a query string
    const originalSearch = `/${removeSearch(location.pathname)}${
      location.search
    }`;
    const originalQuery = parseUrl(
      `${removeSearch(location.pathname)}${location.search}`,
    );

    // Get the human readable text for the email
    const text = getFiltersDescription(activeFilters, totalHits, cars);

    const body = {
      user: email.trim(),
      originalSearch,
      originalQuery,
      text,
      marketing,
      privacyPolicy: confirm,
      commit,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
    };

    // Send off the request to save the search
    dispatch(SaveSearchActions.saveSearch(body))
      .then(response => {
        // If the save search already exists
        if (response.statusCode === 302) {
          this.handleSubmitResponse({
            string: 'You are already signed up for these emails',
          });
        } else {
          this.handleSubmitResponse({
            string: 'You will be emailed when new cars are listed',
            image: '/images/email_success.svg',
          });

          saveSearchRequested(text); // event tracking
        }
      })
      .catch(() => {
        this.handleSubmitResponse({
          string: 'Sorry we made a mistake! Please try again',
        });
      });
  }

  handleClickOutside(event) {
    const { showMobileSaveSearch } = this.props;

    if (
      this.saveSearch &&
      showMobileSaveSearch &&
      !this.saveSearch.contains(event.target)
    ) {
      this.props.dispatch(SaveSearchActions.toggleMobileVisibility(false));
    }
  }

  render() {
    const { currentlyFetching, showMobileSaveSearch } = this.props;
    const {
      email,
      errors,
      confirm,
      showMarketingPrefs,
      marketingSearch,
      marketingNewFeatures,
      contactEmail,
      contactSMS,
      contactPhone,
    } = this.state;

    return (
      <>
        <MarketingPrefs
          {...{
            isOpen: showMarketingPrefs,
            marketingSearch,
            marketingNewFeatures,
            contactEmail,
            contactSMS,
            contactPhone,
            toggleCheckbox: this.toggleMarketingPrefs,
            onClose: this.saveRequest,
          }}
        />
        <Wrapper
          hide={!showMobileSaveSearch}
          ref={div => {
            this.saveSearch = div;
          }}
        >
          {currentlyFetching.saveSearch && (
            <Overlay>
              <Spinner />
            </Overlay>
          )}
          <BackButton onClick={this.hidePanel} type="button">
            <img
              alt="chevron"
              aria-hidden="true"
              src="/images/chevron_filter_right.svg"
            />
            <MobileText>Back to filters</MobileText>
            <TabletText>Close</TabletText>
          </BackButton>
          <Envelope alt="" src="/images/envelope.svg" />
          <Text>Get instant email updates for this search</Text>
          <Form onSubmit={this.submit}>
            <EmailInput
              onChange={this.updateEmail}
              placeholder="Email address"
              type="email"
              value={email}
            />
            <Confirm error={errors.confirm}>
              <input
                checked={confirm}
                name="confirm"
                onChange={this.toggleConfirm}
                type="checkbox"
                value={confirm}
              />
              <p>
                I have read the{' '}
                <a
                  href="/articles/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Privacy Policy"
                >
                  Carsnip Privacy Policy
                </a>{' '}
                and I'm happy for my personal details to be used as decsribed.
              </p>
            </Confirm>
            {errors &&
              Object.entries(errors).map(([key, error]) => (
                <ErrorText key={key}>{error}</ErrorText>
              ))}
            <Submit type="submit" value="GET CAR UPDATES" />
          </Form>
          <Cancel>You can cancel updates at any time</Cancel>
        </Wrapper>
      </>
    );
  }
}

export default SaveSearch;
