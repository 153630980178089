import React, { useEffect } from 'react';

export default () => {
  const canRefresh =
    typeof window !== 'undefined' &&
    window.addthis &&
    window.addthis.layers &&
    window.addthis.layers.refresh;

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.addthis &&
      window.addthis.layers &&
      window.addthis.layers.refresh
    ) {
      window.addthis.layers.refresh();
    }
  }, [canRefresh]);

  return <div className="addthis_inline_share_toolbox" />;
};
