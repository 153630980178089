import React from 'react';

import Form from '../Form';

import Wrapper from './Wrapper';

class FormSection extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Form heading="What are you waiting for?" noSidePadding />
      </Wrapper>
    );
  }
}

export default FormSection;
