import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Tab = styled.div`
  position: absolute;
  left: -${rem('50px')};
  top: calc(50% - ${rem('25px')});
  width: ${rem('50px')};
  height: ${rem('50px')};
  cursor: pointer;
  border: ${rem('1px')} solid ${props => props.theme.orange};
  border-bottom-left-radius: ${rem('4px')};
  border-top-left-radius: ${rem('4px')};
  background-color: #fff;
  background-image: url('/images/icons/orange/gearbox.svg');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
`;

export default Tab;
