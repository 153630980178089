function notFoundFlag(
  state = false,
  { type = '', data = {}, hasCatch = false },
) {
  // If there was an API response of 404
  if (type.includes('ERR') && data.statusCode === 404 && !hasCatch) {
    return true;
  }

  if (type === '@@router/LOCATION_CHANGE') {
    return false;
  }

  return state;
}

export default notFoundFlag;
