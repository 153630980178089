import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const EmailInput = styled.input`
  height: ${rem('42px')};
  font-size: ${rem('15px')};
  padding: ${rem('10px')};
  margin-top: ${rem('20px')};
  margin-bottom: ${rem('10px')};
  display: block;
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: ${rem('4px')};
`;

export default EmailInput;
