import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const QuoteContainer = styled.div`
  padding-left: ${rem('18px')};
  padding-right: ${rem('18px')};
  margin: 0 auto;

  ${media.bpsLarge`
    display: flex;
    align-items: center;
    max-width: ${rem('1010px')};
  `}
`;

export default QuoteContainer;
