const config = {
  nounTypeFields: ['manufacturer', 'range', 'model', 'variant', 'bodyStyle'],
  locationTypeFields: ['locality'],
  carOriginTypeFields: ['dealerName'],
  searchTypeFields: ['q'],
  withFields: [
    'engineSizeL',
    'co2Emissions',
    'secs0to62',
    'powerBhp',
    'torqueLbsFt',
    'topSpeed',
    'postedDate',
  ],
  adjectiveDescriptors: {
    numberOfDoors: 'door',
    numberOfSeats: 'seat',
    numberOfCylinders: 'cylinder',
  },
  modifiedFieldNames: {
    registrationYear: 'registered ',
    secs0to62: '0 to 60 mph in ',
  },
  modifiedTrailingUnits: {
    mpgCombined: ' mpg (combined)',
    mpgUrban: ' mpg (extra Urban)',
    mpgExtraUrban: ' mpg (urban)',
    distance: '',
  },
};

export default config;
