import React from 'react';
import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import { Text } from './Ladder';

import media from 'styles/mixins/media';
import Button from 'shared/Button';

const Section = styled.section`
  margin-top: ${rem('40px')};
  text-align: center;

  ${media.bpsMedium`
    margin-top: ${rem('50px')};
  `} ${media.bpsLarge`
    margin-top: ${rem('60px')};
  `};
`;

const Heading = styled.h3`
  font-size: ${rem('18px')};
  font-weight: 400;
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  margin: 0 0 ${rem('10px')};
  color: ${props => props.theme.mediumGrey};

  ${media.bpsMedium`
    font-size: ${rem('30px')};
  `};
`;

const SignupButton = styled(Button)`
  background: linear-gradient(#1097ec, #0d80c9);
  padding: ${rem('10px')} ${rem('40px')};
  text-transform: none;
  font-size: ${rem('15px')};

  ${media.bpsMedium`
    font-size: ${rem('22px')};
    padding: ${rem('17px')} ${rem('110px')};
  `};
`;

const SellSection = () => (
  <Section>
    <Heading>Sell more cars AND reduce your costs</Heading>
    <Text>
      With Carsnip Premium you can promote your stock to ensure buyers see your
      ads first.
    </Text>
    <a href="#signup-form">
      <SignupButton>Sign up today</SignupButton>
    </a>
  </Section>
);

export default SellSection;
