import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Card from './Card';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${rem('1216px')};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${rem('10px')};
  padding-left: ${rem('18px')};
  padding-right: ${rem('18px')};

  @media (min-width: ${rem('912px')}) {
    margin-bottom: ${rem('55px')};
  }
`;

const BodyStyleCards = ({ bodyStyleCards }) => (
  <Wrapper>
    {bodyStyleCards.map(card => (
      <Card key={card.name} {...card} />
    ))}
  </Wrapper>
);

BodyStyleCards.propTypes = {
  bodyStyleCards: PropTypes.array.isRequired,
};

export default BodyStyleCards;
