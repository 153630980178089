/**
 * Pure function for updating the flag prop
 */
function flags(state = {}, action) {
  if (action.type === 'UPDATE_FLAG' && action.data) {
    return { ...action.data };
  }

  return state;
}

export default flags;
