import React from 'react';

import SVG from './SVG';

const CircledCheckMark = () => (
  <SVG
    fill="none"
    stroke="currentColor"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8,0.5 C12.143,0.5 15.5,3.858 15.5,8 C15.5,12.143 12.143,15.5 8,15.5 C3.858,15.5 0.5,12.143 0.5,8 C0.5,3.858 3.858,0.5 8,0.5 Z" />
    <polyline points="4.5 7.5 7 10 12 5" />
  </SVG>
);

export default CircledCheckMark;
