// Best place to find information on XHR features is:
// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest

const makeRequest = (method, url, callback, body) => {
  // Stop the request running in node
  if (typeof window === 'undefined') {
    return;
  }

  // Create the request object
  const xhr = new XMLHttpRequest();

  // Handle the response of the request
  xhr.onreadystatechange = () => {
    // Get the data from the request
    let data = xhr.responseText;

    // Will reduce down any 2xx to 2
    const status = parseInt(xhr.status / 100, 10);
    // Try to convert it to json
    if (data && xhr.readyState === 4 && status === 2) {
      try {
        data = JSON.parse(data);
      } catch (err) {
        return callback(err, {});
      }
    }

    // Send data if request is all good
    if (xhr.readyState === 4) {
      if (status === 2) {
        return callback(null, data);
      }

      const error = xhr.responseText ? xhr.responseText : 'Unknown Error';

      return callback(error, data);
    }

    return undefined;
  };

  // Open the request
  xhr.open(method, url);

  // Ensure we're sending request as JSON by default
  xhr.setRequestHeader('Content-Type', 'application/json');

  if (body && body.recaptchaResponse) {
    xhr.setRequestHeader('recaptcha-response', body.recaptchaResponse);
  }

  // Send the request
  xhr.send(JSON.stringify(body) || {});
};

const request = {
  get: (url, callback) => makeRequest('GET', url, callback),
  post: (url, body, callback) => makeRequest('POST', url, callback, body),
  put: (url, callback) => makeRequest('PUT', url, callback, ''),
};

export default request;
