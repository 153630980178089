import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Container = styled.div`
  padding: 0 ${rem('18px')};
  max-width: ${rem('1220px')};
  margin: 0 auto;

  ${media.bpTablet`
    min-height: ${rem('675px')};
    background: url("/images/help-me-choose/questionmarks_left.jpg") no-repeat,
      url("/images/help-me-choose/questionmarks_right.jpg") no-repeat;
    background-position: center left, center right;
  `};
`;

export default Container;
