import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Newsletter = styled.section`
  padding: ${rem('35px')} 0 ${rem('40px')};

  @media (min-width: ${rem('515px')}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${media.bpTablet`
    padding: ${rem('20px')} 0 ${rem('25px')};
  `}
`;

export default Newsletter;
