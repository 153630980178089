import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import LazyImage from '../LazyImage';
import ArrowRight from '../../svgs/ArrowRight';
import ArrowLeft from '../../svgs/ArrowLeft';

import { Arrow, Item, ImageNumberIndicator, Wrapper } from './style';

import mod from 'utils/mod';

const ImageCarousel = ({ altText, fillParent, images, ratio }) => {
  const touchManagerRef = useRef(null);
  const wrapper = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const incrementActiveIndex = () =>
    setActiveIndex(previousIndex => mod(previousIndex + 1, images.length));
  const decrementActiveIndex = () =>
    setActiveIndex(previousIndex => mod(previousIndex - 1, images.length));

  const initTouchSwipe = Hammer => {
    if (!wrapper.current) return;

    const swipe = new Hammer.Swipe();

    const touchManager = new Hammer.Manager(wrapper.current, {
      touchAction: 'auto',
    });

    touchManager.add(swipe);
    touchManager.on('swiperight', decrementActiveIndex);
    touchManager.on('swipeleft', incrementActiveIndex);
    touchManagerRef.current = touchManager;
  };

  useEffect(() => {
    import('hammerjs').then(initTouchSwipe);
    return () => touchManagerRef.current && touchManagerRef.current.destroy();
  }, []);

  return (
    <Wrapper fillParent={fillParent} ratio={ratio} ref={wrapper}>
      {images.map((image, index) => (
        <Item
          isActive={index === activeIndex}
          isNext={index > activeIndex}
          isPrevious={index < activeIndex}
          key={image}
        >
          <LazyImage altText={altText} url={image} />
        </Item>
      ))}
      {images.length > 1 && (
        <>
          <ImageNumberIndicator aria-label="image number">
            {activeIndex + 1}/{images.length}
          </ImageNumberIndicator>
          <Arrow
            aria-label="previous image"
            isPrevious
            name="imageCarouselPrevious"
            onClick={decrementActiveIndex}
            type="button"
          >
            <ArrowLeft />
          </Arrow>
          <Arrow
            aria-label="next image"
            isNext
            name="imageCarouselNext"
            onClick={incrementActiveIndex}
            type="button"
          >
            <ArrowRight />
          </Arrow>
        </>
      )}
    </Wrapper>
  );
};

ImageCarousel.propTypes = {
  altText: PropTypes.string.isRequired,
  fillParent: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  ratio: PropTypes.number,
};

ImageCarousel.defaultProps = {
  fillParent: false,
  ratio: 9 / 16,
};

export default ImageCarousel;
