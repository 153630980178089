import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Footer = styled.footer`
  padding: ${rem('12px')};
  font-size: ${rem('12px')};
  border-bottom-left-radius: ${rem('4px')};
  border-bottom-right-radius: ${rem('4px')};
  border-top: ${rem('1px')} solid #e0e0e0;
  background: white;
  color: ${props => props.theme.highlightBlue};
  text-align: center;
  cursor: pointer;

  ${media.bpTablet`
    padding: ${rem('18px')};
  `};
`;

export default Footer;
