import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

export default styled.p`
  margin: ${rem('15px')} 0;
  font-size: ${rem('14px')};
  color: ${props => props.theme.mediumGrey};

  ${media.bpTablet`
    margin: ${rem('20px')} 0;
  `};
`;
