import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Heading = styled.h2`
  font-size: ${rem('18px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  text-align: center;
  margin: 0 0 ${rem('35px')};
  color: ${props => props.theme.mediumGrey};

  ${media.bpTablet`
    font-size: ${rem('32px')};
    margin-bottom: ${rem('65px')};
  `};
`;

export default Heading;
