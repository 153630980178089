/**
 * Removes a specified field from a canonical url
 */
const removeField = (url = '', field = '') =>
  url.replace(new RegExp(`/${field}/[^/?]+`), '');

/**
 * Strips a particular value from a field in the canonical url
 */
const removeFieldValue = (url = '', field = '', value = '') => {
  const selection = (url.match(new RegExp(`${field}/[A-z0-9,+-]+/?`)) || [
    '',
  ])[0];
  const intermediateValue = value.replace(/[+ ]/g, '\\+');

  // If the selection has a comma in it, the field has multiple values so only remove that one
  if (selection.indexOf(',') > -1) {
    const newSelection = selection.replace(
      new RegExp(`,${intermediateValue}|${intermediateValue},`),
      '',
    );
    return url.replace(selection, newSelection);
  }

  // Remove the field and value
  const newSelection = selection.replace(
    new RegExp(`${field}/${intermediateValue}/?`),
    '',
  );
  return url.replace(selection, newSelection);
};

const removeSearch = (url = '') => {
  const regex = new RegExp(/\/search\/?/);

  return url.replace(regex, '');
};

export { removeField, removeFieldValue, removeSearch };
