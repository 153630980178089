import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Offer = styled.h3`
  font-size: ${rem('50px')};
  margin: 0;

  ${media.bpTablet`
    font-size: ${rem('66px')};
  `}
`;

export default Offer;
