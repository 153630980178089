import React from 'react';

import TopSection from './TopSection';

import ManufacturerCards from 'shared/ManufacturerCards';
import ManufacturerList from 'shared/ManufacturerList';
import { HomeActions } from 'actions';

class Home extends React.Component {
  componentDidMount() {
    const { seoPageData, dispatch } = this.props;

    if (!Object.keys(seoPageData).length) {
      dispatch(HomeActions.getManufacturerData());
    }
  }

  render() {
    const {
      searchString,
      location,
      dispatch,
      features,
      seoPageData,
      flags,
      history,
    } = this.props;
    const { manufacturerCards, manufacturerList } = seoPageData;

    return (
      <div>
        <TopSection
          {...{ searchString, location, dispatch, features, flags, history }}
        />
        {manufacturerCards && (
          <ManufacturerCards
            {...{ ...manufacturerCards, dispatch, features, flags }}
          />
        )}
        {manufacturerList && (
          <ManufacturerList
            {...{ ...manufacturerList, dispatch, features, flags }}
          />
        )}
      </div>
    );
  }
}

export default Home;
