import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Highlight = styled.span`
  display: inline-block;
  line-height: normal;
  font-size: ${rem('12px')};
  color: white;
  background: ${props => props.theme.secondary};
  padding: ${rem('5px')} ${rem('8px')};
  border-radius: ${rem('13px')};
  margin-left: ${rem('6px')};

  ${media.bpTablet`
    font-size: ${rem('9px')};
  `};
`;

export default Highlight;
