import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Speech = styled.p`
  position: absolute;
  top: ${rem('30px')};
  right: ${rem('15px')};
  width: ${rem('170px')};
  font-size: ${rem('12px')};
  line-height: ${rem('19px')};
  font-weight: 300;
  font-style: italic;
  opacity: 0.9;
  color: ${props => props.theme.darkBlueGrey};

  ${media.bpTablet`
    font-size: ${rem('20px')};
    line-height: ${rem('36px')};
    width: ${rem('288px')};
    top: ${rem('42px')};
    right: ${rem('30px')};
  `};
`;

export default Speech;
