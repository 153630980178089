import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { resetButton } from '@oodlefinance/ui-library/mixins';

import media from '../../../styles/mixins/media';

const BackButton = styled.button`
  ${resetButton};
  color: ${props => props.theme.blue};
  text-decoration: none;
  font-size: ${rem('14px')};
  margin: 1rem 0 ${rem('30px')};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${media.bpSmall`
    margin: 1.5rem 0 ${rem('30px')};
  `}
`;

export default BackButton;
