import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import container from 'styles/mixins/container';
import media from 'styles/mixins/media';

const StyledHero = styled.div`
  text-align: center;
  position: relative;

  ${media.bpsLarge`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 ${rem('20px')};
  `};
`;

const Container = styled.div`
  ${container}

  ${media.bpsLarge`
    margin: 0;
  `}

  ${media.bpDesktop`
    margin: 0 auto;
    width: 100%;
  `}
`;

const Heading = styled.h1`
  font-size: ${rem('21px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.mediumGrey};
  margin: ${rem('30px')} auto 0;
  font-weight: 400;

  ${media.bpsMedium`
    font-size: ${rem('40px')};
    margin-top: ${rem('70px')};
  `} ${media.bpsLarge`
    margin: ${rem('80px')} 0 0;
  `};
`;

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  max-width: ${rem('600px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};
  margin: ${rem('15px')} auto ${rem('26px')};

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
    margin-top: ${rem('35px')};
    margin-bottom: ${rem('35px')};
  `}

  ${media.bpsLarge`
    margin: ${rem('35px')} 0 0;
    max-width: 60vw;
  `}

  ${media.bpDesktop`
    max-width: ${rem('620px')};
  `}
`;

const Hr = styled.hr`
  height: ${rem('1px')};
  border: 0;
  background: #e5e5e5;
  margin: 0 auto;
`;

const FadedText = styled.p`
  font-size: ${rem('18px')};
  line-height: ${rem('22px')};
  font-style: italic;
  opacity: 0.5;
  text-align: center;
  margin: 20px 0 10px 0;

  ${media.bpsMedium`
    font-size: ${rem('22px')};
    line-height: ${rem('26px')};
  `};
`;

export { StyledHero, Container, Heading, Text, Hr, FadedText };
