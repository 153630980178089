import styled from 'styled-components';
import { resetButton } from '@oodlefinance/ui-library/mixins';

export const CloseModalButton = styled.button`
  ${resetButton}
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.75rem;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin: 2rem 0;
`;

export const Info = styled.aside`
  margin-top: 1rem;
`;

export const PrivacyNotice = styled.p`
  font-size: 0.75rem;
`;
