import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  font-size: ${rem('13px')};
  padding: ${rem('12px')};
  position: fixed;
  top: ${props =>
    props.cookiePolicyAgreed && props.messageDismissed
      ? rem('90px')
      : rem('200px')};
  left: 50%;
  transform: translateX(-50%);
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  text-align: center;
  color: white;
  background: ${props => props.theme.secondary};
  z-index: 5;
  white-space: nowrap;
  border-radius: ${rem('4px')};
  box-shadow: ${rem('2px')} ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.26);

  @media (min-width: ${rem('430px')}) {
    top: ${props =>
      props.cookiePolicyAgreed && props.messageDismissed
        ? rem('60px')
        : rem('155px')};
  }

  ${media.bpTablet`
    font-size: ${rem('16px')};
    padding: ${rem('21px')};
    top: ${props =>
      props.cookiePolicyAgreed && props.messageDismissed
        ? rem('100px')
        : rem('140px')};
  `};
`;

export default Wrapper;
