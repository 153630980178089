/**
 * Pure function for populating the totalCrawlAdverts prop
 */
function totalCrawlAdverts(state = 0, action) {
  if (
    action.type === 'SEARCH_RESPONSE' &&
    action.data &&
    typeof action.data.totalCrawlAdverts === 'number'
  ) {
    return action.data.totalCrawlAdverts;
  }

  return state;
}

export default totalCrawlAdverts;
