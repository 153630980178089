import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Wrapper = styled.div`
  border-left: ${rem('1px')} solid ${props => props.theme.orange};
  background: #fff;
  z-index: 10;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(${props => (props.show ? '0' : '100%')});
  transition: transform 300ms;
`;

export default Wrapper;
