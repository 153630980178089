import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import clearFix from 'polished/lib/mixins/clearFix';

const Confirm = styled.div`
  ${clearFix()}
  padding-top: ${rem('15px')};
  padding-bottom: ${rem('15px')};
  max-width: ${rem('385px')};
  position: relative;
  margin-left: auto;
  margin-right: auto;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    display: none;

    & + label {
      float: left;
      margin-left: ${rem('20px')};
      font-size: ${rem('12px')};

      ::before {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        height: ${rem('12px')};
        width: ${rem('12px')};
        cursor: pointer;
        border: ${rem('1px')} solid;
        border-color: ${p => p.error && 'red'};
        border-radius: ${rem('3px')};
      }
    }

   :checked + label::before {
      background-image: url('/images/checkbox_tick.png');
      background-color: ${p => p.theme.orange};
      background-repeat: no-repeat;
      background-position: 18% 49%;
      border: 1px solid ${p => p.theme.orange};
    }

  }

  a {
    color: ${p => p.theme.blue};
    text-decoration: none;
  }
`;

export default Confirm;
