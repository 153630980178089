import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ListItem from './ListItem';

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-content: space-around;

  @media (min-width: ${rem('604px')}) {
    height: ${props => (props.viewMore ? rem('1026px') : rem('230px'))};
  }

  @media (min-width: ${rem('860px')}) {
    height: ${props => (props.viewMore ? rem('1026px') : rem('152px'))};
  }

  @media (min-width: ${rem('1172px')}) {
    justify-content: flex-start;
    height: ${rem('756px')};
  }
`;

const List = ({ list, viewMore, dispatch, features, flags }) => (
  <Wrapper viewMore={viewMore}>
    {list.map((l, i) =>
      l.map(manufacturer => (
        <ListItem
          key={manufacturer.name}
          {...{ ...manufacturer, viewMore, i, dispatch, features, flags }}
        />
      )),
    )}
  </Wrapper>
);

List.propTypes = {
  list: PropTypes.array.isRequired,
  viewMore: PropTypes.bool,
};

export default List;
