import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('62px')};
  margin-top: ${rem('29px')};
  margin-bottom: ${rem('35px')};
  margin-left: auto;
  margin-right: auto;
  display: block;

  ${media.bpTablet`
    width: ${rem('125px')};
    margin-top: ${rem('40px')};
    margin-bottom: ${rem('50px')};
  `}
`;

class EmailSent extends React.PureComponent {
  render() {
    return (
      <Svg id="Layer_1" viewBox="0 0 42 42" x="0px" y="0px">
        <g>
          <path
            d="M10,40c-0.2,0,4-17.3,4-17.3c0.1-0.3,0.4-0.6,0.7-0.7c0.3-0.1,0.7,0,0.9,0.3l7,7c0.2,0.2,0.3,0.5,0.3,0.8
            c0,0.3-0.2,0.6-0.4,0.7l-12,9C10.4,39.9,10.2,40,10,40z"
            style={{ fill: '#7F7F7F' }}
          />
          <path
            d="M33,42c-0.2,0-0.4-0.1-0.6-0.2l-32-24C0.1,17.6,0,17.2,0,16.9c0-0.4,0.3-0.7,0.6-0.8l40-16
            c0.3-0.1,0.7-0.1,1,0.2c0.3,0.2,0.4,0.6,0.3,1l-8,40c-0.1,0.3-0.3,0.6-0.6,0.7C33.2,42,33.1,42,33,42z"
            style={{ fill: '#E6E6E6' }}
          />
          <path
            d="M10,40c-0.1,0-0.1,0-0.2,0C9.4,39.9,9,39.5,9,39V24c0-0.3,0.2-0.6,0.4-0.8l31-23c0.4-0.3,1-0.2,1.4,0.2
            c0.3,0.4,0.3,1-0.1,1.4L14.2,28.2l-3.3,11.2C10.8,39.7,10.4,40,10,40z"
            style={{ fill: '#B3B3B3' }}
          />
        </g>
      </Svg>
    );
  }
}

export default EmailSent;
