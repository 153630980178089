import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';

import ThemeWrapper from './ThemeWrapper';

function mapStateToProps(state) {
  return {
    adverts: state.adverts,
    activeFilters: state.activeFilters,
    searchTitle: state.searchTitle,
    searchDescription: state.searchDescription,
    searchId: state.searchId,
    filters: state.filters,
    advert: state.advert,
    pages: state.pages,
    rangeSliderValues: state.rangeSliderValues,
    selectedFilter: state.selectedFilter,
    sortOptions: state.sortOptions,
    totalHits: state.totalHits,
    totalCrawlAdverts: state.totalCrawlAdverts,
    searchString: state.searchString,
    showFilterMenu: state.showFilterMenu,
    showMobileHeader: state.showMobileHeader,
    alternateSearches: state.alternateSearches,
    displayFilters: state.displayFilters,
    showMobileSaveSearch: state.showMobileSaveSearch,
    notificationMessage: state.notificationMessage,
    vrmLookupDetails: state.vrmLookupDetails,
    valuation: state.valuation,
    features: state.features,
    offerDetails: state.offerDetails,
    notFoundFlag: state.notFoundFlag,
    cookiePolicyAgreed: state.cookiePolicyAgreed,
    currentlyFetching: state.currentlyFetching,
    seoPageData: state.seoPageData,
    suggestions: state.suggestions,
    flags: state.flags,
    selectedAdvert: state.selectedAdvert,
    messageDismissed: state.messageDismissed,
    visitId: state.visitId,
  };
}

const App = connect(mapStateToProps)(ThemeWrapper);

export default hot(App);
