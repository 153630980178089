import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export default styled.ul`
  margin: 0 0 ${rem('30px')};
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`;
