import request from 'request';

/**
 * Action to send a save search email
 */
function saveSearch(body) {
  return dispatch => {
    // action to facilitate showing a spinner while request is being done
    dispatch({ type: 'SAVE_SEARCH_STARTED', data: true });

    return new Promise((resolve, reject) => {
      request.post('/api/save-search', body, (err, data) => {
        // If there is an error
        if (err) {
          reject(err);
          return dispatch({ type: 'SAVE_SEARCH_ERR', data, hasCatch: true });
        }

        resolve(data);
        return dispatch({ type: 'SEARCH_SAVED', data });
      });
    });
  };
}

/**
 * Updates the value of the showMobileSaveSearch prop which controls the visibility of the component in mobile
 * @param {Boolean}  toggle   The new toggled value of the prop
 */
function toggleMobileVisibility(toggle) {
  return {
    type: 'TOGGLE_MOBILE_SAVE_SEARCH',
    data: toggle,
  };
}

export { saveSearch, toggleMobileVisibility };
