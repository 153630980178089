/**
 * Pure function for populating the pages prop
 */
function pages(state = {}, action) {
  if (action.type === 'SEARCH_RESPONSE' && action.data) {
    return { ...action.data.pages };
  }

  return state;
}

export default pages;
