import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Heading = styled.h1`
  font-size: ${rem('21px')};
  max-width: ${rem('475px')};
  font-family: ${props => props.theme['main-header-font']}, sans-serif;
  color: ${props => props.theme.mediumGrey};
  margin: ${rem('15px')} auto 0;
  font-weight: 400;

  ${media.bpsMedium`
    font-size: ${rem('40px')};
    margin-top: ${rem('35px')};
  `} ${media.bpsLarge`
    margin: ${rem('40px')} 0 0;
    max-width: ${rem('480px')};
  `};
`;

export default Heading;
