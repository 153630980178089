import React from 'react';
import qs from 'query-string';
import uuid from 'uuid';

import { Advert } from '@oodlefinance/shared-components';

import { withRouter } from 'react-router';

import StandardFeaturedAdvert from '../StandardFeaturedAdvert';

import Wrapper from './Wrapper';

import { advertClicked } from 'services/event-tracking';
import runSearch from 'services/run-search';
import parseUrl from 'services/url/parse-url';
import { removeField, removeSearch } from 'services/url/manipulate-url';
import { UserActions } from 'actions';

const AdvertContainer = ({
  advert,
  index,
  pages,
  features,
  staticAd,
  dispatch,
  flags,
  creditCheck = {},
  location,
  history,
}) => {
  const onAdvertClick = (_, e) => {
    e.preventDefault();

    const { carId, advertData } = advert;
    advertClicked(carId, advertData[0], index + 1);

    const { hashedUri, advertId } = advertData[0];

    /*
      If the hashedUri is the car page, we are not going to an external site,
      so can just do an API call to show the car in the context of the current search
     */
    if (hashedUri && hashedUri.includes('/carId/')) {
      let query = parseUrl(
        removeSearch(removeField(location.pathname, 'carId')),
        location.search,
      );

      // Remove page query if present
      const queryObject = qs.parse(query);
      delete queryObject.page;
      query = qs.stringify(queryObject);

      // Add carId if not present
      if (!query.includes('carId=')) {
        query += `&carId=${advertId}`;
      }

      if (
        hashedUri.includes('pageType=car') &&
        !query.includes('pageType=car')
      ) {
        query += '&pageType=car';
      }

      runSearch(dispatch, query, features, history, {});
    } else {
      window.location = hashedUri;
    }
  };

  const onDealerTelephoneClick = () => {
    dispatch(
      UserActions.trackInteractions({
        interactions: [
          {
            id: uuid.v4(),
            eventName: 'search.advert-show-telephone.click',
            sourceUrl: window.location.href,
            impressionId: advert.impressionId,
            advertId: advert.advertId,
            dealerDomain: advert.dealerDomain,
          },
        ],
      }),
    );
  };

  const { advertData } = advert;

  const { sellerTags = [], dealerDomain } = advertData[0];

  const imageFlashClass =
    dealerDomain === 'www.sandicliffe.co.uk' ? 'flash_aa' : '';

  return (
    <>
      <Wrapper
        {...{
          featured: advert.idMatch,
          staticAd,
          features,
          safeAndSound: sellerTags.includes('safeandsound'),
          safeAndSoundVip: sellerTags.includes('safeandsoundvip'),
          imageFlashClass,
        }}
      >
        {advert.idMatch ? (
          <StandardFeaturedAdvert
            {...{
              advert,
              history,
            }}
          />
        ) : (
          <Advert
            key={`${advert.carId}-${index}-ad`}
            {...{
              index,
              pages,
              creditCheck,
              features,
              flags,
              featured: advert.idMatch,
              highlightTel: true,
              showLocation: true,
              monthlyCostDefault: false,
            }}
            {...advert}
            onAdvertClick={onAdvertClick}
            onDealerClick={() => {}}
            onDealerTelephoneReveal={
              features.showPhoneNumbersForPremiumSites
                ? onDealerTelephoneClick
                : () => {}
            }
          />
        )}
      </Wrapper>
    </>
  );
};

export default withRouter(AdvertContainer);
