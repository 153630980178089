import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Wrapper = styled.div`
  position: fixed;
  top: ${props => (props.messageDismissed ? '0' : rem('76px'))};
  left: 0;
  right: 0;
  background: ${props => props.theme.lighterGrey};
  padding: ${rem('10px')} 0 ${rem('12px')};
  text-align: center;
  z-index: 100;
  border-top: ${rem('1px')} solid #d3d3d3;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  transition: top 300ms ease;

  @media (min-width: ${rem('430px')}) {
    top: ${props => (props.messageDismissed ? '0' : rem('50px'))};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${media.bpTablet`
    top: ${props => (props.messageDismissed ? '0' : rem('70px'))};
    padding: ${rem('14px')} 0;
  `};
`;

export default Wrapper;
