import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('19px')};
  font-weight: 400;
  color: #a3a3a3;
  margin: ${rem('18px')} 0 ${rem('38px')};

  ${media.bpMedium`
    font-size: ${rem('24px')};
    margin: ${rem('15px')} 0 ${rem('32px')};
  `}
`;

export default Text;
