import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const ValuationWrapper = styled.div`
  margin-top: ${rem('23px')};
  margin-bottom: ${rem('23px')};
  color: ${props => props.theme.darkBlueGrey};

  &:first-child {
    margin-top: 0;
  }

  ${media.bpTablet`
    width: 25%;
    display: inline-block;
    text-align: center;
    margin-bottom: ${rem('58px')};
  `};
`;

export default ValuationWrapper;
