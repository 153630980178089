import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const AcceptedSection = styled.section`
  max-width: ${rem(`${1024 - 18 * 2}px`)};
  border-bottom: ${rem('1px')} solid #d2d2d2;
  margin: 0 auto;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
`;

export default AcceptedSection;
