import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Description = styled.p`
  font-size: ${rem('12px')};
  line-height: ${rem('20px')};
  color: #e5e5e5;
  margin-top: ${rem('15px')};

  a {
    color: #e5e5e5;
    text-decoration: none;
  }
`;

export default Description;
