import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('39px')};
  height: ${rem('34px')};

  ${media.bpTablet`
    width: ${rem('78px')};
    height: ${rem('68px')};
  `}
`;

const Path1 = styled.path`
  fill: #b3b3b3;
`;

const Path2 = styled.path`
  fill: #e86c60;
`;

const Path3 = styled.path`
  fill: #c64b4b;
`;

class WorkIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 68.2 68.1" x="0px" y="0px">
        <g>
          <Path1
            d="M10.4,68C9.6,68,9,67.4,9,66.6v-5.9c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v5.9
            C11.9,67.4,11.2,68,10.4,68z"
          />
          <Path1 d="M57.7,68c-0.8,0-1.5-0.7-1.5-1.5v-5.9c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v5.9C59.2,67.4,58.6,68,57.7,68z" />
          <Path2
            d="M57.7,47.3H10.4c-0.8,0-1.5-0.7-1.5-1.5V7.4C9,3.4,12.3,0,16.3,0h35.5c4.1,0,7.4,3.3,7.4,7.4v38.4
            C59.2,46.7,58.6,47.3,57.7,47.3z"
          />
          <Path3
            d="M62.2,62.1H6c-3.3,0-5.9-2.6-5.9-5.9v-16c0-4.5,3.2-8.5,7.7-9.1c5.4-0.7,10,3.5,10,8.8v4.4h32.5v-4.1
            c0-4.5,3.2-8.5,7.7-9.1c5.4-0.7,10,3.5,10,8.8v16.3C68.1,59.5,65.4,62.1,62.2,62.1z"
          />
        </g>
      </Svg>
    );
  }
}

export default WorkIcon;
