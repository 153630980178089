import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const InfoIcon = styled.img`
  width: auto;
  height: 1rem;
  position: absolute;
  left: ${rem('10px')};
  top: ${rem('10px')};
`;

export default InfoIcon;
