/**
 * Pure function for populating the searchString prop
 */
function searchString(state = '', action) {
  if (action.type && action.type.startsWith('SEARCH_RESPONSE')) {
    return action.data.searchString || state;
  }

  if (action.type === 'RESET_SEARCH_STRING') {
    return '';
  }

  return state;
}

export default searchString;
