import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const MenuButton = styled.div`
  padding: ${rem('5px')} ${rem('10px')};
  margin-right: ${rem('11px')};
  border-radius: ${rem('3px')};
  background: ${props => props.theme.white};
  border: 1px solid #d5d5d5;

  ${media.bpTablet`
    display: none;
  `}

  ${p =>
    p.pathname === '/' &&
    css`
      display: block;
      position: absolute;
      top: ${rem('10px')};
      left: ${rem('11px')};

      ${media.bpTablet`
          display: none;
        `}
    `}

  ${p =>
    p.pathname.startsWith('/search') &&
    css`
      display: none;
    `}
`;

export default MenuButton;
