import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const QuoteSection = styled.div`
  padding: ${rem('35px')} 0 ${rem('10px')};

  ${media.bpTablet`
    padding: ${rem('60px')} 0 0;
  `}
`;

export default QuoteSection;
