import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  display: none;

  ${media.bpTablet`
    display: block;
    position: absolute;
    top: ${rem('-45px')};
    right: ${rem('-32px')};
    width: ${rem('81px')};
    height: ${rem('81px')};
  `}
`;

const Polygon = styled.polygon`
  fill: url(#SVGID_1_);
`;

const Stop1 = styled.stop`
  stop-color: #ff8e4a;
`;

const Stop2 = styled.stop`
  stop-color: #ff6000;
`;

const Path1 = styled.path`
  fill: #ff6000;
`;

const Path2 = styled.path`
  fill: #ffffff;
`;

const Badge = () => (
  <Svg viewBox="0 0 78.5 78.4" x="0px" y="0px">
    <g>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="SVGID_1_"
        x1="38.7612"
        x2="38.7612"
        y1="1.4526"
        y2="77.0249"
      >
        <Stop1 offset="0" />
        <Stop2 offset="1" />
      </linearGradient>
      <Polygon
        points="38.8,72 23.1,77 15.6,62.4 1,54.9 6,39.2 1,23.6 15.6,16.1 23.1,1.5 38.8,6.5 54.4,1.5 61.9,16.1
        76.5,23.6 71.5,39.2 76.5,54.9 61.9,62.4 54.4,77   "
      />
      <Path1
        d="M53.9,2.7l6.9,13.6l0.3,0.6l0.6,0.3l13.6,6.9l-4.7,14.5l-0.2,0.6l0.2,0.6l4.7,14.5l-13.6,6.9l-0.6,0.3
        l-0.3,0.6l-6.9,13.6l-14.5-4.7l-0.6-0.2l-0.6,0.2l-14.5,4.7l-6.9-13.6l-0.3-0.6l-0.6-0.3L2.2,54.4l4.7-14.5l0.2-0.6l-0.2-0.6
        L2.2,24.1l13.6-6.9l0.6-0.3l0.3-0.6l6.9-13.6l14.5,4.7l0.6,0.2l0.6-0.2L53.9,2.7 M54.9,0.2L38.8,5.5L22.6,0.2l-7.7,15.1l-15.1,7.7
        L5,39.2l-5.2,16.2l15.1,7.7l7.7,15.1L38.8,73l16.2,5.2l7.7-15.1l15.1-7.7l-5.2-16.2l5.2-16.2l-15.1-7.7L54.9,0.2L54.9,0.2z"
      />
    </g>
    <g>
      <Path2 d="M21.8,26.1h-3.3l-0.7,2h-1.5l3.2-8.5h1.3l3.2,8.5h-1.5L21.8,26.1z M18.9,24.9h2.5l-1.2-3.5L18.9,24.9z" />
      <Path2
        d="M25.5,28.1v-8.5H28c0.8,0,1.4,0.2,2,0.5s1,0.8,1.4,1.4s0.5,1.3,0.5,2.1V24c0,0.8-0.2,1.5-0.5,2.1
        s-0.8,1.1-1.4,1.4s-1.3,0.5-2,0.5H25.5z M27,20.7v6.2h1c0.8,0,1.4-0.2,1.8-0.7s0.6-1.2,0.6-2.1v-0.5c0-0.9-0.2-1.6-0.6-2.1
        s-1-0.7-1.8-0.7H27z"
      />
      <Path2 d="M36.8,26.2l2.2-6.7h1.6l-3.1,8.5h-1.4L33,19.5h1.6L36.8,26.2z" />
      <Path2 d="M43.6,28.1h-1.5v-8.5h1.5V28.1z" />
      <Path2
        d="M52.4,25.3c-0.1,0.9-0.4,1.6-1,2.1s-1.4,0.8-2.3,0.8c-0.7,0-1.3-0.2-1.8-0.5s-0.9-0.8-1.2-1.4
        s-0.4-1.3-0.4-2.1v-0.8c0-0.8,0.1-1.5,0.4-2.1s0.7-1.1,1.2-1.4s1.1-0.5,1.8-0.5c0.9,0,1.7,0.3,2.3,0.8s0.9,1.2,1,2.2H51
        c-0.1-0.6-0.2-1.1-0.5-1.3s-0.7-0.4-1.3-0.4c-0.6,0-1.1,0.2-1.5,0.7s-0.5,1.2-0.5,2.1v0.8c0,0.9,0.2,1.6,0.5,2.1s0.8,0.7,1.5,0.7
        c0.6,0,1-0.1,1.3-0.4s0.5-0.7,0.6-1.3H52.4z"
      />
      <Path2 d="M59.2,24.3h-3.5v2.6h4.1v1.2h-5.6v-8.5h5.5v1.2h-4.1v2.4h3.5V24.3z" />
      <Path2 d="M23.3,44.1h-2v-9.7l-3,1v-1.7l4.7-1.7h0.3V44.1z" />
      <Path2
        d="M35.7,39c0,1.7-0.3,3-1,3.9c-0.6,0.9-1.6,1.3-3,1.3c-1.3,0-2.3-0.4-3-1.3c-0.7-0.9-1-2.1-1-3.8V37
        c0-1.7,0.3-3,1-3.9c0.7-0.9,1.6-1.3,3-1.3c1.3,0,2.3,0.4,3,1.3c0.6,0.8,1,2.1,1,3.8V39z M33.7,36.7c0-1.1-0.2-2-0.5-2.5
        c-0.3-0.5-0.8-0.8-1.5-0.8c-0.7,0-1.1,0.3-1.5,0.8c-0.3,0.5-0.5,1.3-0.5,2.3v2.7c0,1.1,0.2,2,0.5,2.5s0.8,0.8,1.5,0.8
        c0.6,0,1.1-0.3,1.4-0.8c0.3-0.5,0.5-1.3,0.5-2.4V36.7z"
      />
      <Path2
        d="M45.7,39c0,1.7-0.3,3-1,3.9c-0.6,0.9-1.6,1.3-3,1.3c-1.3,0-2.3-0.4-3-1.3c-0.7-0.9-1-2.1-1-3.8V37
        c0-1.7,0.3-3,1-3.9c0.7-0.9,1.6-1.3,3-1.3c1.3,0,2.3,0.4,3,1.3c0.6,0.8,1,2.1,1,3.8V39z M43.7,36.7c0-1.1-0.2-2-0.5-2.5
        c-0.3-0.5-0.8-0.8-1.5-0.8c-0.7,0-1.1,0.3-1.5,0.8c-0.3,0.5-0.5,1.3-0.5,2.3v2.7c0,1.1,0.2,2,0.5,2.5s0.8,0.8,1.5,0.8
        c0.6,0,1.1-0.3,1.4-0.8c0.3-0.5,0.5-1.3,0.5-2.4V36.7z"
      />
      <Path2
        d="M47.7,34.3c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.9-0.7c0.8,0,1.4,0.2,1.9,0.7c0.5,0.5,0.7,1.1,0.7,1.8V35
        c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.4-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8V34.3z M49.1,35
        c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4s0.3-0.6,0.3-1v-0.6c0-0.4-0.1-0.7-0.3-0.9
        c-0.2-0.2-0.5-0.4-0.8-0.4s-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.6-0.3,1V35z M50.7,43.2l-1-0.6l5.9-9.4l1,0.6L50.7,43.2z M53.5,41.1
        c0-0.7,0.2-1.3,0.7-1.8s1.1-0.7,1.8-0.7c0.8,0,1.4,0.2,1.8,0.7c0.5,0.5,0.7,1.1,0.7,1.9v0.6c0,0.7-0.2,1.3-0.7,1.8
        c-0.5,0.5-1.1,0.7-1.9,0.7s-1.4-0.2-1.9-0.7c-0.5-0.5-0.7-1.1-0.7-1.8V41.1z M55,41.8c0,0.4,0.1,0.7,0.3,0.9
        c0.2,0.2,0.5,0.4,0.8,0.4c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-1v-0.6c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.8-0.3
        c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.6-0.3,1V41.8z"
      />
      <Path2 d="M26.3,55h-4.9v5.1h-2.1V48H27v1.7h-5.6v3.6h4.9V55z" />
      <Path2
        d="M33.4,55.4h-2.3v4.6H29V48h4.2c1.4,0,2.5,0.3,3.2,0.9c0.8,0.6,1.1,1.5,1.1,2.7c0,0.8-0.2,1.5-0.6,2
        c-0.4,0.5-0.9,1-1.6,1.3l2.7,5v0.1h-2.2L33.4,55.4z M31.1,53.7h2.2c0.7,0,1.3-0.2,1.7-0.5c0.4-0.4,0.6-0.8,0.6-1.5
        c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.5-1.6-0.5h-2.2V53.7z"
      />
      <Path2 d="M47,54.7h-5v3.7h5.8v1.7h-7.9V48h7.8v1.7H42V53h5V54.7z" />
      <Path2 d="M56.9,54.7h-5v3.7h5.8v1.7h-7.9V48h7.8v1.7H52V53h5V54.7z" />
    </g>
  </Svg>
);

export default Badge;
