import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const OfferTitle = styled.h2`
  font-size: ${rem('20px')};
  margin-bottom: ${rem('10px')};
  margin-top: 0;

  ${media.bpTablet`
    font-size: ${rem('24px')};
    margin-bottom : ${rem('15px')};
  `}
`;

export default OfferTitle;
