import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const ScrollIcon = styled.div`
  width: ${rem('33px')};
  height: ${rem('33px')};
  background-color: #fff;
  position: absolute;
  bottom: ${rem('-17px')};
  left: 50%;
  transform: translateX(-50%);

  ${media.bpTablet`
    width: ${rem('48px')};
    height: ${rem('48px')};
    bottom: ${rem('-31px')};
  `}

  svg {
    width: ${rem('9.5px')};
    height: ${rem('5.5px')};
    margin-top: ${rem('14px')};

    ${media.bpTablet`
      width: ${rem('14px')};
      height: ${rem('8px')};
    `}
  }
`;

export default ScrollIcon;
