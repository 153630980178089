import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Title = styled.h1`
  font-size: ${rem('14.5px')};
  font-weight: 500;
  margin: ${rem('30px')} auto 0;
  text-align: center;

  ${media.bpTablet`
    font-size: ${rem('26px')};
    margin: ${rem('50px')} auto 0;
  `}
`;

export default Title;
