import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Button = styled.button`
  padding: ${({ verticalPadding = '10px' }) => rem(verticalPadding)}
    ${({ horizontalPadding = '40px' }) => rem(horizontalPadding)};
  font-size: ${rem('11px')};
  text-transform: uppercase;
  background: ${props =>
    props.background ? props.background : props.theme.orange};
  border: none;
  border-radius: ${rem('4px')};
  color: #fff;

  &:active,
  &:hover {
    background: ${props =>
      props.backgroundHover ? props.backgroundHover : props.theme.orangeHover};
    color: #fff;
    cursor: pointer;
  }
`;

export default Button;
