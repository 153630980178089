import exenv from 'exenv';

/**
 * Function to get an element from either a HTMLCollection or array like object
 * using the name attribute of the given elements
 *
 * Safari doesn't follow spec and returns a NodeList instead of a HTMLCollection
 * for document.getElementsByTagName()
 */
function getElement(elements, name) {
  if (elements.constructor.name === 'HTMLCollection') {
    return elements.namedItem(name);
  }

  for (let i = elements.length - 1; i >= 0; i--) {
    if (elements[i].name === name) {
      return elements[i];
    }
  }

  return null;
}

/**
 * Functional component to update the title and meta tags of the plan
 */
const Meta = ({ title = null, meta = [] }) => {
  // Ensure we can use the dom
  if (!exenv.canUseDOM) {
    return null;
  }

  // Check if a title element exists
  let titleEl = document.getElementsByTagName('TITLE')[0];

  // Create a title element if it doesn't exist
  if (!titleEl) {
    titleEl = document.createElement('title');
    document.head.appendChild(titleEl);
  }

  // Add the new title to the title element
  titleEl.innerHTML = title;

  // Get all the meta elements
  const metas = document.getElementsByTagName('META');

  // Loop over the meta tags
  for (let i = meta.length - 1; i >= 0; i--) {
    // Check that the meta tag exists
    let tag = getElement(metas, meta[i].name);

    // Create the meta tag if it doesn't exist
    if (!tag) {
      tag = document.createElement('meta');
      document.head.appendChild(tag);
    }

    // Add content to the meta tag
    tag.content = meta[i].content;
  }

  return null;
};

export default Meta;
