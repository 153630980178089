import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: ${rem('15px')};
  font-size: ${rem('13px')};
  color: ${props => props.theme.mediumGrey};
  line-height: 1.4em;
`;

export default Title;
