import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    childFilter: PropTypes.string,
    clearUrl: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    histogram: PropTypes.arrayOf(PropTypes.shape({})),
    parentFilter: PropTypes.string,
    queryName: PropTypes.string.isRequired,
    selected: PropTypes.shape({
      displayValue: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    templateUrl: PropTypes.string,
  }),
);
