import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Text = styled.p`
  font-size: ${rem('13px')};
  line-height: ${rem('19px')};
  max-width: ${rem('500px')};
  margin-top: ${rem('15px')};
  margin-bottom: ${rem('26px')};
  font-weight: 300;
  color: ${props => props.theme.mediumGrey};
  margin-left: auto;
  margin-right: auto;

  ${media.bpsMedium`
    font-size: ${rem('16px')};
    line-height: ${rem('26px')};
    margin-top: ${rem('35px')};
    margin-bottom: ${rem('35px')};
  `} ${media.bpTablet`
    font-size: ${rem('24px')};
    line-height: ${rem('38px')};
    margin: ${rem('15px')} 0 ${rem('26px')};
    max-width: ${rem('442px')};

    span {
      font-weight: 500;
    }
  `};
`;

export default Text;
