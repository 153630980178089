/**
 * Pure function for populating the rangeSliderValues prop
 */
function rangeSliderValues(state = {}, action) {
  if (action.type === 'FILTER_RESPONSE') {
    return { ...state, ...action.data };
  }

  return state;
}

export default rangeSliderValues;
