import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Envelope = styled.img`
  width: ${rem('30px')};
  height: ${rem('23px')};
  margin-top: ${rem('30px')};
  margin-bottom: ${rem('20px')};
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
`;

export default Envelope;
