import React from 'react';
import styled, { css } from 'styled-components';

import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Container = styled.div`
  display: none;

  ${p =>
    p.pathname.startsWith('/search') &&
    css`
      padding: 0 ${rem('9px')} 0 ${rem('19px')};
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        width: ${rem('18px')};
        height: ${rem('18px')};
      }
      ${media.bpTablet`
        display: none;
      `}
    `}
`;

const Path = styled.path`
  fill: ${props => props.theme.darkBlueGrey};
`;

const FilterIcon = ({ pathname, onClick }) => (
  <Container {...{ pathname, onClick }}>
    <svg viewBox="0 0 16 16" x="0px" y="0px">
      <g>
        <Path
          d="M16,3c0-2.9-7.2-3-8-3S0,0.1,0,3c0,0.5,0.2,0.8,0.5,1.2L6,10.4V15c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-4.6
          l5.5-6.2C15.8,3.9,16,3.5,16,3z M8,2c3.1,0,5.2,0.6,5.8,1C13.2,3.4,11.1,4,8,4S2.8,3.4,2.2,3C2.8,2.6,4.9,2,8,2z"
        />
      </g>
    </svg>
  </Container>
);

export default FilterIcon;
