import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Svg = styled.svg`
  width: ${rem('26px')};
  height: ${rem('34px')};

  ${media.bpTablet`
    width: ${rem('52px')};
    height: ${rem('68px')};
  `}
`;

const Path1 = styled.path`
  fill: #a67c52;
`;

const Path2 = styled.path`
  fill: #87613e;
`;

const Path3 = styled.path`
  fill: #efd358;
`;

class PriceIcon extends React.PureComponent {
  render() {
    return (
      <Svg viewBox="0 0 49.4 68.1" x="0px" y="0px">
        <g>
          <Path1
            d="M48.1,48.8C48.1,48.8,48.1,48.8,48.1,48.8L35,19.2l5.8-17.3C41,1.5,40.9,1,40.6,0.6C40.3,0.2,39.9,0,39.4,0
            H9.8C9.3,0,8.9,0.2,8.6,0.6C8.3,1,8.3,1.5,8.4,1.9l5.8,17.3L1.1,48.8c-1.8,4.3-1.4,9.2,1.2,13.1c2.6,3.9,6.9,6.2,11.6,6.2h21.4
            c4.7,0,9-2.3,11.6-6.2C49.5,58,49.9,53.1,48.1,48.8z"
          />
          <Path2
            d="M24.6,20.7c-0.8,0-1.5-0.7-1.5-1.5v-8.9c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v8.9
            C26.1,20.1,25.4,20.7,24.6,20.7z"
          />
          <Path3 d="M35,20.7H14.3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5H35c0.8,0,1.5,0.7,1.5,1.5S35.8,20.7,35,20.7z" />
          <g>
            <Path3
              d="M15.5,57.8c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h3.7v-7.3h-3.7c-0.8,0-1.4-0.6-1.4-1.4
              c0-0.8,0.6-1.4,1.4-1.4h3.7V40c0-4.3,3.5-7.7,7.7-7.7c4.3,0,7.7,3.5,7.7,7.7c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4
              c0-2.7-2.2-4.9-4.9-4.9S22,37.3,22,40V45h6.2c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4H22v7.3h11.3c0.8,0,1.4,0.6,1.4,1.4
              c0,0.8-0.6,1.4-1.4,1.4H15.5z"
            />
            <Path3
              d="M26.9,32.4c4.2,0,7.6,3.4,7.6,7.6c0,0.7-0.6,1.3-1.3,1.3S32,40.7,32,40c0-2.8-2.3-5.1-5.1-5.1
              c-2.8,0-5.1,2.3-5.1,5.1v5.1h6.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3h-6.3v7.6h11.4c0.7,0,1.3,0.6,1.3,1.3
              s-0.6,1.3-1.3,1.3H15.5c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h3.8v-7.6h-3.8c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h3.8
              V40C19.3,35.8,22.7,32.4,26.9,32.4 M26.9,32.2c-4.3,0-7.8,3.5-7.8,7.8v4.8h-3.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h3.5
              v7.1h-3.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h17.7c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H22.1v-7.1h6.1
              c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-6.1V40c0-2.7,2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8c0,0.8,0.7,1.5,1.5,1.5
              s1.5-0.7,1.5-1.5C34.7,35.7,31.2,32.2,26.9,32.2L26.9,32.2z"
            />
          </g>
        </g>
      </Svg>
    );
  }
}

export default PriceIcon;
