import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Detail = styled.p`
  margin-bottom: ${rem('15px')};
  font-size: ${rem('13px')};
  color: ${props => props.theme.mediumGrey};

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Detail;
