import cookie from 'react-cookie';

/*
  Updates the cookiePolicyAgreed
 */
function acceptCookiePolicy() {
  // Set a cookiePolicyAgreed cookie for 1 year
  cookie.save('cookiePolicyAgreed', 'true', {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });

  return {
    type: 'COOKIE_POLICY_AGREED',
    data: true,
  };
}

export { acceptCookiePolicy };
