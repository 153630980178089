import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import media from 'styles/mixins/media';

const Step = styled.div`
  display: flex;
  align-items: center;
  max-width: ${rem('580px')};
  margin: 0 auto ${rem('30px')};

  &:last-child {
    margin-bottom: 0;
  }

  ${media.bpTablet`
    margin-bottom: ${rem('40px')};
  `}
`;

export default Step;
