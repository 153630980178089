import { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import clearFix from 'polished/lib/mixins/clearFix';

import media from './media';

export default css`
  ${clearFix()}

  padding-left: ${rem('18px')};
  padding-right: ${rem('18px')};
  margin: 0 auto;

  ${media.bpTablet`
    max-width: ${rem('1150px')};
    padding-left: 0;
    padding-right: 0;
  `}
`;
