function valuation(state = {}, action) {
  if (action.type === 'CAR_VALUATION') {
    // TODO: Add key after data when backend adds car details
    return { ...state, ...action.data };
  }

  return state;
}

export default valuation;
