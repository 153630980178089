import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ListItem from './ListItem';

const Wrapper = styled.ul`
  margin-left: ${rem('20px')};
  margin-right: ${rem('20px')};
  padding: 0;
  list-style: none;
`;

const List = ({ list, viewMore }) => (
  <Wrapper>
    {list.map((l, i) =>
      l.map(item => <ListItem key={item.name} {...{ ...item, viewMore, i }} />),
    )}
  </Wrapper>
);

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  viewMore: PropTypes.bool.isRequired,
};

export default List;
