import styled from 'styled-components';

export const Header = styled.h2`
  border-bottom: 1px solid #e4e5e6;
  font-size: 1.25rem;
  font-family: ${p => p.theme['main-header-font']}, sans-serif;
  font-weight: 400;
  color: ${p => (p.orange ? p.theme.orange : p.theme.mediumGrey)};
  text-align: center;
  padding: 0 0 1.25rem;
  margin: 0;
`;
