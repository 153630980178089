import React from 'react';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const Svg = styled.svg`
  width: ${rem('59px')};
  height: ${rem('31px')};

  @media (min-width: ${rem('515px')}) {
    width: ${rem('52px')};
    height: ${rem('28px')};
  }
`;

const Path1 = styled.path`
  fill: #70747a;
`;

const Path2 = styled.path`
  fill: ${props => props.theme.white};
`;

const LinkedIn = () => (
  <Svg viewBox="0 0 52 28">
    <g>
      <Path1
        d="M38,2c6.6,0,12,5.4,12,12c0,6.6-5.4,12-12,12H14C7.4,26,2,20.6,2,14C2,7.4,7.4,2,14,2H38 M38,0H14
          C6.3,0,0,6.3,0,14v0c0,7.7,6.3,14,14,14h24c7.7,0,14-6.3,14-14v0C52,6.3,45.7,0,38,0L38,0z"
      />
    </g>
    <g>
      <Path2
        d="M24.3,12.2h2.1v1h0c0.3-0.6,1-1.2,2.1-1.2c2.3,0,2.7,1.5,2.7,3.4v3.9h-2.2v-3.5c0-0.8,0-1.9-1.2-1.9
          c-1.2,0-1.3,0.9-1.3,1.8v3.5h-2.2V12.2z"
      />
      <Path2
        d="M20.7,12.2h2.2v7.2h-2.2V12.2z M21.8,8.6c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3
          C20.5,9.2,21.1,8.6,21.8,8.6"
      />
    </g>
  </Svg>
);

export default LinkedIn;
